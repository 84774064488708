/* eslint-disable */
import removeScientificNotation from './removeScientificNotation';

function thousandDivider(x, decPlaces = 4, currency?) {
  const decimalPlaces = currency === 'UZS' ? 0 : decPlaces;
  const formatted = x ? (x.toString().includes('e') ? removeScientificNotation(x) : x) : x;
  const number = Number(formatted) ? parseFloat(Number(formatted)?.toFixed(decimalPlaces)) : 0;
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '0';
}
export default thousandDivider;

export function fromThousandDivider(formattedNumber: string): number {
  const cleanNumberString = formattedNumber.replace(/\s+/g, '').replace(/,/g, '');
  const parsedNumber = parseFloat(cleanNumberString);
  return isNaN(parsedNumber) ? 0 : parsedNumber;
}
