/* eslint-disable */
const clientReportTableHeaders = {
  columns: [
    {
      headerName: 'Дата регистрации',
      field: 'registration_date',
      hide: false,
      is_main: true,
      lockPosition: 'left',
      suppressMovable: false,
    },
    {
      headerName: 'Магазин регистрации',
      field: 'registration_shop_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'ФИО клиента',
      field: 'customer_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Дата рождения',
      field: 'birth_date',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Телефон',
      field: 'customer_phones',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Группы и теги',
      field: 'customer_groups',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Новый клиент (да / нет)',
      field: 'is_new_client',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Возвращающийся клиент (да / нет)',
      field: 'is_returned_client',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Продажи (всего)',
      field: 'total_sale_count',
      hide: false,
    },
    {
      headerName: 'Обмены (Всего)',
      field: 'total_exchange_count',
      hide: false,
    },
    {
      headerName: 'Возвраты (Всего)',
      field: 'total_return_count',
      hide: false,
    },
    {
      headerName: 'Кол-во купленных товаров (всего)',
      field: 'total_measurement_value',
      hide: false,
    },
    {
      headerName: 'Общая сумма покупок (всего)',
      field: 'total_purchase_sum',
      hide: false,
    },
    {
      headerName: 'Среднее кол-во товаров в чеке (всего)',
      field: 'total_average_measurement_value',
      hide: false,
    },
    {
      headerName: 'Средний чек (всего)',
      field: 'total_average_purchase_sum',
      hide: false,
    },
    {
      headerName: 'Средняя скидка (всего)',
      field: 'total_average_purchase_discount',
      hide: false,
    },
    {
      headerName: 'Кол-во возвращенных товаров (всего)',
      field: 'total_returned_measurement_value',
      hide: false,
    },
    {
      headerName: 'Сумма возвращенных товаров (всего)',
      field: 'total_returned_sum',
      hide: false,
    },
    {
      headerName: 'Сумма по цене продажи без учета скидки (всего)',
      field: 'total_retail_sum',
      hide: false,
    },
    {
      headerName: 'Сумма по цене продажи с учетом скидки (всего)',
      field: 'total_sale_sum',
      hide: false,
    },
    {
      headerName: 'Продано товаров без учета возвратов (всего)',
      field: 'total_sold_without_return',
      hide: false,
    },
    {
      headerName: 'Продажи (в выбранном периоде)',
      field: 'period_sale_count',
      hide: false,
    },
    {
      headerName: 'Обмены (в выбранном периоде)',
      field: 'period_exchange_count',
      hide: false,
    },
    {
      headerName: 'Возвраты (в выбранном периоде)',
      field: 'period_return_count',
      hide: false,
    },
    {
      headerName: 'Куплено товаров в выбранном периоде',
      field: 'period_measurement_value',
      hide: false,
    },
    {
      headerName: 'Сумма покупок в выбранном периоде',
      field: 'period_purchase_sum',
      hide: false,
    },
    {
      headerName: 'Среднее кол-во товаров в чеке в выбранном периоде',
      field: 'period_average_measurement_value',
      hide: false,
    },
    {
      headerName: 'Средний чек в выбранном периоде',
      field: 'period_average_purchase_sum',
      hide: false,
    },
    {
      headerName: 'Средняя скидка в выбранном периоде',
      field: 'period_average_purchase_discount',
      hide: false,
    },
    {
      headerName: 'Кол-во возвращенных товаров в выбранном периоде',
      field: 'period_returned_measurement_value',
      hide: false,
    },
    {
      headerName: 'Сумма возвращенных товаров в выбранном периоде',
      field: 'period_returned_sum',
      hide: false,
    },
    {
      headerName: 'Сумма по цене продажи без учета скидки в выбранном периоде',
      field: 'period_retail_sum',
      hide: false,
    },
    {
      headerName: 'Сумма по цене продажи с учетом скидки в выбранном периоде',
      field: 'period_sale_sum',
      hide: false,
    },
    {
      headerName: 'Продано товаров без учета возвратов в выбранном периоде',
      field: 'period_sold_without_return',
      initialWidth: 540,
      hide: false,
    },
  ],
};
export default clientReportTableHeaders;
