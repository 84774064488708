/* eslint-disable */
import i18n from 'i18n';
import { ContactInfo } from 'layouts/Navbar/NeedHelpButtonNew/ContactInfo';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';
import { faComment, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, Popover, Typography, useTheme } from '@mui/material';
import { CustomButton } from './CustomButton';
import { useStyles } from './useStyles';
import { useGetCurrentUserQuery } from '@/shared/hooks/user';

interface INeedHelpPopoverProps {
  id: string;
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isMini?: boolean;
  isAbsolute?: boolean;
}

export const NeedHelpPopover = ({ id, anchorEl, open, handleClose, handleClick, isMini, isAbsolute }: INeedHelpPopoverProps) => {
  const { palette } = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { company } = useSelector(state => state);
  const [isOpenContactInfo, setIsOpenContactInfo] = useState(false);

  const { current_shop_name } = company;
  const { data: user } = useGetCurrentUserQuery();

  if (!window.chaport) {
    return null;
  }

  const items = [
    {
      name: t('menu.settings.shops.shop_create.contacts'),
      icon: faInfoCircle,
      onClick: () => {
        setIsOpenContactInfo(true);
        handleClose();
      },
    },
    {
      name: t('chaport.telegram'),
      icon: faTelegram,
      onClick: () => {
        window.open('https://t.me/billzsupport_bot', '_blank');
      },
    },
    {
      name: t('chaport.whatsapp'),
      icon: faWhatsappSquare,
      onClick: () => {
        window.open('https://wa.me/+77018307144', '_blank');
      },
    },
    {
      name: t('chaport.online_chat'),
      icon: faComment,
      onClick: () => {
        window.chaport.q('open');
      },
    },
  ];

  useEffect(() => {
    if (!!window.chaport) {
      window.chaport.on('ready', function () {
        window.chaport.setVisitorData({
          referrer: 'BILLZ 2.0',
          name: user?.first_name,
          phone: user?.phone_number,
          email: `${current_shop_name} | ${user?.roles?.map(el => el?.role?.name).join(', ')}`,
          language: i18n.language.toUpperCase(),
        });
        window.chaport.newPageView({
          url: window.location.href,
          title: window.location.href,
        });
      });
    }
  }, [user]);

  useEffect(() => {
    const chaportWindow = document.querySelector('.chaport-container.chaport-container-positioned .chaport-window');
    if (chaportWindow) {
      if (!isAbsolute) {
        chaportWindow.classList.add('chaport-non-absolute-position');
        chaportWindow.classList.remove('chaport-absolute-position');
      } else {
        chaportWindow.classList.remove('chaport-non-absolute-position');
        chaportWindow.classList.add('chaport-absolute-position');
      }
    }
  }, [isAbsolute]);

  const pl = isAbsolute ? 32 : isMini ? 0 : 8;
  const pb = isAbsolute ? 96 : 16;

  return (
    <>
      <Popover
        id={id}
        open={open}
        className={`${classes.popoverContainer} ${isMini && !isAbsolute ? classes.popoverMiniContainer : ''}`}
        anchorEl={anchorEl}
        onClose={() => {
          handleClose();
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          className={classes.socialButtons}
          style={{
            paddingLeft: `${pl}px`,
            paddingBottom: `${pb}px`,
          }}
        >
          {items
            .filter(el => el)
            .map((item, index) => (
              <Box className={classes.socialButton} onClick={item.onClick} key={item.name}>
                <Box className={classes.iconContainer}>
                  <FontAwesomeIcon icon={item.icon} color={palette.blue[600]} />
                </Box>
                <Typography>{item.name}</Typography>
              </Box>
            ))}
        </Box>
        {isAbsolute ? null : <CustomButton isOpen={!isMini} classes={classes} open={open} handleClick={handleClick} overlay />}
      </Popover>
      <ContactInfo isOpen={isOpenContactInfo} onClose={() => setIsOpenContactInfo(false)} />
    </>
  );
};
