import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserServiceNS, UserService } from '@/shared/services';
import { error, success } from 'shared/utils/toast';

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: UserServiceNS.CreateIntegratedUserDTO) => (await UserService.postCreateIntegratedUser(params).fetch()).data,
    onSuccess: () => {
      success('integrations.success_create');
      queryClient.invalidateQueries({
        queryKey: ['USERS', 'LIST'],
      });
    },
    onError: (errorData: { status: number }) => {
      const statusCode: number = errorData?.status;

      if (statusCode === 500) {
        error('integrations.error.create');
        return;
      }

      if (statusCode === 403) {
        error('integrations.error.no_create_access');
        return;
      }
      error('integrations.error.create');
    },
  });
};
