/* eslint-disable */
import { request } from '../axios';

const rounding = {
  getAll: company_id => request.get(`v1/rounding?company_id=${company_id}&limit=100`),
  create: data => request.post(`v1/rounding`, data),
  getSingle: id => request.get(`v1/rounding/${id}`),
  update: data => request.put(`v1/rounding/${data.id}`, data.data),
  delete: id => request.delete(`v1/rounding/${id}/variant`),
  getActive: id => request.get(`v1/active-rounding/${id}`),
};

export default rounding;
