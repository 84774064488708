import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import BackArrowIcon from 'shared/icons/BackArrowIcon';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
    '& .MuiDialog-paper.MuiDialog-paper': {
      width: props => (props.maxWidth ? 'auto' : '100%'),
      maxWidth: props => props.maxWidth || 784,
      overflow: ({ overflowVisible }) => (overflowVisible ? 'visible' : 'hidden'),
      boxShadow: 'none !important',
      borderRadius: 24,
      maxHeight: 'calc(100vh - 64px)',
      zIndex: 15,
    },
  },
  back_button: {
    minWidth: '48px !important',
    height: '48px !important',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50% !important',
    backgroundColor: theme.palette.gray[100] + ' !important',
    '&:hover': {
      backgroundColor: theme.palette.gray[101] + ' !important',
    },
    '& svg': {
      fill: theme.palette.blue[500] + ' !important',
    },
  },
  header: {
    padding: '24px 56px !important',
    '&.MuiTypography-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    borderBottom: `2px solid ${theme.palette.gray[200]}`,
  },
  border: {
    height: 2,
    borderRadius: 1,
    background: theme.palette.gray[200],
  },
}));

function StyledDialog({
  onClose,
  open,
  reset,
  children,
  title = 'menu.settings.profile.changing_password',
  buttonLabel,
  buttonId,
  disabled,
  isLoading,
  formId,
  customOnSubmit,
  customButtons,
  maxWidth,
  overflowVisible,
  titleStyles,
  customTitle,
}) {
  const { t } = useTranslation();
  const classes = useStyles({ overflowVisible, maxWidth });
  const handleClose = () => {
    onClose();
    if (reset) {
      reset();
    }
  };

  return (
    <Dialog disableScrollLock disableRestoreFocus open={open} className={classes.root} onClose={handleClose}>
      <DialogTitle className={classes.header}>
        <Button onClick={handleClose} className={classes.back_button}>
          <BackArrowIcon />
        </Button>
        {customTitle || (
          <Typography variant="h4" style={titleStyles}>
            {t(title)}
          </Typography>
        )}
        {customButtons ||
          (buttonLabel ? (
            <Button variant="contained" size="small" type="submit" form={formId} disabled={disabled} onClick={customOnSubmit} id={buttonId}>
              {isLoading ? <CircularProgress size={24} color="inherit" /> : buttonLabel}
            </Button>
          ) : (
            <span />
          ))}
      </DialogTitle>

      {children}
    </Dialog>
  );
}

StyledDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default StyledDialog;
