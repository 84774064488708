import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './CustomLink.styles';
import ArrowForward from '../../../shared/icons/ArrowForward';
import { Link, Typography } from '../../../shared/ui';

type CustomLinkProps = {
  title: string;
  description: string;
  link: string;
  icon: IconProp;
  color: string;
};

const CustomLink = ({ title, link, icon, description, color }: CustomLinkProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Link key={title} to={link}>
      <div className={classes.root}>
        <div className={classes.block}>
          <Box className={classes.icon} bgcolor={color}>
            <FontAwesomeIcon icon={icon} color="white" size="xl" />
          </Box>
          <div>
            <Typography className={classes.mb8}>{title}</Typography>
            <Typography color="textSecondary">{description}</Typography>
          </div>
        </div>
        <Typography className={classes.actions}>
          {t('buttons.set')} <ArrowForward className={classes.arrowIcon} />
        </Typography>
      </div>
    </Link>
  );
};

export default CustomLink;
