/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

interface OrderAcceptTableHeaderState {
  columns: any[];
}

export const initialState: OrderAcceptTableHeaderState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'Заказано',
      accessor: 'ordered_measurement_value',
      is_active: true,
    },
    {
      Header: 'Остатки',
      accessor: 'left',
      is_active: true,
    },
    {
      Header: 'Цена поставки',
      accessor: 'price',
      is_active: true,
    },
    {
      Header: 'Цена продажи',
      accessor: 'sale_price',
      is_active: true,
    },
  ],
};

const orderAcceptTableHeaderSlice = createSlice({
  name: 'orderAcceptTableHeader',
  initialState,
  reducers: {
    updateTable: (state, action) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    resetTable: () => {
      return initialState;
    },
  },
});

export const { updateTable, resetTable } = orderAcceptTableHeaderSlice.actions;
export default orderAcceptTableHeaderSlice.reducer;
