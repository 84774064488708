/* eslint-disable */
const BagIcon = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {props?.gray ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3657 18.0901H20.9505C22.6313 18.0901 24 19.4754 24 21.1767C24 21.1767 23.952 21.8969 23.936 22.8998C23.9344 22.9792 23.8959 23.057 23.8327 23.104C23.4477 23.3883 23.0955 23.6233 23.0635 23.6395C21.7349 24.5306 20.1909 25.1576 18.5461 25.4695C18.4388 25.4906 18.3332 25.4347 18.2787 25.3391C17.8177 24.5403 16.9565 24.0202 15.996 24.0202C15.0419 24.0202 14.1727 24.5346 13.698 25.3342C13.6428 25.4282 13.5388 25.4825 13.4323 25.4622C11.8011 25.1495 10.2571 24.5233 8.93647 23.6476L8.16808 23.1129C8.10405 23.0724 8.06403 22.9995 8.06403 22.9184C8.04002 22.5053 8 21.1767 8 21.1767C8 19.4754 9.36868 18.0901 11.0495 18.0901H12.6263C12.7784 16.9154 13.7629 16 14.9635 16H17.0285C18.2291 16 19.2136 16.9154 19.3657 18.0901ZM14.9635 17.2152C14.4272 17.2152 13.971 17.5878 13.8429 18.0901H18.1491C18.021 17.5878 17.5648 17.2152 17.0285 17.2152H14.9635Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7279 24.6522L23.6958 24.6684C22.079 25.754 20.1341 26.475 18.093 26.7747C17.8049 26.8152 17.5168 26.6289 17.4367 26.3372C17.2606 25.6729 16.6923 25.2355 16.012 25.2355H15.988C15.3077 25.2355 14.7394 25.6729 14.5633 26.3372C14.4832 26.6289 14.1951 26.8152 13.907 26.7747C11.8659 26.475 9.92096 25.754 8.30415 24.6684C8.29615 24.6603 8.21611 24.6117 8.15208 24.6522C8.08004 24.6927 8.08004 24.7899 8.08004 24.7899L8.13607 28.9215C8.13607 30.6228 9.49675 32 11.1776 32H20.8144C22.4952 32 23.8559 30.6228 23.8559 28.9215L23.92 24.7899C23.92 24.7899 23.92 24.6927 23.8479 24.6522C23.8079 24.6279 23.7599 24.636 23.7279 24.6522ZM16.5963 28.0466C16.5963 28.3869 16.3322 28.6542 15.996 28.6542C15.6678 28.6542 15.3957 28.3869 15.3957 28.0466V27.0015C15.3957 26.6694 15.6678 26.394 15.996 26.394C16.3322 26.394 16.5963 26.6694 16.5963 27.0015V28.0466Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3657 18.0901H20.9505C22.6313 18.0901 24 19.4754 24 21.1767C24 21.1767 23.952 21.8969 23.936 22.8998C23.9344 22.9792 23.8959 23.057 23.8327 23.104C23.4477 23.3883 23.0955 23.6233 23.0635 23.6395C21.7349 24.5306 20.1909 25.1576 18.5461 25.4695C18.4388 25.4906 18.3332 25.4347 18.2787 25.3391C17.8177 24.5403 16.9565 24.0202 15.996 24.0202C15.0419 24.0202 14.1727 24.5346 13.698 25.3342C13.6428 25.4282 13.5388 25.4825 13.4323 25.4622C11.8011 25.1495 10.2571 24.5233 8.93647 23.6476L8.16808 23.1129C8.10405 23.0724 8.06403 22.9995 8.06403 22.9184C8.04002 22.5053 8 21.1767 8 21.1767C8 19.4754 9.36868 18.0901 11.0495 18.0901H12.6263C12.7784 16.9154 13.7629 16 14.9635 16H17.0285C18.2291 16 19.2136 16.9154 19.3657 18.0901ZM14.9635 17.2152C14.4272 17.2152 13.971 17.5878 13.8429 18.0901H18.1491C18.021 17.5878 17.5648 17.2152 17.0285 17.2152H14.9635Z"
          fill="#1F78FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7279 24.6522L23.6958 24.6684C22.079 25.754 20.1341 26.475 18.093 26.7747C17.8049 26.8152 17.5168 26.6289 17.4367 26.3372C17.2606 25.6729 16.6923 25.2355 16.012 25.2355H15.988C15.3077 25.2355 14.7394 25.6729 14.5633 26.3372C14.4832 26.6289 14.1951 26.8152 13.907 26.7747C11.8659 26.475 9.92096 25.754 8.30415 24.6684C8.29615 24.6603 8.21611 24.6117 8.15208 24.6522C8.08004 24.6927 8.08004 24.7899 8.08004 24.7899L8.13607 28.9215C8.13607 30.6228 9.49675 32 11.1776 32H20.8144C22.4952 32 23.8559 30.6228 23.8559 28.9215L23.92 24.7899C23.92 24.7899 23.92 24.6927 23.8479 24.6522C23.8079 24.6279 23.7599 24.636 23.7279 24.6522ZM16.5963 28.0466C16.5963 28.3869 16.3322 28.6542 15.996 28.6542C15.6678 28.6542 15.3957 28.3869 15.3957 28.0466V27.0015C15.3957 26.6694 15.6678 26.394 15.996 26.394C16.3322 26.394 16.5963 26.6694 16.5963 27.0015V28.0466Z"
          fill="#1F78FF"
        />
      </>
    )}
  </svg>
);

export default BagIcon;
