/* eslint-disable */
export const employeeStatuses = [
  {
    id: '70162b38-9b4e-4e8e-90d1-a69b04bc51f1',
    name: 'Invited',
  },
  {
    id: '75af5991-a4a3-4bea-b2a7-1306e22d6529',
    name: 'Active',
  },
  {
    id: '7c1d2a5e-8732-445e-8021-10f1b9a1671f',
    name: 'Deleted',
  },
  {
    id: 'efe572a6-9181-4ed6-a82c-1fc9087ebc6f',
    name: 'Blocked',
  },
];
export const tabStatuses = [
  {
    id: ['70162b38-9b4e-4e8e-90d1-a69b04bc51f1', '75af5991-a4a3-4bea-b2a7-1306e22d6529'],
    status_id: 'current',
  },
  {
    id: ['efe572a6-9181-4ed6-a82c-1fc9087ebc6f'],
    status_id: 'blocked',
  },
  {
    id: ['7c1d2a5e-8732-445e-8021-10f1b9a1671f'],
    status_id: 'deleted',
  },
];
