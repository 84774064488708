/* eslint-disable */
import { writeOffTableHeaderActionTypes } from '../actions/writeOffTableHeaderActions/writeOffTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      headerName: 'ID',
      field: 'id',
      minWidth: 70,
      hide: false,
      width: 135,
    },
    {
      field: 'name',
      hide: false,
      minWidth: 70,
    },
    { field: 'shop', minWidth: 70, hide: false },
    {
      field: 'quantity',
      hide: false,
      minWidth: 120,
    },
    {
      field: 'total_retail_price',
      hide: false,
      minWidth: 180,
    },
    {
      field: 'write_off_type',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'user',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'status',
      hide: false,
      resizable: false,
      minWidth: 70,
    },
    {
      field: 'creation_date',
      hide: false,
    },
    {
      field: 'finished_date',
      hide: false,
    },
  ],
};

const writeOffTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case writeOffTableHeaderActionTypes.UPDATE_WRITE_OFF_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case writeOffTableHeaderActionTypes.RESET_WRITE_OFF_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default writeOffTableHeaderReducer;
