import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {},

  default: {},
  primary: {
    color: theme.palette.black,
  },
  secondary: {
    color: theme.palette.gray[400],
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  xxs: {
    fontSize: '0.625rem',
  },
  xs: {
    fontSize: '0.75rem',
  },
  sm: {
    fontSize: '0.875rem',
  },
  md: {
    fontSize: '1rem',
  },
  lg: {
    fontSize: '1.125rem',
  },
  xl: {
    fontSize: '1.5rem',
  },
  xxl: {
    fontSize: '2.250rem',
  },
}));
