import { ApiCaller } from '../utils';
import { Service } from './Service';
import { AxiosRequestConfig } from 'axios';
import { CashBoxServiceNS } from './CashBoxService.d';

const BASE_URL = 'v1';
const apiCaller = new ApiCaller(BASE_URL);

export class CashBoxService implements Service {
  public static getList(params: CashBoxServiceNS.LoadListParamsDTO, config?: AxiosRequestConfig) {
    return apiCaller.get<{ cash_boxes: CashBoxServiceNS.CashBoxDTO[]; count: number }>('/cash-box', { ...params }, { ...config });
  }
}
