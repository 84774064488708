/* eslint-disable */
import { Typography, Tooltip } from '@mui/material';
import TickOutlinedIcon from 'shared/icons/TickOutlinedIcon';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    height: 56,
  },
  input: {
    visibility: 'hidden',
    width: 0,
    margin: 0,
    '&:checked + .radioButton': {
      backgroundColor: theme.palette.blue[600],
    },
    '&:checked + .radioButton svg': {
      fill: theme.palette.common.white,
    },
    '&:checked + .radioButton p': {
      color: theme.palette.common.white,
    },
    '&:checked + .radioButton .statusIcon span': {
      display: 'none',
    },
    '&:checked + .radioButton .titles .subTitle': {
      color: `${theme.palette.blue[100]} !important`,
    },
    '&:not(:checked) + .radioButton .statusIcon svg': {
      display: 'none',
    },
  },
  dashed: {
    '&:not(:checked)': {
      border: `2px dashed ${theme.palette.gray[200]}`,
      backgroundColor: theme.palette.background.default,
      '& div > p': {
        color: `${theme.palette.gray[400]} !important`,
      },
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  button: {
    position: 'absolute',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: '0 16px',
    borderRadius: 16,
    textAlign: 'left',
    cursor: 'pointer',
    backgroundColor: theme.palette.gray[100],
    '& p': {
      color: theme.palette.gray[400],
      margin: 0,
      fontWeight: 600,
    },
    '&:hover': {
      backgroundColor: theme.palette.gray[101],
    },
  },
  radioTitle: {
    display: 'inline-flex',
    alignItems: 'center',
    '& p': {
      color: theme.palette.gray[600],
    },
    '& span': {
      height: '16px',
      borderRadius: '50%',
      display: 'inline-block',
      width: '16px',
      border: `2px solid ${theme.palette.gray[400]}`,
    },
  },
  statusIcon: {
    display: 'inline-flex',
    marginRight: 16,
  },
  error: {
    borderRadius: 16,
    border: `3px solid ${theme.palette.red[500]}`,
  },
  subTitle: {
    fontSize: 14,
    fontFamily: 'Inter',
    color: `${theme.palette.gray[400]} !important`,
  },
  badge: {
    color: `${theme.palette.violet[500]} !important`,
    padding: '4px 6px',
    backgroundColor: theme.palette.violet[200],
    borderRadius: 32,
    fontFamily: 'Gilroy-Bold',
    fontSize: 10,
    marginLeft: 16,
  },
}));

export default function RadioButton(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    margin,
    id,
    name,
    value,
    title,
    description,
    defaultChecked,
    register,
    checked,
    error,
    subTitle,
    height,
    disabled,
    onChange = () => {},
    dashed,
  } = props;
  const content = (
    <div style={{ margin, height: height || 56 }} className={`${classes.root} ${error && classes.error}`}>
      <input
        type="radio"
        name={name}
        value={value}
        id={id}
        onChange={e => {
          onChange(e.target.value);
        }}
        className={classes.input}
        defaultChecked={defaultChecked}
        checked={checked}
        disabled={disabled}
        {...register?.(name)}
      />
      <label htmlFor={id} className={`${classes.button} radioButton ${!defaultChecked && disabled && dashed && classes.dashed}`}>
        <div className={classes.radioTitle}>
          <div className={`${classes.statusIcon} statusIcon`}>
            <span />
            <TickOutlinedIcon />
          </div>
          <div className="titles">
            <Typography>{title}</Typography>
            <Typography className={`${classes.subTitle} subTitle`}>{subTitle}</Typography>
          </div>
          {!defaultChecked && dashed && disabled && <div className={classes.badge}>{t('menu.marketing.certificates.soon')}</div>}
        </div>
      </label>
    </div>
  );
  return description ? (
    <Tooltip title={description} arrow placement="top">
      {content}
    </Tooltip>
  ) : (
    content
  );
}
