/* eslint-disable */
import { useState, useEffect, useRef } from 'react';
import ReactJoyride from 'react-joyride';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import guideData from 'shared/constants/guideData';
import { Button } from '@mui/material';
import BigTickIcon from 'shared/icons/BigTickIcon';
import promiseCallback from 'shared/utils/promiseCallback';
import { useTranslation } from 'react-i18next';
import { setGuideRun, setGuideSlug, setGuideStart } from 'store/actions/guideActions/guideActions';
import ButtonStandartGray from './Buttons/ButtonStandartGray';
import ConfirmDialog from './ConfirmDialog';

const Guide = () => {
  const { guideSlug, step, run, completed, started } = useSelector(state => state.guide, shallowEqual);
  const dispatch = useDispatch();
  const firstRender = useRef(true);
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentGuide, setCurrentGuide] = useState('');
  const [steps, setSteps] = useState(guideData?.[guideSlug] || []);

  const stepData = [
    {
      number: 0,
      slug: 'settings_guide',
      title: t('welcome_page.guide.modal.heading.first'),
      desc: t('welcome_page.guide.modal.paragraph.first'),
    },
    {
      number: 1,
      slug: 'product_guide',
      title: t('welcome_page.guide.modal.heading.second'),
      desc: t('welcome_page.guide.modal.paragraph.second'),
    },
    {
      number: 2,
      slug: 'sale_guide',
      title: t('welcome_page.guide.modal.heading.last'),
      desc: t('welcome_page.guide.modal.paragraph.last'),
      end: true,
    },
  ];

  const activateGuide = () => {
    setOpenDialog(false);
    const currentGuideIndex = stepData.findIndex(el => el.slug === currentGuide.slug);
    const nextGuideSlug = currentGuideIndex + 1 !== stepData.length ? stepData?.[currentGuideIndex + 1]?.slug : stepData?.[0]?.slug;
    // redux is so fast, next dialog data is shown. That's why setTimeout is used here
    setTimeout(() => {
      promiseCallback(() => dispatch(setGuideSlug(nextGuideSlug)))
        .then(() => dispatch(setGuideStart()))
        .then(() => dispatch(setGuideRun()))
        .catch(() => {});
    }, 500);
  };
  useEffect(() => {
    setSteps(guideData?.[guideSlug]?.[step] ? [guideData?.[guideSlug]?.[step]] : []);
    if (guideSlug) {
      setCurrentGuide(stepData.find(el => el.slug === guideSlug));
    }
  }, [guideSlug, step]);
  useEffect(() => {
    if (!started && completed.length && !firstRender.current && currentGuide) {
      setOpenDialog(true);
    }
    firstRender.current = false;
  }, [completed.length, started]);
  // useEffect(() => {
  //   return () => {
  //     promiseCallback(() => dispatch(setGuideSlug(null)))
  //       .then(() => dispatch(setGuideStop()))
  //       .then(() => dispatch(setGuideStep(0)))
  //       .catch(() => {
  //       })
  //   }
  // }, [])
  const handleJoyrideCallback = () => {};
  return (
    <>
      <ReactJoyride
        callback={handleJoyrideCallback}
        hideBackButton
        spotlightPadding={0}
        spotlightClicks
        disableScrollParentFix
        disableOverlayClose
        run={run}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.3)',
          },
          buttonNext: {
            display: 'none',
          },
          buttonClose: {
            display: 'none',
          },
          tooltip: {
            fontFamily: "'Inter', sans-serif",
            padding: 16,
            lineHeight: 19,
            borderRadius: 16,
            fontWeight: '600',
          },
          tooltipFooter: {
            margin: 0,
          },
        }}
      />
      <ConfirmDialog
        open={openDialog}
        setOpen={setOpenDialog}
        icon={<BigTickIcon />}
        title={currentGuide.title}
        desc={currentGuide.desc}
        actions={
          <>
            <Button secondary id="stop" onClick={() => setOpenDialog(false)}>
              {t('welcome_page.guide.modal.btns.skip')}
            </Button>
            <Button size="medium" variant="contained" onClick={activateGuide}>
              {currentGuide.end ? t('welcome_page.guide.modal.btns.last') : t('welcome_page.guide.modal.btns.first')}
            </Button>
          </>
        }
      />
    </>
  );
};
export default Guide;
