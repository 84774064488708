/* eslint-disable */
export const companyActionTypes = {
  SET_COMPANY_ID: 'SET_COMPANY_ID',
  SET_COMPANY_CURRENCY: 'SET_COMPANY_CURRENCY',
  REMOVE_COMPANY_ID: 'REMOVE_COMPANY_ID',
  SET_USER_ID: 'SET_USER_ID',
  REMOVE_USER_ID: 'REMOVE_USER_ID',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_USER_PHONE: 'SET_USER_PHONE',
  SET_CURRENT_SHOP_ID: 'SET_CURRENT_SHOP_ID',
  SET_USER_SHOPS: 'SET_USER_SHOPS',
  SET_CURRENT_CASHBOX_ID: 'SET_CURRENT_CASHBOX_ID',
  SET_SUPPLY_CURRENCY: 'SET_SUPPLY_CURRENCY',
  SET_RETAIL_CURRENCY: 'SET_RETAIL_CURRENCY',
  SET_CURRENT_SHOP_NAME: 'SET_CURRENT_SHOP_NAME',
  SET_CURRENT_CASHBOX_NAME: 'SET_CURRENT_CASHBOX_NAME',
  SET_SETTINGS_CATALOG: 'SET_SETTINGS_CATALOG',
  SET_REPORT_SHOP_CURRENCY: 'SET_REPORT_SHOP_CURRENCY',
  SET_REPORT_FINANCE_CURRENCY: 'SET_REPORT_FINANCE_CURRENCY',
  SET_REPORT_CLIENT_CURRENCY: 'SET_REPORT_CLIENT_CURRENCY',
  SET_REPORT_SELLER_CURRENCY: 'SET_REPORT_SELLER_CURRENCY',
  SET_REPORT_PRODUCT_CURRENCY: 'SET_REPORT_PRODUCT_CURRENCY',
  SET_REPORT_PRODUCT_IMPORT_CURRENCY: 'SET_REPORT_PRODUCT_IMPORT_CURRENCY',
  SET_REPORT_PRODUCT_SUPPLIER_CURRENCY: 'SET_REPORT_PRODUCT_SUPPLIER_CURRENCY',
  SET_REPORT_PRODUCT_PERFORMANCE_CURRENCY: 'SET_REPORT_PRODUCT_PERFORMANCE_CURRENCY',
  SET_REPORT_CLIENT_PURCHASE_CURRENCY: 'SET_REPORT_CLIENT_PURCHASE_CURRENCY',
  SET_COMPANY_TIMEZONE: 'SET_COMPANY_TIMEZONE',
  SET_USER_REGION: 'SET_USER_REGION',
  SET_WEBKASSA_LOGIN: 'SET_WEBKASSA_LOGIN',
};
