/* eslint-disable */
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import CatalogLayout from 'layouts/CatalogLayout';
import { FinanceCategoriesPage, FinanceTransactionsPage, CashboxStatePage, WclPage, WclSendPage, WclPreScoringPage } from 'pages';

const financeRoutes = {
  path: 'finance',

  children: [
    {
      path: 'categories',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <FinanceCategoriesPage />,
        },
      ],
    },
    {
      path: 'transactions',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <FinanceTransactionsPage />,
        },
      ],
    },
    {
      path: 'state',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <CashboxStatePage />,
        },
      ],
    },
    {
      path: 'wcl',
      children: [
        {
          path: '',
          element: <DashboardLayout />,

          children: [
            {
              path: '',
              element: <WclPage />,
            },
          ],
        },
        {
          path: 'send',
          element: <CatalogLayout />,
          children: [{ path: '', element: <WclSendPage /> }],
        },
        {
          path: 'pre-scoring',
          element: <DashboardLayout />,
          children: [{ path: ':id', element: <WclPreScoringPage /> }],
        },
      ],
    },
    { path: '', element: <Navigate to="/finance/categories" /> },
    { path: '*', element: <Navigate to="/finance/categories" /> },
  ],
};

export default financeRoutes;
