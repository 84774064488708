/* eslint-disable */
const PlusIcon = props => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill={props.fill ? props.fill : '#BDBDBD'} {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 5.5H8.5V1C8.5 0.46875 8.03125 0 7.5 0H6.5C5.9375 0 5.5 0.46875 5.5 1V5.5H1C0.4375 5.5 0 5.96875 0 6.5V7.5C0 8.0625 0.4375 8.5 1 8.5H5.5V13C5.5 13.5625 5.9375 14 6.5 14H7.5C8.03125 14 8.5 13.5625 8.5 13V8.5H13C13.5312 8.5 14 8.0625 14 7.5V6.5C14 5.96875 13.5312 5.5 13 5.5Z"
      fill={props.fill ? props.fill : '#BDBDBD'}
    />
  </svg>
);

export default PlusIcon;
