/* eslint-disable */
import { request } from '../axios';
import * as qs from 'qs';

const loyaltyProgram = {
  getAll: () => request.get(`v1/loyalty-program`),
  getExcludedProducts: data =>
    request.get(
      `v1/loyalty-program-products/${data.id}${qs.stringify(data.filter, {
        addQueryPrefix: true,
      })}`,
    ),
  create: data => request.post(`v1/loyalty-program`, data),
  update: data => request.put(`v1/loyalty-program/${data.id}`, data.data),
  addExcludedProducts: data => request.patch(`v1/loyalty-program/${data?.id}/add-excluded-products`, data?.body),
  removeExcludedProducts: data => request.patch(`v1/loyalty-program/${data?.id}/remove-excluded-products`, data?.body),
};

export default loyaltyProgram;
