/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const permission = {
  getAll: filter => request.get(`v2/permission${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v2/permission`, data),
  getPermission: id => request.get(`v2/permission/${id}`),
  update: data => request.put(`v2/permission/${data.id}`, data.data),
  delete: id => request.delete(`v2/permission/${id}`),
  getAllSections: filter => request.get(`v2/section${qs.stringify(filter, { addQueryPrefix: true })}`),
  createSection: data => request.post(`v2/section`, data),
  updateSection: data => request.put(`v2/section/${data.id}`, data.data),
  deleteSection: id => request.delete(`v2/section/${id}`),
};

export default permission;
