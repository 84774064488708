/* eslint-disable */
const movementReportTableHeaders = {
  columns: [
    {
      headerName: 'Дата',
      field: 'created_at',
      hide: false,
      is_main: true,
      always_active: true,
    },
    {
      headerName: 'Касса',
      field: 'cashbox',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Тип денежной операции',
      field: 'payment_type',
      hide: false,
    },
    {
      headerName: 'Тип транзакции',
      field: 'operation',
      hide: false,
    },
    {
      headerName: 'Откуда',
      field: 'from_account',
      hide: false,
    },
    {
      headerName: 'Куда',
      field: 'to_account',
      hide: false,
    },
    {
      headerName: 'Сумма из',
      field: 'amount_from_retail_currency',
      hide: false,
    },
    {
      headerName: 'Сумма из (USD)',
      field: 'amount_from_usd',
      hide: false,
    },
    {
      headerName: 'Сумма в',
      field: 'amount_to_retail_currency',
      hide: false,
    },
    {
      headerName: 'Сумма в (USD)',
      field: 'amount_to_usd',
      hide: false,
    },
    {
      headerName: 'Пользователь',
      field: 'user',
      hide: false,
    },
  ],
};

export default movementReportTableHeaders;
