/* eslint-disable */
import { useEffect } from 'react';

export default function useDisableWheelOnInput() {
  function handleInputBlur() {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  }
  useEffect(() => {
    // disable scroll change in input[type='number']
    document.addEventListener('wheel', handleInputBlur);
    return () => {
      document.removeEventListener('wheel', handleInputBlur);
    };
  }, []);
}
