import React from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import { useWebsocketMutationNew } from 'shared/hooks/useWebsocketMutationNew';
import Button from 'stories/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { requests } from 'services/requests';
import { error, success } from 'shared/utils/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setCurrentShopId } from 'store/actions/companyActions/companyActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useQueryClient } from '@tanstack/react-query';

const useStyles = makeStyles(theme => ({
  drawer: {
    '& .MuiDrawer-paper': {
      width: '30vw',
      padding: '64px 32px',
      borderRadius: '0 64px 64px 0',
      backgroundColor: theme.palette.background.default,
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
  },
  body: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    margin: 0,
    fontSize: 36,
    lineHeight: '42px',
    color: theme.palette.black,
  },
  backBtn: {
    minWidth: 48,
    height: 48,
  },
  shopBtn: {
    marginBottom: 8,
    color: theme.palette.gray[600],
    '& svg': {
      display: 'none',
    },
    '&.active': {
      color: theme.palette.blue[500],
    },
    '&.active svg': {
      display: 'block',
    },
  },
}));

type UserShopSelectDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onBackClick: () => void;
  refetch: () => void;
  userDetails?: unknown;
};

const UserShopSelectDrawer = ({ isOpen, onClose, onBackClick, refetch, userDetails }: UserShopSelectDrawerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [active, setActive] = React.useState<string | null>(null);

  const { mutate } = useWebsocketMutationNew(requests.admin.changeCurrentShop, {
    onWebsocketSuccess: () => {
      refetch();
      if (location.pathname.startsWith('/order/new-order')) {
        navigate(`/order/create`);
      }
      success('Магазин был успешно изменён');
      queryClient.invalidateQueries({
        queryKey: ['USER_SELF'],
      });
      onClose();
    },
    onWebsocketError: () => {
      error('Магазин был изменён с ошибкой');
    },
  });

  const changeCurrentShop = item => {
    dispatch(setCurrentShopId(item.shop_id));
    setActive(item.shop_id);
    mutate(item.shop_id);
  };

  React.useEffect(() => {
    if (userDetails) {
      setActive(userDetails?.current_shop_id);
    }
  }, [userDetails]);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="left" elevation={1} className={classes.drawer}>
      <div className={classes.wrapper}>
        <div className={classes.body}>
          <div>
            <Box width="100%" display="flex" alignItems="center">
              <Button icon circle onClick={onBackClick} className={classes.backBtn}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <Box ml={2}>
                <Typography variant="h1" className={classes.title}>
                  {t('menu.profile.store_change')}
                </Typography>
              </Box>
            </Box>

            <Box mt={4}>
              {userDetails?.shops?.map((item, index) => (
                <Button
                  secondary
                  key={index}
                  fullWidth
                  className={`${classes.shopBtn} ${item.shop_id === active ? 'active' : ''}`}
                  onClick={() => changeCurrentShop(item)}
                  adornmentEnd={<FontAwesomeIcon icon={faCheck} />}
                >
                  {item.shop.name}
                </Button>
              ))}
            </Box>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default UserShopSelectDrawer;
