/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const shop = {
  getAll: filter => request.get(`v1/shop${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v1/shop`, data),
  getSingle: id => request.get(`v1/shop/${id}`),
  update: ({ id, data }) => request.put(`v1/shop/${id}`, data),
  delete: id => request.delete(`v1/shop/${id}`),
  shop: name => request.get(`v1/shop-search?name=${name}`),
};

export default shop;
