import { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import LogoMain from 'shared/icons/LogoMain';
import LogoLetters from 'shared/icons/LogoLetters';
import UserDetails from './UserDetails';
import CompanyDetails from './CompanyDetails';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper.MuiDialog-paper': {
      width: 816,
      maxWidth: 816,
      boxShadow: 'none !important',
      borderRadius: 72,
      position: 'relative',
      zIndex: 11,
      overflow: 'hidden',
      maxHeight: '100vh',
    },
    '& .MuiBackdrop-root': {
      background: 'rgba(0,0,0,0.3)',
    },
    zIndex: 11,
  },
  welcomeContainer: {
    padding: '96px 180px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo_main: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
  },
  brand_logo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 64,
  },
  title: {
    marginBottom: 16,
    whiteSpace: 'nowrap',
    fontSize: 36,
    fontWeight: 900,
    lineHeight: '42px',
    color: theme.palette.black,
    fontFamily: theme.fontFamily.gilroyBold,
  },
  personal_info: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 64,
    fontFamily: 'Inter',
    color: theme.palette.gray[600],
  },
  button: {
    borderRadius: 32,
    width: 256,
    height: 56,
  },
  container: {
    transition: 'height .3s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
      transition: 'all 0.3s ease-in-out',
    },
  },
}));
export default function WelcomeDialog({ open, setOpen }) {
  const { t } = useTranslation();
  const methods = useForm({ mode: 'onChange' });
  const [step, setStep] = useState('welcome');
  const handleClose = reason => {
    if (reason && reason === 'backdropClick') return;
    localStorage.removeItem('first_attempt');
    setOpen(false);
  };
  const classes = useStyles();

  return (
    <>
      <Dialog onClose={handleClose} open={open} className={classes.root}>
        <Box className={classes.container}>
          <Box hidden={step !== 'welcome'}>
            <Box className={classes.welcomeContainer}>
              <div className={classes.brand_logo}>
                <span className={classes.logo_main}>
                  <LogoMain />
                </span>
                <LogoLetters />
              </div>
              <h1 className={classes.title}>{t('menu.register.welcomeBillz')}</h1>
              <Typography className={classes.personal_info}>{t('menu.register.fillPersonalData')}</Typography>
              <Button variant="contained" className={classes.button} size="large" type="submit" onClick={() => setStep('user')} fullWidth>
                {t('menu.register.startSettings')}
              </Button>
            </Box>
          </Box>
          <FormProvider {...methods}>
            <Box hidden={step !== 'user'}>
              <UserDetails setStep={setStep} />
            </Box>
            <Box hidden={step !== 'company_segment'}>
              <CompanyDetails setStep={setStep} handleClose={handleClose} />
            </Box>
          </FormProvider>
        </Box>
      </Dialog>
    </>
  );
}
