/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const promotion = {
  getAll: filter => request.get(`v1/promo${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v1/promo`, data),
  otherPromos: id => request.get(`v1/other-promos/${id}`),
  make: id => request.patch(`v1/promo/${id}/activate`),
  updateDetails: ({ id, data }) => request.put(`v1/promo/${id}/update-details`, data),
  promoDetails: id => request.get(`v1/promo/${id}/details`),
  update: ({ id, data }) => request.put(`v1/promo/${id}/update-settings`, data),
  createByFile: ({ id, data }) => request.post(`v1/promo-by-file/${id}`, data),
  getSingle: id => request.get(`v1/promo/${id}`),
  getReport: id => request.get(`v1/promo-report/${id}`),
  getProductsData: id => request.get(`v1/promo/${id}/products`),
  getTransaction: id => request.get(`v1/promo/${id}/orders`),
  getSingleProducts: (id, filter) => request.get(`v1/promo/${id}/products${qs.stringify(filter, { addQueryPrefix: true })}`),
  addProduct: ({ id, data }) => request.put(`v1/promo/${id}/add-product`, data),
  progress: id => request.get(`v1/promo-progress/${id}`),
  removeProduct: ({ id, data }) => request.delete(`v1/promo/${id}/delete-product`, data),
  includeMultipleProduct: ({ id, data }) => request.post(`v1/promo/add-products/${id}`, data),
  excludeMultipleProduct: ({ id, data }) => request.post(`v1/promo/exclude-products/${id}`, data),
  addActiveDaysTime: ({ id, data }) => request.patch(`v1/promo/${id}`, data),
  delete: id => request.delete(`v1/promo/${id}`),
  getExcelReport: data => request.post(`v1/promo/excel-export`, data),
  pause: id => request.patch(`v1/promo/${id}/stop`),
  restart: id => request.patch(`v1/promo/${id}/restart`),
  uploadFile: data => request.post(`v1/promo/upload_file`, data),
  uploadProducts: data => request.post(`v1/promo/upload-products`, data),
  //promo-codes
  getAllPromoCodes: filter =>
    request.get(
      `v1/promo-code/list-with-stats${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getByPromoId: filter =>
    request.get(
      `v1/promo-code/by-promo-id${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getGenerated: filter =>
    request.get(
      `v1/promo-code/generated${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getSinglePromoCode: (id, filter) =>
    request.get(
      `v1/promo-code/by-id/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getAllPromoCodesStatistics: filter =>
    request.get(
      `v1/promo-code/stats${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  deletePromocodes: data => request.delete(`v1/promo-code/remove-from-promo`, data),
  uploadPromocodes: data => request.post(`v1/promo-code/create-from-excel`, data),
  donwloadPromocodesGeneratedByPromoId: data => request.post(`/v1/promo-code/promo-code-excel-generated`, data),
  donwloadPromocodesByPromoId: data => request.post(`/v1/promo-code/promo-code-excel-by-promo`, data),
  donwloadPromoocdes: data => request.post(`/v1/promo-code/promo-code-excel`, data),
  bindProducts: ({ data, id }) => request.put(`/v1/promo/${id}/products-bind`, data),
};

export default promotion;
