import * as qs from 'qs';
import { request } from '../axios';

const priceTag = {
  getAll: () => request.get(`v1/price-tag`),
  getSingle: id => request.get(`v1/price-tag/${id}`),
  getAllBarcodes: () => request.get(`v1/barcode-type`),

  create: ({ data, protocol }) => request.post(`v1/price-tag${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  update: ({ id, data, protocol }) => request.put(`v1/price-tag/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  delete: ({ id, protocol }) => request.delete(`v1/price-tag/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
};

export default priceTag;
