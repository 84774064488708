import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    padding: 20,
    borderRadius: 24,
    background: theme.palette.gray[100],
    alignItems: 'center',
  },
  warning: {
    color: theme.palette.yellow[400],
  },
  info: {
    color: theme.palette.blue[400],
  },
}));
