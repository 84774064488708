/* eslint-disable */
import { useQuery } from '@tanstack/react-query';
import { requests } from 'services/requests';
import { useMemo } from 'react';

export type Shop = {
  id: string;
  name: string;
  color: string;
  company_id: string;
  cash_boxes_count: number;
};

type Response = {
  shops: Shop[];
  count: number;
};

const useShops = (enabled = true, only_allowed = true, queryKey = 'shopList') => {
  const { data, isLoading, isFetching } = useQuery<Response, Error>(
    [queryKey, only_allowed],
    () =>
      requests.shop
        .getAll({
          limit: 1000,
          only_allowed,
        })
        .then(res => res.data),
    { enabled },
  );

  return {
    shops: data?.shops ?? [],
    count: data?.count ?? [],
    isLoading,
    isFetching,
  };
};

export default useShops;
