/* eslint-disable */
import { faArrowRight, faBell, faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import React, { useRef } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { requests } from 'services/requests';
import useListenWebsocket from 'shared/hooks/useListenWebsocket';
import { getRemainingTime } from 'shared/utils/getRemainingTime';
import { error, success } from 'shared/utils/toast';
import { clearBackgroundProgress, setBackgroundProgress } from 'store/actions/backgroundProgressActions/backgroundProgressActions';
import Button from 'stories/Button/Button';
import paletteLight from 'theme/paletteLight';

const BackgroundProgresses = ({ closeDrawer, classes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const errorCount = useRef(0);
  const backProgresses = useSelector(state => state.backgroundProgress, shallowEqual);
  const progresses = useQueries({
    queries: backProgresses
      ?.filter(el => el.correlation_id)
      .map(progress => {
        const request =
          progress.slug === 'import'
            ? requests.excelImport.getImportProgress
            : progress.slug === 'transfer'
              ? requests.transfer.getTransferProgress
              : progress.slug === 'write-off'
                ? requests.writeOff.getWriteOffProgress
                : progress.slug === 'customer'
                  ? requests.customer.getProgress
                  : requests.excelImport.getImportProgress;
        return {
          queryKey: [progress.slug, progress.correlation_id],
          queryFn: () => request(progress.correlation_id),

          refetchInterval: res => {
            if (progress.correlation_id && (!progress.id || !progress.error)) {
              if (res?.data?.data === undefined) {
                errorCount.current = errorCount.current++;
              }
              if (
                res?.data?.is_finished === true &&
                (res?.data?.message === 'validation' || res?.data?.message === 'product-load') &&
                res?.data?.percent === 100
              ) {
                return false;
              }
              if (res?.data?.data?.is_finished === true && res?.data?.data?.percent !== 100 && !Number.isNaN(res?.data?.data?.percent)) {
                return false;
              }
              if (
                (!res?.data?.data?.is_finished &&
                  (res?.data?.data?.message === 'validation' || res?.data?.data?.message === 'product-load')) ||
                res?.data === undefined
              ) {
                if (errorCount.current < 50) {
                  return 1000;
                }
              }
              return 1000;
            }
            return false;
          },
        };
      }),
  });

  const finishProcess = process => {
    // dispatch(removeBackgroundProgress(process))
    closeDrawer();
  };

  useListenWebsocket((message, wsSuccess) => {
    const currentProgress = backProgresses.find(el => el.correlation_id === message?.correlation_id);
    if (currentProgress && message?.correlation_id) {
      if (wsSuccess) {
        const link =
          currentProgress.slug === 'write-off' ? `/products/write-off/create/${message.id}` : `/products/import/edit/${message.id}`;
        success(
          <Typography>
            {t('menu.settings.profile.completed_proccess_adding_products')} “{currentProgress.name}”!{' '}
            <Link to={link} onClick={() => finishProcess(currentProgress)}>
              {t('menu.settings.profile.view_results')}
            </Link>
          </Typography>,
        );
        dispatch(
          setBackgroundProgress({
            slug: currentProgress.slug,
            correlation_id: currentProgress.correlation_id,
            id: message.id,
            isLoading: false,
            success: currentProgress.slug === 'write-off' ? true : false,
          }),
        );
      } else {
        error(
          <Typography>
            Ошибка при добавления товаров в “{currentProgress.name}”!{' '}
            <Link to={`/products/validation-result/${message.correlation_id}?type=${currentProgress.slug}`}>
              {t('menu.settings.profile.view_results')}
            </Link>
          </Typography>,
        );
        dispatch(
          setBackgroundProgress({
            slug: currentProgress.slug,
            correlation_id: currentProgress.correlation_id,
            isLoading: false,
            error: true,
          }),
        );
      }
    }
  }, []);

  return (
    <Box>
      {backProgresses?.map((el, index) => {
        const resultLink =
          el.slug === 'import'
            ? '/products/import/edit'
            : el.slug === 'transfer'
              ? '/products/transfer/create'
              : el.slug === 'write-off'
                ? '/products/write-off/create'
                : '';
        return (
          <Box key={index}>
            <Box pb={2} />
            <Link
              to={
                el?.success && el?.slug === 'write-off'
                  ? `${resultLink}/${el.id}`
                  : el.error
                    ? `/products/validation-result/${el.correlation_id}?type=${el.slug}`
                    : progresses?.[index]?.data?.data?.percent === 100 && progresses?.[index]?.data?.data?.message === 'product-load'
                      ? `${resultLink}/${el.id}`
                      : `/products/validation-progress/${el.correlation_id}?type=${el.slug}`
              }
              className={classes.progressWrapper}
              onClick={() => finishProcess(el)}
            >
              <Box display="flex" alignItems="center">
                <Box width={56} height={56}>
                  <CircularProgressbar
                    value={progresses?.[index]?.data?.data?.percent}
                    text={`${progresses?.[index]?.data?.data?.percent || 0}%`}
                    sx={theme => ({
                      path: {
                        stroke: el.error
                          ? paletteLight.yellow[400]
                          : progresses?.[index]?.data?.data?.percent === 100
                            ? paletteLight.green[400]
                            : paletteLight.blue[600],
                        strokeLinecap: 'round',
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                        transform: 'rotate(0.25turn)',
                        transformOrigin: 'center center',
                      },
                      trail: {
                        stroke: paletteLight.gray[200],
                        strokeLinecap: 'round',
                        transform: 'rotate(0.25turn)',
                        transformOrigin: 'center center',
                      },
                      text: {
                        fill: paletteLight.gray[600],
                        fontFamily: theme.fontFamily.gilroyBold,
                        fontSize: '28px',
                        lineHeight: '28px',
                      },
                      background: {
                        fill: '#3e98c7',
                      },
                    })}
                  />
                </Box>
                <Box ml={2}>
                  <Typography style={{ color: paletteLight.blue[500] }}>{el.name || ''}</Typography>
                  <Box mb={0.5} />
                  <Typography>
                    {el.error
                      ? `Обнаружены ошибки`
                      : progresses?.[index]?.data?.data?.percent === 100
                        ? `${progresses?.[index]?.data?.data?.total || 0} товаров`
                        : `${progresses?.[index]?.data?.data?.current || 0} из ${progresses?.[index]?.data?.data?.total || 0}`}
                  </Typography>
                  <Box mb={0.5} />
                  <Typography color="textSecondary">
                    {!el.error &&
                      getRemainingTime((progresses?.[index]?.data?.data?.total - progresses?.[index]?.data?.data?.current) / 100, t)}
                  </Typography>
                </Box>
              </Box>
              <Box pr={0.5} color={paletteLight.blue[500]}>
                <FontAwesomeIcon icon={faArrowRight} />
              </Box>
            </Link>
          </Box>
        );
      })}
      {backProgresses?.length ? (
        <Box mt={2}>
          <Button fullWidth secondary onClick={() => dispatch(clearBackgroundProgress())}>
            Очистить
          </Button>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default BackgroundProgresses;
