/* eslint-disable */
const financeRoutes = [
  {
    route: 'finance/transactions',
    options: '',
    slug: 'transactions',
  },
  {
    route: 'finance/categories',
    options: '',
    slug: 'categories',
  },
  {
    route: 'finance/state',
    options: '',
    slug: 'finance-state',
  },
  {
    route: 'finance',
    options: '',
    slug: 'finance',
  },
  {
    route: 'nonsense',
    options: '',
    slug: 'cash-shifts',
  },
  {
    route: 'order/cashbox-operations',
    options: '',
    slug: 'cashbox-operations',
  },
  {
    route: 'reports/finances',
    options: '',
    slug: 'reports-finances',
  },
  {
    route: '/reports/finances/summary',
    options: '',
    slug: 'reports-finances-summary',
  },
];
export default financeRoutes;
