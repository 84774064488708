/* eslint-disable */
import { openSidebarActionTypes } from '../actions/openSidebarActions/openSidebarActionTypes';

const initialState = {
  isOpen: true,
};

const openSidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case openSidebarActionTypes.TOGGLE:
      return { ...state, isOpen: action.payload };

    default:
      return state;
  }
};

export default openSidebarReducer;
