/* eslint-disable */
import { useTheme } from '@mui/material/styles';

const LogoLetters = props => {
  const { mode } = useTheme();

  return (
    <svg width="75" height="16" viewBox="0 0 75 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M66.739 15.0043H73.5739V12.7832H66.7383C66.9963 13.5011 66.9963 14.2864 66.7383 15.0043"
        fill={mode === 'dark' && !props?.presentation ? '#FFFFFF' : '#1D1D1B'}
      />
      <path
        d="M6.11292 0.994343H0.436394V15.0008H5.7126C9.11495 15.0008 10.456 13.4601 10.456 10.8188C10.456 8.47682 8.73454 7.67625 8.73454 7.67625C9.19509 7.36475 9.57136 6.9441 9.82979 6.45183C10.0882 5.95957 10.2208 5.41099 10.2156 4.85505C10.2156 2.49317 8.89453 0.992283 6.11292 0.992283V0.994343ZM6.33265 3.21341C7.39352 3.21341 7.91194 3.89382 7.91194 4.89487C7.91194 6.0353 7.17104 6.63606 6.09026 6.63606H2.75795V3.21684L6.33265 3.21341ZM2.75795 8.83728H6.09232C7.65376 8.83728 8.09459 9.75731 8.09459 10.8188C8.09459 12.1391 7.61393 12.7797 5.79294 12.7797H2.75795V8.83728ZM20.5998 0.994343H18.2577V15.0008H20.5998V0.994343ZM29.9479 0.994343V15.0008H39.0061V12.7831H32.2893V0.994343H29.9479ZM46.6815 0.994343V15.0008H55.7398V12.7831H49.0195V0.994343H46.6815Z"
        fill={mode === 'dark' && !props?.presentation ? '#FFFFFF' : '#1D1D1B'}
      />
      <path
        d="M70.3056 2.97451L70.1202 3.21687L63.5284 11.7917C63.1218 11.814 62.7303 11.9537 62.4014 12.1939C62.0726 12.4341 61.8205 12.7646 61.6757 13.1452C61.5309 13.5258 61.4996 13.9402 61.5856 14.3383C61.6716 14.7363 61.8712 15.1009 62.1603 15.3878C62.4493 15.6746 62.8154 15.8715 63.2141 15.9546C63.6128 16.0376 64.027 16.0032 64.4066 15.8556C64.7861 15.7079 65.1147 15.4534 65.3525 15.1228C65.5903 14.7922 65.7271 14.3997 65.7463 13.993C65.7646 13.6371 65.6914 13.2825 65.5334 12.9631L66.3045 11.9648L72.299 4.20625C72.7491 4.18917 73.1819 4.02797 73.5335 3.7464C73.8851 3.46484 74.137 3.0778 74.252 2.6423C74.3671 2.20681 74.3392 1.74588 74.1725 1.32742C74.0058 0.908969 73.7091 0.555113 73.3261 0.317986C72.9431 0.0808577 72.494 -0.0270037 72.0451 0.0102916C71.5962 0.047587 71.1711 0.228067 70.8325 0.525143C70.4939 0.822219 70.2597 1.22018 70.1643 1.66041C70.069 2.10064 70.1175 2.55984 70.3029 2.97039"
        fill={mode === 'dark' && !props?.presentation ? '#FFFFFF' : '#1D1D1B'}
      />
      <path
        d="M69.1294 0.994358H62.5012V3.21549H69.1294C68.8718 2.4975 68.8718 1.71234 69.1294 0.994358Z"
        fill={mode === 'dark' && !props?.presentation ? '#FFFFFF' : '#1D1D1B'}
      />
    </svg>
  );
};

export default LogoLetters;
