import { lazyLoad } from '../../../shared/utils/loadable';

export const GiftCardsPage = lazyLoad(
  () => import('./GiftCardsPage'),
  module => module.default,
);

export * from './imports';
export * from './create';
export * from './tutorial';
