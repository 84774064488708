import { request } from '../axios';
import * as qs from 'qs';

const productCharacteristic = {
  getAll: filter =>
    request.get(
      `v2/product-characteristic${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  create: ({ data, protocol }) => request.post(`v2/product-characteristic${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  catalogSettingsUpdate: data => request.put(`v2/product-setting`, data),
  catalogSettingsGet: () => request.get(`v2/product-setting`),
  getSingle: id => request.get(`v2/product-characteristic/${id}`),
  update: ({ id, data, protocol }) =>
    request.put(`v2/product-characteristic/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  delete: ({ id, protocol }) => request.delete(`v2/product-characteristic/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  recover: ({ id, protocol }) => request.put(`v2/product-characteristic-recover/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  getAllCustomValues: filter =>
    request.get(
      `v2/product-custom-field-value${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
};

export default productCharacteristic;
