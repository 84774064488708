/* eslint-disable */
const productReturnTableHeaders = {
  settings: {
    showCategoriesInOneColumn: true,
  },
  columns: [
    {
      headerName: 'return id',
      field: 'return_operation_id',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'id',
      field: 'order_operation_id',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Магазин',
      field: 'shop_name',
      hide: false,
      is_main: true,
      always_active: true,
    },

    {
      headerName: 'data',
      field: 'created_at',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'created-name',
      field: 'created_by_name',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Наименование товара',
      field: 'product_name',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Обозначение',
      field: 'product_sku',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Баркод',
      field: 'product_barcode',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Бренд',
      field: 'product_brand',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Категория',
      field: 'product_categories',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Кол-во',
      field: 'measurement_value',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Сумма возврата',
      field: 'total_return_price',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Цена поставки',
      field: 'supply_price',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Цена продажи',
      field: 'retail_price',
      hide: false,
      is_main: true,
    },

    {
      headerName: 'Поставщик',
      field: 'supplier_name',
      hide: false,
      is_main: true,
    },
  ],
};

export default productReturnTableHeaders;
