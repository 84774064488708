import React from 'react';
import ArrowBackIcon from '../../../shared/icons/ArrowBackIcon';
import { useStyles } from './SectionHeader.styles';
import { IconButton, Typography } from '../';
import clsx from 'clsx';

type TypographyProps = React.ComponentProps<typeof Typography>;

type SectionHeaderProps = {
  title: string;
  subTitle?: string;
  variant?: TypographyProps['variant'];
  className?: string;
  onBackClick?: () => void;
};
const SectionHeader = ({ className, title, subTitle, onBackClick, variant = 'h1' as const }: SectionHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {onBackClick && <IconButton icon={ArrowBackIcon} onClick={onBackClick} />}
      <div>
        <Typography variant={variant}>{title}</Typography>
        {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
      </div>
    </div>
  );
};

export default SectionHeader;
