import { useStyles } from './Divider.styles';
import clsx from 'clsx';

const spacingMap = {
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
} as const;

type Spacing = keyof typeof spacingMap;

type DividerProps = {
  type?: 'dashed' | 'solid';
  className?: string;
  marginTop?: Spacing;
  marginBottom?: Spacing;
  marginLeft?: Spacing;
  marginRight?: Spacing;
  marginVertical?: Spacing;
  marginHorizontal?: Spacing;
};

const Divider = ({
  type = 'dashed',
  className,
  marginVertical,
  marginHorizontal,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
}: DividerProps) => {
  const marginTopValue = marginTop ?? marginVertical;
  const marginBottomValue = marginBottom ?? marginVertical;
  const marginLeftValue = marginLeft ?? marginHorizontal;
  const marginRightValue = marginRight ?? marginHorizontal;

  const classes = useStyles({
    marginTop: marginTopValue && spacingMap[marginTopValue],
    marginBottom: marginBottomValue && spacingMap[marginBottomValue],
    marginLeft: marginLeftValue && spacingMap[marginLeftValue],
    marginRight: marginRightValue && spacingMap[marginRightValue],
  });

  const rootClassName = clsx(
    classes.root,
    {
      [classes.dashed]: type === 'dashed',
      [classes.solid]: type === 'solid',
    },
    className,
  );

  return <hr className={rootClassName} />;
};

export default Divider;
