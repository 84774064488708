/* eslint-disable */
import { onboardingActionTypes } from '../actions/onboardingActions/onboardingActionTypes';

const initialState = {
  catalog: 0,
  transfer: 0,
  import: 0,
  employees: 0,
  orders: 0,
};

const onboardingReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case onboardingActionTypes.SET_ONBOARDING_STATUS:
      return {
        ...state,
        [payload]: state[payload] + 1,
      };
    default:
      return state;
  }
};

export default onboardingReducer;
