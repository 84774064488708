/* eslint-disable */
const GoToMainIcon = props => (
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 12C14.6562 12 16 10.6562 16 9V3C16 1.34375 14.6562 0 13 0H10.375C10.1562 0 10 0.1875 10 0.375V1.625C10 1.84375 10.1562 2 10.375 2H13C13.5312 2 14 2.46875 14 3V9C14 9.5625 13.5312 10 13 10H10.375C10.1562 10 10 10.1875 10 10.375V11.625C10 11.8438 10.1562 12 10.375 12H13ZM11.5312 5.71875L6.28125 0.46875C5.8125 0 5 0.34375 5 1V4H0.75C0.3125 4 0 4.34375 0 4.75V7.75C0 8.1875 0.3125 8.5 0.75 8.5H5V11.5C5 12.1875 5.8125 12.5 6.28125 12.0312L11.5312 6.78125C11.8125 6.5 11.8125 6.03125 11.5312 5.71875Z"
      fill="#4993DD"
    />
  </svg>
);

export default GoToMainIcon;
