/* eslint-disable */
import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = '256px';

export const navbarStyles = makeStyles((theme: any) => {
  return {
    root: {
      zIndex: 11,
      display: 'flex',

      '& .MuiPaper-root': {
        border: 0,
        overflowX: 'hidden',
        backgroundColor: theme.palette.bluegray[100],
      },
      position: 'relative',
    },
    list: {
      '&:hover': {
        '& .popper': {
          display: 'block',
          opacity: 1,
          zIndex: 1010,
        },
      },
    },
    popper: {
      backgroundColor: theme.palette.background.blueGray,
      borderRadius: '16px',
      width: 256,
      outline: '0',
      minHeight: 402,
    },
    popperParent: {
      position: 'fixed !important',
      left: `96px !important`,
      top: `90px !important`,
      width: 268,
      opacity: 0,
      borderLeft: '12px solid transparent',
      display: 'none',
      zIndex: 11,
    },
    close_icon: {
      position: 'absolute',
      right: ({ isOpen }) => (isOpen ? 20 : 0),
      top: 32,
      zIndex: 12,
      width: 32,
      height: 32,
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: '0',
      border: '0',
      cursor: 'pointer',
      '& span': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.5s',
      },
    },
    right_faced: {
      transform: 'rotate(0deg)',
    },
    left_faced: {
      transform: 'rotate(-180deg)',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&::-webkit-scrollbar': {
        background: 'transparent',
        width: 6,
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.gray[300],
        width: 6,
        borderRadius: 2,
      },
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 96,
      '& .drawer_icon': {
        paddingRight: 50,
      },
      '& .drawer_list_item': {
        padding: '0 32px',
      },
      '& .drawer_user_avatar': {
        padding: ({ isOpen }) => (isOpen ? '8px 32px' : '12px 16px'),
        transition: '0.3s',
      },
      '& .drawer_user_avatar > div > div:nth-child(1)': {
        width: 32,
        height: 32,
        marginRight: 32,
        fontSize: 12,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    listItem: {
      height: 48,
      fontSize: 16,
      paddingLeft: '32px !important',
      paddingRight: '32px !important',
      fontWeight: '600',
      color: theme.palette.gray[600],
      fontFamily: theme.fontFamily.gilroyBold,
      transition: '0.3s',
      '&.Mui-selected': {
        backgroundColor: theme.palette.bluegray[200],
        color: theme.palette.blue[500],
      },
      '&.Mui-selected:hover': {
        backgroundColor: theme.palette.bluegray[200],
        color: theme.palette.blue[500],
      },
      '&:hover': {
        backgroundColor: theme.palette.bluegray[200],
        color: theme.palette.gray[600],
      },
      '& svg': {
        marginRight: 10,
        transition: '0.3s',
      },
    },
    listItemPopper: {
      height: 56,
      fontSize: 16,
      paddingLeft: 32,
      paddingRight: 32,
      fontWeight: '600',
      color: theme.palette.gray[600],
      fontFamily: theme.fontFamily.gilroyBold,
      transition: '0.3s',
      '& svg': {
        marginRight: 10,
        transition: '0.3s',
      },
    },
    logo: {
      padding: 32,
    },
    brandLogo: {
      display: 'flex',
      alignItems: 'center',
      '& > svg > path': {
        fill: theme.mode === 'dark' && 'white',
      },
    },
    logo_main: {
      marginRight: 10,
      display: 'flex',
      alignItems: 'center',
      '& > svg > path': {
        fill: theme.mode === 'dark' && 'white',
      },
      '& > svg > path:nth-child(2)': {
        fill: theme.mode === 'dark' && '#1D1D1B',
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    currentUser: {
      width: 'calc(100% - 32px) !important',
      marginTop: 'auto !important',
      marginBottom: ({ isOpen }) => (isOpen ? 20 : 0) + 'px !important',
      marginLeft: '16px !important',
      padding: '12px 16px !important',
      borderRadius: '32px !important',
      '&:hover': {
        backgroundColor: theme.palette.bluegray[200],
        '& p': {
          color: theme.palette.blue[500],
        },
        '& .shopname': {
          color: theme.palette.gray[600],
        },
      },
    },
    avatarPlaceholder: {
      position: 'relative',
      height: 40,
      width: 40,
      borderRadius: 20,
      marginRight: 12,
      fontWeight: 600,
      fontSize: 16,
      backgroundColor: theme.palette.blue[600],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      transition: '0.3s',
    },
    shopname: {
      width: 130,
      margin: 0,
      lineHeight: '19px',
      fontWeight: 600,
      fontFamily: "'Inter', sans-serif",
      color: theme.palette.gray[400],
      fontSize: 16,
      transition: 'all .2s',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
    },
    username: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    itemIcon: {
      flex: '0 0 17%',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      transition: '0.3s',
      paddingRight: 12,
      '& svg': {
        margin: 0,
      },
    },
    itemLabel: {
      flex: '1 0 73%',
      lineHeight: '32px',
    },
    itemArrow: {
      flex: '0 0 10%',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    avatar: {
      position: 'relative',
      marginLeft: ({ isOpen }) => (isOpen ? 0 : -4),
      marginTop: ({ isOpen }) => (isOpen ? 0 : -4),
      height: 40,
      width: 40,
      borderRadius: 20,
      overflow: 'hidden',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    settingsIcon: {
      position: 'absolute',
      top: -4,
      right: -4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 16,
      height: 16,
      borderRadius: '50%',
      '& svg': {
        fontSize: 8,
      },
    },
    hr: {
      height: 2,
      border: `1px dashed ${theme.palette.bluegray[200]}`,
    },
    activeChild: {
      transform: 'translateX(0) !important',
    },
    parent: {
      position: 'relative',
      width: '100%',
    },
    child: {
      minHeight: '370px',
      backgroundColor: theme.palette.bluegray[100],
      transform: 'translateX(100%)',
      // transition: 'transform .4s cubic-bezier(.4, .0, .2, 1)',
    },
    skeleton: {
      width: 'calc(100% - 32px)',
      marginTop: 'auto',
      marginBottom: ({ isOpen }) => (isOpen ? 20 : 0),
      marginLeft: 16,
      padding: '12px 16px',
      borderRadius: 32,
      transform: 'none',
      height: 64,
    },
    fakeImage: {
      position: 'absolute',
      height: 40,
      width: 40,
      borderRadius: 20,
      marginRight: 12,
      fontWeight: 600,
      fontSize: 16,
      backgroundColor: theme.palette.blue[600],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      left: 28,
      top: 12,
    },
    news: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 36,
      height: 36,
      borderRadius: '50%',
      marginLeft: '10px',
      backgroundColor: '#DDD6FE',
      '&:hover': {
        backgroundColor: '#C4B5FD',
      },
    },
  };
});
