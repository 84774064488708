import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 12,
    padding: 0,

    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },
  default: {},
  info: {
    background: theme.palette.blue[600],
    '&:hover': {
      background: theme.palette.blue[601],
    },
  },
  error: {
    background: theme.palette.red[500],
    '&:hover': {
      background: theme.palette.red[501],
    },
  },
}));
