/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const insurance = {
  getProductMarks: filter =>
    request.get(
      `v1/insurance/product/mark${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getProductTechNames: filter =>
    request.get(
      `v1/insurance/product/tech-name${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  create: data => request.post(`v1/insurance`, data),
  calculate: data => request.post(`v1/insurance/calculate`, data),
};

export default insurance;
