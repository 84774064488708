/* eslint-disable */
import dayjs from 'dayjs';
import { useAtom, useSetAtom } from 'jotai';
import useCompanyTariff from 'pages/settings/plan/api/useCompanyTariff';
import { planNotificationStateAtom } from 'pages/settings/plan/atoms/usePlanNotification';
import { planStatusAtom } from 'pages/settings/plan/atoms/usePlanStatus';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import thousandDivider from 'shared/utils/thousandDivider';
import NotificationDrawer from './NotificationDrawer';
import paletteLight from 'theme/paletteLight';

export interface IPlanStatus {
  bgColor: string;
  textColor: string;
  text: string;
  drawerTitle: string;
  drawerDesc: string;
  date?: string;
  value?: string;
  status?: 'active' | 'blocked' | 'limited';
}

const PlanNotification = ({ isFullWidth = false }: { isFullWidth?: boolean }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data: tariffData } = useCompanyTariff();
  const [planNotificationState, setPlanNotificationState] = useAtom(planNotificationStateAtom);
  const setPlanStatus = useSetAtom(planStatusAtom);

  const statusData = useMemo<IPlanStatus | null>(() => {
    if (!tariffData) {
      setPlanStatus('active');
      return null;
    }

    const value = `${thousandDivider(tariffData.customer_info.due_amount - tariffData.customer_info.excess_payments)} ${
      tariffData?.currency_code
    }`;
    const date = tariffData?.info_next_date || tariffData?.info_date || '';

    if (tariffData?.info_state === 'active') {
      setPlanStatus('active');
      return null;
    }

    if (tariffData?.info_state === 'in_trial') {
      return {
        bgColor: paletteLight.blue[50],
        textColor: paletteLight.blue[500],
        text: t('plan.notification.free_trial', { date }),
        drawerTitle: t('plan.information.free_trial_title', {
          left: date ? dayjs(date, 'DD.MM.YYYY').diff(dayjs(), 'day') : '-',
        }),
        drawerDesc: 'plan.information.drawer.free_trial_desc',
        date,
      };
    }

    if (tariffData?.info_state === 'blocked') {
      setPlanStatus('blocked');
      setOpen(true);
      return {
        bgColor: paletteLight.red[50],
        textColor: paletteLight.red[500],
        text: t('plan.notification.blocked'),
        drawerTitle: 'plan.information.drawer.blocked_title',
        drawerDesc: 'plan.information.drawer.blocked_desc',
        value,
        status: 'blocked',
      };
    }

    if (tariffData?.info_state === 'overdue') {
      return {
        bgColor: paletteLight.yellow[50],
        textColor: paletteLight.yellow[500],
        text: t('plan.notification.debt', { date }),
        drawerTitle: 'plan.information.drawer.debt_title',
        drawerDesc: 'plan.information.drawer.debt_desc',
        date,
        value,
      };
    }

    if (tariffData?.info_state === 'limited_access') {
      setPlanStatus('limited');
      return {
        bgColor: paletteLight.red[50],
        textColor: paletteLight.red[500],
        text: t('plan.notification.limited', { date }),
        drawerTitle: 'plan.information.drawer.limited_title',
        drawerDesc: 'plan.information.drawer.debt_desc',
        date,
        value,
      };
    }

    return null;
  }, [setPlanStatus, tariffData, t]);

  if (!tariffData || tariffData?.info_state === 'active' || !planNotificationState) {
    return null;
  }

  return (
    <>
      <Box
        sx={theme => {
          return {
            backgroundColor: statusData?.bgColor || theme.palette.blue[100],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isFullWidth ? '16px 144px' : '16px 32px',
            cursor: 'pointer',
            '& svg': {
              color: statusData?.textColor || theme.palette.blue[600],
            },
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            '@media (max-width: 1292px)': {
              padding: isFullWidth ? '16px 64px' : '16px 0',
            },
          };
        }}
        onClick={() => setOpen(true)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <FontAwesomeIcon icon={faCircleExclamation} />
          <Typography color={theme => statusData?.textColor || theme.palette.blue[600]}>{statusData?.text || ''}</Typography>
        </Box>
        <FontAwesomeIcon
          onClick={e => {
            e.stopPropagation();
            setPlanNotificationState(false);
          }}
          icon={faXmark}
        />
      </Box>
      <NotificationDrawer data={statusData} open={open} setOpen={setOpen} />
    </>
  );
};

export default PlanNotification;
