/* eslint-disable */
import './i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ErrorPage from './pages/error';
import ErrorPageLocal from './pages/errorlocal';
import ErrorBoundary from './ErrorBoundary';

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

const isDistEnv = import.meta.env.VITE_APP_ENVIRONMENT === 'prod';
if (import.meta.env.VITE_APP_BUGSNAG) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_APP_BUGSNAG,
    plugins: [new BugsnagPluginReact(React)],
    enabledReleaseStages: ['production'],
  });
}

const ErrorBoundaryBugSnag = import.meta.env.VITE_APP_BUGSNAG && Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const withBoundary = import.meta.env.VITE_APP_BUGSNAG ? (
  <ErrorBoundaryBugSnag FallbackComponent={isDistEnv ? ErrorPage : ErrorPageLocal}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundaryBugSnag>
) : (
  <ErrorBoundary>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>
);

ReactDOM.render(withBoundary, document.getElementById('root'));
