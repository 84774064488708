/* eslint-disable */
import { companyActionTypes } from '../actions/companyActions/companyActionTypes';

export const initialState = {
  company_id: null,
  user_id: null,
  company_name: null,
  user_shops: [],
  current_shop_id: null,
  current_cashbox_id: null,
  company_timezone: {
    gmt: '',
    name: '',
  },
  webkassa: {
    cashier_login: '',
    token: '',
    setTime: '',
  },
  currency: {
    name: '',
    symbol: '',
    iso_code: '',
  },
  supply_currency: {
    name: '',
    symbol: '',
    iso_code: '',
    rate: 0,
  },
  retail_currency: {
    name: '',
    symbol: '',
    iso_code: '',
    rate: 0,
  },
  phone: null,
  country_code: 'UZ',
  catalog_settings: {
    whole_sale: true,
    small_left: true,
    gs1: true,
  },
  report_currency: {
    shop: {
      id: '',
      name: '',
    },
    product: {
      id: '',
      name: '',
    },
    client: {
      id: '',
      name: '',
    },
    finance: {
      id: '',
      name: '',
    },
    seller: {
      id: '',
      name: '',
    },
    product_performance: {
      id: '',
      name: '',
    },
    client_purchase: {
      id: '',
      name: '',
    },
    product_import: {
      id: '',
      name: '',
    },
    product_supplier: {
      id: '',
      name: '',
    },
    product_leftover: {
      id: '',
      name: '',
    },
  },
};

const companyReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case companyActionTypes.SET_COMPANY_ID:
      return {
        ...state,
        company_id: payload,
      };
    case companyActionTypes.SET_COMPANY_CURRENCY:
      return {
        ...state,
        currency: payload,
      };
    case companyActionTypes.SET_SUPPLY_CURRENCY:
      return {
        ...state,
        supply_currency: payload,
      };
    case companyActionTypes.SET_RETAIL_CURRENCY:
      return {
        ...state,
        retail_currency: payload,
      };
    case companyActionTypes.SET_COMPANY_TIMEZONE:
      return {
        ...state,
        company_timezone: payload,
      };
    case companyActionTypes.SET_USER_SHOPS:
      return {
        ...state,
        user_shops: payload,
      };

    case companyActionTypes.REMOVE_COMPANY_ID:
      return {
        ...state,
        company_id: null,
      };

    case companyActionTypes.SET_USER_ID:
      return {
        ...state,
        user_id: payload,
      };

    case companyActionTypes.REMOVE_USER_ID:
      return {
        ...state,
        user_id: null,
      };

    case companyActionTypes.SET_COMPANY_NAME:
      return {
        ...state,
        company_name: payload,
      };
    case companyActionTypes.SET_USER_REGION:
      return {
        ...state,
        currency: payload?.currency,
        country_code: payload?.country_code,
        company_name: payload?.company_name,
        company_timezone: payload?.company_timezone,
      };

    case companyActionTypes.SET_USER_PHONE:
      return {
        ...state,
        phone: payload,
      };
    case companyActionTypes.SET_CURRENT_SHOP_ID:
      return {
        ...state,
        current_shop_id: payload,
      };
    case companyActionTypes.SET_CURRENT_CASHBOX_ID:
      return {
        ...state,
        current_cashbox_id: payload,
      };
    case companyActionTypes.SET_CURRENT_SHOP_NAME:
      return {
        ...state,
        current_shop_name: payload,
      };
    case companyActionTypes.SET_CURRENT_CASHBOX_NAME:
      return {
        ...state,
        current_cashbox_name: payload,
      };
    case companyActionTypes.SET_SETTINGS_CATALOG:
      return {
        ...state,
        catalog_settings: payload,
      };
    case companyActionTypes.SET_REPORT_SHOP_CURRENCY:
      return {
        ...state,
        report_currency: {
          ...state.report_currency,
          shop: payload,
        },
      };
    case companyActionTypes.SET_REPORT_FINANCE_CURRENCY:
      return {
        ...state,
        report_currency: {
          ...state.report_currency,
          finance: payload,
        },
      };
    case companyActionTypes.SET_REPORT_PRODUCT_CURRENCY:
      return {
        ...state,
        report_currency: {
          ...state.report_currency,
          product: payload,
        },
      };
    case companyActionTypes.SET_REPORT_CLIENT_CURRENCY:
      return {
        ...state,
        report_currency: {
          ...state.report_currency,
          client: payload,
        },
      };
    case companyActionTypes.SET_REPORT_SELLER_CURRENCY:
      return {
        ...state,
        report_currency: {
          ...state.report_currency,
          seller: payload,
        },
      };
    case companyActionTypes.SET_REPORT_PRODUCT_PERFORMANCE_CURRENCY:
      return {
        ...state,
        report_currency: {
          ...state.report_currency,
          product_performance: payload,
        },
      };
    case companyActionTypes.SET_REPORT_CLIENT_PURCHASE_CURRENCY:
      return {
        ...state,
        report_currency: {
          ...state.report_currency,
          client_purchase: payload,
        },
      };
    case companyActionTypes.SET_REPORT_PRODUCT_IMPORT_CURRENCY:
      return {
        ...state,
        report_currency: {
          ...state.report_currency,
          product_import: payload,
        },
      };
    case companyActionTypes.SET_REPORT_PRODUCT_SUPPLIER_CURRENCY:
      return {
        ...state,
        report_currency: {
          ...state.report_currency,
          product_supplier: payload,
        },
      };
    case companyActionTypes.SET_WEBKASSA_LOGIN: {
      return {
        ...state,
        webkassa: payload,
      };
    }
    default:
      return state;
  }
};

export default companyReducer;
