/* eslint-disable */
import { tableHeaderActionTypes } from '../actions/suppliersTableHeaderActions/suppliersTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      Header: 'ID',
      accessor: 'external_id',
      is_active: true,
    },
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    { Header: 'Сумма долга', accessor: 'debt_value', is_active: true },
    {
      Header: 'Сумма заказов',
      accessor: 'total_purchase_price',
      is_active: true,
    },
    {
      Header: 'Сумма оплат',
      accessor: 'total_paid_purchase_price',
      is_active: true,
    },
    {
      Header: 'Кол-во товаров',
      accessor: 'total_purchase_measurement_value',
      is_active: true,
    },
    {
      Header: 'Телефон',
      accessor: 'phone_numbers',
      is_active: true,
    },
  ],
};

const supplierTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case tableHeaderActionTypes.UPDATE_SUPPLIERS_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case tableHeaderActionTypes.RESET_SUPPLIERS_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default supplierTableHeaderReducer;
