/* eslint-disable */
const productLeftoverTableHeaders = {
  settings: {
    showCategoriesInOneColumn: true,
  },
  columns: [
    {
      headerName: 'Магазин',
      field: 'shop_name',
      hide: false,
      is_main: true,
      always_active: true,
      lockPosition: 'left',
    },
    {
      headerName: 'Наименование товара',
      field: 'product_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Артикул',
      field: 'product_sku',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Баркод',
      field: 'product_barcode',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Ед. измерения',
      field: 'product_measurement_unit_short_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Категория',
      field: 'product_categories',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Бренд',
      field: 'product_brand_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Поставщик',
      field: 'supplier_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Архивирован',
      field: 'product_is_archived',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Цена продажи',
      field: 'retail_price',
      desc: '',
      hide: false,
      comparable: true,
    },
    {
      headerName: 'Цена поставки',
      field: 'supply_price',
      desc: '',
      hide: false,
      comparable: true,
    },
    {
      headerName: 'Кол-во',
      field: 'measurement_value',
      desc: '',
      hide: false,
      comparable: true,
    },
    {
      headerName: 'Маржа',
      field: 'estimated_margin',
      desc: '',
      hide: false,
      comparable: true,
    },
    {
      headerName: 'data',
      field: 'last_import',
      hide: false,
    },
    {
      headerName: 'Сумма прибыли остатков',
      field: 'estimated_income',
      initialWidth: 330,
      desc: '',
      hide: false,
      comparable: true,
    },
  ],
};

export default productLeftoverTableHeaders;
