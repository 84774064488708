import { lazyLoad } from '../../../shared/utils/loadable';

export const SmsMailingPage = lazyLoad(
  () => import('./SmsMailingPage'),
  module => module.default,
);

export * from './create';
export * from './preview';
export * from './nicknames';
