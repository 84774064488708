/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InputPassword from 'components/Input/InputPassword';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { requests } from 'services/requests';
import ArrowBackIcon from 'shared/icons/ArrowBackIcon';
import { error, success } from 'shared/utils/toast';
import event from 'shared/utils/event';
import Button from 'stories/Button/Button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 42,
    fontSize: 36,
    fontWeight: 900,
    lineHeight: '42px',
    color: theme.palette.black,
    fontFamily: theme.fontFamily.gilroyBold,
    alignItems: 'center',
    display: 'flex',
    '& div': {
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: 16,
    },
  },
  link: {
    color: theme.palette.blue[500],
    '&:hover': {
      color: theme.palette.blue[600],
    },
  },
  formInfo: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 600,
    color: theme.palette.gray[400],
    marginTop: 22,
  },
}));

export const Circle = ({ error }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0.75C3.71875 0.75 0.25 4.21875 0.25 8.5C0.25 12.7812 3.71875 16.25 8 16.25C12.2812 16.25 15.75 12.7812 15.75 8.5C15.75 4.21875 12.2812 0.75 8 0.75ZM8 14.75C4.53125 14.75 1.75 11.9688 1.75 8.5C1.75 5.0625 4.53125 2.25 8 2.25C11.4375 2.25 14.25 5.0625 14.25 8.5C14.25 11.9688 11.4375 14.75 8 14.75Z"
      fill={error ? '#EB5757' : '#BDBDBD'}
    />
  </svg>
);
export const CircleChecked = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 8.5C15.75 4.25 12.25 0.75 8 0.75C3.71875 0.75 0.25 4.25 0.25 8.5C0.25 12.7812 3.71875 16.25 8 16.25C12.25 16.25 15.75 12.7812 15.75 8.5ZM7.09375 12.625C6.90625 12.8125 6.5625 12.8125 6.375 12.625L3.125 9.375C2.9375 9.1875 2.9375 8.84375 3.125 8.65625L3.84375 7.96875C4.03125 7.75 4.34375 7.75 4.53125 7.96875L6.75 10.1562L11.4375 5.46875C11.625 5.25 11.9375 5.25 12.125 5.46875L12.8438 6.15625C13.0312 6.34375 13.0312 6.6875 12.8438 6.875L7.09375 12.625Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default function NewPassword({ setStep, hidden }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useFormContext();
  const password = watch('password');
  const containsNumber = str => {
    return /\d/.test(str);
  };

  const { mutate, isLoading } = useMutation(requests.auth.forgotPasswordChange, {
    onSuccess: () => {
      event('forgot_password_success');
      success('menu.login.successChangePassword');
      setStep('log_in');
    },
    onError: () => {
      error('components.passwordNotMatch');
    },
  });

  const onSubmit = data => {
    setIsSubmitted(true);

    const passcode_token = localStorage.getItem('passcode_token');
    const requestBody = {
      password: data?.password,
      validate_password: data?.confirm_password,
      token: passcode_token,
    };
    if (!!data?.password && !!data?.confirm_password) {
      mutate(requestBody);
    }
  };
  const onError = () => {
    setIsSubmitted(true);
  };

  useEffect(() => {
    event('forgot_password_new_password_entry');
  }, []);

  return (
    !hidden && (
      <Box hidden={hidden}>
        <Box width="100%">
          <h1 className={classes.title}>
            <Box onClick={() => setStep('reset_password')}>
              <ArrowBackIcon />
            </Box>
            {t('menu.settings.profile.new_password')}
          </h1>

          <InputPassword
            id="password"
            autoCompleteOff
            name="password"
            label={t('menu.settings.profile.new_password')}
            placeholder={t('menu.login.newPassword')}
            control={control}
            fullWidth
            error={errors?.password}
            onKeyDown={e => e.key === 'Enter' && handleSubmit(onSubmit, onError)}
            secondary
            minLength={8}
            height={64}
          />
          <InputPassword
            id="confirm_password"
            name="confirm_password"
            placeholder={t('menu.settings.profile.enter_repeat_password')}
            control={control}
            autoCompleteOff
            error={errors?.confirm_password}
            fullWidth
            minLength={8}
            onKeyDown={e => e.key === 'Enter' && handleSubmit(onSubmit, onError)}
            secondary
            height={64}
          />

          <Box mt={4} mb={8}>
            <Typography
              style={{
                display: 'flex',
                color: isSubmitted && password?.length < 8 ? '#EB5757' : '#BDBDBD',
              }}
            >
              {password?.length >= 8 ? <CircleChecked /> : <Circle error={isSubmitted && password?.length < 8} />}
              &nbsp; {t('menu.login.minEightSymbol')}
            </Typography>
            <Typography
              mt={1}
              style={{
                display: 'flex',
                color: isSubmitted && !containsNumber(password) ? '#EB5757' : '#BDBDBD',
              }}
            >
              {containsNumber(password) ? <CircleChecked /> : <Circle error={isSubmitted && !containsNumber(password)} />}
              &nbsp; {t('menu.login.minOneNumber')}
            </Typography>
          </Box>
        </Box>

        <Box width="100%">
          <Button
            fullWidth
            variant="contained"
            size="large"
            id="apply"
            isLoading={isLoading}
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
          >
            {t('buttons.apply')}
          </Button>
        </Box>
      </Box>
    )
  );
}
