import { faBuilding, faCashRegister, faKey, faStore } from '@fortawesome/free-solid-svg-icons';
import { CustomLink } from '../CustomLink';
import { useTranslation } from 'react-i18next';
import { useStyles } from './CompanySettings.styles';
import { Divider, SectionHeader } from '../../../shared/ui';
import LoadingContainer from '../../LoadingContainer';
import { useGetCurrentUserQuery, useGetUserPermissionsQuery } from '@/shared/hooks/user';

const CompanySettings = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: user } = useGetCurrentUserQuery();
  const { data: permissionsData, isLoading } = useGetUserPermissionsQuery(user?.id);

  const canCreateIntegratedUsers = permissionsData?.sections?.find(section => {
    return section?.permissions?.find(permission => permission?.slug === 'create-integrated' && permission.is_active);
  });

  const items = [
    {
      link: '/settings/company/basic',
      title: t('navbar.company'),
      description: t('descriptions.settings.company'),
      color: 'blue.600',
      icon: faBuilding,
    },
    {
      link: '/settings/company/shop',
      title: t('navbar.shops'),
      color: 'violet.500',
      icon: faStore,
      description: t('descriptions.settings.shops'),
    },
    {
      link: '/settings/company/cashbox',
      title: t('navbar.cashboxes'),
      color: 'orange.400',
      icon: faCashRegister,
      description: t('descriptions.settings.cashboxes'),
    },
    ...(canCreateIntegratedUsers
      ? [
          {
            link: '/settings/company/integration-keys',
            title: t('navbar.integration_keys'),
            color: 'green.400',
            icon: faKey,
            description: t('descriptions.settings.integration_keys'),
          },
        ]
      : []),
  ];

  return (
    <LoadingContainer readyState={!isLoading}>
      <div className={classes.root}>
        <SectionHeader className={classes.title} title={t('menu.settings.company.heading')} />

        <Divider type="solid" marginVertical="xl" />

        <div className={classes.content}>
          {items.map(item => (
            <CustomLink key={item.title} {...item} />
          ))}
        </div>
      </div>
    </LoadingContainer>
  );
};

export default CompanySettings;
