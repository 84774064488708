/* eslint-disable */
const productWriteOffTableHeaders = {
  settings: {
    showCategoriesInOneColumn: true,
  },
  columns: [
    {
      headerName: 'ID Списания',
      field: 'write_off_external_id',
      is_main: true,
      lockPosition: 'left',
      suppressMovable: true,
      always_active: true,
      block: 'write_off_properties',
    },
    {
      headerName: 'Название списания',
      field: 'write_off_name',
      is_main: true,
      block: 'write_off_properties',
    },
    {
      headerName: 'Тип списания',
      field: 'write_off_reason',
      is_main: true,
      block: 'write_off_properties',
    },
    // {
    //   headerName: 'ID Магазина',
    //   field: 'shop_id',
    //   is_main: true,
    //   block: 'write_off_properties',
    // },
    {
      headerName: 'Название магазина',
      field: 'shop_name',
      is_main: true,
      block: 'write_off_properties',
    },
    {
      headerName: 'Пользователь',
      field: 'created_by_name',
      is_main: true,
      block: 'write_off_properties',
    },
    {
      headerName: 'Дата создания',
      field: 'created_at',
      is_main: true,
      block: 'write_off_properties',
    },
    {
      headerName: 'Дата завершения',
      field: 'finished_at',
      is_main: true,
      block: 'write_off_properties',
    },
    // {
    //   headerName: 'ID Продукта',
    //   field: 'product_id',
    //   is_main: true,
    //   block: 'product_properties',
    // },
    {
      headerName: 'Наименование',
      field: 'product_name',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Артикул',
      field: 'product_sku',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Баркод',
      field: 'product_barcode',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Единица измерения',
      field: 'product_measurement_unit_name',
      block: 'product_properties',
    },
    {
      headerName: 'Категория',
      field: 'product_categories',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Бренд',
      field: 'product_brand',
      is_main: true,
      block: 'product_properties',
    },

    {
      headerName: 'Поставщик',
      field: 'supplier',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Архивирован',
      field: 'product_is_archived',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Описание',
      field: 'description',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Цена поставки',
      field: 'product_supply_price',
      block: 'product_properties',
    },
    {
      headerName: 'Цена продажи',
      field: 'product_retail_price',
      block: 'product_properties',
    },
    {
      headerName: 'Кол-во списанных',
      field: 'written_off_measurement_value',
      block: 'write_off_details',
    },
    {
      headerName: 'Сумма списания по цене поставки',
      field: 'written_off_supply_price_sum',
      block: 'write_off_details',
    },
    {
      headerName: 'Сумма списания по цене продажи',
      field: 'written_off_retail_price_sum',
      block: 'write_off_details',
      initialWidth: 300,
    },
  ],
};

export default productWriteOffTableHeaders;
