import { lazyLoad } from '../../../../shared/utils/loadable';

export const BulkOperationsPage = lazyLoad(
  () => import('./BulkOperationsPage'),
  module => module.default,
);

export const RoleBulkOperationsPage = lazyLoad(
  () => import('./RoleBulkOperationsPage'),
  module => module.default,
);
