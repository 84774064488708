import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import CatalogLayout from 'layouts/CatalogLayout';
import {
  AllClientsPage,
  ClientCreatePage,
  ClientManagementPage,
  ClientPropertyOperationsPage,
  ClientUpdatePage,
  GroupsTagsPage,
  CardPage,
  LoyaltyProgramPage,
  LoyaltyProgramCreatePage,
  DebtsPage,
  DebtsSMSPage,
  CreateSMSPage,
  OnboardingPage,
  LoyaltyProgramDiscountPage,
  LoyaltyProgramCashbackPage,
  OrderCreatePage,
} from 'pages';

const clientsRoutes = {
  path: 'clients',
  children: [
    {
      path: '',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <AllClientsPage /> },
        { path: 'management', element: <ClientManagementPage /> },
      ],
    },
    {
      path: 'settings',
      element: <CatalogLayout />,
      children: [{ path: '', element: <ClientPropertyOperationsPage /> }],
    },
    {
      path: 'create',
      element: <CatalogLayout />,
      children: [
        { path: '', element: <ClientCreatePage /> },
        { path: '*', element: <Navigate to="/clients/create" /> },
      ],
    },
    {
      path: 'clients-group',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <GroupsTagsPage /> }],
        },
        { path: '*', element: <Navigate to="/clients/clients-group" /> },
      ],
    },
    {
      path: 'update',
      element: <CatalogLayout />,
      children: [
        { path: ':id', element: <ClientUpdatePage /> },
        { path: '*', element: <Navigate to="/clients" /> },
      ],
    },
    {
      path: 'card',
      element: <CatalogLayout />,
      children: [
        { path: ':id', element: <CardPage /> },
        { path: '*', element: <Navigate to="/clients" /> },
      ],
    },
    {
      path: 'loyalty-program',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <LoyaltyProgramPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [
            { path: '', element: <LoyaltyProgramCreatePage /> },
            { path: 'discount', element: <LoyaltyProgramDiscountPage /> },
            { path: 'cashback', element: <LoyaltyProgramCashbackPage /> },
            {
              path: '*',
              element: <Navigate to="/clients/loyalty-program/create" />,
            },
          ],
        },
        { path: '*', element: <Navigate to="/clients/loyalty-program" /> },
      ],
    },
    {
      path: 'debts',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [
            { path: '', element: <DebtsPage /> },
            { path: 'cashbox', element: <OrderCreatePage isDebt /> },
          ],
        },
        {
          path: 'sms-create',
          element: <CatalogLayout />,
          children: [
            { path: '', element: <CreateSMSPage /> },
            {
              path: '*',
              element: <Navigate to="/clients/debts/cashbox" />,
            },
          ],
        },
        {
          path: 'sms',
          element: <DashboardLayout />,
          children: [
            { path: '', element: <DebtsSMSPage /> },
            { path: 'onboarding', element: <OnboardingPage /> },
            { path: '*', element: <Navigate to="/clients/debts/sms" /> },
          ],
        },
      ],
    },
    { path: '*', element: <Navigate to="/clients" /> },
  ],
};

export default clientsRoutes;
