import { lazyLoad } from '@/shared/utils';

export const DashboardPage = lazyLoad(
  () => import('./DashboardPage'),
  module => module.default,
);

export const WelcomePage = lazyLoad(
  () => import('./WelcomePage'),
  module => module.default,
);

export * from './yearly-stats';
