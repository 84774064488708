/* eslint-disable */
import { permissionRouteActionTypes } from 'store/actions/permissonRouteActions/permissionRouteActionTypes';

const initialState = {
  permissions: [],
  forbiddenRoutes: [],
};
function removeUnnessesaryData(route) {
  if (route?.children?.length) {
    return {
      ...route,
      element: '',
      children: route.children?.map(el => removeUnnessesaryData(el)),
    };
  }
  return {
    ...route,
    element: '',
  };
}
const permissionRouteReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case permissionRouteActionTypes.SET_ROUTES:
      return {
        permissions: payload?.permissions?.map(el => removeUnnessesaryData(el)),
        forbiddenRoutes: payload?.forbiddenRoutes || [],
      };
    default:
      return state;
  }
};

export default permissionRouteReducer;
