/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const cheque = {
  getAll: filter => request.get(`v1/cheque${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v1/cheque`, data),
  getSingle: id => request.get(`v1/cheque/${id}`),
  update: data => request.put(`v1/cheque/${data.id}`, data.data),
  delete: id => request.delete(`v1/cheque/${id}`),
  getOptions: () => request.get(`v1/cheque-option`),
};

export default cheque;
