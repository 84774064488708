/* eslint-disable */
const WarningExclamationIcon = props =>
  props.small ? (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7812 14.7812C19.3438 15.7812 18.625 17 17.4688 17H2.5C1.34375 17 0.625 15.75 1.1875 14.7812L8.6875 1.75C9.25 0.75 10.7188 0.78125 11.2812 1.75L18.7812 14.7812ZM10 12.0625C9.1875 12.0625 8.5625 12.7188 8.5625 13.5C8.5625 14.3125 9.1875 14.9375 10 14.9375C10.7812 14.9375 11.4375 14.3125 11.4375 13.5C11.4375 12.7188 10.7812 12.0625 10 12.0625ZM8.625 6.90625L8.84375 11.1562C8.875 11.375 9.03125 11.5 9.21875 11.5H10.75C10.9375 11.5 11.0938 11.375 11.125 11.1562L11.3438 6.90625C11.375 6.6875 11.1875 6.5 10.9688 6.5H9C8.78125 6.5 8.59375 6.6875 8.625 6.90625Z"
        fill="#F2C94C"
      />
    </svg>
  ) : (
    <svg width="43" height="38" viewBox="0 0 43 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M41.2578 32.5078L24.3828 3.1875C23.1172 1.00781 19.8125 0.9375 18.5469 3.1875L1.67188 32.5078C0.40625 34.6875 2.02344 37.5 4.625 37.5H38.3047C40.9062 37.5 42.5234 34.7578 41.2578 32.5078ZM21.5 26.3906C23.2578 26.3906 24.7344 27.8672 24.7344 29.625C24.7344 31.4531 23.2578 32.8594 21.5 32.8594C19.6719 32.8594 18.2656 31.4531 18.2656 29.625C18.2656 27.8672 19.6719 26.3906 21.5 26.3906ZM18.4062 14.7891C18.3359 14.2969 18.7578 13.875 19.25 13.875H23.6797C24.1719 13.875 24.5938 14.2969 24.5234 14.7891L24.0312 24.3516C23.9609 24.8438 23.6094 25.125 23.1875 25.125H19.7422C19.3203 25.125 18.9688 24.8438 18.8984 24.3516L18.4062 14.7891Z"
        fill="#F2C94C"
      />
    </svg>
  );

export default WarningExclamationIcon;
