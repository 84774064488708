/* eslint-disable */
import { Box } from '@mui/material';
import StyledTooltip from 'components/StyledTooltip';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import thousandDivider from 'shared/utils/thousandDivider';
import Tab from './Tab';

interface Tab {
  label: string;
  id: any;
  tooltip?: any;
  placement?: string;
  icon?: React.ReactNode;
  noCount?: boolean;
  checkSlug?: string;
  separator?: boolean;
}

interface Props {
  tabs: Tab[];
  setSelected: (id: any) => void;
  selected: any;
  counts?: number[] | undefined;
  thousandDividable?: boolean;
  countFormatter?: (count: number) => string;
  primary?: boolean;
  className?: string;
}

const TabContainer: React.FC<Props> = ({
  tabs = [],
  setSelected,
  selected,
  counts,
  thousandDividable = true,
  countFormatter,
  primary,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      className={className}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {tabs.map((tab, index) => {
        return (
          <div key={tab.id}>
            {tab.tooltip ? (
              <StyledTooltip title={tab?.tooltip} placement={`${tab?.placement ? tab?.placement : 'bottom'}`}>
                <Tab
                  primary={primary}
                  id={tab?.id}
                  key={tab?.id}
                  tabIndex={index}
                  name={`${t(tab?.label) || ''} ${
                    counts && !tab?.noCount
                      ? `(${
                          countFormatter
                            ? countFormatter(counts?.[index] || 0)
                            : thousandDividable
                              ? thousandDivider(counts?.[index] || 0)
                              : counts?.[index] || 0
                        })`
                      : ''
                  }`}
                  selected={selected === tab?.id}
                  setTab={setSelected}
                  icon={tab?.icon}
                  checkSlug={tab?.checkSlug}
                />
                {tab?.separator && (
                  <Box
                    sx={theme => ({
                      height: '35px',
                      borderRight: `2px solid ${theme.palette.gray[200]}`,
                      margin: '0 8px',
                    })}
                  />
                )}
              </StyledTooltip>
            ) : (
              <Box display="flex" alignItems="center">
                <Tab
                  primary={primary}
                  id={tab?.id}
                  key={tab?.id}
                  tabIndex={index}
                  name={`${t(tab?.label) || ''} ${
                    counts && !tab?.noCount
                      ? `(${
                          countFormatter
                            ? countFormatter(counts?.[index] || 0)
                            : thousandDividable
                              ? thousandDivider(counts?.[index] || 0)
                              : counts?.[index] || 0
                        })`
                      : ''
                  }`}
                  selected={selected === tab?.id}
                  setTab={setSelected}
                  checkSlug={tab?.checkSlug}
                />
                {tab?.separator && (
                  <Box
                    sx={theme => ({
                      height: '35px',
                      borderRight: `2px solid ${theme.palette.gray[200]}`,
                      margin: '0 8px',
                    })}
                  />
                )}
              </Box>
            )}
          </div>
        );
      })}
    </Box>
  );
};

export default memo(TabContainer);
