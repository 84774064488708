/* eslint-disable */
import { clientTableHeaderActionTypes } from '../actions/clientTableHeaderActions/clientTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      Header: 'ID',
      accessor: 'id',
      is_active: true,
    },
    {
      accessor: 'name',
      is_active: true,
    },
    { accessor: 'phone_number', is_active: true },

    {
      accessor: 'groups',
      is_active: true,
    },
    {
      accessor: 'tags',
      is_active: true,
    },
    {
      accessor: 'purchase_amount',
      is_active: true,
    },
    {
      accessor: 'last_purchase_date',
      is_active: true,
    },
    {
      accessor: 'birth_date',
      is_active: true,
    },
    {
      accessor: 'created_at',
      is_active: true,
    },
    { accessor: 'shop_name', is_active: true },
    { accessor: 'balance', is_active: true },
    {
      accessor: 'debt_amount',
      is_active: true,
    },
    {
      accessor: 'actions',
      is_active: true,
    },
  ],
};

const clientTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case clientTableHeaderActionTypes.UPDATE_CLIENT_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case clientTableHeaderActionTypes.RESET_CLIENT_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default clientTableHeaderReducer;
