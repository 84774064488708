import React from 'react';
import { useStyles } from './InlineMessage.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Typography } from '../Typography';

type InlineMessageProps = {
  status: 'warning' | 'info';
  text: string;
};

const InlineMessage = ({ status, text }: InlineMessageProps) => {
  const classes = useStyles();

  const iconsMap: Record<InlineMessageProps['status'], React.ReactElement> = {
    warning: <FontAwesomeIcon icon={faExclamationTriangle} className={classes.warning} size="xl" />,
    info: <FontAwesomeIcon icon={faInfoCircle} className={classes.info} size="xl" />,
  };

  return (
    <div className={classes.root}>
      <div>{iconsMap[status]}</div>
      <div>
        <Typography>{text}</Typography>
      </div>
    </div>
  );
};

export default InlineMessage;
