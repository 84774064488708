/* eslint-disable */
import { faPhone, faComment, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog';
import { IPlanStatus } from 'pages/settings/plan/notification/Notification';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GoToMainIcon from 'shared/icons/GoToMainIcon';
import Button from 'stories/Button/Button';
import paletteLight from 'theme/paletteLight';

interface NotificationDrawerProps {
  data: IPlanStatus | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const NotificationDrawer = ({ data, open, setOpen }: NotificationDrawerProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const desc = useMemo(() => {
    return (
      <Trans
        i18nKey={data?.drawerDesc || ''}
        components={{
          span: (
            <span
              style={{
                color: paletteLight.blue[600],
              }}
            />
          ),
          p: <Typography />,
          br: <br />,
        }}
        values={{
          date: data?.date || '',
          value: data?.value || '',
        }}
      />
    );
  }, [data]);

  return (
    <ConfirmDialog
      open={open}
      setOpen={setOpen}
      icon={
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          style={{
            color: data?.status === 'blocked' ? paletteLight.red[500] : paletteLight.yellow[500],
          }}
          size="3x"
        />
      }
      title={data?.drawerTitle || ''}
      noCloseIcon={data?.status === 'blocked'}
      descComponent={<DescComponent desc={desc} setOpen={setOpen} />}
      actions={
        <>
          <Button secondary onClick={() => setOpen(false)}>
            {t('buttons.return')}
          </Button>
          {data?.status === 'blocked' ? null : (
            <Button
              variant="contained"
              onClick={() => {
                navigate('/settings/plan');
                setOpen(false);
              }}
            >
              {t('plan.plan_details')}
            </Button>
          )}
        </>
      }
    />
  );
};

export default NotificationDrawer;

const DescComponent = ({ desc, setOpen }: { desc: React.ReactNode; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { t } = useTranslation();
  return (
    <Box>
      {desc}
      <Typography mt={2}>{t('plan.information.drawer.sub_desc')}</Typography>
      <ContactComponent setOpen={setOpen} />
    </Box>
  );
};

const ContactComponent = ({ setOpen }: { setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { t } = useTranslation();
  const { country_code } = useSelector(state => state?.company);

  const items = useMemo(
    () => [
      {
        icon: faPhone,
        title: 'plan.drawer.contact.with_us',
        desc: country_code?.toLowerCase() === 'kz' ? '+7 707 774 01 70' : '+998 78 113 71 44',
        onClick: () => {
          window.open(`tel:${country_code?.toLowerCase() === 'kz' ? '+77077740170' : '+998781137144'}`);
        },
      },
      {
        icon: faTelegram,
        title: 'need_help.write_to',
        desc: country_code?.toLowerCase() === 'kz' ? 'WhatsApp' : 'Telegram',
        onClick: () => {
          country_code?.toLowerCase() === 'kz'
            ? window.open('https://wa.me/+77018307144', '_blank')
            : window.open('https://t.me/billzsupport_bot', '_blank');
        },
      },
      {
        icon: faComment,
        title: 'need_help.write_to',
        desc: 'chaport.online_chat',
        onClick: () => {
          window.chaport.q('open');
          setOpen(false);
        },
      },
    ],
    [country_code],
  );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '8px',
        marginTop: '32px',
      }}
    >
      {items.map((item, i) => (
        <Box
          key={i}
          onClick={item.onClick}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            borderRadius: '16px',
            backgroundColor: 'bluegray.100',
            '&:hover': {
              backgroundColor: 'bluegray.200',
            },
            transition: 'background-color .1s ease-in',
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon color={paletteLight.blue[600]} icon={item.icon} />
          <Typography mt={2}>{t(item.title)}</Typography>
          <Typography mt={1} color="blue.600">
            {t(item.desc)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
