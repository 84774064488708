/* eslint-disable */
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  header: {
    padding: '64px 72px 32px',
    borderBottom: '2px solid #EAEAEA;',
  },
  title: {
    whiteSpace: 'nowrap',
    fontSize: 36,
    fontWeight: 900,
    lineHeight: '42px',
    color: theme.palette.black,
    fontFamily: theme.fontFamily.gilroyBold,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: 16,
    },
  },
  button: {
    borderRadius: 32,
    width: 160,
    height: 48,
  },
  accountSettings: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  section: {
    padding: '32px 72px',
  },
  error: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.gray[400],
    '&:first-child': {
      marginRight: 16,
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 32,
    gridRowGap: 8,
  },
  percentageBox: {
    background: theme.palette.gray[200],
    borderRadius: 12,
    maxHeight: 48,
    width: 100,
    marginRight: 1,
  },
  currencyRate: {
    width: 320,
    border: `1px dashed ${theme.palette.gray[300]}`,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 56,
  },
}));
