/* eslint-disable */
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowForward from 'shared/icons/ArrowForward';
import { useFormContext } from 'react-hook-form';
import Button from 'stories/Button/Button';
import InputSimple from 'components/Input/InputSimple';
import InputPassword from 'components/Input/InputPassword';
import { Circle, CircleChecked } from 'pages/login/NewPassword';
import { useTranslation } from 'react-i18next';
import { useStyles } from './useStyles';
import Steps from './Steps';
import { makeStyles } from '@mui/styles';

const UserDetails = ({ setStep }) => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError,
  } = useFormContext();
  const password = watch('password');
  const containsNumber = str => {
    return /\d/.test(str);
  };
  const classes = useStyles();

  const onSubmit = data => {
    setIsSubmitted(true);
    if (data?.password !== data?.confirm_password || !containsNumber(data?.password)) {
      setError('password', { type: 'not equal' });
      setError('confirm_password', { type: 'not equal' });
      return;
    }
    setStep('company_segment');
  };
  const onError = () => {
    setIsSubmitted(true);
  };
  return (
    <>
      <header className={classes.header}>
        <Steps current={1} />
        <div className={classes.accountSettings}>
          <h1 className={classes.title}>{t('menu.register.accountSettings')}</h1>
          <Button
            variant="contained"
            className={classes.button}
            size="small"
            type="submit"
            onClick={handleSubmit(onSubmit, onError)}
            fullWidth
            adornmentEnd={<ArrowForward fill="#fff" />}
          >
            {t('dashboard.next')}
          </Button>
        </div>
      </header>
      <section className={classes.section}>
        <Box mb={4} display="flex">
          <input name="first_name" id="first_name" type="hidden" />
          <input name="last_name" id="last_name" type="hidden" />
          <input name="password" id="password" type="hidden" />

          <InputSimple
            id="first_name"
            name="first_name"
            label={'menu.settings.profile.name'}
            placeholder={'menu.register.yourName'}
            control={control}
            fullWidth
            autoCompleteOff
            boxStyle={{ mr: 4 }}
            required
            error={errors?.first_name}
            secondary
            inputHeight={56}
          />
          <InputSimple
            id="last_name"
            name="last_name"
            label={'menu.clients.new.last_name'}
            placeholder={'menu.register.yourSurname'}
            control={control}
            autoCompleteOff
            fullWidth
            error={errors?.last_name}
            required
            secondary
            inputHeight={56}
          />
        </Box>
        <Box mb={4} display="flex">
          <InputPassword
            id="password"
            autoCompleteOff
            name="password"
            label={t('menu.settings.profile.password')}
            placeholder={t('menu.register.createPassword')}
            control={control}
            fullWidth
            boxStyle={{ mr: 4 }}
            error={errors?.password}
            secondary
            minLength={8}
          />
          <InputPassword
            id="confirm_password"
            name="confirm_password"
            placeholder={t('menu.settings.profile.enter_repeat_password')}
            control={control}
            autoCompleteOff
            error={errors?.confirm_password}
            fullWidth
            noLabel
            minLength={8}
            secondary
          />
        </Box>
        <Box display="flex">
          <Typography
            style={{
              display: 'flex',
              marginRight: 16,
              color: isSubmitted && password?.length < 8 ? '#EB5757' : '#BDBDBD',
            }}
          >
            {password?.length >= 8 ? <CircleChecked /> : <Circle error={isSubmitted && password?.length < 8} />}
            &nbsp; {t('menu.login.minEightSymbol')}
          </Typography>
          <Typography
            style={{
              display: 'flex',
              color: isSubmitted && !containsNumber(password) ? '#EB5757' : '#BDBDBD',
            }}
          >
            {containsNumber(password) ? <CircleChecked /> : <Circle error={isSubmitted && !containsNumber(password)} />}
            &nbsp; {t('menu.login.minOneNumber')}
          </Typography>
        </Box>
      </section>
    </>
  );
};

export default UserDetails;
