/* eslint-disable */
import { Box, Typography } from '@mui/material';
import UserLogOutDrawer from 'layouts/Navbar/UserLogOutDrawer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from 'shared/icons/CloseIcon';
import Button from 'stories/Button/Button';

const UserInfo = ({ userDetails, classes, closeDrawer }) => {
  const { t } = useTranslation();
  const [errorProfileImage, setErrorProfileImage] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  useEffect(() => {
    if (userDetails) {
      setErrorProfileImage(false);
    }
  }, [userDetails]);

  return (
    <>
      <Box>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h1" className={classes.title}>
              {t('menu.profile.account')}{' '}
            </Typography>
          </Box>
          <Box onClick={closeDrawer}>
            <CloseIcon />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt={4}>
          <Box width={40} height={40} borderRadius={2} overflow="hidden">
            {errorProfileImage && !userDetails?.image_url ? (
              <div className={classes.avatarPlaceholder}>
                {userDetails?.first_name.charAt(0)}
                {userDetails?.last_name.charAt(0)}
              </div>
            ) : (
              <img
                onError={() => setErrorProfileImage(true)}
                src={userDetails?.image_url}
                alt={userDetails?.first_name}
                className={classes.avatar}
              />
            )}
          </Box>
          <Box ml={1.5}>
            <Typography>{`${userDetails?.first_name} ${userDetails?.last_name}`}</Typography>
            <Typography className={classes.shopname}>
              {userDetails?.shops?.find(item => item.shop_id === userDetails?.current_shop_id)?.shop?.name}
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Button secondary fullWidth className={classes.logoutBtn} onClick={() => setIsLogout(true)}>
            {t('menu.profile.account_exit')}
          </Button>
        </Box>
      </Box>
      <UserLogOutDrawer
        isOpen={isLogout}
        closeDrawer={() => {
          setIsLogout(false);
          closeDrawer();
        }}
        goBack={() => setIsLogout(false)}
      />
    </>
  );
};

export default UserInfo;
