import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 0.75,
    },
  },
}));
