import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Provider as JotaiProvider } from 'jotai';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Theme, ThemeProvider } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useStore } from 'store/store';
import { createTheme } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';
import { theme } from 'theme';
import paletteLight from 'theme/paletteLight';
import paletteDark from 'theme/paletteDark';
import useListenSystemTheme from './shared/hooks/useListenSystemTheme';
import i18n from './i18n';
import { Palette } from './theme/types';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {
    mode: string;
    palette: Palette;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function Providers({ children }: { children: React.ReactNode }) {
  const { values } = useQueryParams();
  const store = useStore();
  const persistor = persistStore(store);
  const [themeMode, setThemeMode] = useState('auto');
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
  useListenSystemTheme(setThemeMode);

  useEffect(() => {
    const user_theme = localStorage.getItem('user_theme');

    if (!user_theme || user_theme === 'auto') {
      setThemeMode(prefersDarkMode.matches ? 'dark' : 'light');
    } else {
      setThemeMode(user_theme);
    }
  }, [values.theme_changed, prefersDarkMode]);

  const muiTheme = useMemo(() => {
    const themeObj = theme({
      palette: themeMode === 'dark' ? paletteDark : paletteLight,
      mode: themeMode,
    });
    return createTheme(themeObj);
  }, [themeMode]);

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <JotaiProvider>
              <ThemeProvider theme={muiTheme}>
                <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
              </ThemeProvider>
            </JotaiProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  );
}

export default Providers;
