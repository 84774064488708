import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UserServiceNS, UserService } from '@/shared/services';

type Options = UseQueryOptions<UserServiceNS.UserDTO, unknown, UserServiceNS.UserDTO, string[]>;

export const useGetCurrentUserQuery = (options: Options = {}) => {
  return useQuery({
    queryKey: ['USER_SELF'],
    queryFn: async () => (await UserService.getUser().fetch()).data,
    ...options,
  });
};
