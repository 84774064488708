import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: 'fit-content',
    minWidth: 'fit-content',
    width: '100%',
    fontSize: '16px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    '&:disabled': {
      cursor: 'default',
    },
  },

  primary: {
    background: theme.palette?.blue[600],
    color: theme.palette?.common.white,

    '&:hover': {
      background: theme.palette?.blue[601],
    },
  },
  secondary: {
    background: theme.palette?.gray[100],
    color: theme.palette?.blue[500],

    '&:hover': {
      background: theme.palette?.gray[101],
    },
  },
  error: {
    background: theme.palette?.gray[100],
    color: theme.palette?.red[500],

    '&:hover': {
      background: theme.palette?.gray[101],
    },
  },

  sm: {
    padding: '0.875rem 4.25rem',
  },
  md: {
    padding: '1.125rem 4.25rem',
  },
  lg: {
    padding: '1.375rem 4.25rem',
  },

  spacing_default: {},
  spacing_fit: {
    '&$sm': {
      padding: '0.875rem',
    },
    '&$md': {
      padding: '1.125rem',
    },
    '&$lg': {
      padding: '1.375rem',
    },
  },
}));
