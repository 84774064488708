/* eslint-disable */
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWebSocketQueryFn } from 'services/websocket/useWebSocketQueryFn';
import { v4 as uuidv4 } from 'uuid';
import { useWebSocketMessageHandlerNew } from 'services/websocket/WebsocketManagerNew';

interface IWebSocketMutation {
  onWebsocketSuccess?: (msg: any) => void;
  onWebsocketError?: (msg: any) => void;
  onSuccess?: (msg: any) => void;
  onError?: (msg: any) => void;
  onMutate?: () => void;
  answersQty?: number;
}

function useUpdatedCallback(handler) {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args) => {
    const currentHandler = handlerRef.current;
    if (currentHandler) {
      currentHandler(...args);
    }
  }, []);
}

export function useWebsocketMutationNew(
  request: any,
  { onWebsocketSuccess, onWebsocketError, onError, onSuccess, onMutate, answersQty = 1 }: IWebSocketMutation,
) {
  const { enqueueMutation, setLoading } = useWebSocketMessageHandlerNew() || {};
  const stableOnWebsocketSuccess = useUpdatedCallback(onWebsocketSuccess);
  const stableOnWebsocketError = useUpdatedCallback(onWebsocketError);
  const stableOnSuccess = useUpdatedCallback(onSuccess);
  const stableOnError = useUpdatedCallback(onError);

  // const loading = useRef(false)
  const [isLoading, setIsLoading] = useState(false);

  const { data, mutate, isSuccess } = useMutation(request, {
    onMutate: () => {
      if (onMutate) onMutate();
    },
    onSuccess: data => {
      const correlationId = data?.data?.message || data?.data?.correlation_id || data?.data?.id;
      if (stableOnSuccess) stableOnSuccess(data);

      if (correlationId) {
        setLoading!(correlationId, answersQty);
      } else {
        setIsLoading(false);
      }
    },
    onError: err => {
      setIsLoading(false);
      stableOnError(err);
    },
  });
  const correlationId = data?.data?.message || data?.data?.correlation_id || data?.data?.id;

  useWebSocketQueryFn({
    setIsLoading,
    correlationId,
    onSuccess: stableOnWebsocketSuccess,
    onError: stableOnWebsocketError,
  });

  const handleMutation = (data: any) => {
    if (enqueueMutation) {
      setIsLoading(true);
      enqueueMutation(uuidv4(), () => mutate(data));
    }
  };

  return useMemo(
    () => ({
      mutate: handleMutation,
      isLoading,
      isSuccess,
    }),
    [mutate, handleMutation, isLoading, isSuccess],
  );
}
