/* eslint-disable */
import { revaluationCreateTableHeaderActionTypes } from '../actions/revaluationCreateTableHeaderActions/revaluationCreateTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'Курс',
      accessor: 'course',
      is_active: true,
    },
    { Header: 'Цена поставщика', accessor: 'price', is_active: true },
    { Header: 'Цена продажи', accessor: 'retail_price', is_active: true },
    { Header: 'Наценка', accessor: 'margin', is_active: true },
    {
      Header: 'Кол-во',
      accessor: 'quantity',
      is_active: true,
    },
  ],
};

const revaluationCreateTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case revaluationCreateTableHeaderActionTypes.UPDATE_REVALUATION_CREATE_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case revaluationCreateTableHeaderActionTypes.RESET_REVALUATION_CREATE_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default revaluationCreateTableHeaderReducer;
