const abcReportTableHeaders = {
  settings: {
    showCategoriesInOneColumn: true,
  },
  columns: [
    {
      headerName: 'Сегмент',
      field: 'segment',
      hide: false,
      is_main: true,
      always_active: true,
      lockPosition: 'left',
    },
    {
      headerName: 'Наименование товара',
      field: 'product_name',
      hide: false,
      always_active: true,
      is_main: true,
      lockPosition: 'left',
    },
    {
      headerName: 'Артикул',
      field: 'product_sku',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Баркод',
      field: 'product_barcode',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Кол-во продаж',
      field: 'indicator',
      hide: false,
    },
    {
      headerName: 'Вклад в %',
      field: 'contribution',
      hide: false,
    },
    {
      headerName: 'Накопительный %',
      field: 'accumulation',
      hide: false,
    },
    {
      headerName: 'Остаток на начало',
      field: 'stock_amount_start',
      hide: false,
    },
    {
      headerName: 'Остаток на конец',
      field: 'stock_amount_end',
      hide: false,
    },
    {
      headerName: 'Среднее кол-во продаж в день',
      field: 'day_avg_sold_qty',
      hide: false,
    },
    {
      headerName: 'Дней до пополнения',
      field: 'days_to_refill',
      hide: false,
    },
  ],
};

export default abcReportTableHeaders;
