/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { AgGridColumnProps } from 'ag-grid-react';

interface PromocodesTableHeaderSlice {
  columns: AgGridColumnProps[];
}

export const initialState: PromocodesTableHeaderSlice = {
  columns: [
    {
      headerName: 'ID',
      field: 'id',
      hide: false,
      minWidth: 70,
      width: 129,
    },
    {
      headerName: 'Промокод',
      field: 'promo_code',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Магазин',
      field: 'shop',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Кол-во использований',
      field: 'count',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Средний чек',
      field: 'avg_order_sum',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Ср. кол-во продуктов',
      field: 'avg_order_products_count',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Сумма транзакций',
      field: 'sum_order_sum',
      hide: false,
      minWidth: 70,
    },
  ],
};

const promocodesTableHeaderSlice = createSlice({
  name: 'promocodesTableHeader',
  initialState,
  reducers: {
    updateTable: (state, action) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    resetTable: () => {
      return initialState;
    },
  },
});

export const { updateTable, resetTable } = promocodesTableHeaderSlice.actions;
export default promocodesTableHeaderSlice.reducer;
