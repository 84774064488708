import { persistReducer, createMigrate } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import { combineReducers } from 'redux';
import guideReducer from './guideReducers';
import onboardingReducer from './onboardingReducer';
import certificatesTableHeaderReducer, { initialState as initialStateHeaders } from './certificatesTableHeaderReducer';
import tableHeaderReducer, { initialState as initialCatalogTableHeaders } from './catalogTableHeaderReducer';
import companyReducer, { initialState } from './companyReducers';
import cartReducer from './cartReducer';
import importTableHeaderReducer, { initialState as initialImportTableHeaders } from './importTableHeaderReducers';
import writeOffTableHeaderReducer, { initialState as initialWriteOffTableHeaders } from './writeOffTableHeaderReducers';
import importSingleTableHeaderReducer, { initialState as initialImportSingleTableHeaders } from './importSingleTableHeaderReducers';
import importReducer from './importReducers';
import transferTableHeaderReducer, { initialState as initialTransferTableHeaders } from './transferTableHeaderReducers';
import revaluationTableHeaderReducer, { initialState as initialRevaluationTableHeaders } from './revaluationTableHeaderReducers';
import transferCreateTableHeaderReducer, { initialState as initialTransferCreateTableHeaders } from './transferCreateTableHeaderReducers';
import writeOffCreateTableHeaderReducer, { initialState as initialWriteOffCreateTableHeaders } from './writeOffCreateTableHeaderReducers';
import revaluationCreateTableHeaderReducer, {
  initialState as initialRevaluationCreateTableHeaders,
} from './revaluationCreateTableHeaderReducers';
import transferSingleTableHeaderReducer from './transferSingleTableHeaderReducers';
import repricingReducer from './repricingReducers';
import clientTableHeaderReducer, { initialState as initialClientTableHeaders } from './clientTableHeaderReducers';
import supplierTableHeaderReducer, { initialState as supplierTableHeader } from './supplierTableHeaderReducers';
import inventoryScanTableHeaderReducer, { initialState as initialInventoryScanTableHeaders } from './inventoryScanTableHeaderReducers';
import ordersTableHeaderReducer, { initialState as initialOrderTableHeaders } from './ordersTableHeaderReducers';
import orderCreateTableHeaderReducer, { initialState as initialOrderCreateTableHeaders } from './orderCreateTableHeaderReducers';
import employeeMassiveOperationReducer from './employeeMassiveOperationReducer';
import productMassiveOperationReducer from './productMassiveOperationReducer';
import permissionRouteReducer from './permissionRouteReducers';
import backgroundProgressReducer from './backgroundProgress';
import openSidebarReducer from './openSidebarReducer';
import reportTableHeaderReducer, { initialState as initialReportTableHeaders } from './reportTableHeaderReducers';
import promoTableHeaderReducer, { initialState as initialPromoTableHeaders } from './promoTableHeaderReducers';
import locationReducer, { initialState as initialLocationReducer } from '../slicers/locationSlice';
import inventoryTableHeaderReducer, { initialState as initialInventoryTableHeaders } from '../slicers/inventoryTableHeaderSlice';
import returnOrderTableHeaderReducer, { initialState as initialReturnOrderTableHeaders } from '../slicers/returnOrderTableHeaderSlice';
import returnCreateTableHeaderReducer, { initialState as initialReturnCreateTableHeaders } from '../slicers/returnCreateTableHeaderSlice';
import orderViewTableHeaderReducer, { initialState as initialOrderViewTableHeaders } from '../slicers/orderViewTableHeaderSlice';
import orderAcceptTableHeaderReducer, { initialState as initialOrderAcceptTableHeaders } from '../slicers/orderAcceptTableHeaderSlice';
import variationsStateReducer, { initialState as initialVariationsState } from '../slicers/variationsStateSlice';
import promocodesTableHeaderReducer, { initialState as initialPromocodesState } from '../slicers/promocodesTableHeaderSlice';
import archivedProductsTableHeaderReducer, {
  initialState as initialArchivedProductsState,
} from 'store/slicers/archivedProductsTableHeaderSlice';

const migrations = {
  130: state => ({
    ...state,
    tableHeader: initialCatalogTableHeaders,
    reportTableHeaders: initialReportTableHeaders,
    revaluationCreateTableHeader: initialRevaluationCreateTableHeaders,
    importSingleTableHeader: initialImportSingleTableHeaders,
    importTableHeader: initialImportTableHeaders,
    orderAcceptTableHeader: initialOrderAcceptTableHeaders,
    orderCreateTableHeader: initialOrderCreateTableHeaders,
    ordersTableHeader: initialOrderTableHeaders,
    writeOffCreateTableHeader: initialWriteOffCreateTableHeaders,
    promoTableHeader: initialPromoTableHeaders,
    clientTableHeader: initialClientTableHeaders,
    transferCreateTableHeader: initialTransferCreateTableHeaders,
    transferTableHeader: initialTransferTableHeaders,
    inventoryScanTableHeader: initialInventoryScanTableHeaders,
    inventoryTableHeader: initialInventoryTableHeaders,
    returnOrderTableHeader: initialReturnOrderTableHeaders,
    returnCreateTableHeader: initialReturnCreateTableHeaders,
    orderViewTableHeader: initialOrderViewTableHeaders,
    promocodesTableHeader: initialPromocodesState,
    location: initialLocationReducer,
    supplierTableHeader: supplierTableHeader,
    company: initialState,
    revaluationTableHeader: initialRevaluationTableHeaders,
    writeOffTableHeader: initialWriteOffTableHeaders,
    variationsStateReducer: initialVariationsState,
    certificateTableHeaders: initialStateHeaders,
    archivedProductsTableHeader: initialArchivedProductsState,
  }),
};

const persistConfig = {
  key: 'root',
  storage: localStorage,
  version: 130,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: ['productMassiveOperation', 'cart'],
};

const cartConfig = {
  key: 'cart',
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  cart: persistReducer(cartConfig, cartReducer),
  guide: guideReducer,
  onboarding: onboardingReducer,
  tableHeader: tableHeaderReducer,
  company: companyReducer,
  importTableHeader: importTableHeaderReducer,
  inventoryTableHeader: inventoryTableHeaderReducer,
  returnOrderTableHeader: returnOrderTableHeaderReducer,
  returnCreateTableHeader: returnCreateTableHeaderReducer,
  orderViewTableHeader: orderViewTableHeaderReducer,
  writeOffTableHeader: writeOffTableHeaderReducer,
  importSingleTableHeader: importSingleTableHeaderReducer,
  import: importReducer,
  transferTableHeader: transferTableHeaderReducer,
  revaluationTableHeader: revaluationTableHeaderReducer,
  transferCreateTableHeader: transferCreateTableHeaderReducer,
  writeOffCreateTableHeader: writeOffCreateTableHeaderReducer,
  revaluationCreateTableHeader: revaluationCreateTableHeaderReducer,
  transferSingleTableHeader: transferSingleTableHeaderReducer,
  supplierTableHeader: supplierTableHeaderReducer,
  repricing: repricingReducer,
  clientTableHeader: clientTableHeaderReducer,
  inventoryScanTableHeader: inventoryScanTableHeaderReducer,
  ordersTableHeader: ordersTableHeaderReducer,
  promoTableHeader: promoTableHeaderReducer,
  orderCreateTableHeader: orderCreateTableHeaderReducer,
  orderAcceptTableHeader: orderAcceptTableHeaderReducer,
  employees: employeeMassiveOperationReducer,
  productMassiveOperation: productMassiveOperationReducer,
  permissionRoutes: permissionRouteReducer,
  backgroundProgress: backgroundProgressReducer,
  sidebarSettings: openSidebarReducer,
  reportTableHeaders: reportTableHeaderReducer,
  certificateTableHeaders: certificatesTableHeaderReducer,
  location: locationReducer,
  variationsState: variationsStateReducer,
  promocodesTableHeader: promocodesTableHeaderReducer,
  archivedProductsTableHeader: archivedProductsTableHeaderReducer,
});

export default persistReducer(persistConfig, rootReducer);
