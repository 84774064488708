/* eslint-disable */
export const productActionTypes = {
  SET_PRODUCT_ID: 'SET_PRODUCT_ID',
  REMOVE_PRODUCT_ID: 'REMOVE_PRODUCT_ID',
  INCREMENT_COUNT: 'INCREMENT_COUNT',
  DECREMENT_COUNT: 'DECREMENT_COUNT',
  SET_PRODUCTS: 'SET_PRODUCTS',
  CLEAR_PRODUCTS: 'CLEAR_PRODUCTS',
  RESET_COUNT: 'RESET_COUNT',
};
