/* eslint-disable */
export const guideActionTypes = {
  SET_GUIDE_SLUG: 'SET_GUIDE_SLUG',
  SET_GUIDE_STEP: 'SET_GUIDE_STEP',
  SET_GUIDE_SIZE: 'SET_GUIDE_SIZE',
  SET_GUIDE_START: 'SET_GUIDE_START',
  SET_GUIDE_RUN: 'SET_GUIDE_RUN',
  SET_GUIDE_STOP: 'SET_GUIDE_STOP',
  SET_GUIDE_COMPLETED: 'SET_GUIDE_COMPLETED',
};
