/* eslint-disable */
import { useCallback, useEffect } from 'react';
import { useWebSocketMessageHandlerNew } from 'services/websocket/WebsocketManagerNew';

export function useWebSocketQueryFn({ setIsLoading, correlationId, onSuccess, onError }) {
  const { addHandler, removeHandler } = useWebSocketMessageHandlerNew() || {};

  const successHandler = useCallback(
    (message: any) => {
      const error = 'status_code' in message && message.status_code !== 200;
      if (!error) {
        if (!message?.customWsLoading) {
          setIsLoading(false);
        }
        if (onSuccess) {
          onSuccess(message);
        }
      } else {
        setIsLoading(false);
        if (onError) {
          onError(message);
        }
      }
    },
    [onSuccess, onError],
  );

  useEffect(() => {
    if (correlationId) {
      addHandler!(correlationId, successHandler);
    }

    return () => {
      if (correlationId && removeHandler) {
        removeHandler(correlationId);
      }
    };
  }, [correlationId, addHandler, removeHandler, successHandler]);
}
