import PlusIcon from '../../../shared/icons/PlusIcon';
import { useStyles } from './EmptyStub.styles';
import { Button, Typography, Paper } from '../../../shared/ui';
import { useTranslation } from 'react-i18next';

type EmptyStubProps = {
  onClick: () => void;
};

const EmptyStub = ({ onClick }: EmptyStubProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root} type="dashed" borderRadius="xl">
      <div className={classes.content}>
        <Typography className={classes.title} variant="h3">
          {t('integrations.empty_screen.title')}
        </Typography>
        <Typography className={classes.description} variant="caption" marginBottom={4}>
          {t('integrations.empty_screen.description')}
        </Typography>
        <div className={classes.button}>
          <Button startIcon={<PlusIcon fill="#fff" />} onClick={onClick}>
            {t('integrations.create_key')}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default EmptyStub;
