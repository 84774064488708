import { Button as ButtonTemplate, ButtonProps as ButtonTemplateProps } from '@mui/material';
import { useStyles } from './Button.styles';
import clsx from 'clsx';

type ButtonProps = {
  className?: string;
  variant?: 'primary' | 'secondary' | 'error';
  size?: 'sm' | 'md' | 'lg';
  spacing?: 'default' | 'fit';
} & Omit<ButtonTemplateProps, 'variant' | 'size'>;

const Button = ({ variant = 'primary', size = 'md', spacing = 'default', className, ...props }: ButtonProps) => {
  const classes = useStyles(props);
  const rootClassName = clsx(classes.root, classes[variant], classes[size], classes[`spacing_${spacing}`], className);

  return <ButtonTemplate className={rootClassName} fullWidth {...props} />;
};

export default Button;
