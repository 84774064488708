/* eslint-disable */
import { request } from 'services/axios';
import * as qs from 'qs';

const admin = {
  getAll: filter => request.get(`v1/user${qs.stringify(filter, { addQueryPrefix: true })}`),
  getSingle: id => request.get(`v1/user/${id}`),
  getSelf: () => request.get(`v1/user-self`),
  update: data => request.put(`v1/user/${data.user_id}`, data),
  create: data => request.post(`v1/user`, data),
  getUserPermissions: id => request.get(`v2/user/${id}/permissions`),
  changeCurrentShop: id => request.patch(`v1/user/set-current-shop/${id}`),
};

export default admin;
