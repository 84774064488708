/* eslint-disable */
import { Box, CircularProgress } from '@mui/material';
import { memo } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 112px)',
    display: 'flex',
    flexGrow: '1',
    '& > div': {
      width: '100%',
    },
    background: 'transparent',
  },
  fullHeight: {
    minHeight: '100vh',
  },
  noHeight: {
    minHeight: '100%',
  },
  loader_container: {
    height: ({ height }) => height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

function LoadingContainer({ children, readyState = false, fullHeight = true, noHeight = false, boxStyle = {}, height = '100vh' }) {
  const classes = useStyles({ height });
  return (
    <Box className={`${classes.root} ${fullHeight && classes.fullHeight} ${noHeight && classes.noHeight} `} sx={boxStyle}>
      {readyState ? (
        children
      ) : (
        <Box className={classes.loader_container}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default memo(LoadingContainer);
