/* eslint-disable */
import { Box, Typography } from '@mui/material';
import { Button } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import React from 'react';

type ErrorPageProps = {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
};

const ErrorPage = ({ error, info, clearError }: ErrorPageProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        {...{ position: 'fixed', top: 0, left: 0, zIndex: 9999 }}
      >
        <Box>
          <Typography variant="h1" color="primary" style={{ fontSize: 48, lineHeight: '56px' }}>
            {t('menu.settings.cashbox.cashbox_create.on_error')}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography style={{ marginBottom: 16 }} variant="h5">
            {t('components.unexpectedAnswer')}
          </Typography>
          <Button onClick={() => window.location.replace('/')}>{t('buttons.reload')}</Button>
        </Box>
      </Box>
    </>
  );
};

export default ErrorPage;
