/* eslint-disable */
const ShowPasswordIcon = props => (
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 2.54464C8.66879 2.54991 8.34003 2.60347 8.02375 2.70368C8.17005 2.96556 8.24795 3.26125 8.25 3.5625C8.25 3.79642 8.20473 4.02804 8.11679 4.24415C8.02884 4.46027 7.89994 4.65663 7.73743 4.82203C7.57493 4.98744 7.38201 5.11864 7.16969 5.20816C6.95737 5.29768 6.72981 5.34375 6.5 5.34375C6.20403 5.34166 5.91353 5.26237 5.65624 5.11346C5.45325 5.83003 5.47691 6.59344 5.72387 7.29557C5.97083 7.99769 6.42856 8.60293 7.03224 9.02555C7.63591 9.44817 8.35492 9.66674 9.08743 9.65032C9.81993 9.63389 10.5288 9.38329 11.1136 8.93402C11.6984 8.48475 12.1295 7.85958 12.3458 7.14707C12.5622 6.43456 12.5528 5.67082 12.319 4.96405C12.0852 4.25727 11.6388 3.64329 11.0432 3.20907C10.4475 2.77484 9.73268 2.54239 9 2.54464V2.54464ZM17.8913 5.64275C16.1966 2.27714 12.8416 0 9 0C5.15843 0 1.80249 2.27873 0.108733 5.64306C0.0372461 5.787 0 5.94602 0 6.1073C0 6.26858 0.0372461 6.42761 0.108733 6.57154C1.80342 9.93715 5.15843 12.2143 9 12.2143C12.8416 12.2143 16.1975 9.93556 17.8913 6.57122C17.9628 6.42729 18 6.26827 18 6.10698C18 5.9457 17.9628 5.78668 17.8913 5.64275V5.64275ZM9 10.6875C5.91718 10.6875 3.09093 8.93806 1.56467 6.10714C3.09093 3.27623 5.91687 1.52679 9 1.52679C12.0831 1.52679 14.9091 3.27623 16.4353 6.10714C14.9094 8.93806 12.0831 10.6875 9 10.6875Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default ShowPasswordIcon;
