import * as qs from 'qs';
import { request } from '../axios';

const category = {
  getAll: filter => request.get(`v2/category${qs.stringify(filter, { addQueryPrefix: true })}`),
  getAllV2: filter => request.get(`/v2/category-list${qs.stringify(filter, { addQueryPrefix: true })}`),
  getSingle: id => request.get(`v2/category/${id}`),
  create: ({ data, protocol }) => request.post(`v2/category${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  update: ({ id, data, protocol }) => request.put(`v2/category/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  delete: ({ id, protocol }) => request.delete(`v2/category/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  recover: ({ id, protocol }) => request.put(`v2/category-recover/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
};

export default category;
