import { Palette } from './types';

const paletteDark: Palette = {
  type: 'dark',
  white: '#000000',
  black: '#ffffff',
  common: {
    white: '#ffffff',
    black: '#000000',
  },
  background: {
    default: '#262626',
    blueGray: '#404040',
    paper: '#FFFFFF',
    gray: '#404040',
  },
  primary: {
    main: '#4993dd',
  },
  secondary: {
    main: '#F5F5F5',
  },
  text: {
    primary: '#fff',
    secondary: '#BDBDBD',
  },
  border: {
    default: '#CFCFCF',
  },
  action: {
    disabledBackground: '#F5F5F5',
  },
  gray: {
    50: '#404040',
    100: '#404040',
    101: '#4d4d4d',
    200: '#5E5E5E',
    300: '#5E5E5E',
    400: '#bdbdbd',
    500: '#71717a',
    600: '#ffffff',
    700: '#5e5e5e',
    800: '#404040',
    801: '#404040',
    900: '#262626',
  },
  blue: {
    50: '#404040',
    100: '#dbeafe',
    200: '#bfdbfe',
    300: '#93c5fd',
    400: '#60a5fa',
    500: '#4993dd',
    600: '#1f78ff',
    601: '#1d70ed',
    650: '#0065ff',
    700: '#1d4ed8',
    800: '#1e40af',
    900: '#1e3a8a',
  },
  bluegray: {
    50: '#f8fafc',
    100: '#404040',
    200: '#5E5E5E',
    300: '#cbd5e1',
    400: '#5E5E5E',
    401: '#4d4d4d',
    500: '#64748b',
    600: '#475569',
    700: '#334155',
    800: '#1e293b',
    900: '#0f172a',
  },
  purple: {
    50: '#faf5ff',
    100: '#f3e8ff',
    200: '#e9d5ff',
    300: '#d8b4fe',
    400: '#c084fc',
    500: '#a855f7',
    600: '#9333ea',
    700: '#7e22ce',
    800: '#6b21a8',
    900: '#581c87',
  },
  violet: {
    50: '#f5f3ff',
    100: '#ede9fe',
    200: '#ddd6fe',
    300: '#c4b5fd',
    400: '#a78bfa',
    500: '#8b5cf6',
    600: '#7c3aed',
    700: '#6d28d9',
    800: '#5b21b6',
    900: '#4c1d95',
  },
  indigo: {
    50: '#eef2ff',
    100: '#e0e7ff',
    200: '#c7d2fe',
    300: '#a5b4fc',
    400: '#818cf8',
    500: '#6366f1',
    600: '#4f46e5',
    700: '#4338ca',
    800: '#3730a3',
    900: '#312e81',
  },
  lightblue: {
    50: '#f0f9ff',
    100: '#e0f2fe',
    200: '#bae6fd',
    300: '#7dd3fc',
    400: '#38bdf8',
    500: '#0ea5e9',
    600: '#0284c7',
    700: '#0369a1',
    800: '#075985',
    900: '#0c4a6e',
  },
  teal: {
    50: '#f0fdfa',
    100: '#ccfbf1',
    200: '#99f6e4',
    300: '#5eead4',
    400: '#2dd4bf',
    500: '#14b8a6',
    600: '#0d9488',
    700: '#0f766e',
    800: '#115e59',
    900: '#134e4a',
  },
  green: {
    50: '#404040',
    100: '#dcfce7',
    200: '#bbf7d0',
    300: '#86efac',
    400: '#6fcf97',
    500: '#4ade80',
    600: '#22c55e',
    700: '#16a34a',
    800: '#166534',
    900: '#14532d',
  },
  yellow: {
    50: '#fefce8',
    100: '#fef9c3',
    200: '#fef08a',
    300: '#fde047',
    400: '#f2c94c',
    500: '#eab308',
    600: '#ca8a04',
    700: '#a16207',
    800: '#854d0e',
    900: '#713f12',
  },
  orange: {
    50: '#fff7ed',
    100: '#ffedd5',
    200: '#fed7aa',
    300: '#fdba74',
    400: '#fb923c',
    500: '#f97316',
    600: '#ea580c',
    700: '#c2410c',
    800: '#9a3412',
    900: '#7c2d12',
  },
  red: {
    50: '#404040',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#f87171',
    500: '#eb5757',
    501: '#db5151',
    600: '#ff0844',
    601: '#ff0844',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d',
  },
  pink: {
    50: '#fdf2f8',
    100: '#fce7f3',
    200: '#fbcfe8',
    300: '#f9a8d4',
    400: '#f472b6',
    500: '#ec4899',
    600: '#db2777',
    700: '#be185d',
    800: '#9d174d',
    900: '#831843',
  },
  boxShadow: {
    '64-16': '0px 0px 64px rgba(255, 255, 255, 0.16)',
    '32-12': '0px 0px 32px rgba(255, 255, 255, 0.12)',
    '16-8': '0px 0px 16px rgba(255, 255, 255, 0.08)',
    tableShadow: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #262626 100%)',
  },
};
export default paletteDark;
