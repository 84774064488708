/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const currency = {
  getAll: filter => request.get(`v1/currency${qs.stringify(filter, { addQueryPrefix: true })}`),
  getDefault: () => request.get(`v1/default-currency`),
  updateDefault: data => request.put(`v1/default-currency`, data),
  updateCompanyCurrencies: data => request.post(`v2/company-currencies`, data),
};

export default currency;
