/* eslint-disable */
const UserIcon = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {props?.gray ? (
      <>
        <path
          d="M20.8014 20.2328C20.8014 22.5825 18.888 24.4665 16.5 24.4665C14.1128 24.4665 12.1986 22.5825 12.1986 20.2328C12.1986 17.8832 14.1128 16 16.5 16C18.888 16 20.8014 17.8832 20.8014 20.2328Z"
          fill="currentColor"
        />
        <path
          d="M16.5 32C12.9757 32 10 31.436 10 29.26C10 27.0832 12.9944 26.5391 16.5 26.5391C20.0251 26.5391 23 27.1032 23 29.2792C23 31.456 20.0056 32 16.5 32Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <path
          d="M20.8014 20.2328C20.8014 22.5825 18.888 24.4665 16.5 24.4665C14.1128 24.4665 12.1986 22.5825 12.1986 20.2328C12.1986 17.8832 14.1128 16 16.5 16C18.888 16 20.8014 17.8832 20.8014 20.2328Z"
          fill="#1F78FF"
        />
        <path
          d="M16.5 32C12.9757 32 10 31.436 10 29.26C10 27.0832 12.9944 26.5391 16.5 26.5391C20.0251 26.5391 23 27.1032 23 29.2792C23 31.456 20.0056 32 16.5 32Z"
          fill="#1F78FF"
        />
      </>
    )}
  </svg>
);

export default UserIcon;
