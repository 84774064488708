/* eslint-disable */
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import checkAccess from 'components/RouteAccess/checkAccess';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '8px 16px',
    borderRadius: 32,
    backgroundColor: ({ primary }) => (primary ? theme.palette.gray[100] : 'transparent'),
    color: ({ primary }) => (primary ? theme.palette.blue[500] : theme.palette.gray[400]),
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '19px',
    border: 0,
    margin: ({ primary }) => (primary ? '0px 4px 6px 0' : 0),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: ({ primary }) => (primary ? theme.palette.gray[200] : 'transparent'),
      color: ({ primary }) => (primary ? theme.palette.blue[500] : theme.palette.gray[400]),
    },
  },
  selected: {
    color: ({ primary }) => (primary ? 'white' : theme.palette.gray[600]),
    backgroundColor: ({ primary }) => (primary ? theme.palette.blue[600] : theme.palette.gray[100]),
    '&:hover': {
      color: ({ primary }) => (primary ? 'white' : theme.palette.gray[600]),
      backgroundColor: ({ primary }) => (primary ? theme.palette.blue[600] : theme.palette.gray[100]),
    },
    transition: 'background-color 0.2s',
  },
}));

interface Props {
  primary: boolean;
  link?: string;
  name: string | number;
  selected;
  setTab: (id: any) => void;
  icon?: React.ReactNode;
  [key: string]: any;
}

const Tab: React.FC<Props> = ({ primary = false, link, name, selected, id, setTab, icon, ...rest }) => {
  const classes = useStyles({ primary });

  const onChange = e => {
    e.preventDefault();
    if (setTab) {
      setTab(id);
    }
  };

  return (
    <>
      {link ? (
        <Link to={link || '/'} className={`${classes.root} ${selected ? classes.selected : ''}`} onClick={onChange} {...rest}>
          {name}
        </Link>
      ) : (
        <span id={id} className={`${classes.root} ${selected ? classes.selected : ''}`} onClick={onChange} {...rest}>
          {icon || ''} {name}
        </span>
      )}
    </>
  );
};

export default memo(checkAccess(Tab));
