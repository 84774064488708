/* eslint-disable */
export const cartActionTypes = {
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  REDUCE_CART_ITEM_QUANTITY: 'REDUCE_CART_ITEM_QUANTITY',
  CLEAR_CART: 'CLEAR_CART',
  CHANGE_CART_ITEM_QUANTITY: 'CHANGE_CART_ITEM_QUANTITY',
  SET_CART_ITEM_DISCOUNT: 'SET_CART_ITEM_DISCOUNT',
  SET_MANUAL_DISCOUNT: 'SET_MANUAL_DISCOUNT',
  ADD_TO_ORDER_PAYMENT: 'ADD_TO_ORDER_PAYMENT',
  ADD_TO_RETURN_PAYMENT: 'ADD_TO_RETURN_PAYMENT',
  REMOVE_FROM_ORDER_PAYMENT: 'REMOVE_FROM_ORDER_PAYMENT',
  SET_ORDER_AMOUNT: 'SET_PAID_AMOUNT',
  SET_ACTIVE_ROUNDING: 'SET_ACTIVE_ROUNDING',
  ADD_SELLER: 'ADD_SELLER',
  REMOVE_SELLER: 'REMOVE_SELLER',
  SET_ACTIVE_SELLER: 'SET_ACTIVE_SELLER',
  TRANSFER_SELLER_ITEMS: 'TRANSFER_SELLER_ITEMS',
  ADD_TO_RETURNS: 'ADD_TO_RETURNS',
  REMOVE_FROM_RETURNS: 'REMOVE_FROM_RETURNS',
  CHANGE_RETURN_ITEM_QUANTITY: 'CHANGE_RETURN_ITEM_QUANTITY',
  SET_VALID_CART_ITEMS: 'SET_VALID_CART_ITEMS',
  SYNC_CART_WITH_DATABASE: 'SYNC_CART_WITH_DATABASE',
  SYNC_CART_WITH_DATABASE_NEW: 'SYNC_CART_WITH_DATABASE_NEW',
  REMOVE_ALL_FROM_ORDER_PAYMENT: 'REMOVE_ALL_FROM_ORDER_PAYMENT',
  REMOVE_ALL_FROM_RETURN_PAYMENT: 'REMOVE_ALL_FROM_RETURN_PAYMENT',
};
