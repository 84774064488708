/* eslint-disable */
import { cartActionTypes } from './cartActionTypes';

export const asyncAddToCartAction = product => dispatch => {
  dispatch({
    type: cartActionTypes.ADD_TO_CART,
    payload: product,
  });
};
export const asyncRemoveFromCartAction = (id, type?) => dispatch => {
  dispatch({
    type: cartActionTypes.REMOVE_FROM_CART,
    payload: { id, type },
  });
};

export const clearCartAction = (type?) => ({
  type: cartActionTypes.CLEAR_CART,
  payload: type,
});

export const changeCartItemQuantity = (data, measurement_value, precision) => dispatch => {
  dispatch({
    type: cartActionTypes.CHANGE_CART_ITEM_QUANTITY,
    payload: { ...data, measurement_value, precision },
  });
};

export const syncCartWithDatabase =
  (data, isManual = false) =>
  dispatch => {
    dispatch({
      type: cartActionTypes.SYNC_CART_WITH_DATABASE,
      payload: { data, isManual },
    });
  };

export const syncCartWithDatabaseNew =
  (data, isManual = false) =>
  dispatch => {
    dispatch({
      type: cartActionTypes.SYNC_CART_WITH_DATABASE_NEW,
      payload: { data, isManual },
    });
  };

export const setManualDiscount = payload => dispatch => {
  dispatch({
    type: cartActionTypes.SET_MANUAL_DISCOUNT,
    payload,
  });
};
export const addToOrderPayment = order => dispatch => {
  dispatch({
    type: cartActionTypes.ADD_TO_ORDER_PAYMENT,
    payload: order,
  });
};
export const addToReturnPayment = payment => dispatch => {
  dispatch({
    type: cartActionTypes.ADD_TO_RETURN_PAYMENT,
    payload: payment,
  });
};
export const removeFromOrderPayment = order => dispatch => {
  dispatch({
    type: cartActionTypes.REMOVE_FROM_ORDER_PAYMENT,
    payload: order,
  });
};
export const removeAllFromOrderPayment = () => ({
  type: cartActionTypes.REMOVE_ALL_FROM_ORDER_PAYMENT,
});
export const removeAllFromReturnPayment = () => ({
  type: cartActionTypes.REMOVE_ALL_FROM_RETURN_PAYMENT,
});

export const setOrderAmount = (id, paid_amount) => dispatch => {
  dispatch({
    type: cartActionTypes.SET_ORDER_AMOUNT,
    payload: { id, paid_amount },
  });
};
export const setActiveRounding = payload => dispatch => {
  dispatch({
    type: cartActionTypes.SET_ACTIVE_ROUNDING,
    payload,
  });
};
export const addSeller = payload => dispatch => {
  dispatch({
    type: cartActionTypes.ADD_SELLER,
    payload,
  });
};
export const removeSeller = payload => dispatch => {
  dispatch({
    type: cartActionTypes.REMOVE_SELLER,
    payload,
  });
};
export const setActiveSeller = payload => dispatch => {
  dispatch({
    type: cartActionTypes.SET_ACTIVE_SELLER,
    payload,
  });
};
export const transferSellerItems = (fromSeller, item, toSeller) => dispatch => {
  dispatch({
    type: cartActionTypes.TRANSFER_SELLER_ITEMS,
    payload: { fromSeller, item, toSeller },
  });
};

export const addToReturnsAction = product => dispatch => {
  dispatch({
    type: cartActionTypes.ADD_TO_RETURNS,
    payload: product,
  });
};
export const removeFromReturnsAction = product => dispatch => {
  dispatch({
    type: cartActionTypes.REMOVE_FROM_RETURNS,
    payload: product,
  });
};
export const changeReturnItemQuantity = (data, measurement_value) => dispatch => {
  dispatch({
    type: cartActionTypes.CHANGE_RETURN_ITEM_QUANTITY,
    payload: { ...data, measurement_value },
  });
};
