/* eslint-disable */
import { useEffect, useCallback } from 'react';
import debounce from '../utils/debounce';
import { success } from '../utils/toast';

export default function useNotifyOnNetworkChange() {
  const showNetworkRetievedNotification = useCallback(
    debounce(() => success('Подключение к Интернету восстановлено!'), 1500),
    [],
  );
  useEffect(() => {
    if (!location.pathname.includes('presentation')) {
      window.addEventListener('online', showNetworkRetievedNotification);
      return () => {
        window.removeEventListener('online', showNetworkRetievedNotification);
      };
    }
  }, []);
}
