/* eslint-disable */
import { repricingActionTypes } from '../actions/repricingActions/repricingActionTypes';

const initialState = {
  name: '',
  repricing_type: null,
  shop: null,
};

const repricingReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case repricingActionTypes.SET_REPRICING:
      return {
        ...state,
        name: payload.name,
        repricing_type: payload.repricing_type,
        shop: payload.shop,
      };

    case repricingActionTypes.REMOVE_REPRICING:
      return {
        ...state,
        name: '',
        repricing_type: null,
        shop: null,
      };

    default:
      return state;
  }
};

export default repricingReducer;
