/* eslint-disable */
import { NeedHelpButtonNew } from 'layouts/Navbar/NeedHelpButtonNew';
import { useCallback, useMemo, useEffect, useState, memo, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useMutation, useQuery } from '@tanstack/react-query';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { Box, Drawer, Hidden, Typography, useTheme, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import {
  setCompanyCurrency,
  setSupplyCurrency,
  setRetailCurrency,
  setCurrentCashboxId,
  setCurrentShopId,
  setCurrentShopName,
  setSettingsCatalog,
  setCompanyTimezone,
  setCompanyId,
} from 'store/actions/companyActions/companyActions';
import { changeLocation } from 'store/slicers/locationSlice';
import { toggle } from 'store/actions/openSidebarActions/openSidebarActions';
import { clearCartAction } from 'store/actions/cartActions/cartActions';
import { requests } from 'services/requests';
import useDeepCompareEffect from 'shared/hooks/useDeepCompareEffect';
import useDidUpdate from 'shared/hooks/useDidUpdate';
import { useWebsocketMutationNew } from 'shared/hooks/useWebsocketMutationNew';
import event from 'shared/utils/event';
import { error, success } from 'shared/utils/toast';
import BackArrowIcon from 'shared/icons/BackArrowIcon';
import LogoLetters from 'shared/icons/LogoLetters';
import LogoMain from 'shared/icons/LogoMain';
import SidebarIcon from 'shared/icons/NavbarIcons/SidebarIcon';
import BigWarningIcon from 'shared/icons/BigWarningIcon';
import Button from 'stories/Button/Button';
import ConfirmDialog from 'components/ConfirmDialog';
import { navbarStyles } from './NavbarStyles';
import UserDrawer from './UserDrawer';
import NavItem from './NavItem';
import NavItemMini from './NavItemMini';
import { routeData as drawerData } from 'routes/routeData';
import { Skeleton } from '@mui/material';
import { useSetAtom } from 'jotai';
import { planNotificationStateAtom } from 'pages/settings/plan/atoms/usePlanNotification';
import { List, ListItem } from '@/shared/ui';

import { useGetCurrentUserQuery } from '@/shared/hooks/user';

function Navbar() {
  const { forbiddenRoutes } = useSelector(state => state.permissionRoutes);
  const { isOpen } = useSelector(state => state.sidebarSettings);
  const { currency, supply_currency, retail_currency, current_cashbox_id, user_id, company_id } = useSelector(state => state.company);
  const classes = navbarStyles({ isOpen });
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:1024px)');
  const [data, setData] = useState<any>(drawerData || []);
  const [isUserOpen, setIsUserOpen] = useState<boolean>(false);
  const [currentRoutes, setCurrentRoutes] = useState(null);
  const currentRoutesRef = useRef(currentRoutes);
  const [errorProfileImage, setErrorProfileImage] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [nextHref, setNextHref] = useState('');
  const prev_theme = localStorage.getItem('user_theme');
  const setPlanNotification = useSetAtom(planNotificationStateAtom);

  const httpResponseChannelNewSale = !forbiddenRoutes?.find(el => el?.slug === 'http-response-channel-new-sale');

  const backProgresses = useSelector(state => state.backgroundProgress, shallowEqual);
  const { company_timezone } = useSelector(state => state.company);

  const location = useLocation();
  const isLoading = useMemo(() => !!backProgresses?.find(el => el.isLoading || el.error), [backProgresses]);
  const handleDrawerToggle = useCallback(() => {
    dispatch(toggle(!isOpen));
  }, [isOpen]);

  useDeepCompareEffect(() => {
    const updatedDrawerData = drawerData?.map(item => ({
      ...item,
      children: item?.children?.map(child => ({
        ...child,
        disallowedRoute: checkIsDisallowed(child, forbiddenRoutes),
      })),
      disallowedRoute: checkIsDisallowed(item, forbiddenRoutes),
    }));

    setData(updatedDrawerData);
    updatedDrawerData.forEach(item => {
      const isCurrent = location.pathname.split('/')[1] === item.href.split('/')[1] && item?.children?.length;

      if (isCurrent) setCurrentRoutes(item);
    });
  }, [forbiddenRoutes]);

  const { data: userDetails, isInitialLoading: isLoadingUser, refetch } = useGetCurrentUserQuery();

  const { data: defaultCurrency } = useQuery(['defaultCurrency'], () => requests.currency.getDefault(), { enabled: !!user_id });
  const { data: catalogSettings } = useQuery(['catalogSettings'], () => requests.productCharacteristic.catalogSettingsGet(), {
    enabled: !!user_id,
  });

  const { data: companyCurrencies } = useQuery(['currencies'], () => requests.companyCurrency.getAllCompanyCurrencies(), {
    enabled: !!user_id,
  });
  const { data: companyDetails } = useQuery(['company', company_id], () => requests.company.getSingle(), { enabled: !!user_id });

  const { mutate: createPostponeWs, isLoading: isPostponeWsCreating } = useWebsocketMutationNew(requests.order.createPostpone, {
    onWebsocketSuccess: () => {
      event('new_sale_successful_deposite');
      success(t('menu.sales.new.postpone_success'));
      dispatch(clearCartAction());
      window.sessionStorage.removeItem('disableRoute');
      window.sessionStorage.removeItem('expiredDatePostpone');
      setShowDialog(false);
      navigate(nextHref);
    },
    onWebsocketError: () => error(t('menu.sales.new.postpone_error')),
  });

  const { mutate: createPostponeHttp, isLoading: isPostponeHttpCreating } = useMutation(requests.order.createPostpone, {
    onSuccess: () => {
      event('new_sale_successful_deposite');
      success(t('menu.sales.new.postpone_success'));
      dispatch(clearCartAction());
      window.sessionStorage.removeItem('disableRoute');
      window.sessionStorage.removeItem('expiredDatePostpone');
      setShowDialog(false);
      navigate(nextHref);
    },
    onError: () => error(t('menu.sales.new.postpone_error')),
  });

  const createPostpone = data => {
    if (httpResponseChannelNewSale) {
      createPostponeHttp({
        ...data,
        filter: { 'Billz-Response-Channel': 'HTTP' },
      });
      return;
    }
    createPostponeWs(data);
  };

  useDidUpdate(() => {
    refetch();
  }, [current_cashbox_id]);

  useDeepCompareEffect(() => {
    if (!!currentRoutes) currentRoutesRef.current = currentRoutes;
  }, [currentRoutes]);

  useEffect(() => {
    if (catalogSettings?.data) {
      dispatch(
        setSettingsCatalog({
          whole_sale: catalogSettings?.data?.settings?.whole_sale,
          small_left: catalogSettings?.data?.settings?.small_left,
          gs1: catalogSettings?.data?.settings?.gs1,
          sku_is_variation_id: catalogSettings?.data?.settings?.sku_is_variation_id,
          permit_negative_marginality: catalogSettings?.data?.settings?.permit_negative_marginality,
          free_price: catalogSettings?.data?.settings?.free_price,
          card_properties: catalogSettings?.data?.settings?.card_properties,
          variation_id_field: !!catalogSettings?.data?.settings?.variation_id_field,
        }),
      );
    }
  }, [catalogSettings]);

  useEffect(() => {
    if (userDetails?.id) {
      const { company_id, language, current_shop_id, shops, current_cashbox_id, theme } = userDetails;

      if (company_id !== company_id) {
        dispatch(setCompanyId(company_id));
      }
      setErrorProfileImage(false);
      if (language && i18n.language !== language) {
        i18n.changeLanguage(language);
      }
      if (current_shop_id) {
        dispatch(setCurrentShopId(current_shop_id));
        dispatch(setCurrentShopName(shops?.find(shop => shop?.shop_id === current_shop_id)?.shop?.name));
      }
      if (current_cashbox_id) {
        dispatch(setCurrentCashboxId(current_cashbox_id));
      }
      if (!!theme) {
        localStorage.setItem('user_theme', theme || 'auto');

        if (prev_theme !== theme) {
          navigate(`${window.location.pathname}?theme_changed=${theme}`);
        }
      }
    }
  }, [userDetails?.id]);

  useEffect(() => {
    if (companyDetails?.data && !!company_timezone?.name) {
      dispatch(
        setCompanyTimezone({
          gmt: companyDetails?.data?.time_zone_gmt,
          name: companyDetails?.data?.time_zone_name,
        }),
      );
    }
  }, [companyDetails?.data]);

  useDeepCompareEffect(() => {
    if (defaultCurrency?.data && currency?.id !== defaultCurrency?.data?.id) {
      dispatch(
        setCompanyCurrency({
          ...defaultCurrency?.data?.currency,
          id: defaultCurrency?.data?.id,
        }),
      );
    }
  }, [defaultCurrency]);

  useDeepCompareEffect(() => {
    if (
      companyCurrencies?.data &&
      (supply_currency?.iso_code !== companyCurrencies?.data?.supply_currencies?.[0]?.code ||
        retail_currency?.iso_code !== companyCurrencies?.data?.retail_currency?.code)
    ) {
      dispatch(
        setSupplyCurrency({
          iso_code: companyCurrencies?.data?.supply_currencies?.[0]?.code,
          rate: companyCurrencies?.data?.currency_rates?.find(
            item => item?.source_currency === companyCurrencies?.data?.supply_currencies?.[0]?.code,
          )?.rate,
        }),
      );
      dispatch(
        setRetailCurrency({
          iso_code: companyCurrencies?.data?.retail_currency?.code,
        }),
      );
    }
  }, [companyCurrencies]);

  useEffect(() => {
    if (matches) dispatch(toggle(false));
  }, [matches]);

  useEffect(() => {
    dispatch(changeLocation({ path: location.pathname, filter: location.search }));
  }, [location]);

  useEffect(() => {
    setPlanNotification(true);
  }, [location.pathname]);

  const shopName = userDetails?.shops?.find(item => item.shop_id === userDetails?.current_shop_id)?.shop?.name;
  const currentRoutesMemoized = useMemo(() => currentRoutes || currentRoutesRef?.current, [currentRoutesRef, currentRoutes]);

  const onSubmitPostpone = () => {
    const requestBody = {
      order_id: window.sessionStorage.getItem('disableRoute'),
      comment: '',
      time: window.sessionStorage.getItem('expiredDatePostpone') || null,
    };
    createPostpone({ data: requestBody });
  };

  const isAllowedRoute = item => {
    return (
      !forbiddenRoutes.find(el => el.route === item.href.replace('/', '') && (el.options === '' || el?.option === '')) &&
      !(
        item.children?.length &&
        item.children?.every(route =>
          forbiddenRoutes.find(el => el.route === route?.href?.replace('/', '') && (el.options === '' || el?.option === '')),
        )
      )
    );
  };

  const drawer = (
    <div id="navbar" className={classes.container}>
      <div className={classes.logo}>
        <Box display="flex">
          <Link to="/dashboard" className={classes.brandLogo} onClick={() => setCurrentRoutes(null)}>
            <span className={classes.logo_main}>
              <LogoMain />
            </span>
            {isOpen && <LogoLetters />}
          </Link>
        </Box>

        {!matches && (
          <button type="button" className={classes.close_icon} onClick={handleDrawerToggle}>
            <span className={isOpen ? classes.right_faced : classes.left_faced}>
              <SidebarIcon />
            </span>
          </button>
        )}
      </div>
      <List className={classes.list}>
        {!isOpen && (
          <div className={`${classes.popperParent} popper`}>
            <div className={`${classes.popper}`}>
              <ListItem className={classes.listItemPopper}>
                {currentRoutesMemoized?.icon} {t(`navbar.${currentRoutesMemoized?.label}`)}
              </ListItem>
              <div className={classes.hr} />
              {currentRoutesMemoized?.children?.map((item, index) => {
                return (
                  !item.disallowedRoute && (
                    <NavItem
                      item={item}
                      key={index}
                      classes={classes}
                      handleClickNavItems={setCurrentRoutes}
                      handleShowDialog={setShowDialog}
                      setNextHref={setNextHref}
                    />
                  )
                );
              })}
            </div>
          </div>
        )}
        <Box className="fixed_navlist">
          {!currentRoutes && isOpen && (
            <div className={`${classes.parent}`}>
              {data?.map((item, index) =>
                isAllowedRoute(item) ? (
                  <NavItem
                    item={item}
                    isActive={`/${location.pathname.split('/')[1]}` === item?.href}
                    key={index}
                    classes={classes}
                    handleClickNavItems={setCurrentRoutes}
                  />
                ) : null,
              )}
            </div>
          )}
          {!isOpen && (
            <div className={`${classes.parent}`}>
              {data?.map((item, index) =>
                isAllowedRoute(item) ? (
                  <NavItemMini
                    item={item}
                    isActive={`/${location.pathname.split('/')[1]}` === item?.href}
                    key={index}
                    handleClickNavItems={setCurrentRoutes}
                  />
                ) : null,
              )}
            </div>
          )}
          {isOpen && currentRoutes && (
            <div className={`${classes.child} ${classes.activeChild}`}>
              <ListItem className={classes.listItem} id="back-nav" onClick={() => setCurrentRoutes(null)}>
                <>
                  <BackArrowIcon /> {currentRoutesMemoized?.icon} {t(`navbar.${currentRoutesMemoized?.label}`)}
                </>
              </ListItem>
              {currentRoutesMemoized?.children?.map((item, index) => {
                return (
                  !item.disallowedRoute && (
                    <NavItem
                      item={item}
                      key={index}
                      classes={classes}
                      handleClickNavItems={setCurrentRoutes}
                      handleShowDialog={setShowDialog}
                      setNextHref={setNextHref}
                    />
                  )
                );
              })}
            </div>
          )}
        </Box>
      </List>
      {isLoadingUser || !userDetails?.first_name ? (
        <Box position="relative" marginTop={'auto'}>
          <Box className={classes.fakeImage} />
          <Skeleton className={classes.skeleton} />
        </Box>
      ) : (
        <ListItem
          className={`${classes.currentUser} drawer_user_avatar`}
          button
          id="avatar"
          onClick={() => {
            refetch();
            setIsUserOpen(true);
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            {userDetails.image_url && !errorProfileImage ? (
              <Box position="relative" mr={1.5}>
                <div className={classes.avatar}>
                  <img onError={() => setErrorProfileImage(true)} src={userDetails?.image_url} alt={userDetails?.first_name} />
                </div>
                {isLoading ? (
                  <div
                    className={classes.settingsIcon}
                    style={{
                      backgroundColor: backProgresses?.filter(el => el.id)?.length ? theme.palette.green[400] : theme.palette.yellow[400],
                    }}
                  >
                    <FontAwesomeIcon icon={faCog} />
                  </div>
                ) : (
                  ''
                )}
              </Box>
            ) : (
              <div className={classes.avatarPlaceholder}>
                {userDetails?.first_name.charAt(0)}
                {userDetails?.last_name.charAt(0)}
                {isLoading ? (
                  <div
                    className={classes.settingsIcon}
                    style={{
                      backgroundColor: backProgresses?.filter(el => el.id)?.length ? theme.palette.green[400] : theme.palette.yellow[400],
                    }}
                  >
                    <FontAwesomeIcon icon={faCog} />
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}

            <Box maxWidth="73%">
              <Typography id="user-username" className={classes.username}>
                {`${userDetails?.first_name || ''} ${userDetails?.last_name.charAt(0) + '.' || ''}`}
              </Typography>
              <p id="user-shopname" className={`${classes.shopname} shopname`}>
                {shopName}
              </p>
            </Box>
          </Box>
        </ListItem>
      )}
      <NeedHelpButtonNew isOpen={isOpen} />
    </div>
  );

  return (
    <>
      <div className={classes.root}>
        <nav>
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: isOpen,
                  [classes.drawerClose]: !isOpen,
                }),
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>

      <UserDrawer isOpen={isUserOpen} userDetails={userDetails} closeDrawer={() => setIsUserOpen(false)} refetchUserDetails={refetch} />
      <ConfirmDialog
        open={showDialog}
        setOpen={setShowDialog}
        icon={<BigWarningIcon />}
        title={t('alerts.save_changes_postpone')}
        desc={t('alerts.save_changes_postpone_desc')}
        actions={
          <>
            <Button
              secondary
              id="stopOrderPostpone"
              onClick={() => {
                setShowDialog(false);
                navigate(nextHref);
                window.sessionStorage.removeItem('disableRoute');
                window.sessionStorage.removeItem('expiredDatePostpone');
              }}
            >
              {t('buttons.dont_save')}
            </Button>
            <Button size="medium" variant="contained" onClick={onSubmitPostpone} isLoading={isPostponeWsCreating || isPostponeHttpCreating}>
              {t('buttons.save_changes')}
            </Button>
          </>
        }
      />
    </>
  );
}

export default Navbar;

function checkIsDisallowed(item, forbiddenRoutes) {
  let disallowedRoute;
  if (item?.slug) {
    const slugInc = forbiddenRoutes?.some(el => el.slug === item.slug);
    if (item?.featureFlag) {
      disallowedRoute = forbiddenRoutes?.some(el => el.slug === item.featureFlag) || slugInc;
    } else {
      disallowedRoute = slugInc;
    }

    if (disallowedRoute) return true;
  } else {
    disallowedRoute = forbiddenRoutes?.some(el => el.route === item.href.replace('/', '') && (el.options === '' || el.option === ''));
  }
  return disallowedRoute;
}
