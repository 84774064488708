import * as qs from 'qs';
import { request } from '../axios';

const transfer = {
  getDetails: id => request.get(`v2/transfer/${id}`),
  getAll: filter => request.get(`v2/transfer${qs.stringify(filter, { addQueryPrefix: true })}`),
  getItems: filter =>
    request.get(
      `v2/transfer-items/${filter.id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  getAllProducts: filter =>
    request.get(
      `v2/transfer-products/${filter.id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  getExcel: () => request.get(`v2/excel/transfer-properties-file`),
  delete: id => request.put(`v2/transfer/${id}`),
  search: filter =>
    request.get(
      `v2/transfer-search/${filter.id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  createFromFile: data => request.post(`v2/transfer-from-file`, data), // Нужна доработка чтобы работало на HTTP
  getTransferProgress: id => request.get(`v2/file-transfer-progress/${id}`),
  getFileTransfer: filter =>
    request.get(
      `v2/file-transfer/${filter.correlation_id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  create: ({ data, protocol }) => request.post(`v2/transfer${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  cancelTransfer: ({ id, protocol }) => request.put(`v2/transfer/cancel/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  deleteTransfer: ({ id, protocol }) => request.delete(`v2/transfer/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  finish: ({ id, data, protocol }) => request.put(`v2/transfer/finish/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  update: ({ id, data, protocol }) => request.put(`v2/transfer/update/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  send: ({ id, protocol }) => request.post(`v2/transfer/${id}/send${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  addItemHttp: ({ id, data }) => request.post(`v2/transfer/${id}/add?Billz-Response-Channel=HTTP`, data), // Работает на HTTP
  addItemBarcodeHttp: ({ id, data }) => request.post(`v2/transfer/${id}/add-by-barcode?Billz-Response-Channel=HTTP`, data), // Работает на HTTP
  transferInventory: ({ data, protocol }) => request.post(`v2/inventory/transfer${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  removeItem: data => request.post(`v2/transfer/${data.id}/remove`, data.product),
  addComment: ({ id, data, protocol }) =>
    request.put(`v2/transfer/add-comment/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  getTransferPriceDiff: id => request.get(`v2/transfer-price-diff/${id}`),
  generateExcel: data => request.post(`v2/transfer-export/${data.id}`, data.body),
  generateTransferAllExcel: data => request.post('v2/transfer-excel-export', data),
  generateTransferAllExcelNew: data => request.post('v1/transfer-report-by-products-excel', data),
  generateReport: ({ id, data, protocol }) =>
    request.put(`v2/transfer-report/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  sendAll: data => request.post(`v2/transfer-add/${data.id}`, data.body),
  progress: id => request.get(`v2/transfer-progress/${id}`),
};

export default transfer;
