/* eslint-disable */
import { Button, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  button: {
    padding: '0 !important',
    height: '100% !important',
    display: 'inline-block',
  },
  tooltip: {
    padding: 16,
    backgroundColor: theme.palette.gray[400],
    borderRadius: 16,
    color: theme.palette.common.white,
  },
}));

export default function StyledTooltip({ title, children, placement, hide = false, withButton = true }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return hide ? (
    children
  ) : (
    <Tooltip title={typeof title === 'string' ? t(title) : title} placement={placement} arrow={true}>
      {withButton ? <Button className={classes.button}>{children}</Button> : children}
    </Tooltip>
  );
}
