/* eslint-disable */
const SettingIcon = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {props?.gray ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.1334 25.264C23.4158 25.416 23.6337 25.656 23.7869 25.896C24.0855 26.392 24.0613 27 23.7708 27.536L23.206 28.496C22.9075 29.008 22.3508 29.328 21.778 29.328C21.4956 29.328 21.181 29.248 20.9228 29.088C20.713 28.952 20.471 28.904 20.2128 28.904C19.414 28.904 18.7444 29.568 18.7202 30.36C18.7202 31.28 17.9779 32 17.0501 32H15.9528C15.0169 32 14.2747 31.28 14.2747 30.36C14.2585 29.568 13.5889 28.904 12.7901 28.904C12.5239 28.904 12.2819 28.952 12.0802 29.088C11.822 29.248 11.4993 29.328 11.2249 29.328C10.644 29.328 10.0873 29.008 9.78882 28.496L9.23212 27.536C8.9336 27.016 8.91747 26.392 9.21598 25.896C9.34507 25.656 9.58712 25.416 9.86143 25.264C10.0873 25.152 10.2326 24.968 10.3697 24.752C10.7731 24.064 10.5311 23.16 9.8453 22.752C9.04655 22.296 8.78838 21.28 9.24826 20.488L9.78882 19.544C10.2568 18.752 11.2572 18.472 12.064 18.936C12.7659 19.32 13.6776 19.064 14.0891 18.384C14.2182 18.16 14.2908 17.92 14.2747 17.68C14.2585 17.368 14.3473 17.072 14.5006 16.832C14.7991 16.336 15.3397 16.016 15.9286 16H17.0662C17.6633 16 18.2038 16.336 18.5024 16.832C18.6476 17.072 18.7444 17.368 18.7202 17.68C18.7041 17.92 18.7767 18.16 18.9058 18.384C19.3172 19.064 20.2289 19.32 20.9389 18.936C21.7377 18.472 22.7462 18.752 23.206 19.544L23.7466 20.488C24.2146 21.28 23.9564 22.296 23.1496 22.752C22.4638 23.16 22.2217 24.064 22.6332 24.752C22.7623 24.968 22.9075 25.152 23.1334 25.264ZM14.2182 24.008C14.2182 25.264 15.2428 26.264 16.5095 26.264C17.7762 26.264 18.7767 25.264 18.7767 24.008C18.7767 22.752 17.7762 21.744 16.5095 21.744C15.2428 21.744 14.2182 22.752 14.2182 24.008Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.1334 25.264C23.4158 25.416 23.6337 25.656 23.7869 25.896C24.0855 26.392 24.0613 27 23.7708 27.536L23.206 28.496C22.9075 29.008 22.3508 29.328 21.778 29.328C21.4956 29.328 21.181 29.248 20.9228 29.088C20.713 28.952 20.471 28.904 20.2128 28.904C19.414 28.904 18.7444 29.568 18.7202 30.36C18.7202 31.28 17.9779 32 17.0501 32H15.9528C15.0169 32 14.2747 31.28 14.2747 30.36C14.2585 29.568 13.5889 28.904 12.7901 28.904C12.5239 28.904 12.2819 28.952 12.0802 29.088C11.822 29.248 11.4993 29.328 11.2249 29.328C10.644 29.328 10.0873 29.008 9.78882 28.496L9.23212 27.536C8.9336 27.016 8.91747 26.392 9.21598 25.896C9.34507 25.656 9.58712 25.416 9.86143 25.264C10.0873 25.152 10.2326 24.968 10.3697 24.752C10.7731 24.064 10.5311 23.16 9.8453 22.752C9.04655 22.296 8.78838 21.28 9.24826 20.488L9.78882 19.544C10.2568 18.752 11.2572 18.472 12.064 18.936C12.7659 19.32 13.6776 19.064 14.0891 18.384C14.2182 18.16 14.2908 17.92 14.2747 17.68C14.2585 17.368 14.3473 17.072 14.5006 16.832C14.7991 16.336 15.3397 16.016 15.9286 16H17.0662C17.6633 16 18.2038 16.336 18.5024 16.832C18.6476 17.072 18.7444 17.368 18.7202 17.68C18.7041 17.92 18.7767 18.16 18.9058 18.384C19.3172 19.064 20.2289 19.32 20.9389 18.936C21.7377 18.472 22.7462 18.752 23.206 19.544L23.7466 20.488C24.2146 21.28 23.9564 22.296 23.1496 22.752C22.4638 23.16 22.2217 24.064 22.6332 24.752C22.7623 24.968 22.9075 25.152 23.1334 25.264ZM14.2182 24.008C14.2182 25.264 15.2428 26.264 16.5095 26.264C17.7762 26.264 18.7767 25.264 18.7767 24.008C18.7767 22.752 17.7762 21.744 16.5095 21.744C15.2428 21.744 14.2182 22.752 14.2182 24.008Z"
          fill="#1F78FF"
        />
      </>
    )}
  </svg>
);

export default SettingIcon;
