/* eslint-disable */
import { useAtomValue } from 'jotai';
import { planStatusAtom } from 'pages/settings/plan/atoms/usePlanStatus';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { requests } from 'services/requests';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackSpecificURLs } from 'shared/utils/useTrackSpecificURLs';
import { setPermissionRoutes } from 'store/actions/permissonRouteActions/permissionRouteActions';
import { setUserShops } from 'store/actions/companyActions/companyActions';
import getDisallowedRoutes from './shared/utils/getDisallowedRoutes';
import filterRoutesBasedOnRoles from './shared/utils/filterRoutesBasedOnRoutes';
import LoadingContainer from 'components/LoadingContainer';
import routes from './routes/index';
import { useUnleashContext } from '@unleash/proxy-client-react';
import useFeatureFlagRoutes from './shared/hooks/useFeatureFlagRoutes';
import useShops from './shared/api/useShops';
import useListenWebsocket from './shared/hooks/useListenWebsocket';
import financeRoutes from './shared/constants/financeRoutes';
import Tracker from '@openreplay/tracker';

const tracker = new Tracker({
  projectKey: import.meta.env.VITE_APP_OPEN_REPLAY_TOKEN,
  // ingestPoint: import.meta.env.VITE_APP_OPEN_REPLAY_URL,
  defaultInputMode: 1,
  network: {
    capturePayload: true,
    sessionTokenHeader: false,
    failuresOnly: false,
    ignoreHeaders: false,
    captureInIframes: true,
  },
});

export default function Routes() {
  const [rolePermissions, setRolePermissions] = useState();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [financeEnabled, setFinanceEnabled] = useState<boolean>(false);
  const [isGetCompanyFinished, setIsGetCompanyFinished] = useState(false);
  const forbiddenRoutesFromFeatureFlag = useFeatureFlagRoutes();
  const { company_id, user_id, company_timezone } = useSelector(state => state.company);
  const planStatus = useAtomValue(planStatusAtom);

  useTrackSpecificURLs(tracker);
  const updateContext = useUnleashContext();
  dayjs.tz.setDefault(company_timezone.name === 'Asia/Astana' ? 'Asia/Almaty' : company_timezone.name || 'Asia/Tashkent');

  const { shops } = useShops(!!user_id);

  const dispatch = useDispatch();

  useEffect(() => {
    updateContext({
      company_id: company_id,
    });
  }, [company_id]);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (user_id || userId) {
      requests.admin
        .getSingle(user_id)
        .then(user => {
          const isAdmin = user?.data?.roles?.find(role => role?.role?.name === 'Админ');
          requests.company.getSingle().then(({ data }) => {
            setFinanceEnabled(data?.finances_enabled);
            setIsGetCompanyFinished(true);
          });
          if (isAdmin) {
            dispatch(
              setUserShops(
                shops?.map(item => ({
                  id: item?.id,
                  name: item?.name,
                })),
              ),
            );
            setIsAdmin(true);
          } else if (user?.data?.shops?.length) {
            dispatch(
              setUserShops(
                user?.data?.shops?.map(item => ({
                  id: item?.shop_id,
                  name: item?.shop?.name,
                })),
              ),
            );
          }
          if (isAdmin && planStatus === 'active') {
            setRolePermissions({});
          } else if (user?.data?.roles?.length) {
            requests.admin
              .getUserPermissions(user_id || userId)
              .then(res => {
                setRolePermissions(res?.data || []);
              })
              .catch(err => {
                setRolePermissions({});
              });
          } else {
            setRolePermissions({});
          }
        })
        .catch(err => {
          setRolePermissions({});
          setIsGetCompanyFinished(true);
        });
    } else {
      setRolePermissions({});
      setIsGetCompanyFinished(true);
    }
  }, [planStatus]);

  useListenWebsocket(({ data }) => {
    if (typeof data === 'object' && data !== null && 'finances_enabled' in (data || {})) {
      setFinanceEnabled(data?.finances_enabled);
    }
  }, []);

  return isGetCompanyFinished ? (
    <>
      {rolePermissions ? (
        <RenderRoutes
          forbiddenRoutesFromFeatureFlag={
            financeEnabled ? forbiddenRoutesFromFeatureFlag : [...forbiddenRoutesFromFeatureFlag, ...financeRoutes]
          }
          permissions={rolePermissions}
          isAdmin={isAdmin}
        />
      ) : (
        <LoadingContainer fullHeight />
      )}
    </>
  ) : null;
}
function RenderRoutes({ permissions, forbiddenRoutesFromFeatureFlag, isAdmin }) {
  const dispatch = useDispatch();

  const validRoutes = useMemo(() => {
    return forbiddenRoutesFromFeatureFlag?.length ? filterRoutesBasedOnRoles(permissions, routes, forbiddenRoutesFromFeatureFlag) : null;
  }, [permissions, forbiddenRoutesFromFeatureFlag]);

  useEffect(() => {
    const forbiddenRoutesFromPermission = getDisallowedRoutes(permissions);

    const forbiddenRoutes = [
      ...forbiddenRoutesFromFeatureFlag,
      ...forbiddenRoutesFromPermission,
      {
        slug: isAdmin ? 'orders-epos' : '',
        route: '',
        options: '',
      },
    ];

    dispatch(setPermissionRoutes({ permissions: validRoutes || [], forbiddenRoutes }));
  }, [forbiddenRoutesFromFeatureFlag]);

  const routing = useRoutes(validRoutes || routes);
  return <Suspense fallback={<LoadingContainer />}>{routing || null}</Suspense>;
}
