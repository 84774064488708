/* eslint-disable */
const FinancingIcon = props => (
  <svg width="32" height="48" viewBox="0 0 16 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4036 20.7838H17C17 17.7641 15.2698 16 12.3382 16H4.66178C1.73022 16 0 17.7641 0 20.7453V27.2547C0 30.2359 1.73022 32 4.66178 32H12.3382C15.2698 32 17 30.2359 17 27.2547V26.9774H13.4036C11.7344 26.9774 10.3813 25.5978 10.3813 23.896C10.3813 22.1942 11.7344 20.8146 13.4036 20.8146V20.7838ZM13.4036 22.1089H16.3653C16.7159 22.1089 17 22.3986 17 22.756V25.0054C16.9959 25.361 16.7141 25.6483 16.3653 25.6525H13.4716C12.6266 25.6641 11.8877 25.0742 11.696 24.235C11.6 23.7141 11.7348 23.1766 12.0641 22.7665C12.3935 22.3564 12.8837 22.1157 13.4036 22.1089ZM13.532 24.4738H13.8116C14.1704 24.4738 14.4613 24.1772 14.4613 23.8113C14.4613 23.4455 14.1704 23.1488 13.8116 23.1488H13.532C13.3604 23.1468 13.1951 23.2149 13.073 23.3379C12.9509 23.4609 12.8822 23.6286 12.8822 23.8036C12.8822 24.1708 13.1719 24.4696 13.532 24.4738ZM4.02711 20.7839H8.82489C9.18375 20.7839 9.47467 20.4872 9.47467 20.1214C9.47467 19.7555 9.18375 19.4589 8.82489 19.4589H4.02711C3.67118 19.4588 3.38147 19.7508 3.37733 20.1137C3.37731 20.4808 3.66703 20.7796 4.02711 20.7839Z"
      fill="#1F78FF"
    />
  </svg>
);

export default FinancingIcon;
