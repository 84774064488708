import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';

import {
  ApplicationsPage,
  SingleApplicationPage,
  CashboxListPage,
  CashboxSinglePage,
  CashboxCreatePage,
  ChequeCreatePage,
  ChequeListPage,
  ChequeSinglePage,
  CompanySettingsPage,
  PaymentSettingsPage,
  RoundingPage,
  CurrencyPage,
  PaymentTypesPage,
  NotificationPage,
  TelegramSettingsPage,
  SettingsPlanPage,
  ProductSettingsPage,
  ProfileSettingsPage,
  ShopCreateSettingsPage,
  ShopListSettingsPage,
  ShopSingleSettingsPage,
} from 'pages';
import { CompanySettings } from '../../components/settings/CompanySettings';
import { IntegrationKeys } from '../../components/settings/IntegrationKeys';

const settingsRoutes = {
  path: 'settings',
  element: <DashboardLayout />,
  children: [
    {
      path: 'company',
      children: [
        { path: '', element: <CompanySettings /> },
        { path: 'basic', element: <CompanySettingsPage /> },
        {
          path: 'shop',
          children: [
            { path: ':id', element: <ShopSingleSettingsPage /> },
            { path: '', element: <ShopListSettingsPage /> },
            { path: 'create', element: <ShopCreateSettingsPage /> },
          ],
        },
        {
          path: 'cashbox',
          children: [
            { path: ':id', element: <CashboxSinglePage /> },
            { path: '', element: <CashboxListPage /> },
            { path: 'create', element: <CashboxCreatePage /> },
          ],
        },
        { path: 'integration-keys', element: <IntegrationKeys /> },
      ],
    },
    { path: 'profile', element: <ProfileSettingsPage /> },
    {
      path: 'cheque',
      children: [
        { path: ':id', element: <ChequeSinglePage /> },
        { path: '', element: <ChequeListPage /> },
        { path: 'create', element: <ChequeCreatePage /> },
      ],
    },
    {
      path: 'payment',
      children: [
        { path: '', element: <PaymentSettingsPage /> },
        { path: 'currency', element: <CurrencyPage /> },
        { path: 'types', element: <PaymentTypesPage /> },
        { path: 'rounding', element: <RoundingPage /> },
      ],
    },
    { path: 'product', element: <ProductSettingsPage /> },
    { path: 'plan', element: <SettingsPlanPage /> },
    {
      path: 'notification',
      children: [
        { path: '', element: <NotificationPage /> },
        { path: 'telegram', element: <TelegramSettingsPage /> },
      ],
    },
    { path: '', element: <Navigate to="/settings/profile" /> },
    { path: '*', element: <Navigate to="/settings/company" /> },
    {
      path: 'applications',
      children: [
        { path: '', element: <ApplicationsPage /> },
        { path: ':id', element: <SingleApplicationPage /> },
      ],
    },
  ],
};
export default settingsRoutes;
