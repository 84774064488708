/* eslint-disable */
import { request } from '../axios';
import * as qs from 'qs';

const integrations = {
  getSingleCashboxIntegrations: (id, filter) =>
    request.get(
      `v1/get-cashbox-integrations/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getAllCashboxIntegrations: () => request.get(`v1/get-cashbox-integration-info`),
};

export default integrations;
