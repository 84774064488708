/* eslint-disable */
import { useTheme } from '@mui/material/styles';

const LogoMain = props => {
  const { mode } = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.6606 0H10.3394C4.6291 0 0 4.6291 0 10.3394V21.6606C0 27.3709 4.6291 32 10.3394 32H21.6606C27.3709 32 32 27.3709 32 21.6606V10.3394C32 4.6291 27.3709 0 21.6606 0Z"
        fill={mode === 'dark' && !props?.presentation ? '#FFFFFF' : '#0065FF'}
      />
      <path
        d="M18.4035 10.9439L18.2154 11.1891L11.5466 19.8676C11.09 19.8929 10.6536 20.0647 10.3022 20.3574C9.95076 20.6501 9.70298 21.0483 9.59555 21.4928C9.48812 21.9374 9.52675 22.4047 9.70571 22.8256C9.88468 23.2464 10.1945 23.5985 10.5892 23.8295C10.9839 24.0606 11.4425 24.1584 11.8972 24.1084C12.3518 24.0585 12.7783 23.8634 13.1134 23.5521C13.4485 23.2409 13.6744 22.8299 13.7576 22.3802C13.8409 21.9305 13.7771 21.466 13.5757 21.0554L14.3523 20.042L20.4188 12.1915C20.8749 12.175 21.3136 12.0123 21.6703 11.7275C22.0269 11.4427 22.2825 11.0509 22.3994 10.6097C22.5164 10.1686 22.4884 9.70158 22.3197 9.27755C22.1509 8.85352 21.8504 8.49495 21.4623 8.25473C21.0743 8.01451 20.6193 7.90537 20.1645 7.94341C19.7096 7.98145 19.2791 8.16465 18.9363 8.46599C18.5936 8.76733 18.3568 9.17084 18.2608 9.61701C18.1649 10.0632 18.2149 10.5284 18.4035 10.9439V10.9439ZM17.2135 8.94116H10.507V11.1891H17.2149C16.9546 10.4624 16.9546 9.66785 17.2149 8.94116H17.2135ZM14.7931 23.1158H21.7104V20.8679H14.7924C15.0532 21.5946 15.0532 22.3892 14.7924 23.1158H14.7931Z"
        fill={mode === 'dark' && !props?.presentation ? '#1D1D1B' : '#FFFFFF'}
      />
    </svg>
  );
};

export default LogoMain;
