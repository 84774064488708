import { useQuery } from '@tanstack/react-query';
import { UserService } from 'shared/services/lib/UserService';

export const useGetUserPermissionsQuery = (id?: string) => {
  return useQuery({
    queryKey: ['USER_PERMISSIONS'],
    queryFn: async () => id && (await UserService.getUserPermissions(id).fetch()).data,
    enabled: Boolean(id),
  });
};
