import { FC } from 'react';
import { IconProps } from '../../types/icon.types';

export const IconCopyFile: FC<IconProps> = ({ width = 40, height = 40, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="40" height="40" rx="12" />
      <path
        d="M23 26V27.25C23 27.6875 22.6562 28 22.25 28H13.75C13.3125 28 13 27.6875 13 27.25V15.75C13 15.3438 13.3125 15 13.75 15H16V24.25C16 25.2188 16.7812 26 17.75 26H23ZM23 15.25C23 15.6875 23.3125 16 23.75 16H27V24.25C27 24.6875 26.6562 25 26.25 25H17.75C17.3125 25 17 24.6875 17 24.25V12.75C17 12.3438 17.3125 12 17.75 12H23V15.25ZM26.75 14.2812C26.9062 14.4375 27 14.625 27 14.8125V15H24V12H24.1875C24.375 12 24.5625 12.0938 24.7188 12.25L26.75 14.2812Z"
        fill="white"
      />
    </svg>
  );
};
