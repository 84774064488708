/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VariationsState {}

export const initialState: VariationsState = {};

const variationsStateSlice = createSlice({
  name: 'variationsState',
  initialState,
  reducers: {
    addVariation: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearVariationsState: () => {
      return initialState;
    },
  },
});

export const { addVariation, clearVariationsState } = variationsStateSlice.actions;
export default variationsStateSlice.reducer;
