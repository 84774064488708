/* eslint-disable */
import { guideActionTypes } from './guideActionTypes';

export const setGuideSlug = slug => ({
  type: guideActionTypes.SET_GUIDE_SLUG,
  payload: slug,
});
export const setGuideSize = size => ({
  type: guideActionTypes.SET_GUIDE_SIZE,
  payload: size,
});
export const setGuideRun = () => ({
  type: guideActionTypes.SET_GUIDE_RUN,
});
export const setGuideStart = () => ({
  type: guideActionTypes.SET_GUIDE_START,
});
export const setGuideStop = () => ({
  type: guideActionTypes.SET_GUIDE_STOP,
});
export const setGuideStep = step => ({
  type: guideActionTypes.SET_GUIDE_STEP,
  payload: step,
});
export const setGuideCompleted = slug => ({
  type: guideActionTypes.SET_GUIDE_COMPLETED,
  payload: slug,
});
