/* eslint-disable */
import { transferCreateTableHeaderActionTypes } from '../actions/transferCreateTableHeaderActions/transferCreateTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'Категория',
      accessor: 'category',
      is_active: true,
    },
    { Header: 'Цена поставки', accessor: 'price', is_active: true },
    { Header: 'Цена продажи', accessor: 'retail_price', is_active: true },
    { Header: 'Из магазина', accessor: 'from_shop', is_active: true },
    { Header: 'В магазин', accessor: 'to_shop', is_active: true },
    { Header: 'К отправке', accessor: 'to_departure', is_active: true },
  ],
};

const transferCreateTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case transferCreateTableHeaderActionTypes.UPDATE_TRANSFER_CREATE_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case transferCreateTableHeaderActionTypes.RESET_TRANSFER_CREATE_TABLE_HEADER:
      return initialState;
    case transferCreateTableHeaderActionTypes.REMOVE_CUSTOM_COLUMN:
      const filteredColumns = [...state?.columns].filter(column => column?.accessor !== payload);
      return {
        ...state,
        columns: filteredColumns,
      };
    case transferCreateTableHeaderActionTypes.UPDATE_CUSTOM_COLUMN:
      const newColumns = [...state?.columns].map(column => {
        return column?.accessor === payload?.id ? { ...column, Header: payload?.name } : column;
      });
      return {
        ...state,
        columns: newColumns,
      };

    default:
      return state;
  }
};

export default transferCreateTableHeaderReducer;
