/* eslint-disable */
import ReconnectingWebSocket from 'reconnecting-websocket';
import { BehaviorSubject, Subject } from 'rxjs';
import tryParseJSON from '../../shared/utils/tryParseJSON';
import SharedWorkerU from './WebSocketWorker?sharedworker';

const access_token = localStorage.getItem('access_token');
const passcode_token = localStorage.getItem('passcode_token');

const getWsUrl = (websocketMakeBillzTop: boolean): string => {
  return `wss://${
    websocketMakeBillzTop ? import.meta.env.VITE_APP_WEBSOCKET_FRA_URL : import.meta.env.VITE_APP_WEBSOCKET_URL
  }/ws?Authorization=${access_token || passcode_token}`;
};

let socket$: ReconnectingWebSocket | null = null;
const messageSubject = new Subject<any>();
const readyStateSubject = new BehaviorSubject<number>(WebSocket.CLOSED); // Default to closed

const connect = (isSharedWorker: boolean, websocketMakeBillzTop: boolean) => {
  if (!!window.SharedWorker && !isSharedWorker) {
    const myWorker = new SharedWorkerU();

    myWorker.port.postMessage({
      type: 'connect',
      payload: getWsUrl(websocketMakeBillzTop),
    });

    myWorker.port.onmessage = function (event) {
      const message = tryParseJSON(event.data) || event.data;
      messageSubject.next(message);
    };

    const state = {
      get readyState() {
        return readyStateSubject.getValue(); // Get the latest readyState
      },
      reconnect: function () {
        myWorker.port.postMessage({
          type: 'reconnect',
          payload: getWsUrl(websocketMakeBillzTop),
        });
      },
      send: function (data) {
        myWorker.port.postMessage({ type: 'send', payload: data });
      },
      close: function () {
        myWorker.port.postMessage({ type: 'close' });
      },
    };

    return state;
  } else {
    socket$ = new ReconnectingWebSocket(getWsUrl(websocketMakeBillzTop), [], {
      connectionTimeout: 3_000,
      maxReconnectionDelay: 5_000,
    });
    socket$.addEventListener('message', event => {
      const message = tryParseJSON(event.data) || event.data;
      messageSubject.next(message);
    });

    return socket$;
  }
};

export const sendMessage = (msg: any) => {
  if (socket$ && socket$.readyState === WebSocket.OPEN) {
    socket$.send(msg);
  }
};

export const initWebSocket = (isSharedWorker: boolean, websocketMakeBillzTop: boolean) => {
  return (access_token || passcode_token) && connect(isSharedWorker, websocketMakeBillzTop);
};

export const messages$ = messageSubject.asObservable();
export const readyState$ = readyStateSubject.asObservable();
