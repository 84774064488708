/* eslint-disable */
import { NeedHelpButtonNew } from 'layouts/Navbar/NeedHelpButtonNew';
import PlanNotification from 'pages/settings/plan/notification/Notification';
import React, { memo, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  wrapper: {
    width: '100%',
  },
  contentContainer: {
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.default,
  },
  content: {
    display: 'flex',
    maxWidth: '100%',
    borderRadius: 6,
    margin: '0 auto',
    '&::-webkit-scrollbar': {
      background: theme.palette.gray[100],
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.gray[300],
      width: 6,
    },
  },
  main: {
    width: '100%',
    minHeight: '100vh',
    padding: '0 144px',
    '@media (max-width: 1292px)': {
      padding: '0 64px',
    },
  },
}));

const CatalogLayout = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user_id } = useSelector(state => state.company);

  useEffect(() => {
    if ((!user_id || !localStorage.getItem('access_token')) && !localStorage.getItem('first_attempt')) {
      navigate('/login');
    }
  }, [user_id]);

  return (
    <div className={classes.root}>
      <NeedHelpButtonNew isAbsolute />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <PlanNotification isFullWidth />
          <div className={classes.content}>
            <main className={classes.main}>
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CatalogLayout);
