/* eslint-disable */
import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Subscription } from 'rxjs';
import useDeepCompareEffect from 'shared/hooks/useDeepCompareEffect';
import { initWebSocket, sendMessage, messages$, readyState$ } from './SharedWorkerWebsocketClient';
import ReconnectingWebSocket from 'reconnecting-websocket';

interface IWebSocketManager {
  addHandler: (id: string, handler: (message: any) => void) => void;
  removeHandler: (id: string) => void;
  enqueueMutation: (id: string, mutate: Function) => void;
  setLoading: (id: string, answersQty: number) => void;
}

const WebSocketContext = createContext<IWebSocketManager | null>(null);

export const WebSocketProviderNew = ({ children }) => {
  const messageHandlers = useRef(new Map<string, (message: any) => void>());
  const messageQueue = useRef(new Map<string, any[]>());
  const mutationQueue = useRef<{ id: string; mutationCall: Function }[]>([]);

  const socketRef = useRef<ReconnectingWebSocket | null>(null);
  const subscriptionRef = useRef<Subscription | null>(null);
  const pingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const isLoading = useRef(new Map<string, number>());
  const readyStateRef = useRef<any>(WebSocket.CLOSED);
  const awaitingPong = useRef(false);

  const { forbiddenRoutes } = useSelector(state => state?.permissionRoutes);
  const sharedWorkerRoute = forbiddenRoutes.find(el => el?.slug === 'sharedworker');
  const websocketMakeBillzTop = !forbiddenRoutes.find(el => el?.slug === 'websocket-makebillz-top');

  const enqueueMutation = (id: string, mutationCall: Function) => {
    if ((socketRef.current?.readyState === WebSocket.OPEN || readyStateRef.current === WebSocket.OPEN) && !awaitingPong.current) {
      mutationCall();
      return;
    }
    mutationQueue.current.push({ id, mutationCall });
  };

  const processMutationQueue = () => {
    while (
      mutationQueue.current.length > 0 &&
      (socketRef.current?.readyState === WebSocket.OPEN || readyStateRef.current === WebSocket.OPEN)
    ) {
      const { id, mutationCall } = mutationQueue.current.shift();
      if (mutationCall) mutationCall();
    }
  };

  const flushMessageQueue = (id: string) => {
    const queuedMessages = messageQueue.current.get(id);
    if (queuedMessages && messageHandlers.current.has(id)) {
      queuedMessages.forEach(msg => {
        const handler = messageHandlers.current.get(id);
        if (handler) {
          handler(msg);
        }
      });
      messageQueue.current.delete(id);
    }
  };

  const addHandler = (id: string, handler: (msg: any) => void) => {
    messageHandlers.current.set(id, handler);
    flushMessageQueue(id);
  };

  const removeHandler = (id: string) => {
    messageHandlers.current.delete(id);
  };

  const setLoading = (id: string, answersQty: number) => {
    if (answersQty === 0) {
      isLoading.current.delete(id);
      return;
    }
    isLoading.current.set(id, answersQty);
  };

  const sendPing = () => {
    if (socketRef.current?.readyState === WebSocket.OPEN || readyStateRef.current === WebSocket.OPEN) {
      if (!sharedWorkerRoute) {
        socketRef.current?.send('PING');
      } else {
        sendMessage('PING');
      }
      awaitingPong.current = true;

      pingTimeoutRef.current = setTimeout(() => {
        awaitingPong.current = false;
        socketRef.current?.reconnect();
      }, 1_0000);
    }
  };

  useDeepCompareEffect(() => {
    // if (socketRef.current) {
    //   socketRef.current.close()
    // }
    socketRef.current = initWebSocket(sharedWorkerRoute, websocketMakeBillzTop);

    const handleIncomingMessage = (msg: any) => {
      if (msg?.type === 'state') {
        readyStateRef.current = msg.readyState;
        return;
      }

      if (msg === 'PONG') {
        awaitingPong.current = false;
        if (pingTimeoutRef.current) {
          clearTimeout(pingTimeoutRef.current);
        }

        processMutationQueue();
        return;
      }

      if (msg === 'connected') {
        processMutationQueue();

        if (pingIntervalRef.current) {
          clearInterval(pingIntervalRef.current);
        }

        return;
      }

      const handler = messageHandlers.current.get(msg.correlation_id);
      const customWsLoadingQty = isLoading.current.get(msg.correlation_id) || 1;

      if (handler) {
        handler({
          ...msg,
          customWsLoading: customWsLoadingQty - 1,
        });
      } else {
        const existingQueue = messageQueue.current.get(msg.correlation_id) || [];
        messageQueue.current.set(msg.correlation_id, [
          ...existingQueue,
          {
            ...msg,
            customWsLoading: customWsLoadingQty - 1,
          },
        ]);
      }

      if (msg.correlation_id && isLoading.current.has(msg.correlation_id)) {
        setLoading(msg.correlation_id, customWsLoadingQty - 1);
      }
    };

    subscriptionRef.current = messages$.subscribe(handleIncomingMessage);
    readyState$.subscribe(() => {});

    const handleOnline = () => {
      if (!sharedWorkerRoute) {
        if (readyStateRef.current !== WebSocket.OPEN) {
          socketRef.current?.reconnect();
        }
        return;
      }
      if (socketRef.current?.readyState !== WebSocket.OPEN) {
        socketRef.current?.reconnect();
      }
    };

    const handleOffline = () => {
      socketRef.current?.close();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        sendPing();
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      socketRef.current?.close();
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
      if (pingTimeoutRef.current) {
        clearTimeout(pingTimeoutRef.current);
      }
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [sharedWorkerRoute]);

  return (
    <WebSocketContext.Provider
      value={{
        addHandler,
        removeHandler,
        enqueueMutation,
        setLoading,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketMessageHandlerNew = () => useContext(WebSocketContext);
