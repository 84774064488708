import { FC } from 'react';
import { IconProps } from '../../types/icon.types';

export const IconDelete: FC<IconProps> = ({ width = 40, height = 40, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="40" height="40" rx="12" />
      <path
        d="M26.5 13C26.75 13 27 13.25 27 13.5V14.5C27 14.7812 26.75 15 26.5 15H13.5C13.2188 15 13 14.7812 13 14.5V13.5C13 13.25 13.2188 13 13.5 13H17.25L17.5312 12.4375C17.6562 12.1875 17.9062 12 18.1875 12H21.7812C22.0625 12 22.3125 12.1875 22.4375 12.4375L22.75 13H26.5ZM14.6562 26.5938L14 16H26L25.3125 26.5938C25.2812 27.4062 24.625 28 23.8125 28H16.1562C15.3438 28 14.6875 27.4062 14.6562 26.5938Z"
        fill="white"
      />
    </svg>
  );
};
