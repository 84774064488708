/* eslint-disable */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  steps: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '41.5px',
  },
  step: {
    height: '8px',
    width: '100%',
    borderRadius: 16,
    backgroundColor: '#F1F3F5',
    marginRight: 12,
    '&:last-child': {
      marginRight: 0,
    },
  },
  activeStep: {
    backgroundColor: theme.palette.blue[600],
  },
}));

const Steps = ({ current }) => {
  const classes = useStyles();

  return (
    <div className={classes.steps}>
      {[1, 2].map(index => (
        <div className={`${classes.step} ${index <= current && classes.activeStep}`} />
      ))}
    </div>
  );
};

export default Steps;
