import { AxiosRequestConfig } from 'axios';
import { ApiCaller } from '../utils';
import { Service } from './Service';
import { UserServiceNS } from './UserService.d';
import { DefaultResponse } from '../../types/common.types';

const BASE_URL = 'v1';
const BASE_URL_V2 = 'v2';

const apiCaller = new ApiCaller(BASE_URL);
const apiCallerV2 = new ApiCaller(BASE_URL_V2);

export class UserService implements Service {
  public static getUsers(params: UserServiceNS.LoadIntegratedUsersParamsDTO, config?: AxiosRequestConfig) {
    return apiCaller.get<UserServiceNS.LoadUsersListDTO>(
      '/user',
      { ...params, force_roles: true },
      {
        paramsSerializer: {
          indexes: null,
        },
        ...config,
      },
    );
  }

  public static postCreateIntegratedUser(body: UserServiceNS.CreateIntegratedUserDTO, config: AxiosRequestConfig = {}) {
    return apiCallerV2.post<DefaultResponse>('/user/create_integrated?Billz-Response-Channel=HTTP', body, {
      ...config,
    });
  }

  public static putEditUser(body: UserServiceNS.UserDTO, config: AxiosRequestConfig = {}) {
    const { id, ...user } = body;
    return apiCaller.put<DefaultResponse>(`/user/${id}?Billz-Response-Channel=HTTP`, user, {
      ...config,
    });
  }

  public static deleteUser(id: string, config: AxiosRequestConfig = {}) {
    return apiCaller.delete<DefaultResponse>(`/user/${id}`, config);
  }

  public static getUserSecret(id: string, config: AxiosRequestConfig = {}) {
    return apiCallerV2.get<{ secret: string }>(`/user/${id}/secret`, {
      ...config,
    });
  }

  public static getUserPermissions(id: string, config: AxiosRequestConfig = {}) {
    return apiCallerV2.get<{ sections: UserServiceNS.Section[]; user_id: string }>(`/user/${id}/permissions`, {
      ...config,
    });
  }

  public static getUser(config: AxiosRequestConfig = {}) {
    return apiCaller.get<UserServiceNS.UserDTO>(`/user-self`, {
      ...config,
    });
  }

  public static getUserById(id: string, config: AxiosRequestConfig = {}) {
    return apiCaller.get<UserServiceNS.UserDTO>(`/user/${id}`, { force_roles: true }, { ...config });
  }

  public static deleteUserSession(id: string, config: AxiosRequestConfig = {}) {
    return apiCaller.delete<DefaultResponse>(`/user/${id}/clear-session`, config);
  }
}
