/* eslint-disable */
import CartIcon from 'shared/icons/CartIcon';

export const productsRouteData = {
  label: 'products',
  icon: <CartIcon />,
  id: '1',
  href: '/products',
  className: 'products_guide_element',
  children: [
    {
      label: 'catalog',
      active: '/products/catalog/*',
      id: '11',
      href: '/products/catalog',
    },
    {
      label: 'import',
      active: '/products/import/*',
      id: '13',
      href: '/products/import',
    },
    {
      label: 'orders',
      active: '/products/orders/*',
      id: '14',
      href: '/products/orders',
    },
    {
      label: 'inventory',
      id: '15',
      active: '/products/inventory/*',
      href: '/products/inventory',
    },
    {
      label: 'transfer',
      id: '16',
      active: '/products/transfer/*',
      href: '/products/transfer',
    },
    {
      label: 'revaluation',
      active: '/products/revaluation/*',
      id: '17',
      href: '/products/revaluation',
    },
    {
      label: 'write-off',
      active: '/products/write-off/*',
      id: '18',
      href: '/products/write-off',
    },
    {
      label: 'suppliers',
      active: '/products/suppliers/*',
      id: '19',
      href: '/products/suppliers',
    },
  ],
};
