/* eslint-disable */
import { employeeActionTypes } from '../actions/employeeActions/employeeActionTypes';

const initialState = [];

const employeeMassiveOperationReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case employeeActionTypes.SET_EMPLOYEES:
      return [...payload];
    case employeeActionTypes.CLEAR_EMPLOYEES:
      return [];
    default:
      return state;
  }
};

export default employeeMassiveOperationReducer;
