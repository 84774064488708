import * as qs from 'qs';
import { request } from '../axios';

const excelImport = {
  getProperties: filter => request.get(`v2/excel/import-properties${qs.stringify(filter, { addQueryPrefix: true })}`),
  getExcel: () => request.get(`v2/excel/import-properties-file`),
  getProductImport: filter =>
    request.get(
      `v2/product-import/${filter.correlation_id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  getImportProgress: id => request.get(`v2/import-progress/${id}`),
  validateImport: data => request.post(`v2/excel/validate-import`, data),
  finishImport: ({ id, data, protocol }) => request.post(`v2/import/finish/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  cancelImport: ({ id, protocol }) => request.post(`v2/import/cancel/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
};

export default excelImport;
