import { lazyLoad } from '../../../../shared/utils/loadable';

export const TransferSinglePage = lazyLoad(
  () => import('./TransferSinglePage'),
  module => module.default,
);

export const TransferSingleViewPage = lazyLoad(
  () => import('./TransferSingleViewPage'),
  module => module.default,
);
