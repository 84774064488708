/* eslint-disable */
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { LifeRingIcon } from './LifeRingIcon';
import Button from 'stories/Button/Button';

interface ICustomButtonProps {
  id: string;
  isOpen?: boolean;
  classes: any;
  open: boolean;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  overlay?: boolean;
}

export const CustomButton = ({ id, isOpen, classes, open, handleClick, overlay }: ICustomButtonProps) => {
  const { t } = useTranslation();
  return isOpen ? (
    <Button id={id} className={`${classes.button} ${overlay ? classes.customMargin : ''}`} sx={{ minHeight: '64px' }} onClick={handleClick}>
      <Box className={classes.buttonBox}>
        {open ? (
          <>
            <FontAwesomeIcon icon={faAngleUp} />
            {t('buttons.cancel')}
          </>
        ) : (
          <>
            <LifeRingIcon />
            {t('chaport.support')}
          </>
        )}
      </Box>
    </Button>
  ) : (
    <Button id={id} className={`${classes.buttonMini} ${overlay ? classes.customMiniMargin : ''}`} onClick={handleClick}>
      {open ? <FontAwesomeIcon icon={faXmark} /> : <LifeRingIcon />}
    </Button>
  );
};
