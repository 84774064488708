/* eslint-disable */
const CalcIcon = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {props?.gray ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7228 21.7176L23.2991 22.2938C23.7554 22.7419 24.0035 23.35 24.0035 23.9901C24.0115 24.6303 23.7634 25.2392 23.3151 25.6945C23.3098 25.7004 23.3044 25.7056 23.2991 25.7107C23.2964 25.7133 23.2938 25.7159 23.2911 25.7185L22.7228 26.2867C22.4986 26.5107 22.3705 26.8148 22.3705 27.1357V27.9591C22.3705 29.2874 21.2899 30.3684 19.9611 30.3684H19.1366C18.8164 30.3684 18.5123 30.4957 18.2881 30.7197L17.7118 31.2958C17.2395 31.7687 16.6231 32 16.0068 32C15.3904 32 14.774 31.7687 14.3018 31.3046L13.7174 30.7197C13.4933 30.4957 13.1891 30.3684 12.8689 30.3684H12.0444C10.7156 30.3684 9.63498 29.2874 9.63498 27.9591V27.1357C9.63498 26.8148 9.50691 26.5107 9.28277 26.2787L8.70643 25.7105C7.76988 24.7751 7.76187 23.246 8.69843 22.3026L9.28277 21.7176C9.50691 21.4936 9.63498 21.1895 9.63498 20.8614V20.0452C9.63498 18.7169 10.7156 17.6375 12.0444 17.6375H12.8689C13.1891 17.6375 13.4933 17.5087 13.7174 17.2846L14.2937 16.7085C15.2303 15.765 16.7592 15.765 17.7038 16.7013L18.2881 17.2846C18.5123 17.5087 18.8164 17.6375 19.1366 17.6375H19.9611C21.2899 17.6375 22.3705 18.7169 22.3705 20.0452V20.8702C22.3705 21.1895 22.4986 21.4936 22.7228 21.7176ZM13.9415 26.7588C14.1337 26.7588 14.3098 26.6868 14.4378 26.5507L18.5523 22.4386C18.8244 22.1665 18.8244 21.7176 18.5523 21.4456C18.2801 21.1743 17.8399 21.1743 17.5677 21.4456L13.4532 25.5585C13.1811 25.8306 13.1811 26.2787 13.4532 26.5507C13.5813 26.6868 13.7574 26.7588 13.9415 26.7588ZM17.3596 26.0546C17.3596 26.4467 17.6718 26.7588 18.064 26.7588C18.4482 26.7588 18.7604 26.4467 18.7604 26.0546C18.7604 25.6713 18.4482 25.3585 18.064 25.3585C17.6718 25.3585 17.3596 25.6713 17.3596 26.0546ZM13.9495 21.2455C14.3338 21.2455 14.646 21.5576 14.646 21.9417C14.646 22.3346 14.3338 22.6458 13.9495 22.6458C13.5653 22.6458 13.2451 22.3346 13.2451 21.9417C13.2451 21.5576 13.5653 21.2455 13.9495 21.2455Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7228 21.7176L23.2991 22.2938C23.7554 22.7419 24.0035 23.35 24.0035 23.9901C24.0115 24.6303 23.7634 25.2392 23.3151 25.6945C23.3098 25.7004 23.3044 25.7056 23.2991 25.7107C23.2964 25.7133 23.2938 25.7159 23.2911 25.7185L22.7228 26.2867C22.4986 26.5107 22.3705 26.8148 22.3705 27.1357V27.9591C22.3705 29.2874 21.2899 30.3684 19.9611 30.3684H19.1366C18.8164 30.3684 18.5123 30.4957 18.2881 30.7197L17.7118 31.2958C17.2395 31.7687 16.6231 32 16.0068 32C15.3904 32 14.774 31.7687 14.3018 31.3046L13.7174 30.7197C13.4933 30.4957 13.1891 30.3684 12.8689 30.3684H12.0444C10.7156 30.3684 9.63498 29.2874 9.63498 27.9591V27.1357C9.63498 26.8148 9.50691 26.5107 9.28277 26.2787L8.70643 25.7105C7.76988 24.7751 7.76187 23.246 8.69843 22.3026L9.28277 21.7176C9.50691 21.4936 9.63498 21.1895 9.63498 20.8614V20.0452C9.63498 18.7169 10.7156 17.6375 12.0444 17.6375H12.8689C13.1891 17.6375 13.4933 17.5087 13.7174 17.2846L14.2937 16.7085C15.2303 15.765 16.7592 15.765 17.7038 16.7013L18.2881 17.2846C18.5123 17.5087 18.8164 17.6375 19.1366 17.6375H19.9611C21.2899 17.6375 22.3705 18.7169 22.3705 20.0452V20.8702C22.3705 21.1895 22.4986 21.4936 22.7228 21.7176ZM13.9415 26.7588C14.1337 26.7588 14.3098 26.6868 14.4378 26.5507L18.5523 22.4386C18.8244 22.1665 18.8244 21.7176 18.5523 21.4456C18.2801 21.1743 17.8399 21.1743 17.5677 21.4456L13.4532 25.5585C13.1811 25.8306 13.1811 26.2787 13.4532 26.5507C13.5813 26.6868 13.7574 26.7588 13.9415 26.7588ZM17.3596 26.0546C17.3596 26.4467 17.6718 26.7588 18.064 26.7588C18.4482 26.7588 18.7604 26.4467 18.7604 26.0546C18.7604 25.6713 18.4482 25.3585 18.064 25.3585C17.6718 25.3585 17.3596 25.6713 17.3596 26.0546ZM13.9495 21.2455C14.3338 21.2455 14.646 21.5576 14.646 21.9417C14.646 22.3346 14.3338 22.6458 13.9495 22.6458C13.5653 22.6458 13.2451 22.3346 13.2451 21.9417C13.2451 21.5576 13.5653 21.2455 13.9495 21.2455Z"
          fill="#1F78FF"
        />
      </>
    )}
  </svg>
);

export default CalcIcon;
