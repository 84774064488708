import qs from 'qs';
import { request } from '../axios';

const writeOff = {
  getAll: filter => request.get(`v2/write-off${qs.stringify(filter, { addQueryPrefix: true })}`),
  getAllProducts: filter =>
    request.get(
      `v2/write-off-products/${filter.id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  getExcel: () => request.get(`v2/excel/write-off-properties-file`),
  getItems: filter =>
    request.get(
      `v2/write-off-items/${filter.id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  getWriteOffProgress: id => request.get(`v2/file-write-off-progress/${id}`),
  getFileWriteOff: filter =>
    request.get(
      `v2/file-write-off/${filter.correlation_id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  getWriteOffReasons: filter => request.get(`v2/write-off-reason${qs.stringify(filter, { addQueryPrefix: true })}`),
  getDetails: id => request.get(`v2/write-off/${id}`),
  create: ({ data, protocol }) => request.post(`v2/write-off${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  createFromFile: data => request.post(`v2/write-off-from-file`, data),
  createWriteOffReason: data => request.post(`v2/write-off-reason`, data),
  cancelWriteOff: ({ id, protocol }) => request.put(`v2/write-off/cancel/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  updateWriteOff: ({ id, data, protocol }) =>
    request.put(`v2/write-off/update/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  finish: ({ id, protocol }) => request.put(`v2/write-off/finish/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  addItem: ({ id, data, protocol }) => request.post(`v2/write-off/${id}/add${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  removeItem: ({ id, data, protocol }) =>
    request.post(`v2/write-off/${id}/remove${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  delete: ({ id, protocol }) => request.delete(`v2/write-off/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  generateExcel: data => request.post(`v2/write-off-export/${data.id}`, data.body),
  addItemBarcode: ({ id, data }) => request.post(`v2/write-off/${id}/add-by-barcode?Billz-Response-Channel=HTTP`, data), // Работает через HTTP
};

export default writeOff;
