import { CountDrawer, DownloadButton, RowsPerPageSelector } from './local-components';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
  },
  advanced: {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
  },
}));

type PaginationProps = {
  count: number;
  onChangePage: (nextPage: number) => void;
  page: number;
  isDownloading: boolean;
  onDownload?: () => void;
  pageSize: number;
  onSetPageSize: (pageSize: number) => void;
};

const Pagination = ({ count, onChangePage, page, isDownloading, onDownload, pageSize, onSetPageSize }: PaginationProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CountDrawer count={count} onChangePage={onChangePage} page={page} />
      <div className={classes.advanced}>
        {onDownload && <DownloadButton isDownloading={isDownloading} onDownload={onDownload} />}
        <RowsPerPageSelector pageSize={pageSize} onSetPageSize={onSetPageSize} />
      </div>
    </div>
  );
};

export default Pagination;
