import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, IconButton, Typography } from '../../../shared/ui';
import { IconCopyFile } from '../../../shared/icons/new/IconCopyFile';
import { IconDelete } from '../../../shared/icons/new/IconDelete';
import { UserType } from '../../../shared/types/user.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CellProps } from 'react-table';
import { useStyles } from './IntegrationKeys.styles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { UserServiceNS } from '@/shared/services';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

type UseColumnsProps = {
  status: 'active' | 'deleted';
  onEdit: (user: UserServiceNS.UserDTO) => void;
  onCopy: (id: string) => void;
  onDelete: (id: string) => void;
};

export const useColumns = ({ status, onCopy, onDelete, onEdit }: UseColumnsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return React.useMemo(
    () => [
      {
        Header: t('table_columns.title'),
        accessor: 'first_name',
        Cell: ({ row }: CellProps<UserServiceNS.UserDTO>) => {
          return (
            <Typography className={classes.nameCell} color="primary" onClick={() => onEdit(row.original)}>
              {row.original.first_name}
            </Typography>
          );
        },
      },
      {
        Header: t('table_columns.created_at'),
        accessor: 'created_at',
        Cell: ({ row }: CellProps<UserServiceNS.UserDTO>) => {
          return <div>{dayjs(row.original.created_at).format('DD.MM.YYYY HH:mm:ss')}</div>;
        },
      },
      {
        Header: t('table_columns.employee'),
        Cell: ({ row }: CellProps<UserServiceNS.UserDTO>) => {
          return (
            <Link to={`/management/employees/view/${row.original.id}`}>
              {t('integrations.key')} {`"${row.original.first_name}"`} <FontAwesomeIcon icon={faArrowCircleRight} />
            </Link>
          );
        },
      },
      {
        Header: t('table_columns.role'),
        Cell: ({ row }: CellProps<UserServiceNS.UserDTO>) => {
          const roles = row.original?.roles ?? [];
          const integratedRole = roles?.find(role => role?.role?.type === UserType.INTEGRATED);

          if (integratedRole) {
            return (
              <Link to={`/management/roles/create/${integratedRole.role_id}?update=true&page=1`}>
                {t('integrations.key')} {`"${integratedRole?.role?.name}"`} <FontAwesomeIcon icon={faArrowCircleRight} />
              </Link>
            );
          }
          return (
            <Typography>
              {t('integrations.key')} {`"${row.original.first_name}"`}
            </Typography>
          );
        },
      },
      {
        Header: t('table_columns.actions'),
        Cell: ({ row }: CellProps<UserServiceNS.UserDTO>) => {
          return (
            <div className={classes.actionsCell}>
              <IconButton
                className={clsx(status === 'deleted' && classes.hidden)}
                aria-label="copy"
                variant="info"
                icon={IconCopyFile}
                onClick={() => onCopy(row.original.id)}
              />
              <IconButton
                className={clsx(status === 'deleted' && classes.hidden)}
                aria-label="delete"
                variant="error"
                icon={IconDelete}
                onClick={() => onDelete(row.original.id)}
              />
            </div>
          );
        },
      },
    ],
    [t, classes.nameCell, classes.actionsCell, classes.hidden, onEdit, status, onCopy, onDelete],
  );
};
