import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {},

  header: {
    padding: '0px',
  },

  input: {
    marginBottom: 4,
  },

  content: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}));
