/* eslint-disable */
import { request } from '../axios';

const telegramNotification = {
  getAll: () => request.get(`/v1/settings/telegram-notification`),
  getProductFields: () => request.get(`/v1/settings/product-fields`),
  getSortFields: () => request.get(`/v1/settings/sort-fields`),
  getSellerFields: () => request.get(`/v1/settings/seller-fields`),
  getReportFields: () => request.get(`/v1/settings/report-fields`),
  update: data => request.post(`/v1/settings/telegram-notification`, data),
};

export default telegramNotification;
