import * as qs from 'qs';
import { request } from '../axios';

const brand = {
  getAll: filter => request.get(`v2/brand${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: ({ data, protocol }) => request.post(`v2/brand${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  getSingle: id => request.get(`v2/brand/${id}`),
  update: data => request.put(`v2/brand/${data.id}`, data.data),
  delete: id => request.delete(`v2/brand/${id}`),
};

export default brand;
