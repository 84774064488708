import { lazyLoad } from '../../shared/utils/loadable';

export const OrderCreatePage = lazyLoad(
  () => import('./OrderCreatePage'),
  module => module.default,
);

export const AllOrdersPage = lazyLoad(
  () => import('./AllOrdersPage'),
  module => module.default,
);

export * from './new-order';
export * from './cashbox-operations';
export * from './cash-shifts';
