import { InputBase, InputLabel, InputBaseProps } from '@mui/material';
import { useStyles } from './Input.styles';
import clsx from 'clsx';
import { useCrypto } from '../../../shared/hooks/useCrypto';

type InputProps = {
  label?: string;
  className?: string;
} & InputBaseProps;

const Input = ({ label, className, ...props }: InputProps) => {
  const classes = useStyles();
  const randomId = useCrypto();

  const id = `input_${randomId}`;

  return (
    <div className={clsx(classes.root, className)}>
      {label && (
        <InputLabel htmlFor={id} className={classes.label}>
          {label}
        </InputLabel>
      )}
      <InputBase id={id} className={classes.input} {...props} />
    </div>
  );
};

export default Input;
