import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '2px',
    border: 'none',
    margin: ({
      marginTop = 0,
      marginBottom = 0,
      marginLeft = 0,
      marginRight = 0,
    }: {
      marginTop?: number;
      marginBottom?: number;
      marginLeft?: number;
      marginRight?: number;
    }) => `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`,
  },

  dashed: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.gray[200]}, ${theme.palette.gray[200]} 75%, transparent 75%, transparent 100%)`,
    backgroundSize: '20px 1px',
  },

  solid: {
    backgroundImage: `linear-gradient(90deg, ${theme.palette.gray[200]}, ${theme.palette.gray[200]} 100%)`,
    backgroundSize: '20px 1px',
  },
}));
