/* eslint-disable */
import { importActionTypes } from '../actions/importActions/importActionTypes';

const initialState = {
  correlation_id: null,
  isLoading: false,
  import_name: '',
  import_id: '',
};

const importReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case importActionTypes.SET_CORRELATION_ID:
      return {
        ...state,
        correlation_id: payload,
      };
    case importActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case importActionTypes.SET_IMPORT_NAME:
      return {
        ...state,
        import_name: payload,
      };
    case importActionTypes.SET_IMPORT_ID:
      return {
        ...state,
        import_id: payload,
      };

    default:
      return state;
  }
};

export default importReducer;
