import * as qs from 'qs';
import { request } from '../axios';

const supplier = {
  getAll: filter => request.get(`v2/supplier${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: ({ data, protocol }) => request.post(`v1/supplier${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  getSingle: id => request.get(`v2/supplier/${id}`),
  getSingleV1: id => request.get(`v1/supplier/${id}`),
  update: data => request.put(`v1/supplier/${data.id}`, data.data),
  delete: id => request.delete(`v1/supplier/${id}`),
  makePayment: data => request.post(`v2/supplier/payment`, data),
  getPayments: (id, filter) =>
    request.get(
      `v2/supplier-payments/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getPayment: id => request.get(`v2/supplier-payment/${id}`),
  deleteSupplierPayment: id => request.delete(`v2/supplier-payment/${id}`),
  getSuppliersExcel: data => request.post(`v1/supplier/generate_excel`, data),
};

export default supplier;
