/* eslint-disable */
import { promoTableHeaderActionTypes } from 'store/actions/promotionsTableHeaderActions/promotionsTableHeaderActionsTypes';

export const initialState = {
  columns: [
    {
      Header: 'ID',
      accessor: 'external_id',
      is_active: true,
    },
    {
      Header: 'Магазин',
      accessor: 'shop_name',
      is_active: true,
    },
    { Header: 'Наименование', accessor: 'name', is_active: true },
    { Header: 'Начало', accessor: 'start_time', is_active: true },
    {
      Header: 'Конец',
      accessor: 'end_time',
      is_active: true,
    },
    {
      Header: 'Статус',
      accessor: 'status',
      is_active: true,
    },
    {
      Header: 'Описание',
      accessor: 'description',
      is_active: true,
    },
    {
      Header: 'Тип акции',
      accessor: 'promotion_type',
      is_active: true,
    },
    {
      Header: 'Действия',
      accessor: 'actions',
      is_active: true,
    },
  ],
};

const promoTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case promoTableHeaderActionTypes.UPDATE_PROMO_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case promoTableHeaderActionTypes.RESET_PROMO_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default promoTableHeaderReducer;
