/* eslint-disable */
import { request } from '../axios';

const productAttributes = {
  getAll: id => request.get(`v1/product-attribute?product_id=${id}`),
  create: data => request.post(`v1/product-attribute`, data),
  update: data => request.put(`v1/product-attribute/${data.id}`, data),
};

export default productAttributes;
