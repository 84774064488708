import React from 'react';
import { useStyles } from './CreateUserDrawer.styles';
import { Button, Input, SectionHeader, Drawer, Divider } from '@/shared/ui';
import { useTranslation } from 'react-i18next';
import { useCreateUserMutation } from '@/shared/hooks/user';

type CreateUserDrawerProps = {
  visible: boolean;
  onClose: () => void;
};
const CreateUserDrawer = ({ visible, onClose }: CreateUserDrawerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const nameRef = React.useRef('');

  const { mutate: createUser, isSuccess: isCreateSuccess, reset: resetCreateUser, isLoading } = useCreateUserMutation();

  const isSuccess = isCreateSuccess;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    nameRef.current = event.target.value;
  };

  const handleCreate = () => {
    const payload = {
      service_name: nameRef.current,
    };
    createUser(payload);
  };

  React.useEffect(() => {
    if (isSuccess) {
      onClose();
      resetCreateUser();
    }
  }, [isSuccess, onClose, resetCreateUser]);

  return (
    <Drawer open={visible} onClose={onClose} anchor="right" elevation={1} className={classes.root}>
      <div className={classes.content}>
        <div>
          <SectionHeader className={classes.header} title={t('integrations.new_key')} onBackClick={onClose} />

          <Divider marginVertical="xl" />

          <Input
            className={classes.input}
            fullWidth
            placeholder={t('placeholders.enter_name')}
            label={t('integrations.key_name')}
            onChange={handleNameChange}
            autoFocus
          />
        </div>

        <Button disabled={isLoading} onClick={handleCreate}>
          {t('buttons.create')}
        </Button>
      </div>
    </Drawer>
  );
};

export default CreateUserDrawer;
