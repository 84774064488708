/* eslint-disable */
import arrayMove from 'shared/utils/arrayMove';
import { tableHeaderActionTypes } from '../actions/tableHeaderActions/tableHeaderActionTypes';

export const initialState = {
  settings: {
    showShopColumns: false,
    showVariatives: false,
  },
  columns: [
    {
      field: 'image',
      hide: false,
      minWidth: 70,
      initialWidth: 97,
      sortable: false,
      resizable: false,
    },
    {
      field: 'name',
      hide: false,
      minWidth: 70,
      default_width: 206,
    },
    {
      field: 'sku',
      hide: false,
      minWidth: 70,
      default_width: 158,
    },
    {
      field: 'barcode',
      hide: false,
      minWidth: 70,
      default_width: 190,
    },
    {
      field: 'category',
      hide: false,
      minWidth: 70,
      default_width: 158,
      enableRowGroup: true,
    },
    {
      field: 'suppliers',
      hide: false,
      minWidth: 70,
      default_width: 158,
      enableRowGroup: true,
    },
    {
      field: 'quantity',
      hide: false,
      minWidth: 70,
      default_width: 126,
    },
    {
      field: 'supply_price',
      hide: false,
      minWidth: 70,
      default_width: 187,
    },
    {
      field: 'retail_price',
      hide: false,
      minWidth: 70,
      default_width: 187,
    },
    {
      field: 'discount_price',
      hide: false,
      minWidth: 70,
      default_width: 187,
    },
    {
      field: 'wholesale_price',
      minWidth: 70,
      default_width: 158,
      hide: true,
      initialHide: true,
    },
    {
      field: 'brand',
      hide: false,
      minWidth: 70,
      default_width: 187,
    },
    {
      field: 'description',
      hide: true,
      minWidth: 70,
      default_width: 187,
    },
  ],
};

const changeColumnSequence = (columns, payload) => {
  const newColumns = arrayMove(payload?.tableColumns || columns, payload.oldIndex, payload.newIndex, 'sequence_number');
  return newColumns;
};
const updateColumnProperty = (columns, payload, field) => {
  const updatedColumn = columns.map(el => (el.field === field ? { ...el, ...payload } : el));

  return updatedColumn;
};
const tableHeaderReducer = (state = initialState, action) => {
  const { payload, field, options } = action;
  switch (action.type) {
    case tableHeaderActionTypes.UPDATE_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };
    case tableHeaderActionTypes.UPDATE_TABLE_HEADER_SETTINGS:
      return {
        ...state,
        settings: payload,
      };
    case tableHeaderActionTypes.CHANGE_COLUMN_SEQUENCE:
      return {
        ...state,
        columns: changeColumnSequence(state.columns, payload),
      };
    case tableHeaderActionTypes.UPDATE_COLUMN_PROPERTY:
      return {
        ...state,
        settings: {
          ...state.settings,
          showVariatives: payload?.grouped ? false : state.settings?.showVariatives,
        },
        columns: updateColumnProperty(state.columns, payload, field, options),
      };

    case tableHeaderActionTypes.RESET_COLUMNS_WIDTH:
      return {
        ...state,
        columns: state.columns.map(el => ({
          ...el,
          width: payload?.find(col => col.field === el.field)?.width || el?.default_width,
        })),
      };
    case tableHeaderActionTypes.SET_TABLE_COLUMNS:
      const columns = [...state?.columns];
      const accessors = state?.columns?.map(column => column?.field);
      payload?.forEach(item => {
        if (!accessors.includes(item?.field)) {
          columns?.push(item);
        }
      });
      return {
        ...state,
        columns,
      };
    case tableHeaderActionTypes.REMOVE_CUSTOM_COLUMN:
      const filteredColumns = [...state?.columns].filter(column => column?.colId !== payload);
      return {
        ...state,
        columns: filteredColumns,
      };
    case tableHeaderActionTypes.UPDATE_CUSTOM_COLUMN:
      const newColumns = [...state?.columns].map(column => {
        return column?.colId === payload?.id ? { ...column, headerName: payload?.name, field: payload?.name } : column;
      });
      return {
        ...state,
        columns: newColumns,
      };
    case tableHeaderActionTypes.RESET_TABLE_HEADER:
      return payload?.columns?.length ? payload : initialState;

    default:
      return state;
  }
};

export default tableHeaderReducer;
