/* eslint-disable */
import { importSingleTableHeaderActionTypes } from '../actions/importSingleTableHeaderActions/importSingleTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'Заявлено',
      accessor: 'declared',
      is_active: true,
    },
    {
      Header: 'Кол-во',
      accessor: 'quantity',
      is_active: true,
    },
    { Header: 'Цена прихода', accessor: 'price', is_active: true },
    { Header: 'Цена продажи', accessor: 'retail_price', is_active: true },
    { Header: 'Тип продукта', accessor: 'product_type', is_active: true },
  ],
};

const importSingleTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case importSingleTableHeaderActionTypes.UPDATE_IMPORT_SINGLE_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case importSingleTableHeaderActionTypes.RESET_IMPORT_SINGLE_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default importSingleTableHeaderReducer;
