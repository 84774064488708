import React from 'react';
import { Paper, ClickAwayListener, Typography } from '@mui/material';
import Button from 'stories/Button/Button';
import SortIcon from 'shared/icons/SortIcon';
import TickIcon from 'shared/icons/TickIcon';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { List, ListItem } from '@/shared/ui';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    flexShrink: 0,
  },
  lineSortContainer: {
    position: 'absolute',
    bottom: 'calc(100% + 8px)',
    width: '100%',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.gray[300]}`,
    borderRadius: 16,
    zIndex: '2',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  lineSortList: {
    padding: 0,
    overflow: 'hidden',
  },
  lineSortItem: {
    background: 'inherit',
    border: '0',
    outline: '0',
    fontWeight: '600',
    fontSize: '16px',
    cursor: 'pointer',
    lineHeight: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 16px',
    '&:hover': {
      background: theme.palette.gray[100],
    },
  },
}));

type RowsPerPageSelectorProps = {
  pageSize: number;
  onSetPageSize: (page: number) => void;
};

const ROWS_PER_PAGE = [5, 10, 20, 30, 40, 50];

const RowsPerPageSelector = ({ pageSize, onSetPageSize }: RowsPerPageSelectorProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleListItemClick = (option: number) => {
    onSetPageSize(option);
    setOpen(false);
  };

  const rowsPerPageOptions = ROWS_PER_PAGE.map(option => (
    <ListItem key={option} component="button" className={classes.lineSortItem} onClick={() => handleListItemClick(option)}>
      <Typography>
        {option} {t('menu.products.import.nav.lines')}
      </Typography>
      {option === pageSize && <TickIcon />}
    </ListItem>
  ));

  return (
    <div className={classes.container}>
      <Button variant="outlined" size="small" startIcon={<SortIcon />} fullWidth onClick={() => setOpen(!open)}>
        {t('menu.products.import.nav.show_by')} {pageSize}
      </Button>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper className={classes.lineSortContainer}>
            <List className={classes.lineSortList}>{rowsPerPageOptions}</List>
          </Paper>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default RowsPerPageSelector;
