/* eslint-disable */
import { useQuery } from '@tanstack/react-query';
import { requests } from 'services/requests';
import { ICompanyTariff } from '../types/plan.types';

const useCompanyTariff = () => {
  const { data, isLoading } = useQuery<ICompanyTariff, Error>(
    ['company_tariff'],
    () => requests.company.getTariff().then(res => res.data),
    {
      staleTime: Infinity,
      refetchOnMount: false,
    },
  );

  return {
    data,
    isLoading,
  };
};

export default useCompanyTariff;
