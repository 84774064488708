/* eslint-disable */
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Button from 'stories/Button/Button';

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper.MuiDialog-paper': {
      width: '100%',
      maxWidth: 768,
      boxShadow: 'none !important',
      borderRadius: 24,
      position: 'relative',
      zIndex: 11,
      padding: '48px 128px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 512,
  },
  desc: {
    textAlign: 'center',
  },
  button: {
    width: 240,
    height: 56,
  },
}));

const Downtime = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '48px',
          backgroundColor: 'yellow.500',
          padding: '15px 16px 15px 16px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <FontAwesomeIcon
          style={{
            color: '#fff',
            fontSize: '16px',
          }}
          icon={faTriangleExclamation}
        />
        <Typography
          sx={{
            color: 'white',
            fontFamily: 'Inter',
            ml: 1,
          }}
        >
          {t('alerts.new_downtime')}
        </Typography>
      </Box>
      <Dialog
        open={isOpen}
        className={classes.root}
        onClose={e => {
          handleClick();
        }}
      >
        <Box className={classes.content}>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            style={{
              color: theme.palette.yellow[500],
            }}
            size="3x"
          />
          <Typography
            sx={{
              fontSize: '36px',
              my: 4,
              fontFamily: 'Gilroy-Bold',
              lineHeight: '44.57px',
              color: theme.palette.black,
              textAlign: 'center',
            }}
          >
            {t('alerts.new_downtime_title')}
          </Typography>
          <Typography className={classes.desc}>
            <Trans
              i18nKey={'alerts.new_downtime_desc'}
              components={{
                br: <br />,
              }}
            />
          </Typography>
          <Typography
            className={classes.desc}
            sx={{
              my: 3,
            }}
          >
            <Trans
              i18nKey={'alerts.new_downtime_sub_desc'}
              components={{
                br: <br />,
              }}
            />
          </Typography>
          <Typography
            className={classes.desc}
            sx={{
              mb: 6,
            }}
          >
            <Trans
              i18nKey={'alerts.downtime_sorry_text'}
              components={{
                br: <br />,
              }}
            />
          </Typography>
          <Button className={classes.button} secondary onClick={handleClick}>
            {t('buttons.continue')}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default Downtime;
