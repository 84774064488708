/* eslint-disable */
import { companyActionTypes } from './companyActionTypes';

export const setCompanyId = id => ({
  type: companyActionTypes.SET_COMPANY_ID,
  payload: id,
});
export const setCompanyCurrency = id => ({
  type: companyActionTypes.SET_COMPANY_CURRENCY,
  payload: id,
});
export const setSupplyCurrency = data => ({
  type: companyActionTypes.SET_SUPPLY_CURRENCY,
  payload: data,
});
export const setRetailCurrency = data => ({
  type: companyActionTypes.SET_RETAIL_CURRENCY,
  payload: data,
});

export const setCompanyTimezone = data => ({
  type: companyActionTypes.SET_COMPANY_TIMEZONE,
  payload: data,
});

export const removeCompanyId = id => ({
  type: companyActionTypes.REMOVE_COMPANY_ID,
  payload: id,
});

export const setUserId = id => ({
  type: companyActionTypes.SET_USER_ID,
  payload: id,
});

export const setUserShops = shop_ids => ({
  type: companyActionTypes.SET_USER_SHOPS,
  payload: shop_ids,
});

export const removeUserId = id => ({
  type: companyActionTypes.REMOVE_USER_ID,
  payload: id,
});

export const setCompanyName = name => ({
  type: companyActionTypes.SET_COMPANY_NAME,
  payload: name,
});

export const setUserRegionData = data => ({
  type: companyActionTypes.SET_USER_REGION,
  payload: data,
});

export const setUserPhone = number => ({
  type: companyActionTypes.SET_USER_PHONE,
  payload: number,
});

export const setCurrentShopId = id => ({
  type: companyActionTypes.SET_CURRENT_SHOP_ID,
  payload: id,
});
export const setCurrentCashboxId = id => ({
  type: companyActionTypes.SET_CURRENT_CASHBOX_ID,
  payload: id,
});
export const setCurrentShopName = name => ({
  type: companyActionTypes.SET_CURRENT_SHOP_NAME,
  payload: name,
});
export const setCurrentCashboxName = name => ({
  type: companyActionTypes.SET_CURRENT_CASHBOX_NAME,
  payload: name,
});
export const setSettingsCatalog = data => ({
  type: companyActionTypes.SET_SETTINGS_CATALOG,
  payload: data,
});

export const setReportShopCurrency = data => ({
  type: companyActionTypes.SET_REPORT_SHOP_CURRENCY,
  payload: data,
});

export const setReportClientCurrency = data => ({
  type: companyActionTypes.SET_REPORT_CLIENT_CURRENCY,
  payload: data,
});
export const setReportSellerCurrency = data => ({
  type: companyActionTypes.SET_REPORT_SELLER_CURRENCY,
  payload: data,
});
export const setReportProductCurrency = data => ({
  type: companyActionTypes.SET_REPORT_PRODUCT_CURRENCY,
  payload: data,
});
export const setReportFinanceCurrency = data => ({
  type: companyActionTypes.SET_REPORT_FINANCE_CURRENCY,
  payload: data,
});
export const setReportProductPerformanceCurrency = data => ({
  type: companyActionTypes.SET_REPORT_PRODUCT_PERFORMANCE_CURRENCY,
  payload: data,
});
export const setReportClientPurchaseCurrency = data => ({
  type: companyActionTypes.SET_REPORT_CLIENT_PURCHASE_CURRENCY,
  payload: data,
});
export const setReportProductImportCurrency = data => ({
  type: companyActionTypes.SET_REPORT_PRODUCT_IMPORT_CURRENCY,
  payload: data,
});
export const setReportProductSupplierCurrency = data => ({
  type: companyActionTypes.SET_REPORT_PRODUCT_SUPPLIER_CURRENCY,
  payload: data,
});
export const setWebkassaLogin = data => ({
  type: companyActionTypes.SET_WEBKASSA_LOGIN,
  payload: data,
});
