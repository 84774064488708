/* eslint-disable */
import { useFlag } from '@unleash/proxy-client-react';

function useFeatureFlagRoutes() {
  const featureFlags = [
    { flag: 'stockscan-http', route: 'stockscan-http', slug: 'stockscan-http' },
    { flag: 'variation_id', route: 'variation_id', slug: 'variation_id' },
    { flag: 'scanitem-ws', route: 'scanitem-ws', slug: 'scanitem-ws' },
    {
      flag: 'transfer-report',
      route: 'transfer-report',
      slug: 'transfer-report',
    },
    {
      flag: 'wholesale-report',
      route: 'wholesale-report',
      slug: 'wholesale-report',
    },
    {
      flag: 'order-search-by-attr',
      route: 'order-search-by-attr',
      slug: 'order-search-by-attr',
    },
    { flag: 'sms-debt', route: 'sms-debt', slug: 'sms-debt' },
    {
      flag: 'autosegmentation',
      route: 'autosegmentation',
      slug: 'autosegmentation',
    },
    { flag: 'kaspi-pay', route: 'kaspiPay', slug: 'kaspiPay' },
    { flag: 'rounding', route: 'rounding', slug: 'rounding' },
    { flag: 'wcl', route: 'wcl', slug: 'wcl' },
    { flag: 'flowsell', route: 'flowsell', slug: 'flowsell' },
    { flag: 'add-shop', route: 'add-shop', slug: 'add-shop' },
    {
      flag: 'new-sale-new-flow',
      route: 'new-sale-new-flow',
      slug: 'new-sale-new-flow',
    },
    // Модуль "Каталог". Перевод на HTTP
    {
      flag: 'http-response-channel-new-sale',
      route: 'http-response-channel-new-sale',
      slug: 'http-response-channel-new-sale',
    },
    // Модуль "Инвентаризация". Перевод на HTTP
    {
      flag: 'inventory-http',
      route: 'inventory-http',
      slug: 'inventory-http',
    },
    // Модуль "Заказы". Перевод на HTTP
    {
      flag: 'supplier-order-http',
      route: 'supplier-order-http',
      slug: 'supplier-order-http',
    },
    // Модуль "Трансферы". Перевод на HTTP
    {
      flag: 'transfer-http',
      route: 'transfer-http',
      slug: 'transfer-http',
    },
    // Модуль "Все клиенты" и "Долги клиентов". Перевод на HTTP
    {
      flag: 'import-http',
      route: 'import-http',
      slug: 'import-http',
    },
    {
      flag: 'epos-eps',
      route: 'epos-eps',
      slug: 'epos-eps',
    },
    {
      flag: 'downtime',
      route: 'downtime',
      slug: 'downtime',
    },
    {
      flag: 'check-order-integration',
      route: 'check-order-integration',
      slug: 'check-order-integration',
    },
    {
      flag: 'order-precheck',
      route: 'order-precheck',
      slug: 'order-precheck',
    },
    {
      flag: 'webkassa-max-amount',
      route: 'webkassa-max-amount',
      slug: 'webkassa-max-amount',
    },
    {
      flag: 'pricetag-a4',
      route: 'pricetag-a4',
      slug: 'pricetag-a4',
    },
    {
      flag: 'payment-types-from-cashbox',
      route: 'payment-types-from-cashbox',
      slug: 'payment-types-from-cashbox',
    },
    {
      flag: 'dual-screen',
      route: 'dual-screen',
      slug: 'dual-screen',
    },
    {
      flag: 'sharedworker',
      route: 'sharedworker',
      slug: 'sharedworker',
    },
    {
      flag: 'return-exchange-date-filters',
      route: 'return-exchange-date-filters',
      slug: 'return-exchange-date-filters',
    },
    {
      flag: 'ingenico',
      route: 'ingenico',
      slug: 'ingenico',
    },
    {
      flag: 'openreplay',
      route: 'openreplay',
      slug: 'openreplay',
    },
    {
      flag: 'websocket-makebillz-top',
      route: 'websocket-makebillz-top',
      slug: 'websocket-makebillz-top',
    },
    {
      flag: 'billz-partners',
      route: 'billz-partners',
      slug: 'billz-partners',
    },
    {
      flag: 'inventory-results-report',
      route: 'inventory-results-report',
      slug: 'inventory-results-report',
    },
    {
      flag: 'billz-http-response-channel',
      route: 'billz-http-response-channel',
      slug: 'billz-http-response-channel',
    },
    {
      flag: 'qr-pricetag',
      route: 'qr-pricetag',
      slug: 'qr-pricetag',
    },
    {
      flag: 'orders-es',
      route: 'orders-es',
      slug: 'orders-es',
    },
    {
      flag: 'write-off-report',
      route: 'write-off-report',
      slug: 'write-off-report',
    },
    {
      flag: 'epos-storage',
      route: 'epos-storage',
      slug: 'epos-storage',
    },
    {
      flag: 'http-response-channel-cashbox',
      route: 'http-response-channel-cashbox',
      slug: 'http-response-channel-cashbox',
    },
    {
      flag: 'insurance',
      route: 'insurance',
      slug: 'insurance',
    },
    {
      flag: 'http-upsert-order-product',
      route: 'http-upsert-order-product',
      slug: 'http-upsert-order-product',
    },
    {
      flag: 'scales',
      route: 'scales',
      slug: 'scales',
    },
    {
      flag: 'debts-module',
      route: 'clients/debts/cashbox',
      slug: 'debts-module',
    },
    {
      flag: 'abc-report',
      route: 'abc-report',
      slug: 'abc-report',
    },
    {
      flag: 'report-transfer',
      route: 'report-transfer',
      slug: 'report-transfer',
    },
    {
      flag: 'new-sale-history-es',
      route: 'new-sale-history-es',
      slug: 'new-sale-history-es',
    },
    {
      flag: 'promo-conditions',
      route: 'promo-conditions',
      slug: 'promo-conditions',
    },
  ];

  const processFlag = ({ flag, route, slug }) => {
    const isEnabled = useFlag(flag);
    return !isEnabled ? [{ route, slug, option: '' }] : [];
  };

  const routes = featureFlags.flatMap(processFlag);

  return routes;
}

export default useFeatureFlagRoutes;
