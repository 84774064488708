/* eslint-disable */
export function calculateProductRounding(rounding, price) {
  const { digit, kind, subject } = rounding;
  if (subject === 'product') {
    switch (kind) {
      case 'nearest':
        return (Math.round(price / digit) * digit).toFixed(6) * 1;
      case 'biggest':
        return (Math.ceil(price / digit) * digit).toFixed(6) * 1;
      case 'smallest':
        return (Math.floor(price / digit) * digit).toFixed(6) * 1;
      default:
        return '';
    }
  } else {
    return Number(price).toFixed(6) * 1;
  }
}
