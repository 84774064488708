/* eslint-disable */
import { revaluationTableHeaderActionTypes } from '../actions/revaluationTableHeaderActions/revaluationTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      headerName: 'ID',
      field: 'id',
      hide: false,
      width: 135,
      minWidth: 70,
    },
    {
      field: 'name',
      hide: false,
      minWidth: 70,
    },
    { field: 'shop_name', hide: false, minWidth: 70 },
    {
      field: 'type',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'qty',
      hide: false,
      minWidth: 70,
    },

    {
      field: 'status',
      hide: false,
      resizable: false,
      minWidth: 70,
    },
    {
      field: 'created',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'finished',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'date_revaluation',
      hide: false,
      minWidth: 70,
    },
  ],
};

const revaluationTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case revaluationTableHeaderActionTypes.UPDATE_REVALUATION_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case revaluationTableHeaderActionTypes.RESET_REVALUATION_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default revaluationTableHeaderReducer;
