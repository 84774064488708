/* eslint-disable */
import { request } from '../axios';
import * as qs from 'qs';

const giftCard = {
  upload: data => request.post(`/v1/gift-card/upload-excel`, data),
  generate: data => request.post(`/v1/gift-card/generate`, data),
  update: data => request.put(`/v1/gift-card`, data),
  activate: ({ data, filter }) => request.post(`/v1/gift-card/activate-list${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  add: ({ data, filter }) => request.put(`/v1/order-gift-card/${data?.id}${qs.stringify(filter, { addQueryPrefix: true })}`, data?.body),
  delete: data => request.delete(`/v1/gift-card`, data),
  deleteImport: data => request.delete(`/v1/gift-card/imports`, data),
  addGiftCardPayment: () => request.put(`/v1/company-gift-card-payment`),
  getExcel: () => request.get(`/v2/excel/gift-card-properties-file`),
  getExcelReport: data => request.post(`/v1/gift-card/excel-import`, data),
  getExcelTable: data => request.post(`/v1/gift-card/search/excel`, data),
  getExcelImports: data => request.post(`/v1/gift-card/imports/search/excel`, data),
  getHistory: filter => request.get(`/v1/gift-card/history${qs.stringify(filter, { addQueryPrefix: true })}`),
  removeFromOrder: ({ data, filter }) =>
    request.post(`/v1/order-gift-card-remove/${data?.id}${qs.stringify(filter, { addQueryPrefix: true })}`, data?.body),
  getList: filter => request.get(`/v1/gift-card/search${qs.stringify(filter, { addQueryPrefix: true })}`),
  updateList: data => request.put(`/v1/gift-card/imports`, data),
  getImports: filter =>
    request.get(
      `v1/gift-card/imports/search${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
};

export default giftCard;
