/* eslint-disable */
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useMemo } from 'react';

export const useQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  const set = (name, value) => navigate({ search: qs.stringify({ ...queryParams, [name]: value }) });

  const clear = () => navigate({ search: qs.stringify({}) });

  const merge = values => navigate({ search: qs.stringify({ ...queryParams, ...values }) });

  const reset = name => {
    const newParams = { ...queryParams };
    if (newParams[name]) {
      delete newParams[name];
    }

    navigate({ search: qs.stringify({ ...newParams }) });
  };

  return { values: queryParams, set, reset, clear, merge };
};
