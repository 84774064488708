import { tableHeaderActionTypes } from '../../actions/tableHeaderActions/tableHeaderActionTypes';
import clientReportTableHeaders from './clientReportTableHeaders';
import productReportTableHeaders from './productReportTableHeaders';
import sellerReportTableHeaders from './sellerReportTableHeaders';
import shopReportTableHeaders from './shopReportTableHeaders';
import productEfficiencyTableHeaders from './productEfficiencyTableHeaders';
import clientPurchasesReportTableHeaders from './clientPurchasesTableHeaders';
import productImportTableHeaders from './productImportTableHeaders';
import shopTransactionTableHeaders from './shopTransactionTableHeaders';
import productSupplierTableHeaders from './productSupplierTableHeaders';
import productLeftoverTableHeaders from './productLeftoverTableHeaders';
import productReturnTableHeaders from './productReturnTableHeader';
import movementReportTableHeaders from './movementReportTableHeaders';
import sellerReportProductsTableHeaders from './sellerReportProductsTableHeaders';
import productInventoryTableHeaders from './productInventoryTableHeaders';
import productWriteOffTableHeaders from './productWriteOffTableHeaders';
import abcReportTableHeaders from './abcReportTableHeaders';
import productTransferTableHeaders from './productTransferTableHeaders';

export const initialState = {
  productReport: productReportTableHeaders,
  shopReport: shopReportTableHeaders,
  clientReport: clientReportTableHeaders,
  sellerReport: sellerReportTableHeaders,
  productEfficiencyReport: productEfficiencyTableHeaders,
  clientPurchasesReport: clientPurchasesReportTableHeaders,
  productImportReport: productImportTableHeaders,
  productSupplierReport: productSupplierTableHeaders,
  shopTransactionReport: shopTransactionTableHeaders,
  productLeftoverReport: productLeftoverTableHeaders,
  productReturnReport: productReturnTableHeaders,
  movementReport: movementReportTableHeaders,
  sellerProductsReport: sellerReportProductsTableHeaders,
  inventoryProductsReport: productInventoryTableHeaders,
  writeOffProductsReport: productWriteOffTableHeaders,
  abcReport: abcReportTableHeaders,
  productTransferReport: productTransferTableHeaders,
};

const reportTableHeaderReducer = (state = initialState, action) => {
  const { payload, section } = action;
  switch (action.type) {
    case `${tableHeaderActionTypes.UPDATE_TABLE_HEADER}_${section?.toUpperCase()}`:
      return {
        ...state,
        [section]: {
          ...state[section],
          initialState: initialState[section],
          columns: payload,
        },
      };
    case `${tableHeaderActionTypes.RESET_TABLE_HEADER}_${section?.toUpperCase()}`:
      return {
        ...state[section],
        [section]: {
          ...state[section],
          initialState: initialState[section],
          columns: initialState[section],
        },
      };
    case `${tableHeaderActionTypes.UPDATE_TABLE_HEADER_SETTINGS}_${section?.toUpperCase()}`:
      return {
        ...state,
        [section]: {
          ...state[section],
          settings: payload,
        },
      };
    default:
      return state;
  }
};

export default reportTableHeaderReducer;
