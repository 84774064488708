import { lazyLoad } from '../../../shared/utils/loadable';

export const PaymentSettingsPage = lazyLoad(
  () => import('./PaymentSettingsPage'),
  module => module.default,
);

export * from './rounding';
export * from './currency';
export * from './types';
