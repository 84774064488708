import * as qs from 'qs';
import { request } from '../axios';

const sms = {
  get: () => request.get(`v1/debt-distribution`),
  create: () => request.post(`v1/debt-distribution`, {}),
  messages: filter =>
    request.get(
      `v1/debt-distribution/messages${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  text: data => request.put(`v1/debt-distribution/text`, data),
  settings: data => request.put(`v1/debt-distribution/settings`, data),
  moderation: () => request.put(`v1/debt-distribution/moderation`, {}),
  start: () => request.put(`v1/debt-distribution/start`, {}),
  stop: () => request.put(`v1/debt-distribution/stop`, {}),
  customers: filter =>
    request.get(
      `v1/debt-distribution/customers${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  exclude: data => request.delete(`v1/debt-distribution/customers`, { data: data?.data }),
};

const debt = {
  getAll: filter => request.get(`v1/debt${qs.stringify(filter, { addQueryPrefix: true })}`),
  getDebtExcel: filter =>
    request.get(
      `/v1/debt-generate-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getStats: filter => request.get(`v1/debt-stats${qs.stringify(filter, { addQueryPrefix: true })}`),
  getTransactions: filter => request.get(`v1/debt-transactions${qs.stringify(filter, { addQueryPrefix: true })}`),
  get: id => request.get(`v1/debt/${id}`),
  getShiftInfo: id => request.get(`/v1/get-debt-shift-info/${id}`),
  create: ({ data, protocol }) => request.post(`v1/debt${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  pay: ({ id, data, protocol }) => request.post(`v1/debt/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  update: ({ id, data, protocol }) => request.put(`v1/debt/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  bulkRepay: data => request.post(`v1/bulk-repay-debts`, data.data),
  updateDraft: data => request.put(`v1/draft-debt/${data.id}`, data.data),
  getCustomerDebts: id => request.get(`v1/customer-debt/${id}`),
  getExistingDebts: id => request.get(`v1/order-draft-debt/${id}`),
  getRepaymentStats: filter => request.get(`v1/debt-payment-stats${qs.stringify(filter, { addQueryPrefix: true })}`),
};

const all = {
  ...debt,
  sms,
};

export default all;
