/* eslint-disable */
import axios from 'axios';
import i18n from 'i18n';

export const webkassaApi = axios.create({
  baseURL: import.meta.env.VITE_APP_WEBKASSA_URL,
  headers: {
    'Accept-Language': i18n.language,
    // Authorization: `Bearer ${import.meta.env.VITE_APP_WEBKASSA_TOKEN}`,
    'X-API-KEY': import.meta.env.VITE_APP_WEBKASSA_TOKEN,
  },
});

// Пробуем способ без регистрации

// export const createKaspiApiByIp = ({ ip, name }) => {
//   const baseURL = `http://${ip}:8080`
//   // const baseURL = 'https://j2z1q.wiremockapi.cloud'
//
//   const client = axios.create({
//     baseURL,
//   })
//   client.interceptors.request.use(
//     (config) => {
//       let newConfig = { ...config }
//       // const token = localStorage.getItem(ip)
//       // if (token) {
//       // const parsedToken = JSON.parse(token)
//       newConfig = {
//         ...newConfig,
//         headers: {
//           ...config.headers,
//         },
//       }
//       return newConfig
//     },
//     async (error) => {
//       return Promise.reject(error)
//     }
//   )

// client.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     if (error.response.status === 403) {
//       const token = localStorage.getItem(ip)
//       if (token) {
//         const parsedToken = JSON.parse(token)
//         const newToken = await client.get(`${baseURL}/v2/revoke`, {
//           params: {
//             name: `${name}-test`,
//             refreshToken: parsedToken.refreshToken,
//           },
//         })
//         if (newToken?.data) {
//           localStorage.setItem(ip, JSON.stringify(newToken.data))
//           client.interceptors.request.use(
//             (config) => {
//               config.headers['accesstoken'] = `${newToken.data?.accessToken}`
//               return config
//             },
//             (error) => Promise.reject(error)
//           )
//           return client(error.config)
//         }
//       }
//       return Promise.reject(error)
//     }
//     return Promise.reject(error)
//   }
// )

//   return client
// }
