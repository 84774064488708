/* eslint-disable */
import { request } from '../axios';

const dashboard = {
  setDashboardSettings: data => request.post(`v1/dashboard-setting`, data),
  getdashboardSettings: () => request.get(`v1/dashboard-setting`),
  getYearlyStats: () => request.get(`v1/year-summary-report?start_date=2024-01-01&end_date=2025-01-01`),
};

export default dashboard;
