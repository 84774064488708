import { UserService } from '@/shared/services';
import { useMutation } from '@tanstack/react-query';
import { success } from 'shared/utils/toast';

export const useUserSecretMutation = () => {
  return useMutation({
    mutationFn: async (id: string) => (await UserService.getUserSecret(id).fetch()).data,
    onSuccess: data => {
      navigator.clipboard.writeText(data.secret).then(() => success('toast.success.copy_integration_key'));
    },
  });
};
