/* eslint-disable */
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

type PlanStatus = 'active' | 'limited' | 'blocked';

const storage = createJSONStorage<PlanStatus>(() => sessionStorage);
const planStatusAtom = atomWithStorage<PlanStatus>('active', 'active', storage);

planStatusAtom.debugLabel = 'planStatusAtom';

export { planStatusAtom };
