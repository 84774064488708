import { Pagination as MuiPagination, PaginationItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForward from 'shared/icons/ArrowForward';
import BackArrowIcon from 'shared/icons/BackArrowIcon';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 'fit-content',
    '& ul li button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
      width: 40,
      padding: 4,
      borderRadius: 16,
      border: `1px solid transparent`,
      outline: 'none',
      backgroundColor: 'transparent',
      color: theme.palette['gray'][600],
      fontSize: 16,
      lineHeight: '19px',
      fontFamily: theme['fontFamily'].inter,
      fontWeight: 600,
      cursor: 'pointer',
      '&[disabled]': {
        cursor: 'default',
      },
    },
    '& ul li button.Mui-selected': {
      backgroundColor: `transparent`,
      border: `1px solid #e5e9eb`,
      fontWeight: 600,
      color: theme.palette.primary[600],
    },
    '& ul li button:hover': {
      backgroundColor: theme.palette['gray'][100],
    },
    '& ul li button.Mui-selected:hover': {
      backgroundColor: 'transparent',
    },
    '& ul li button span ': {
      display: 'none',
    },
  },
  arrowBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginRight: 4,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '& svg': {
      fill: theme.palette['blue'][500],
    },
  },
}));

type CountDrawerProps = {
  count?: number;
  onChangePage: (page: number) => void;
  page?: number;
};

const CountDrawer = ({ count = 1, onChangePage, page = 1 }: CountDrawerProps) => {
  const classes = useStyles();
  return (
    <MuiPagination
      count={count}
      onChange={(_, page) => onChangePage(page)}
      renderItem={props =>
        ['previous', 'next'].includes(props.type) ? (
          <button {...props} type="button" className={classes.arrowBtn} id={props.type === 'previous' ? 'previousPageBtn' : 'nextPageBtn'}>
            {props.type === 'previous' ? <BackArrowIcon /> : <ArrowForward />}
          </button>
        ) : (
          <PaginationItem id={`pageCount-${page}`} {...props} />
        )
      }
      page={page}
      siblingCount={1}
      boundaryCount={1}
      className={classes.root}
    />
  );
};

export default CountDrawer;
