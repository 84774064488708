import * as qs from 'qs';
import { request } from '../axios';

const management = {
  getRecommendations: () => request.get(`v1/company-recommendation`),
  updateRecommendations: data => request.put(`v1/company-recommendation`, data),
  getPreference: () => request.get(`v1/client-preference`),
  createPreference: data => request.post(`v1/client-preference`, data),
  deletePreference: id => request.delete(`v1/client-preference/${id}`),
  getTopCharacteristics: id => request.get(`v1/top-characteristics/${id}`),
  getTopProducts: id => request.get(`v1/top-products/${id}`),
  getProductsByCategory: filter => request.get(`v1/customer-product${qs.stringify(filter, { addQueryPrefix: true })}`),
};

const customer = {
  getAll: filter => request.get(`v1/customers-list${qs.stringify(filter, { addQueryPrefix: true })}`),
  getStatistics: filter => request.get(`v1/customers-stats${qs.stringify(filter, { addQueryPrefix: true })}`),
  getSingle: id => request.get(`v1/customer/${id}`),
  create: ({ data, protocol }) => request.post(`v1/customer${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  update: ({ id, data, protocol }) => request.put(`v1/customer/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  delete: ({ id, protocol }) => request.delete(`v1/customer/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  getSocialNetworks: () => request.get(`v1/customer-social-network`),
  createSocialNetworks: data => request.post(`v1/customer-social-network`, data),
  deleteSocialNetworks: id => request.post(`v1/customer-social-network/${id}`),
  getAllGroups: filter => request.get(`v1/customer-group${qs.stringify(filter, { addQueryPrefix: true })}`),
  getAllClauses: () => request.get(`/v1/segment-clause-list`),
  setBalance: ({ id, data, protocol }) =>
    request.patch(`v1/customer/set-balance/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  createGroup: data => request.post(`v1/customer-group`, data),
  updateGroup: data => request.put(`v1/customer-group/${data.id}`, data.data),
  deleteGroup: id => request.delete(`v1/customer-group/${id}`),
  getGroup: id => request.get(`v1/customer-group/${id}`),
  getAllTags: () => request.get(`v1/customer-tag`),
  getLogs: (id, filter) => request.get(`v1/customer-log/${id}${qs.stringify(filter, { addQueryPrefix: true })}`),
  createCard: data => request.post(`v1/customer-card/${data.id}`, data.data),
  deleteCard: data => request.delete(`v1/customer-card/${data.customer_id}/${data.card_id}`),
  updateCardStatus: data => request.patch(`v1/customer-card/${data.customer_id}/${data.card_id}/status`, data.data),
  getExcelReport: data => request.post(`v1/customer-excel`, data),
  addNote: ({ data, protocol }) => request.post(`v1/customer-notes${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  deleteNote: ({ id, protocol }) =>
    request.delete(`v1/customer-notes${qs.stringify(protocol, { addQueryPrefix: true })}`, { data: { note_id: id } }), // Переведен на HTTP
  getCustomerNotes: id => request.get(`v1/customer-notes/${id}`),
  getExcelReportEs: data => request.post(`v1/customer-es-excel`, data),
  getDiscountPromoOptions: () => request.get(`v2/promo-options`),
  getExcelProperties: () => request.get(`v1/customer/properties`),
  getValidationErrors: (id, filter) =>
    request.get(
      `v1/customer/import-errors/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getExcelFile: filter =>
    request.get(
      `v1/customer/excel-properties-file${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  validateExcelFile: data => request.post(`v1/customer/excel-validate`, data),
  getProgress: id => request.get(`v1/customer-progress/${id}`),
  importPreview: (id, filter) =>
    request.get(
      `v1/customer/import/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  import: data => request.post(`v1/customer/import`, data),
  getMassEditTemplate: () => request.get(`v1/customer/bulk/excel-properties`),
  getMassGroupEditTemplate: type => request.get(`v1/customer-group/update/template?language=ru&type=${type}`),
  getCustomersByExcel: data => request.post(`v1/customer/get-from-excel`, data),
  getCustomersByNumber: data => request.post(`v1/customer/get-selected`, data),
  getMassEditProperties: language => request.get(`v1/customer/bulk/operations_properties?language=${language}`),
  updateClientPropertiesFromExcel: data => request.post(`v1/customer/mass/update-from-file`, data),
  updateClientProperties: data => request.post(`v1/customer/mass/update`, data),
  massUpdateGroups: data => request.post(`v1/customer-group/mass/update`, data),
  massUpdateGroupsFromFile: data => request.post(`v1/customer-group/mass/update-from-file`, data),
  massDelete: ({ data, protocol }) =>
    request.delete(`v1/customer/mass/delete${qs.stringify(protocol, { addQueryPrefix: true })}`, { data }), // Переведен на HTTP
  ...management,
};

export default customer;
