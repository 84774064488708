/* eslint-disable */
const DownloadIcon = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#4993DD" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.75 8C15.75 3.71875 12.2812 0.25 8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8ZM11.25 7.125C11.5312 6.8125 12.0312 6.8125 12.3125 7.09375L12.6562 7.4375C12.9688 7.71875 12.9688 8.21875 12.6562 8.5L8.5 12.6562C8.21875 12.9375 7.75 12.9375 7.46875 12.6562L3.3125 8.5C3.03125 8.21875 3.03125 7.75 3.3125 7.4375L3.65625 7.09375C3.9375 6.8125 4.4375 6.8125 4.71875 7.125L7 9.46875V3.75C7 3.34375 7.3125 3 7.75 3H8.25C8.65625 3 9 3.34375 9 3.75V9.46875L11.25 7.125Z"
      fill="inherit"
    />
  </svg>
);

export default DownloadIcon;
