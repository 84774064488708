/* eslint-disable */
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import CatalogLayout from 'layouts/CatalogLayout';
import {
  EmployeeListPage,
  CreateEmployeePage,
  ViewEmployeePage,
  UpdateEmployeePage,
  OperationsPage,
  ShopOperationsPage,
  RoleOperationsPage,
  RoleListPage,
  BulkOperationsPage,
  RoleBulkOperationsPage,
  PermissionsPage,
  SectionsPage,
  CreateRolePage,
} from 'pages';

const managementRoutes = {
  path: 'management',
  children: [
    { path: '', element: <Navigate to="/management/employees" /> },
    { path: 'create/permissions', element: <PermissionsPage /> },
    { path: 'create/sections', element: <SectionsPage /> },
    {
      path: 'employees',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <EmployeeListPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [{ path: '', element: <CreateEmployeePage /> }],
        },
        {
          path: 'update',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <UpdateEmployeePage /> }],
        },
        {
          path: 'view',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <ViewEmployeePage /> }],
        },
        {
          path: 'settings',
          element: <CatalogLayout />,
          children: [
            { path: '', element: <OperationsPage /> },
            { path: 'shops', element: <ShopOperationsPage /> },
            {
              path: 'roles',
              element: <RoleOperationsPage />,
              children: [{ path: '', element: <RoleOperationsPage /> }],
            },
          ],
        },
      ],
    },
    {
      path: 'roles',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <RoleListPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <CreateRolePage /> }],
        },
        {
          path: 'settings',
          element: <CatalogLayout />,
          children: [
            { path: '', element: <BulkOperationsPage /> },
            {
              path: 'roles',
              element: <RoleBulkOperationsPage />,
            },
          ],
        },
      ],
    },
  ],
};

export default managementRoutes;
