/* eslint-disable */
const BigTickIcon = props => (
  <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M35.4375 17.5C35.4375 7.9375 27.5625 0.0625 18 0.0625C8.36719 0.0625 0.5625 7.9375 0.5625 17.5C0.5625 27.1328 8.36719 34.9375 18 34.9375C27.5625 34.9375 35.4375 27.1328 35.4375 17.5ZM15.9609 26.7812C15.5391 27.2031 14.7656 27.2031 14.3438 26.7812L7.03125 19.4688C6.60938 19.0469 6.60938 18.2734 7.03125 17.8516L8.64844 16.3047C9.07031 15.8125 9.77344 15.8125 10.1953 16.3047L15.1875 21.2266L25.7344 10.6797C26.1562 10.1875 26.8594 10.1875 27.2812 10.6797L28.8984 12.2266C29.3203 12.6484 29.3203 13.4219 28.8984 13.8438L15.9609 26.7812Z"
      fill="#6FCF97"
    />
  </svg>
);
export default BigTickIcon;
