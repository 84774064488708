/* eslint-disable */
const DragDropIcon = props => (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="#4993DD" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 6H1C0.4375 6 0 6.46875 0 7V9C0 9.5625 0.4375 10 1 10H3C3.53125 10 4 9.5625 4 9V7C4 6.46875 3.53125 6 3 6ZM8 6H6C5.4375 6 5 6.46875 5 7V9C5 9.5625 5.4375 10 6 10H8C8.53125 10 9 9.5625 9 9V7C9 6.46875 8.53125 6 8 6ZM13 6H11C10.4375 6 10 6.46875 10 7V9C10 9.5625 10.4375 10 11 10H13C13.5312 10 14 9.5625 14 9V7C14 6.46875 13.5312 6 13 6ZM3 0H1C0.4375 0 0 0.46875 0 1V3C0 3.5625 0.4375 4 1 4H3C3.53125 4 4 3.5625 4 3V1C4 0.46875 3.53125 0 3 0ZM8 0H6C5.4375 0 5 0.46875 5 1V3C5 3.5625 5.4375 4 6 4H8C8.53125 4 9 3.5625 9 3V1C9 0.46875 8.53125 0 8 0ZM13 0H11C10.4375 0 10 0.46875 10 1V3C10 3.5625 10.4375 4 11 4H13C13.5312 4 14 3.5625 14 3V1C14 0.46875 13.5312 0 13 0Z"
      fill="inherit"
    />
  </svg>
);

export default DragDropIcon;
