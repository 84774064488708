/* eslint-disable */
import { OrderPresentationPage } from 'pages';

const ordersRoutes = {
  path: 'presentation',

  children: [
    {
      path: 'order',
      element: <OrderPresentationPage />,
    },
  ],
};
export default ordersRoutes;
