/* eslint-disable */
import useNavbarStatuses from 'pages/settings/plan/api/useNavbarStatuses';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'stories/Button/Button';

const PlanInfoButton = ({ classes, closeDrawer }) => {
  const { planName, planStatus } = useNavbarStatuses();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const text = useMemo(() => {
    return (
      <Typography variant="smallInfo" color={planStatus.color}>
        {planStatus.text}
      </Typography>
    );
  }, [planStatus.color, planStatus.text]);

  const handleClick = () => {
    navigate('/settings/plan');
    closeDrawer();
  };

  return (
    <Button
      contentClass={classes.actionBtnContent}
      className={classes.actionBtn}
      secondary
      fullWidth
      adornmentEnd={<FontAwesomeIcon icon={faArrowRight} />}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: '2px',
        }}
      >
        <Typography>{`${t('navbar.plan')} "${planName || '-'}"`}</Typography>
        {text}
      </Box>
    </Button>
  );
};

export default PlanInfoButton;
