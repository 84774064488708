/* eslint-disable */
import { useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-day-picker/lib/style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './styles/main.css';
import { ToastContainer } from 'react-toastify';
import { WebSocketProviderNew } from 'services/websocket/WebsocketManagerNew';
import Providers from './Providers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ru';
import mixpanel from 'mixpanel-browser';
import InvalidUserDialog from 'components/InvalidUserDialog';
import Routes from './Routes';
import useNotifyOnNetworkChange from './shared/hooks/useNotifyOnNetworkChange';
import useDisableWheelOnInput from './shared/hooks/useDisableWheelOnInput';
import useDisableEnterOnInput from './shared/hooks/useDisableEnterOnInput';
import i18n from './i18n';
import GlobalStyles from './components/GlobalStyles';
import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react';
import { MetrikaCounter } from 'react-metrika';

if (import.meta.env.VITE_APP_ENVIRONMENT === 'prod') {
  mixpanel.init(import.meta.env.VITE_APP_MIXPANEL_PROJECT_TOKEN);
}

const unleashClient = new UnleashClient({
  context: {
    company_id: JSON.parse(JSON.parse(localStorage.getItem('persist:root') || null)?.company || null)?.company_id,
  },
  url: import.meta.env.VITE_APP_UNLEASH_URL,
  clientKey: import.meta.env.VITE_APP_UNLEASH_TOKEN,
  refreshInterval: 15,
  appName: 'billz-admin',
  environment: import.meta.env.VITE_APP_ENVIRONMENT,
});

function App() {
  useNotifyOnNetworkChange();
  useDisableWheelOnInput();
  useDisableEnterOnInput();
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(localeData);
  dayjs.extend(customParseFormat);
  dayjs.extend(updateLocale);
  dayjs.extend(timezone);

  useEffect(() => {
    dayjs.locale(i18n.language);
    dayjs.updateLocale(i18n.language, { weekStart: 1 });
  }, [i18n.language]);

  useEffect(() => {
    window.chaport.q('startSession');
  }, []);

  return (
    <FlagProvider unleashClient={unleashClient}>
      <Providers>
        <GlobalStyles />
        <WebSocketProviderNew>
          <Routes />
        </WebSocketProviderNew>
        {import.meta.env.VITE_APP_ENVIRONMENT === 'prod' && (
          <MetrikaCounter
            id={Number(import.meta.env.VITE_APP_YM_TAG_ID)}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
            }}
          />
        )}
        <ToastContainer position="top-center" autoClose={3000} hideProgressBar closeOnClick pauseOnHover draggable closeButton={false} />
        <InvalidUserDialog />
      </Providers>
    </FlagProvider>
  );
}

export default App;
