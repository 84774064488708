import { useMutation, useQueryClient } from '@tanstack/react-query';
import { error, success } from 'shared/utils/toast';
import { UserServiceNS, UserService } from '@/shared/services';

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (user: UserServiceNS.UserDTO) => (await UserService.putEditUser(user).fetch()).data,
    onSuccess: () => {
      success('integrations.success_edit');
      queryClient.invalidateQueries({
        queryKey: ['USERS', 'LIST'],
      });
    },
    onError: () => {
      error('integrations.error.edit');
    },
  });
};
