/* eslint-disable */
import { NotFoundPage } from '../../pages';
import getDisallowedRoutes from './getDisallowedRoutes';

function recursiveCheckChildren(route, parent, forbiddenRoutes) {
  let parentPath = parent || '';
  if (route?.children?.length) {
    parentPath = parentPath ? `${parentPath}/${route.path}` : route.path;
    const disallowedRoute = forbiddenRoutes.find(el => el.route === parentPath);
    if (disallowedRoute && (disallowedRoute?.options === '' || disallowedRoute?.option === '')) {
      return {
        path: route.path,
        disallowed: true,
        children: route.children?.map(child => recursiveCheckChildren(child, parentPath, forbiddenRoutes)),
        element: <NotFoundPage />,
      };
    }
    return {
      ...route,
      children: route.children?.map(child => recursiveCheckChildren(child, parentPath, forbiddenRoutes)),
    };
  }
  const routePath = parentPath ? (route.path ? `${parentPath}/${route.path}` : parentPath) : route.path;
  const disallowedRoute = forbiddenRoutes.find(el => el.route === routePath);
  if (disallowedRoute && disallowedRoute?.options === '') {
    return {
      path: route.path,
      disallowed: true,
      element: <NotFoundPage />,
    };
  }
  return route;
}

export default function filterRoutesBasedOnRoles(rolePermissions, routes, forbiddenRoutesFromFeatureFlag) {
  const forbiddenRoutesFromPermission = getDisallowedRoutes(rolePermissions);

  const forbiddenRoutes = [...forbiddenRoutesFromFeatureFlag, ...forbiddenRoutesFromPermission];
  const validRoutes = routes.map(route => recursiveCheckChildren(route, '', forbiddenRoutes));

  return validRoutes;
}
