/* eslint-disable */
import { authRequest } from 'services/axios';

const token = localStorage.getItem('access_token');

const auth = {
  register: data => authRequest.post(`v1/auth/user/register`, data),
  login: data => authRequest.post(`v2/auth/standard/login`, data),
  passcodeGenerate: data => authRequest.post(`v2/auth/passcode/generate`, data),
  passcodeConfirm: data => authRequest.post(`v2/auth/passcode/confirm`, data),
  passwordUpdate: data =>
    authRequest.put(`v1/auth/user/password`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  checkSubdomain: () => authRequest.get(`v1/auth/check-subdomain`),
  forgotPasswordGenerate: data => authRequest.post(`v2/auth/forgot_password/generate`, data),
  forgotPasswordConfirm: data => authRequest.post(`v2/auth/forgot_password/confirm-passcode`, data),
  forgotPasswordChange: data => authRequest.post(`v2/auth/forgot_password/change-password`, data),
  forgotSubdomen: data => authRequest.post(`v2/auth/forgot-subdomen`, data),
};

export default auth;
