/* eslint-disable */
const DocumentIcon = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {props?.gray ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0431 21.6205C22.2348 21.6183 22.4435 21.616 22.6331 21.616C22.8369 21.616 23 21.776 23 21.976V28.408C23 30.392 21.3611 32 19.339 32H12.8486C10.7286 32 9 30.312 9 28.232V19.608C9 17.624 10.6471 16 12.6773 16H17.0315C17.2434 16 17.4065 16.168 17.4065 16.368V18.944C17.4065 20.408 18.6377 21.608 20.1299 21.616C20.4784 21.616 20.7857 21.6185 21.0546 21.6207C21.2638 21.6225 21.4497 21.624 21.6139 21.624C21.7299 21.624 21.8804 21.6223 22.0431 21.6205ZM13.4683 27.512H17.9039C18.2382 27.512 18.5154 27.24 18.5154 26.912C18.5154 26.584 18.2382 26.32 17.9039 26.32H13.4683C13.134 26.32 12.8567 26.584 12.8567 26.912C12.8567 27.24 13.134 27.512 13.4683 27.512ZM16.2242 22.32H13.4683C13.134 22.32 12.8567 22.592 12.8567 22.92C12.8567 23.248 13.134 23.512 13.4683 23.512H16.2242C16.5585 23.512 16.8358 23.248 16.8358 22.92C16.8358 22.592 16.5585 22.32 16.2242 22.32Z"
          fill="currentColor"
        />
        <path
          d="M22.268 20.4528C21.5977 20.4552 20.8076 20.4528 20.2393 20.4472C19.3375 20.4472 18.5947 19.7184 18.5947 18.8336V16.7248C18.5947 16.38 19.0211 16.2088 19.2649 16.4576C19.7062 16.9078 20.3127 17.5267 20.9164 18.1427C21.5184 18.7571 22.1177 19.3686 22.5476 19.8072C22.7857 20.0496 22.6112 20.452 22.268 20.4528Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0431 21.6205C22.2348 21.6183 22.4435 21.616 22.6331 21.616C22.8369 21.616 23 21.776 23 21.976V28.408C23 30.392 21.3611 32 19.339 32H12.8486C10.7286 32 9 30.312 9 28.232V19.608C9 17.624 10.6471 16 12.6773 16H17.0315C17.2434 16 17.4065 16.168 17.4065 16.368V18.944C17.4065 20.408 18.6377 21.608 20.1299 21.616C20.4784 21.616 20.7857 21.6185 21.0546 21.6207C21.2638 21.6225 21.4497 21.624 21.6139 21.624C21.7299 21.624 21.8804 21.6223 22.0431 21.6205ZM13.4683 27.512H17.9039C18.2382 27.512 18.5154 27.24 18.5154 26.912C18.5154 26.584 18.2382 26.32 17.9039 26.32H13.4683C13.134 26.32 12.8567 26.584 12.8567 26.912C12.8567 27.24 13.134 27.512 13.4683 27.512ZM16.2242 22.32H13.4683C13.134 22.32 12.8567 22.592 12.8567 22.92C12.8567 23.248 13.134 23.512 13.4683 23.512H16.2242C16.5585 23.512 16.8358 23.248 16.8358 22.92C16.8358 22.592 16.5585 22.32 16.2242 22.32Z"
          fill="#1F78FF"
        />
        <path
          d="M22.268 20.4528C21.5977 20.4552 20.8076 20.4528 20.2393 20.4472C19.3375 20.4472 18.5947 19.7184 18.5947 18.8336V16.7248C18.5947 16.38 19.0211 16.2088 19.2649 16.4576C19.7062 16.9078 20.3127 17.5267 20.9164 18.1427C21.5184 18.7571 22.1177 19.3686 22.5476 19.8072C22.7857 20.0496 22.6112 20.452 22.268 20.4528Z"
          fill="#1F78FF"
        />
      </>
    )}
  </svg>
);

export default DocumentIcon;
