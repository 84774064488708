import React from 'react';
import { Link as LinkBase } from 'react-router-dom';
import { useStyles } from './Link.styles';

type LinkProps = React.ComponentProps<typeof LinkBase>;

const Link = ({ to, children, ...props }: LinkProps) => {
  const classes = useStyles();

  return (
    <LinkBase to={to} className={classes.root} {...props}>
      {children}
    </LinkBase>
  );
};

export default Link;
