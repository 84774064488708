/* eslint-disable */
const CartIcon = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {props?.gray ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5413 19.142C23.0289 19.142 23.3486 19.3134 23.6684 19.689C23.9881 20.0645 24.0441 20.6034 23.9721 21.0924L23.2127 26.448C23.0689 27.4775 22.2055 28.236 21.1903 28.236H12.4691C11.4059 28.236 10.5266 27.404 10.4387 26.3264L9.70327 17.4267L8.49621 17.2145C8.17646 17.1573 7.95263 16.8389 8.00859 16.5123C8.06454 16.1784 8.3763 15.9572 8.70404 16.007L10.6106 16.3001C10.8823 16.3499 11.0822 16.5777 11.1062 16.8552L11.2581 18.684C11.282 18.9461 11.4899 19.142 11.7457 19.142H22.5413ZM17.697 23.3865H19.9113C20.247 23.3865 20.5108 23.1089 20.5108 22.7742C20.5108 22.4313 20.247 22.1619 19.9113 22.1619H17.697C17.3613 22.1619 17.0975 22.4313 17.0975 22.7742C17.0975 23.1089 17.3613 23.3865 17.697 23.3865Z"
          fill="currentColor"
        />
        <path
          d="M12.341 29.5264C11.6696 29.5264 11.126 30.0815 11.126 30.7673C11.126 31.4449 11.6696 32.0001 12.341 32.0001C13.0045 32.0001 13.5481 31.4449 13.5481 30.7673C13.5481 30.0815 13.0045 29.5264 12.341 29.5264Z"
          fill="currentColor"
        />
        <path
          d="M21.334 29.5264C20.6625 29.5264 20.1189 30.0815 20.1189 30.7673C20.1189 31.4449 20.6625 32.0001 21.334 32.0001C21.9975 32.0001 22.5411 31.4449 22.5411 30.7673C22.5411 30.0815 21.9975 29.5264 21.334 29.5264Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5413 19.142C23.0289 19.142 23.3486 19.3134 23.6684 19.689C23.9881 20.0645 24.0441 20.6034 23.9721 21.0924L23.2127 26.448C23.0689 27.4775 22.2055 28.236 21.1903 28.236H12.4691C11.4059 28.236 10.5266 27.404 10.4387 26.3264L9.70327 17.4267L8.49621 17.2145C8.17646 17.1573 7.95263 16.8389 8.00859 16.5123C8.06454 16.1784 8.3763 15.9572 8.70404 16.007L10.6106 16.3001C10.8823 16.3499 11.0822 16.5777 11.1062 16.8552L11.2581 18.684C11.282 18.9461 11.4899 19.142 11.7457 19.142H22.5413ZM17.697 23.3865H19.9113C20.247 23.3865 20.5108 23.1089 20.5108 22.7742C20.5108 22.4313 20.247 22.1619 19.9113 22.1619H17.697C17.3613 22.1619 17.0975 22.4313 17.0975 22.7742C17.0975 23.1089 17.3613 23.3865 17.697 23.3865Z"
          fill="#1F78FF"
        />
        <path
          d="M12.341 29.5264C11.6696 29.5264 11.126 30.0815 11.126 30.7673C11.126 31.4449 11.6696 32.0001 12.341 32.0001C13.0045 32.0001 13.5481 31.4449 13.5481 30.7673C13.5481 30.0815 13.0045 29.5264 12.341 29.5264Z"
          fill="#1F78FF"
        />
        <path
          d="M21.334 29.5264C20.6625 29.5264 20.1189 30.0815 20.1189 30.7673C20.1189 31.4449 20.6625 32.0001 21.334 32.0001C21.9975 32.0001 22.5411 31.4449 22.5411 30.7673C22.5411 30.0815 21.9975 29.5264 21.334 29.5264Z"
          fill="#1F78FF"
        />
      </>
    )}
  </svg>
);

export default CartIcon;
