import * as qs from 'qs';
import { request } from '../axios';

const product = {
  getAll: filter => request.get(`v1/product${qs.stringify(filter, { addQueryPrefix: true })}`),
  getAllSkus: filter => request.get(`v2/get-all-sku${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v1/product`, data),
  getSingle: id => request.get(`v1/product/${id}`),
  update: ({ id, data, protocol }) => request.put(`v2/product/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  getFilterAttribute: data => request.post(`v2/product-filter-values/${data.type}/${data.id}/`, data.data),
  delete: id => request.put(`v1/product/${id}`),
  createv2: ({ data, protocol }) => request.post(`v2/product${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  getStatistics: filter =>
    request.get(
      `v2/product-statistics${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getFilterValues: (filter, data) =>
    request.post(
      `v2/product-statistics${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
      data,
    ),
  newGetStatistics: filter =>
    request.get(
      `v2/product-search-stats${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getStatisticsByStatus: filter =>
    request.get(
      `v2/product-statistics-by-status${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getAllv2: filter =>
    request.get(
      `v2/product${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  newGetAllv2: filter =>
    request.get(
      `v2/product-search${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getAllWithFilters: filter => request.post(`v2/product-search-with-filters`, filter), // Работает только с HTTP
  getStatisticsWithFilters: filter => request.post(`v2/product-search-stats-with-filters`, filter), // Работает только с HTTP
  productGroup: filter => request.get(`v2/product-group${qs.stringify(filter, { addQueryPrefix: true })}`),
  getSingle2: id => request.get(`v2/product/${id}`),
  getMovement: (id, filter) =>
    request.get(
      `/v2/product-movement/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getProductsByExcel: data => request.post(`v2/products/bulk/upload_file`, data),
  getProductsBySKU: data => request.post(`v2/products/bulk/upload_products`, data),
  updateProductsSmallLeft: data => request.put(`v2/products/bulk/small_measurement_value`, data),
  updateProductsPrice: data => request.put(`v2/products/bulk/price`, data),
  updateProductsScaleSettings: data => request.post(`v2/product/bulk/update-scale-status`, data),
  updateProductsMarkingSettings: data => request.post(`v2/product/bulk/update-is-marked`, data),
  updateProductsPhoto: data => request.put(`v2/products/bulk/photo`, data),
  updateProductsProperties: data => request.put(`v2/products/bulk/fields`, data),
  updateProductsPropertiesFromExcel: data => request.put(`v2/products/bulk/fields-from-file`, data),
  getFileFields: () => request.get(`v2/products/bulk/operations_properties`),
  getExcel: () => request.get(`v2/products/bulk/properties-file`),
  archive: data => request.put('v2/products/bulk/archive', data),
  unarchive: data => request.put(`v2/products/bulk/unarchive`, data),
  selectAll: data => request.post(`v2/products/get-products-by-websocket`, data),
  searchProducts: filter => request.get(`v2/new-sale/products${qs.stringify(filter, { addQueryPrefix: true })}`),
  getProductSupplyPrices: (id, filter) =>
    request.get(
      `v2/product-supply-price/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getGs1Products: filter =>
    request.get(
      `v2/gs1-product${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  downloadJsonFile: data => request.post(`v2/product/json-file/scale`, data),
};

export default product;
