import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import { DashboardPage, WelcomePage, YearlyStatsPage } from 'pages';

const dashboardRoutes = {
  path: 'dashboard',
  element: <DashboardLayout />,
  children: [
    {
      path: '',
      element: <DashboardPage />,
    },
    {
      path: 'yearly-stats',
      element: <YearlyStatsPage />,
    },
    {
      path: 'welcome',
      element: <WelcomePage />,
    },
    { path: '*', element: <Navigate to="/" /> },
  ],
};

export default dashboardRoutes;
