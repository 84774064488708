/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const repricing = {
  getAll: filter => request.get(`v2/repricing${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v2/repricing`, data),
  getItems: filter =>
    request.get(
      `v2/repricing-products/${filter.id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  delete: id => request.delete(`v2/repricing/${id}`),
  finish: id => request.put(`v2/repricing/finish/${id}`),
  createFromFile: data => request.post(`v2/repricing-from-file`, data),
  getRepricingProgress: id => request.get(`v2/file-repricing-progress/${id}`),
  getFileRepricing: filter =>
    request.get(
      `v2/file-repricing/${filter.correlation_id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  cancelRepricing: id => request.put(`v2/repricing/cancel/${id}`),
  getDetails: id => request.get(`v2/repricing/${id}`),
  getExcel: () => request.get(`v2/excel/repricing-properties-file`),
  getProperties: () => request.get(`v2/excel/repricing-properties`),
  addItems: data => request.post(`v2/repricing/add-items`, data),
  removeItems: data => request.delete(`v2/repricing/remove-items`, data),
  update: data => request.put(`v2/repricing/${data.id}`, data),
  generateExcel: data => request.post(`v2/repricing-export/${data.id}`, data.body),
};

export default repricing;
