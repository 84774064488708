/* eslint-disable */
import { request } from '../axios';

const productDetail = {
  create: data => request.post(`v1/product-detail`, data),
  getSingle: id => request.get(`v1/product-detail/${id}`),
  update: data => request.put(`v1/product-detail/${data.id}`, data.data),
  updatePrices: data => request.patch(`v1/product-detail/update-prices`, data.data),
};

export default productDetail;
