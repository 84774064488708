/* eslint-disable */
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

export const useStyles = makeStyles(theme => ({
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    marginLeft: 32,
    marginBottom: 16,
    borderRadius: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.bluegray[200],
    },
  },
  selected: {
    backgroundColor: theme.palette.bluegray[200],
  },
}));

function NavItemMini({ item, handleClickNavItems, isActive }) {
  const classes = useStyles();
  const { isOpen } = useSelector(state => state.sidebarSettings);

  return (
    <div
      className={`${classes.itemIcon} ${isActive && classes.selected}`}
      onMouseOver={event => {
        if (item.children?.length > 0 && !isOpen) {
          event.preventDefault();
          handleClickNavItems(item);
        }
      }}
    >
      {item.icon}
    </div>
  );
}

export default NavItemMini;
