import { v4 } from 'uuid';

const useCrypto = (): { id: string } => {
  if (typeof crypto !== 'undefined' && crypto.randomUUID) {
    crypto.randomUUID();
  }

  return v4();
};

export default useCrypto;
