import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import CatalogLayout from 'layouts/CatalogLayout';
import {
  SmsMailingPage,
  SmsMailingCreatePage,
  SmsMailingPreviewPage,
  NicknamesPreviewPage,
  PromotionsListPage,
  PromotionCreatePage,
  ViewPromotionPage,
  PromotionEditPage,
  PromoCodesPage,
  PromoCodesOnboardingPage,
  GiftCardsPage,
  GiftCardsImportsPage,
  GiftCardCreatePage,
  GiftCardsTutorialPage,
} from 'pages';

const marketingRoutes = {
  path: 'marketing',
  children: [
    {
      path: 'promotions',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <PromotionsListPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [
            {
              path: ':id',
              element: <PromotionCreatePage />,
            },
          ],
        },
        {
          path: 'edit',
          element: <CatalogLayout />,
          children: [
            {
              path: ':id',
              element: <PromotionEditPage />,
            },
          ],
        },
        {
          path: 'view',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <ViewPromotionPage /> }],
        },
      ],
    },
    {
      path: 'promo-codes',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <PromoCodesPage /> }],
        },
        {
          path: 'tutorial',
          element: <DashboardLayout />,
          children: [
            {
              path: '',
              element: <PromoCodesOnboardingPage />,
            },
          ],
        },
      ],
    },
    {
      path: 'sms-mailing',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <SmsMailingPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [
            {
              path: '',
              element: <SmsMailingCreatePage />,
            },
            {
              path: ':id',
              element: <SmsMailingCreatePage />,
            },
          ],
        },
        {
          path: 'preview',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <SmsMailingPreviewPage /> }],
        },
        {
          path: 'nicknames',
          element: <DashboardLayout />,
          children: [{ path: '', element: <NicknamesPreviewPage /> }],
        },
      ],
    },
    {
      path: 'gift-cards',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <GiftCardsPage /> }],
        },
        {
          path: 'imports',
          element: <DashboardLayout />,
          children: [
            {
              path: '',
              element: <GiftCardsImportsPage />,
            },
          ],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [
            {
              path: '',
              element: <GiftCardCreatePage />,
            },
          ],
        },
        {
          path: 'tutorial',
          element: <DashboardLayout />,
          children: [
            {
              path: '',
              element: <GiftCardsTutorialPage />,
            },
          ],
        },
      ],
    },
    { path: '', element: <Navigate to="/marketing/sms-mailing" /> },
    { path: '*', element: <Navigate to="/marketing/sms-mailing" /> },
  ],
};

export default marketingRoutes;
