/* eslint-disable */
import { request } from '../axios';

const barcode = {
  generateBarcode: () => request.get(`v2/barcode/generate`),
  generatePlu: count => request.get(`v2/plu-codes/generate?count=${count}`),
  generateScaleCode: count => request.get(`v2/scale-codes/generate`),
  generateAllBarcode: amount => request.get(`v2/barcodes/generate/${amount}`),
};

export default barcode;
