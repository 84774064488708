/* eslint-disable */
import { request } from '../axios';

const productVariant = {
  getAll: id => request.get(`v1/product-variant?product_id=${id}`),
  create: data => request.post(`v1/product-variant`, data),
  getSingle: id => request.get(`v1/product-variant/${id}`),
  update: data => request.put(`v1/product-variant`, data),
  updatePrice: data => request.patch(`v1/product-variant/prices`, data),
};

export default productVariant;
