import { Navigate } from 'react-router-dom';

import DashboardLayout from 'layouts/DashboardLayout';
import CatalogLayout from 'layouts/CatalogLayout';

import {
  CatalogListPage,
  MassiveOperationsPage,
  SmallLeftOperationsPage,
  ProductPropertyOperationsPage,
  ProductPriceOperationsPage,
  UploadPhotoOperationsPage,
  PrintPriceTagOperationsPage,
  ProductScaleOperationsPage,
  CatalogManagementPage,
  CatalogArchivePage,
  ProductCreatePage,
  CreatePriceTagPage,
  ProductUpdatePage,
  ImportPage,
  ImportSinglePage,
  ImportSingleViewListPage,
  InventoryPage,
  InventoryScanPage,
  InventoryResultPage,
  RevaluationPage,
  TransferPage,
  TransferSinglePage,
  RevaluationCreatePage,
  TransferSingleViewPage,
  WriteOffPage,
  WriteOffCompletedPage,
  WriteOffCreatePage,
  RevaluationFinishPage,
  RevaluationViewPage,
  TransferCreatePage,
  SupplierListPage,
  CreateSupplierPage,
  ViewSupplierPage,
  UpdateSupplierPage,
  OrdersPage,
  CreateOrderPage,
  ViewOrderPage,
  ViewReturnDetailsPage,
  ValidationResultPage,
  ValidationProgressPage,
  ProductMarkingOperationsPage,
} from 'pages';

const productsRoutes = {
  path: 'products',
  children: [
    {
      path: 'catalog',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <CatalogListPage />,
        },
        {
          path: 'management',
          element: <CatalogManagementPage />,
        },
        {
          path: 'archive',
          element: <CatalogArchivePage />,
        },
      ],
    },
    {
      path: 'create-price-tag',
      element: <CatalogLayout />,
      children: [{ path: '', element: <CreatePriceTagPage /> }],
    },
    {
      path: 'settings',
      element: <CatalogLayout />,
      children: [
        { path: '', element: <MassiveOperationsPage /> },
        { path: 'small-left', element: <SmallLeftOperationsPage /> },
        { path: 'product-property', element: <ProductPropertyOperationsPage /> },
        { path: 'product-price', element: <ProductPriceOperationsPage /> },
        { path: 'upload-photo', element: <UploadPhotoOperationsPage /> },
        { path: 'price-tag', element: <PrintPriceTagOperationsPage /> },
        { path: 'scales', element: <ProductScaleOperationsPage /> },
        { path: 'marking', element: <ProductMarkingOperationsPage /> },
      ],
    },
    { path: 'validation-result/:id', element: <ValidationResultPage /> },
    { path: 'validation-progress/:id', element: <ValidationProgressPage /> },
    {
      path: 'create',
      element: <CatalogLayout />,
      children: [
        { path: '', element: <ProductCreatePage /> },
        { path: '*', element: <Navigate to="/products/create" /> },
      ],
    },
    {
      path: 'update/:id',
      element: <CatalogLayout />,
      children: [
        { path: '', element: <ProductUpdatePage /> },
        { path: '*', element: <Navigate to="/products/update/:id" /> },
      ],
    },
    {
      path: 'import',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <ImportPage /> }],
        },
        {
          path: 'edit',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <ImportSinglePage /> }],
        },
        {
          path: 'list',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <ImportSingleViewListPage /> }],
        },
        { path: '*', element: <Navigate to="/products/import" /> },
      ],
    },
    {
      path: 'inventory',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <InventoryPage /> }],
        },
        {
          path: 'scan',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <InventoryScanPage /> }],
        },
        {
          path: 'result',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <InventoryResultPage /> }],
        },
        { path: '*', element: <Navigate to="/products/inventory" /> },
      ],
    },
    {
      path: 'transfer',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <TransferPage /> }],
        },
        {
          path: 'finish',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <TransferSinglePage /> }],
        },
        {
          path: 'list',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <TransferSingleViewPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <TransferCreatePage /> }],
        },
        { path: '*', element: <Navigate to="/products/transfer" /> },
      ],
    },
    {
      path: 'write-off',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <WriteOffPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <WriteOffCreatePage /> }],
        },
        {
          path: 'completed',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <WriteOffCompletedPage /> }],
        },
        { path: '*', element: <Navigate to="/products/write-off" /> },
      ],
    },
    {
      path: 'revaluation',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <RevaluationPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <RevaluationCreatePage /> }],
        },
        {
          path: 'view',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <RevaluationViewPage /> }],
        },
        {
          path: 'finish',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <RevaluationFinishPage /> }],
        },
        { path: '*', element: <Navigate to="/products/revaluation" /> },
      ],
    },
    {
      path: 'suppliers',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <SupplierListPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [{ path: '', element: <CreateSupplierPage /> }],
        },
        {
          path: 'update',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <UpdateSupplierPage /> }],
        },
        {
          path: 'view',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <ViewSupplierPage /> }],
        },
        { path: '*', element: <Navigate to="/products/suppliers" /> },
      ],
    },
    {
      path: 'orders',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <OrdersPage /> }],
        },
        {
          path: 'create',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <CreateOrderPage /> }],
        },
        {
          path: 'view',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <ViewOrderPage /> }],
        },
        {
          path: 'return',
          element: <CatalogLayout />,
          children: [{ path: ':id', element: <ViewReturnDetailsPage /> }],
        },
        { path: '*', element: <Navigate to="/products/orders" /> },
      ],
    },
    { path: '', element: <Navigate to="/products/catalog" /> },
    { path: '*', element: <Navigate to="/products/catalog" /> },
  ],
};

export default productsRoutes;
