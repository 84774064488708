/* eslint-disable */
const guideData = {
  settings_guide: [
    {
      content: <span>Для перехода в раздел “Настройки”, нажмите на соответствующую кнопку в боковом меню</span>,
      placement: 'right',
      disableBeacon: true,
      styles: {
        options: {
          width: 300,
        },
      },
      target: '.settings_guide_element',
    },
    {
      content: <span>Выберите нужный подраздел настроек</span>,
      target: '.fixed_navlist',
      placement: 'right',
      disableBeacon: true,
      styles: {
        options: {
          width: 200,
        },
      },
    },
  ],
  product_guide: [
    {
      content: <span>Для перехода в раздел “Товары”, нажмите на соответствующую кнопку в боковом меню</span>,
      placement: 'right',
      disableBeacon: true,
      styles: {
        options: {
          width: 300,
        },
      },
      target: '.products_guide_element',
    },
    {
      content: <span>Выберите нужный подраздел настроек</span>,
      target: '.fixed_navlist',
      placement: 'right',
      disableBeacon: true,
      styles: {
        options: {
          width: 200,
        },
      },
    },
  ],
  sale_guide: [
    {
      content: <span>Для перехода в раздел “Продажи”, нажмите на соответствующую кнопку в боковом меню</span>,
      placement: 'right',
      disableBeacon: true,
      styles: {
        options: {
          width: 300,
        },
      },
      target: '.sale_guide_element',
    },
    {
      content: <span>Выберите нужный подраздел настроек</span>,
      target: '.fixed_navlist',
      placement: 'right',
      disableBeacon: true,
      styles: {
        options: {
          width: 200,
        },
      },
    },
  ],
};
export default guideData;
