/* eslint-disable */
import { useSelector, shallowEqual } from 'react-redux';
import React, { ReactNode } from 'react';

type Props = {
  checkSlug?: string;
  placeholderComponent?: ReactNode | false;
  [key: string]: any; // allow for any additional props
};

const checkAccess = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  function EnhancedComponent({ checkSlug, placeholderComponent = false, ...rest }: Props) {
    const { forbiddenRoutes } = useSelector(
      (state: { permissionRoutes: { forbiddenRoutes: { slug: string }[] } }) => state.permissionRoutes,
      shallowEqual,
    );

    return (
      <>{!forbiddenRoutes?.find(el => el.slug === checkSlug) ? <WrappedComponent {...(rest as P)} /> : (placeholderComponent ?? null)}</>
    );
  };

export default checkAccess;
