/* eslint-disable */
import { certificatesTableHeaderActionTypes } from 'store/actions/certificatesActions/actionTypes';

export const initialState = {
  certificate: [
    {
      accessor: 'certificate_code',
      is_active: true,
    },
    {
      accessor: 'certificate_sum',
      is_active: true,
    },
    { accessor: 'certificate_term', is_active: true },
    {
      accessor: 'certificate_type',
      is_active: true,
    },
    {
      accessor: 'certificate_status',
      is_active: true,
    },
    {
      accessor: 'certificate_balance',
      is_active: true,
    },
    {
      accessor: 'certificate_creation',
      is_active: true,
    },
    {
      accessor: 'certificate_sale',
      is_active: true,
    },
    {
      accessor: 'certificate_usage',
      is_active: true,
    },
    {
      accessor: 'certificate_actions',
      is_active: true,
    },
  ],
  voucher: [
    {
      accessor: 'voucher_code',
      is_active: true,
    },
    {
      accessor: 'voucher_sum',
      is_active: true,
    },
    { accessor: 'voucher_term', is_active: true },
    {
      accessor: 'voucher_type',
      is_active: true,
    },
    {
      accessor: 'voucher_status',
      is_active: true,
    },
    {
      accessor: 'voucher_balance',
      is_active: true,
    },
    {
      accessor: 'voucher_creation',
      is_active: true,
    },
    {
      accessor: 'voucher_activation',
      is_active: true,
    },
    {
      accessor: 'voucher_usage',
      is_active: true,
    },
    {
      accessor: 'voucher_actions',
      is_active: true,
    },
  ],
  imports: [
    {
      accessor: 'imports_id',
      is_active: true,
    },
    {
      accessor: 'imports_name',
      is_active: true,
    },
    {
      accessor: 'imports_type',
      is_active: true,
    },
    { accessor: 'imports_quantity', is_active: true },
    {
      accessor: 'imports_sum',
      is_active: true,
    },
    {
      accessor: 'imports_date',
      is_active: true,
    },
    {
      accessor: 'imports_actions',
      is_active: true,
    },
  ],
};

const certificatesTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case certificatesTableHeaderActionTypes.UPDATE_CERTIFICATES_TABLE:
      return {
        ...state,
        [payload.slug]: payload.columns,
      };

    case certificatesTableHeaderActionTypes.RESET_CERTIFICATES_TABLE:
      return initialState;

    default:
      return state;
  }
};

export default certificatesTableHeaderReducer;
