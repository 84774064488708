import * as qs from 'qs';
import { request } from '../axios';
import { UserServiceNS } from '@/shared/services';

const employee = {
  getAll: filter => request.get(`v1/user${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v1/user`, data),
  search: phoneNumber => request.get(`v1/user-search${qs.stringify(phoneNumber, { addQueryPrefix: true })}`),
  getEmployee: id => request.get<UserServiceNS.UserDTO>(`v1/user/${id}`),
  update: data => request.put(`v1/user/${data.id}`, data.data),
  updateUserProfile: data => request.put(`v1/user/${data.id}/profile`, data),
  delete: id => request.delete(`v1/user/${id}`),
  changeStatus: data => request.patch(`v1/user/change-status/${data.id}`, data?.data),
  restore: id => request.patch(`v1/user/restore/${id}`),
  bulkUpdateShops: data => request.patch('v1/user/bulk-update-shops', data),
  bulkUpdateRoles: data => request.patch('v1/user/bulk-update-roles', data),
};

export default employee;
