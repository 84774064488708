import * as qs from 'qs';
import { request } from '../axios';

const stocktaking = {
  getAll: filter => request.get(`v2/stocktaking${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: ({ data, protocol }) => request.post(`v2/stocktaking${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  createV2: ({ id, body }) => request.post(`v2/stocktaking/${id}/scan-from-file`, body),
  getSingle: (id, filter) => request.get(`v2/stocktaking/${id}${qs.stringify(filter, { addQueryPrefix: true })}`),
  getResortedProducts: (id, filter) =>
    request.get(
      `v2/stocktaking-resort-products/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  check: id => request.get(`v2/stocktaking-check/${id}`),
  getSingleStats: (id, filter) =>
    request.get(
      `v2/stocktaking-stats/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getLogs: (id, filter) =>
    request.get(
      `v2/stocktaking-logs/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  addProduct: ({ id, data, protocol }) =>
    request.patch(`v2/stocktaking/${id}/add-product-by-barcode${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  addProductV2Http: data => request.patch(`v2/stocktaking/${data.id}/add-product-by-barcode-v2`, data.data),
  insertProduct: data => request.post(`v2/stocktaking/${data.id}/insert-products`, data.data),
  removeProducts: data => request.patch(`v2/stocktaking/${data.id}/remove-products`, data.data),
  selectAll: filter => request.post(`v2/stocktaking-by-websocket`, filter),
  remove: data => request.patch(`v2/stocktaking/${data.id}/remove`, data.data),
  setMeasurementValue: data => request.patch(`v2/stocktaking/${data.id}/set-measurement-value`, data.data),
  delete: ({ id, protocol }) => request.delete(`v2/stocktaking/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  cancel: ({ id, protocol }) => request.post(`v2/stocktaking/${id}/cancel${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  finish: data => request.post(`v2/stocktaking/${data?.id}/finish`, data?.body),
  lockAndUnlock: ({ id, protocol }) => request.post(`v2/stocktaking/${id}/lock-unlock${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  generateReport: data => request.put(`v2/stocktaking/${data.id}/generate-report`, data.body),
  generateExcel: data => request.post(`v2/stocktaking-export/${data.id}`, data.body),
  stocktakingFromFile: data => request.post(`v2/stocktaking-from-file`, data),

  getProperties: filter =>
    request.get(
      `v2/excel/stocktaking-properties${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getPropertiesFile: filter =>
    request.get(
      `v2/excel/stocktaking-properties-file${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  setProductByBarcode: ({ id, data, protocol }) =>
    request.patch(`v2/stocktaking/${id}/set-product-by-barcode${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  setProductByBarcodeV2Http: data => request.patch(`v2/stocktaking/${data?.id}/set-product-by-barcode-v2`, data?.body),
  resortProducts: data => request.post(`v2/stocktaking/${data.id}/resort-products`, data?.body),
  partialAddProduct: body => request.post('/v2/stocktaking-add-to-partial', body),
  partialRemoveProduct: body => request.post('/v2/stocktaking-remove-from-partial', body),
  partialCreateFromFile: body => request.post('v2/stocktaking-from-file-v2', body),
  logsExcelReport: data => request.put(`/v2/stocktaking/${data?.id}/generate-log-report`, data?.body),
};

export default stocktaking;
