/* eslint-disable */
// import { employeeActionTypes } from '../actions/employeeActions/employeeActionTypes'
import { productActionTypes } from '../actions/productActions/productActionTypes';

const initialState = {
  type: '',
  products: [],
};

const productMassiveOperationReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case productActionTypes.SET_PRODUCTS:
      return {
        products: payload.products,
        type: payload.type,
        from_excel_data: payload.from_excel_data,
        properties: payload.properties,
      };
    case productActionTypes.CLEAR_PRODUCTS:
      return { products: [], type: '', from_excel_data: {} };
    default:
      return state;
  }
};

export default productMassiveOperationReducer;
