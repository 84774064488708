/* eslint-disable */
export const tableHeaderActionTypes = {
  UPDATE_TABLE_HEADER: 'UPDATE_TABLE_HEADER',
  RESET_TABLE_HEADER: 'RESET_TABLE_HEADER',
  UPDATE_TABLE_HEADER_SETTINGS: 'UPDATE_TABLE_HEADER_SETTINGS',
  UPDATE_COLUMN_WIDTH: 'UPDATE_COLUMN_WIDTH',
  UPDATE_COLUMN_PROPERTY: 'UPDATE_COLUMN_PROPERTY',
  RESET_COLUMNS_WIDTH: 'RESET_COLUMNS_WIDTH',
  RESET_ALL_COLUMNS: 'RESET_ALL_COLUMNS',
  CHANGE_COLUMN_SEQUENCE: 'CHANGE_COLUMN_SEQUENCE',
  SET_TABLE_COLUMNS: 'SET_TABLE_COLUMNS',
  REMOVE_CUSTOM_COLUMN: 'REMOVE_CUSTOM_COLUMN',
  UPDATE_CUSTOM_COLUMN: 'UPDATE_CUSTOM_COLUMN',
};
