import React from 'react';

interface LazyLoadOptions {
  fallback?: React.ReactNode;
  retries?: number;
  retryInterval?: number;
}

function retry<T>(fn: () => Promise<T>, retriesLeft = 5, interval = 1000): Promise<T> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        if (retriesLeft <= 1) {
          reject(error);
          return;
        }

        setTimeout(() => {
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const lazyLoad = <
  T extends Promise<{ default: React.ComponentType<Props> }>,
  U extends React.ComponentType<Props>,
  Props = React.ComponentProps<U>,
>(
  importFunc: () => T,
  selectorFunc?: (module: Awaited<T>) => U,
  { fallback, retries = 3, retryInterval = 1000 }: LazyLoadOptions = {},
): React.FC<Props> => {
  const LazyComponent = React.lazy(
    (): Promise<{ default: React.ComponentType<Props> }> =>
      retry(
        () =>
          importFunc().then(module => ({
            default: selectorFunc ? selectorFunc(module) : module.default,
          })),
        retries,
        retryInterval,
      ),
  );

  const LazyWrapper: React.FC<Props> = props => (
    <React.Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </React.Suspense>
  );

  LazyWrapper.displayName = `LazyLoad(${selectorFunc ? selectorFunc.name : 'Component'})`;

  return LazyWrapper;
};
