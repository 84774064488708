/* eslint-disable */
import { orderTableHeaderActionTypes } from '../actions/orderTableHeaderActions/orderTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      headerName: 'ID',
      field: 'id',
      hide: false,
      width: 110,
      minWidth: 70,
    },
    {
      field: 'name',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'supplier',
      hide: false,
      minWidth: 70,
    },
    { field: 'shop', hide: false, minWidth: 70 },
    { field: 'status', hide: false, minWidth: 70, resizable: false },
    {
      field: 'payment_amount',
      hide: false,
      minWidth: 180,
    },
    {
      field: 'quantity',
      hide: false,
      minWidth: 120,
    },
    {
      field: 'order_amount',
      hide: false,
      minWidth: 180,
    },
    {
      field: 'created_at',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'accepted_at',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'maturity_date',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'created_by',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'accepted_by',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'progress',
      hide: false,
      resizable: false,
    },
  ],
};

const ordersTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case orderTableHeaderActionTypes.UPDATE_ORDER_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case orderTableHeaderActionTypes.RESET_ORDER_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default ordersTableHeaderReducer;
