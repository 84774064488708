/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

interface Settings {
  showShopColumns?: boolean;
  showVariatives?: boolean;
}

interface TableHeaderState {
  settings: Settings;
  columns: any[];
}

export const initialState: TableHeaderState = {
  settings: {
    showShopColumns: false,
    showVariatives: false,
  },
  columns: [
    {
      field: 'image',
      hide: false,
      minWidth: 70,
      initialWidth: 97,
      sortable: false,
      resizable: false,
    },
    {
      field: 'name',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'archived_at',
      hide: false,
      minWidth: 70,
      initialWidth: 134,
    },
    {
      field: 'archived_by',
      hide: false,
      minWidth: 70,
      initialWidth: 188,
    },
    {
      field: 'sku',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'barcode',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'category',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'quantity',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'supply_price',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'retail_price',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'action',
      hide: false,
      minWidth: 70,
    },
  ],
};

const archivedProductsTableHeaderSlice = createSlice({
  name: 'archivedProductsTableHeader',
  initialState,
  reducers: {
    updateTable: (state, action) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    updateTableSettings: (state, action) => {
      return {
        ...state,
        settings: action.payload,
      };
    },
    resetTable: () => {
      return initialState;
    },
  },
});

export const { updateTable, resetTable, updateTableSettings } = archivedProductsTableHeaderSlice.actions;
export default archivedProductsTableHeaderSlice.reducer;
