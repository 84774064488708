/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const order = {
  getSingleCheckboxData: id => request.get(`v1/cashbox-history/${id}`),
  openCashbox: ({ filter, data }) => request.post(`v1/cashbox-history${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  closeCashbox: ({ filter, data }) => request.post(`v1/cashbox-history${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  patchUserData: data => request.patch(`v1/user`, data),
  changeCashbox: id => request.put(`v1/change-cashbox/${id}`),
  cashBoxStatus: filter =>
    request.get(
      `v1/cashbox-current-status${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),

  create: ({ data, filter }) => request.post(`v2/order${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  getSingle: id => request.get(`v2/order/${id}`),
  getOrderInfo: id => request.get(`v1/get-order-shift-info?order_id=${id}`),
  delete: ({ id, filter }) => request.delete(`v2/order/${id}${qs.stringify(filter, { addQueryPrefix: true })}`),
  getStats: filter => request.get(`v1/orders-stats${qs.stringify(filter, { addQueryPrefix: true })}`),
  getStatsES: filter => request.get(`v3/order-search-stats${qs.stringify(filter, { addQueryPrefix: true })}`),
  exchange: ({ id, data }) => request.post(`v1/exchange-order/${id}`, data),
  getAllByDate: filter => request.get(`v3/order-by-date${qs.stringify(filter, { addQueryPrefix: true })}`),
  getAllByDateEs: filter => request.get(`v3/order-search${qs.stringify(filter, { addQueryPrefix: true })}`),
  // works with WEBSOCKET
  addItem: ({ id, data }) => request.put(`v2/order-item/${id}`, data),
  clearCartItems: ({ id, filter }) => request.put(`v2/order/${id}/clear-items${qs.stringify(filter, { addQueryPrefix: true })}`),

  // works with HTPPS
  addItemHttps: ({ id, data }) => request.put(`v1/order-item/${id}`, data),
  scanItem: ({ id, data }) => request.put(`v1/add-search-order-item/${id}`, data),
  scanItemWS: ({ id, data }) => request.put(`v1/add-search-order-item-ws/${id}`, data),
  addCustomerHttps: ({ id, data }) => request.put(`v1/order-customer/${id}`, data),
  removeCustomerHttps: id => request.delete(`v1/order-customer/${id}`),
  addDiscountHttps: ({ id, data }) => request.put(`v1/order-discount/${id}`, data),
  addSellerHttps: ({ id, data }) => request.put(`v1/order-seller/${id}`, data),
  addFirstSeller: ({ id, data, filter }) => request.put(`v3/order-seller/${id}${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  addPaymentHttps: ({ id, data }) => request.post(`v2/order-payment/${id}`, data),
  addPromocode: ({ id, data, filter }) => request.put(`v1/order-promo-code/${id}${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  deletePromocode: ({ id, data, filter }) =>
    request.delete(`v1/order-promo-code/${id}${qs.stringify(filter, { addQueryPrefix: true })}`, data),

  // NEW FLOW
  addProduct: ({ id, data, filter }) => request.post(`v2/order-product/${id}${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  returnProduct: ({ id, data, filter }) =>
    request.post(`v2/return-order-product/${id}${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  orderDiscount: ({ id, data, filter }) =>
    request.post(`v2/order-manual-discount/${id}${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  deleteDiscount: ({ id, data, filter }) =>
    request.delete(`v2/order-manual-discount/${id}${qs.stringify(filter, { addQueryPrefix: true })}`, { data }),
  updateReturnOrderItemsNew: ({ id, data }) => request.post(`/v2/order-product-return/${id}`, data),
  addCustomer: ({ id, data, filter }) => request.put(`v2/order-customer-new/${id}${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  removeCustomer: ({ id, filter }) => request.delete(`v2/order-customer-new/${id}${qs.stringify(filter, { addQueryPrefix: true })}`),
  syncWs: id => request.post(`/v2/recalculate-order/${id}`),
  draftPostponeCall: id => request.post(`/v2/order-product-fill/${id}`),
  //
  getAll: filter => request.get(`v2/order${qs.stringify(filter, { addQueryPrefix: true })}`),
  getAllEs: filter => request.get(`v3/orders${qs.stringify(filter, { addQueryPrefix: true })}`),
  addExchangeOrderItem: ({ id, data }) => request.post(`v2/exchange-order-item/${id}`, data),
  returnOrder: ({ id, data }) => request.post(`v2/return-order/${id}`, data),
  updateReturnOrderItems: ({ id, data }) => request.put(`v2/return-order/${id}`, data),
  updateOrder: ({ id, data }) => request.put(`v2/order/${id}`, data),
  createPostpone: ({ data, filter }) => request.post(`v2/order/create_postpone${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  returnPostpone: ({ data, filter }) => request.post(`v2/order/return-postpone${qs.stringify(filter, { addQueryPrefix: true })}`, data),
  getAllDraftOrders: filter => request.get(`v2/order-notes${qs.stringify(filter, { addQueryPrefix: true })}`),
  getAllDraftOrdersEs: filter => request.get(`v3/order-note-search${qs.stringify(filter, { addQueryPrefix: true })}`),
  generateReport: filter => request.post(`v1/order/generate-report`, filter),
  getSinglePdf: id => request.get(`v1/cashbox-shift-pdf/${id}`),
  getSinglePdfCashboxOperations: data => request.post(`v1/cashbox-operations-pdf`, data),
  createCode: data => request.put(`v2/order/${data?.id}/auth-code-create`, data?.body),
  sendCode: data => request.put(`v2/order/${data?.id}/auth-code-send`, data?.body),
  deleteDebt: ({ id, filter }) => request.put(`v2/order/${id}/delete-debt${qs.stringify(filter, { addQueryPrefix: true })}`),
  sync: id => request.post(`/v1/recalculate-order-bill/${id}`),
  paymeGo: data => request.post(`v1/payme/pay-with-go`, data),
  getTransactionReportByProducts: data => request.post(`v1/transaction-report-by-products-excel`, data),
  getTerminalByCashBoxId: id => request.get(`v1/kaspi/terminal/${id}`),
  createPaymentKaspi: data => request.post(`v1/kaspi/payment`, data),
  updatePaymentKaspi: data => request.post('v1/kaspi/payment/status', data),
  orderTransaction: id => request.get(`v1/kaspi/transaction/${id}`),
  preCheck: ({ data, id }) => request.post(`/v1/order-precheck/${id}`, { params: data }),
  orderIntegrationInfo: id => request.get(`/v1/get-order-integration-info/${id}`),
};

export default order;
