/* eslint-disable */
import axios from 'axios';
import debounce from 'shared/utils/debounce';
import { error } from 'shared/utils/toast';
import i18n from 'i18n';

export const authRequest = axios.create({
  baseURL: import.meta.env.VITE_APP_AUTH_URL,
  headers: {
    'Accept-Language': i18n.language,
    'platform-id': import.meta.env.VITE_APP_PLATFORM_ID,
  },
});

export const request = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  headers: {
    'Accept-Language': i18n.language,
    Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`,
    'platform-id': import.meta.env.VITE_APP_PLATFORM_ID,
  },
});

export const requestNotRelative = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL_OLD,
  headers: {
    'Accept-Language': i18n.language,
    Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`,
    'platform-id': import.meta.env.VITE_APP_PLATFORM_ID,
  },
});

const debouncedShowNotification = debounce(() => error('no_connection'), 2000);

// Get new access token when it is expired
request.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const access_token = localStorage.getItem('access_token');
    const refresh_token = localStorage.getItem('refresh_token');
    const invalid = localStorage.getItem('invalid');
    // show notification if user has no internet connection
    // it is not perfect solution though
    if (err?.toJSON()?.message === 'Network Error' && !location.pathname.includes('presentation')) {
      debouncedShowNotification();
    }
    if (err?.response?.data?.error === 'company is blocked') {
      if (!invalid) {
        localStorage.setItem('invalid', true);
        window.location.reload();
      }
    }
    if (err?.response?.status === 401 && refresh_token && access_token) {
      authRequest
        .put('v1/auth/refresh', {
          refresh_token,
        })
        .then(res => {
          const a_token = res?.data?.data?.access_token;
          const r_token = res?.data?.data?.refresh_token;
          if (a_token) {
            localStorage.setItem('access_token', a_token);
            localStorage.setItem('refresh_token', r_token);
            request.interceptors.request.use(
              config => {
                config.headers['Authorization'] = `Bearer ${a_token}`;
                return config;
              },
              error => Promise.reject(error),
            );
          }
        })
        .catch(error => {
          if (error.toJSON()?.message === 'Request failed with status code 401') {
            localStorage.clear();
            window.location.reload();
          } else {
            if (!invalid) {
              localStorage.setItem('invalid', true);
              window.location.reload();
            }
          }
        });
    }
    if (err?.response?.status === 403 && !invalid) {
      localStorage.setItem('invalid', true);
      window.location.reload();
    }

    return Promise.reject(err);
  },
);
