/* eslint-disable */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'stories/Button/Button';
import { Box, Dialog, Typography } from '@mui/material';
import { useStyles } from 'layouts/Navbar/NeedHelpButton';
import TelegramIcon from 'shared/icons/TelegramIcon';
import WarningExclamationIcon from 'shared/icons/WarningExclamationIcon';
import GoToMainIcon from 'shared/icons/GoToMainIcon';
import { clearLocalStorageItems } from 'shared/utils/clearLocalStorageItems';

function InvalidUserDialog() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const logout = () => {
    clearLocalStorageItems();
    window.location.reload();
  };

  const handleStorageChange = () => {
    const invalid = localStorage.getItem('invalid');
    if (invalid === 'true') {
      setOpen(true);
    }
  };
  useEffect(() => {
    if (window) {
      if (localStorage.getItem('invalid') === 'true') {
        setOpen(true);
      }
      window.addEventListener('storage', handleStorageChange);
    }
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  return (
    <Dialog onClose={() => {}} open={open} className={`${classes.root} ${classes.blockedUser}`}>
      <Box className={classes.content}>
        <WarningExclamationIcon />

        <Box className={classes.title}>
          <Typography variant="h2">{t('employees.blocked_user.modal_heading')}</Typography>
        </Box>
        <Typography className={classes.desc}>
          {t('employees.blocked_user.was_blocked')} <br /> {t('employees.blocked_user.modal_paragraph')}
        </Typography>
        <Box className={classes.phoneContainer} px={4} mb={2}>
          <Box>
            <Typography>{t('navbar.support_phone')}</Typography>
            <a href="tel:+998781137144">
              <Typography className={classes.phone} variant="h2">
                +998 78 113 71 44
              </Typography>
            </a>
          </Box>
          <Box>
            <img src="/images/Pic.png" />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width={512}>
          <Button
            onClick={() => {
              window.open('https://t.me/BILLZ1144', '_blank');
            }}
            style={{ marginRight: 8 }}
            secondary
            fullWidth
            adornmentStart={<TelegramIcon />}
          >
            {t('navbar.need_help_tg')}
          </Button>

          <Button onClick={logout} secondary fullWidth adornmentStart={<GoToMainIcon />}>
            {t('navbar.login_acc')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default InvalidUserDialog;
