import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    flexFlow: 'column',
  },

  content: {
    height: '100%',
    padding: '32px',
  },

  block: {
    display: 'flex',
    flexDirection: 'column',
    padding: 4,
    gap: 4,
  },

  top: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    marginBottom: '24px',
  },
  createButton: {
    width: 'fit-content',
    whiteSpace: 'nowrap',
  },
  tabs: {
    marginBottom: '32px',
  },
  nameCell: {
    width: 'fit-content',
    cursor: 'pointer',
  },
  actionsCell: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
}));
