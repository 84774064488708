import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '16px',
    padding: '32px 32px 0 32px',
  },
  subTitle: {
    color: theme.palette.gray[400],
    fontSize: '16px',
  },
}));
