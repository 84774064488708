/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

interface ReturnCreateTableHeaderState {
  columns: any[];
}

export const initialState: ReturnCreateTableHeaderState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'Заказано',
      accessor: 'ordered',
      is_active: true,
    },
    {
      Header: 'Остатки',
      accessor: 'left',
      is_active: true,
    },
    {
      Header: 'К возврату',
      accessor: 'for_order',
      is_active: true,
    },
    {
      Header: 'Цена поставки',
      accessor: 'supply_pr',
      is_active: true,
    },
    {
      Header: 'Цена продажи',
      accessor: 'retail_pr',
      is_active: true,
    },
  ],
};

const returnCreateTableHeaderSlice = createSlice({
  name: 'returnCreateTableHeader',
  initialState,
  reducers: {
    updateTable: (state, action) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    resetTable: () => {
      return initialState;
    },
  },
});

export const { updateTable, resetTable } = returnCreateTableHeaderSlice.actions;
export default returnCreateTableHeaderSlice.reducer;
