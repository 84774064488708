/* eslint-disable */
export function createRounding(payload) {
  const rounding = {
    digit: payload.digit,
    kind: payload.kind,
    subject: payload.subject,
  };
  switch (payload.digit) {
    case 'units':
      rounding.digit = 1;
      break;
    case 'dozens':
      rounding.digit = 10;
      break;
    case 'hundreds':
      rounding.digit = 100;
      break;
    case 'thousands':
      rounding.digit = 1000;
      break;
    case 'tens_of_thousands':
      rounding.digit = 10000;
      break;
    case 'hundreds_of_thousands':
      rounding.digit = 100000;
      break;
    default:
      break;
  }
  return rounding;
}
