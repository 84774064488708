/* eslint-disable */
import { backgroundProgressActionTypes } from './backgroundProgressActionTypes';

export const setBackgroundProgress = payload => ({
  type: backgroundProgressActionTypes.SET_BACKGROUND_PROGRESS,
  payload,
});

export const removeBackgroundProgress = payload => ({
  type: backgroundProgressActionTypes.REMOVE_BACKGROUND_PROGRESS,
  payload,
});

export const clearBackgroundProgress = payload => ({
  type: backgroundProgressActionTypes.CLEAR_BACKGROUND_PROGRESS,
  payload,
});
