import React from 'react';
import clsx from 'clsx';
import { useStyles } from './Paper.styles';

const borderRadiusMap = {
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
} as const;

type BorderRadius = keyof typeof borderRadiusMap;

type CommonPaperProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

type DashedBorderPaperProps = {
  type: 'dashed';
  borderRadius: BorderRadius;
} & CommonPaperProps;

type NoBorderPaperProps = {
  type?: 'default';
  borderRadius?: BorderRadius;
};

// Когда type === dashed, то borderRadius обязателен, чтобы правильно отрисовалась обводка
type PaperProps = (NoBorderPaperProps | DashedBorderPaperProps) & CommonPaperProps;

const Paper = ({ className, children, type = 'default', borderRadius = 'md', ...props }: PaperProps) => {
  const classes = useStyles({ borderRadius: borderRadiusMap[borderRadius] });
  const rootClassName = clsx(
    classes.root,
    {
      [classes.dashed]: type === 'dashed',
    },
    className,
  );

  return (
    <div className={rootClassName} {...props}>
      {children}
    </div>
  );
};

export default Paper;
