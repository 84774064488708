/* eslint-disable */
import { webkassaApi } from '../axios/integrations';
import { request } from '../axios';
import * as qs from 'qs';

const webkassa = {
  addWebkassaLog: data => request.post(`v1/order-add-webkassa-log`, data),
  getWebkassaLog: filter => request.get(`/v1/get-webkassa-log${qs.stringify(filter, { addQueryPrefix: true })}`),
  getUserToken: filter => request.get(`v1/get-user-token${qs.stringify(filter, { addQueryPrefix: true })}`),
  getUnits: filter => request.get(`/v1/webkassa-unit-types${qs.stringify(filter, { addQueryPrefix: true })}`),
  getXReport: filter => request.get(`v1/webkassa-x-report${qs.stringify(filter, { addQueryPrefix: true })}`),
  setUserToken: data => request.post(`v1/set-user-token`, data),
  authorize: data => request.post(`v1/webkassa-authorize`, data),
  sendTransaction: data => request.post(`v1/webkassa-send-transaction`, data),
  closeShift: data => request.post(`v1/webkassa-close-shift`, data),
  moneyOperation: data => request.post(`v1/webkassa-money-operation `, data),
  // authorize: (data) => webkassaApi.post(`Authorize`, data),
  changeToken: data => webkassaApi.post(`Cashbox/ChangeToken`, data),
  check: data => webkassaApi.post(`Check`, data),
  // moneyOperation: (data) => webkassaApi.post(`MoneyOperation`, data),
  zReport: data => webkassaApi.post(`ZReport`, data),
  // xReport: (data) => webkassaApi.post(`XReport`, data),
  controlTape: data => webkassaApi.post(`ControlTape`, data),
  cashboxes: data => webkassaApi.post(`Cashboxes`, data),
  shiftHistory: data => webkassaApi.post(`ShiftHistory`, data),
  employeeList: data => webkassaApi.post(`Employee/List`, data),
  checkHistory: data => webkassaApi.post(`Check/History`, data),
  singleCheck: data => webkassaApi.post(`Check/HistoryByNumber`, data),
  ticketPrintFormat: data => webkassaApi.post(`Ticket/PrintFormat`, data),
  refUnits: data => webkassaApi.post(`references/RefUnits`, data),
  courierUpload: data => webkassaApi.post(`Courier/UploadExternalOrder`, data),
};

export default webkassa;
