import { Typography as TypographyTemplate, TypographyProps as TypographyTemplateProps } from '@mui/material';
import { useStyles } from './Typography.styles';
import clsx from 'clsx';

/*
FIXME: Собрать у бизнеса все возможные варианты использования текста
 и поддержать в этом компоненте
*
* */

const headerVariants = ['h1', 'h2', 'h3', 'h4', 'h5'];

type TypographyProps = {
  className?: string;
  secondary?: boolean;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  type?: 'default' | 'primary' | 'secondary' | 'link'; //| 'primary' | 'success' | 'inverse' | 'error' | 'warning';
} & TypographyTemplateProps;

const Typography = ({ variant, type = 'default', size = 'md', className, ...props }: TypographyProps) => {
  const isHeader = headerVariants.some(v => v === variant);

  const classes = useStyles();
  const rootClassName = clsx(classes.root, classes[type], !isHeader && classes[size], className);

  return <TypographyTemplate className={rootClassName} variant={variant} {...props} />;
};

export default Typography;
