/* eslint-disable */
import React, { useEffect, useState, memo } from 'react';
import { Box, Button as MuiButton, CircularProgress } from '@mui/material';
import checkAccess from 'components/RouteAccess/checkAccess';
import useStyles from './ButtonStyles';

interface ButtonType {
  button: 'button';
  reset: 'reset';
  submit: 'submit';
  undefined: undefined;
}

interface ButtonProps {
  secondary?: boolean;
  size?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
  label?: string;
  disabled?: boolean;
  className?: string;
  adornmentEnd?: React.ReactNode;
  adornmentStart?: React.ReactNode;
  adornmentStartWidth?: number;
  adornmentEndWidth?: number;
  isLoading?: boolean;
  successContent?: React.ReactNode;
  text?: string;
  icon?: React.ReactNode;
  circle?: boolean;
  contentClass?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  whiteDisabled?: boolean;
  circledBorder?: boolean;
  tabIndex?: number;
  height?: number;
  align?: 'left' | 'right' | 'center';
  variant?: 'contained' | 'outlined' | 'text';
  type?: ButtonType;
  checkSlug?: string;
  primary?: boolean;
}

function Button({
  secondary,
  size,
  children,
  label,
  disabled,
  className,
  adornmentEnd,
  adornmentStart,
  adornmentStartWidth,
  adornmentEndWidth,
  isLoading = false,
  successContent,
  text,
  icon,
  circle,
  contentClass,
  buttonRef,
  whiteDisabled,
  circledBorder,
  tabIndex,
  height,
  align,
  variant,
  type,
  primary,
  ...props
}: ButtonProps) {
  const classes = useStyles({ height, align });
  const [loading, setLoading] = useState<boolean>(isLoading);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <MuiButton
      ref={buttonRef}
      disabled={!!disabled}
      tabIndex={tabIndex}
      variant={variant || 'contained'}
      color={!secondary ? 'primary' : 'secondary'}
      type={type}
      size={size}
      className={`${whiteDisabled ? classes.whiteDisabled : ''} ${
        adornmentEnd ? classes.spaceBetweenCont : adornmentStart && classes.spaceNormal
      } ${(text || icon) && classes.inline} ${className} ${circle ? classes.circle : ''} ${isLoading ? classes.isLoading : ''} ${
        circledBorder ? classes.circledBorder : ''
      }`}
      primary={(primary || '').toString()}
      {...props}
    >
      {text || icon ? (
        label || children
      ) : (
        <>
          {loading && (
            <span className={classes.loading}>
              <CircularProgress
                sx={{ color: !secondary ? 'inherit' : 'white' }}
                size={size === 'medium' ? 24 : size === 'small' ? 22 : 28}
              />
            </span>
          )}
          <span
            className={`${classes.content} ${loading && classes.hide} ${adornmentEnd && classes.spaceBetween} ${
              adornmentStart ? classes.flexStart : ''
            } ${contentClass}`}
          >
            {adornmentStart && (
              <Box
                className="adornmentStart"
                sx={theme => ({
                  width: adornmentStartWidth ? adornmentStartWidth : 56,
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '& svg, & path': {
                    fill: !secondary ? 'currentColor' : theme.palette.blue[500],
                  },
                })}
              >
                {adornmentStart}
              </Box>
            )}
            {label || children}
            {adornmentEnd && (
              <Box
                sx={theme => ({
                  width: adornmentEndWidth ? adornmentEndWidth : 56,
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '& svg, & path': {
                    fill: !secondary ? 'currentColor' : theme.palette.blue[500],
                  },
                })}
              >
                {adornmentEnd}
              </Box>
            )}
          </span>
        </>
      )}
    </MuiButton>
  );
}

export default memo(checkAccess(Button));
