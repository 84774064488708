/* eslint-disable */
const PieChartIcon = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {props?.gray ? (
      <>
        <path
          d="M16.2611 16.1677C16.3889 16.0531 16.5579 15.9929 16.7308 16.0007C20.3875 16.1101 23.4435 18.7646 23.9974 22.3125C24.0009 22.3337 24.0009 22.3553 23.9974 22.3765C24.0093 22.5443 23.9526 22.71 23.8397 22.8367C23.7269 22.9634 23.5672 23.0408 23.396 23.0517L17.2525 23.4569C17.0493 23.4749 16.8478 23.408 16.6975 23.2727C16.5472 23.1374 16.4619 22.9462 16.4628 22.7461L16.0498 16.7115V16.6119C16.0573 16.4422 16.1334 16.2824 16.2611 16.1677Z"
          fill="currentColor"
        />
        <path
          d="M15.8397 25.0348L21.1646 24.6937L21.2009 24.7079C21.4295 24.7116 21.6473 24.8043 21.8063 24.9656C21.9653 25.1269 22.0524 25.3436 22.0485 25.5679C21.8387 28.6256 19.5969 31.1804 16.5462 31.8386C13.4955 32.4968 10.3681 31.1003 8.87023 28.4111C8.42977 27.6366 8.15144 26.7835 8.05156 25.902C8.01283 25.6408 7.99586 25.3769 8.00085 25.113C8.01095 21.8617 10.3258 19.0553 13.5649 18.3676C13.957 18.2942 14.3501 18.49 14.5212 18.8438C14.5638 18.908 14.5978 18.9773 14.6226 19.05C14.6833 19.9872 14.7463 20.9156 14.809 21.8401C14.8585 22.5702 14.9079 23.2979 14.9559 24.0255C14.9534 24.1969 14.9803 24.3675 15.0355 24.5302C15.1656 24.8502 15.4891 25.0532 15.8397 25.0348Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <path
          d="M16.2611 16.1677C16.3889 16.0531 16.5579 15.9929 16.7308 16.0007C20.3875 16.1101 23.4435 18.7646 23.9974 22.3125C24.0009 22.3337 24.0009 22.3553 23.9974 22.3765C24.0093 22.5443 23.9526 22.71 23.8397 22.8367C23.7269 22.9634 23.5672 23.0408 23.396 23.0517L17.2525 23.4569C17.0493 23.4749 16.8478 23.408 16.6975 23.2727C16.5472 23.1374 16.4619 22.9462 16.4628 22.7461L16.0498 16.7115V16.6119C16.0573 16.4422 16.1334 16.2824 16.2611 16.1677Z"
          fill="#1F78FF"
        />
        <path
          d="M15.8397 25.0348L21.1646 24.6937L21.2009 24.7079C21.4295 24.7116 21.6473 24.8043 21.8063 24.9656C21.9653 25.1269 22.0524 25.3436 22.0485 25.5679C21.8387 28.6256 19.5969 31.1804 16.5462 31.8386C13.4955 32.4968 10.3681 31.1003 8.87023 28.4111C8.42977 27.6366 8.15144 26.7835 8.05156 25.902C8.01283 25.6408 7.99586 25.3769 8.00085 25.113C8.01095 21.8617 10.3258 19.0553 13.5649 18.3676C13.957 18.2942 14.3501 18.49 14.5212 18.8438C14.5638 18.908 14.5978 18.9773 14.6226 19.05C14.6833 19.9872 14.7463 20.9156 14.809 21.8401C14.8585 22.5702 14.9079 23.2979 14.9559 24.0255C14.9534 24.1969 14.9803 24.3675 15.0355 24.5302C15.1656 24.8502 15.4891 25.0532 15.8397 25.0348Z"
          fill="#1F78FF"
        />
      </>
    )}
  </svg>
);

export default PieChartIcon;
