import typography from './typography';

export const theme = ({ mode, palette }) => {
  const fontFamily = {
    inter: '"Inter", sans-serif',
    gilroyMedium: `"Gilroy-Medium", sans-serif`,
    gilroyBold: `"Gilroy-Bold", sans-serif`,
  };
  return {
    mode,
    palette: palette,
    typography: typography,
    fontFamily: fontFamily,
    boxShadow: palette.boxShadow,
    fontSize: {
      xs2: '0.625rem',
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.5rem',
      xl2: '2.25rem',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            color: 'red',
          },
        },
      },
      MuiStack: {
        defaultProps: {
          direction: 'row',
        },
      },

      MuiContainer: {
        defaultProps: {
          disableGutters: true,
          fixed: true,
          maxWidth: 'xl',
        },
        styleOverrides: {
          maxWidthXl: {
            maxWidth: '1024px',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: palette.black + '30',
          },
        },
      },

      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: palette.blue[500],
          },
        },
      },

      MuiButton: {
        defaultProps: {
          variant: 'text',
          disableRipple: true,
          disableTouchRipple: true,
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            fontFamily: '"Inter", sans-serif',
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.2,
            textTransform: 'none',
            letterSpacing: 'normal',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 16,
            padding: '0 16px',
            minHeight: 0,
            height: 56,
            minWidth: 0,
            outline: '0',
            border: '0',
            cursor: 'pointer',
            position: 'relative',

            '&.Mui-disabled': {
              cursor: 'auto',
              background: palette.gray[100],
              color: palette.gray[400],
              '&:hover': {
                background: palette.gray[100],
                color: palette.gray[400],
              },
            },
          },

          containedPrimary: {
            backgroundColor: palette.blue[600],
            color: '#fff',
            '&:hover': {
              background: palette.blue[601],
            },
          },

          containedSecondary: {
            color: mode === 'dark' ? 'white' : palette.blue[500],
            backgroundColor: palette.gray[100],
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: palette.gray[101],
            },
          },

          outlinedPrimary: {
            padding: '0 16px',
            color: palette.gray[600],
            backgroundColor: palette.background.default,
            border: `1px solid ${palette.gray[300]}`,
            '&:hover': {
              backgroundColor: palette.gray[101],
              border: `1px solid ${palette.gray[300]}`,
            },
          },

          outlinedSecondary: {
            backgroundColor: palette.blue[600],
            color: '#fff',
            '&:hover': {
              background: palette.blue[601],
            },
          },

          sizeLarge: {
            height: 64,
          },
          sizeSmall: {
            height: 48,
          },
          text: {
            '&:hover': {
              backgroundColor: 'inherit',
            },
          },
          startIcon: {
            paddingLeft: 4,
            display: 'flex',
            justifyContent: 'center',
          },

          endIcon: {},
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
          color: 'inherit',
        },
        styleOverrides: {
          root: {
            padding: '18px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          colorInherit: {
            color: '#8A99B6',
          },
          colorPrimary: {
            color: '#4B5B7A',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 48,
            height: 32,
            padding: 0,
            '& .MuiSwitch-thumb': {
              boxShadow: '0',
              backgroundColor: palette.background.palette,
              color: palette.common.white,
            },
          },
          switchBase: {
            padding: 0,
            transform: 'translateX(4px)',
          },

          thumb: {
            marginTop: 4,
            width: 24,
            height: 24,
            boxShadow: 'none',
            borderRadius: 32,
          },
          track: {
            borderRadius: 32,
            backgroundColor: palette.gray[300],
            opacity: '1 !important',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: palette.black,
          },
          defaultProps: {
            underline: 'none',
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variant: 'body1',
        },
        styleOverrides: {
          h1: {
            fontFamily: fontFamily.gilroyBold,
            color: palette.black,
          },
          h2: {
            fontSize: 24,
            lineHeight: '28px',
            fontFamily: fontFamily.gilroyBold,
            color: palette.black,
          },
          h4: {
            display: 'inline-flex',
            alignItems: 'center',
            color: palette.black,
          },
        },
      },
      MuiAccordion: {
        defaultProps: {
          square: true,
          disableGutters: true,
        },
        styleOverrides: {
          root: {
            '&:before': {
              display: 'none',
            },
            borderRadius: 16,
            boxShadow: 'none',
          },
          rounded: {
            borderRadius: 16,
            '&:last-child': {
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            },
            '&:first-child': {
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
          },
          content: {
            height: 56,
            margin: 0,
            alignItems: 'center',
          },
          expandIconWrapper: {
            padding: '0 16px',
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '24px 0',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: 16,
            backgroundColor: palette.gray[300],
            borderRadius: 16,
            fontSize: 16,
            lineHeight: '19px',
            fontWeight: 600,
            fontFamily: 'Inter',
            textAlign: 'center',
          },
          arrow: {
            color: palette.gray[300],
            '&:before': {
              borderRadius: '0 0 3px 0',
            },
          },
          tooltipPlacementTop: {
            '@media(min-width: 600px)': {
              margin: '8px 0',
            },
          },
        },
      },
      MuiToolbar: {
        defaultProps: { disableGutters: true },
        styleOverrides: {
          regular: {
            '@media (min-width: 600px)': {
              minHeight: '56px',
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'primary',
          disableRipple: true,
          disableTouchRipple: true,
        },
        styleOverrides: {
          colorPrimary: {
            '&:hover, &.Mui-checked:hover': {
              backgroundColor: 'inherit !important',
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {},
      },
      MuiTab: {
        defaultProps: { disableRipple: true, disableTouchRipple: true },
      },
      MuiFilledInput: {
        defaultProps: { disableUnderline: true },
        styleOverrides: {
          root: {
            borderRadius: 16,
            backgroundColor: palette.background.palette,
            '&:hover': {
              backgroundColor: palette.background.palette,
            },
            '&.Mui-focused': {
              backgroundColor: palette.background.palette,
            },
          },
          input: {
            padding: '18.5px 14px',
          },
        },
      },
      MuiTextField: {
        defaultProps: { autoComplete: 'off' },
        styleOverrides: {
          root: {
            height: 56,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: '#4B5B7A',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            margin: '0 auto',
            fontSize: '18px',
          },
          fontSizeInherit: {
            fontSize: '17px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            backgroundColor: palette.gray[100],
            transition: '0.3s',
            '&:hover': {
              backgroundColor: palette.gray[101],
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .Mui-disabled': {
              color: palette.gray[600],
              WebkitTextFillColor: palette.gray[600] + ' !important',
            },
            paddingRight: '0px !important',
          },

          notchedOutline: {
            borderColor: palette.gray[300],
            borderWidth: 0,
          },
          multiline: {
            minHeight: 120,
            padding: 20,
            alignItems: 'start',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: palette.gray[600],
            fontSize: 16,
            fontWeight: 600,
            lineHeight: '16px',
            letterSpacing: 'normal',
            border: '3px solid transparent',
            height: 56,
            '&.Mui-focused': {
              border: '3px solid #4993DD',
            },
            '&.Mui-error:not(.Mui-focused)': {
              border: '3px solid red',
            },
          },
          input: {
            '&::placeholder': {
              color: palette.gray[600],
              fontWeight: 600,
            },
          },
        },
      },
      MuiTreeItem: {
        styleOverrides: {
          root: {
            width: '100%',
            background: palette.gray[50],
            borderRadius: 16,
            marginTop: 8,
          },
          content: {
            padding: '0 16px',
            color: palette.gray[500],
            width: '100%',
            minHeight: 48,
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              '&.Mui-focused': {
                backgroundColor: 'transparent',
              },
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'transparent',
            },
            '&.Mui-focused': {
              backgroundColor: 'transparent',
            },
            ':hover': {
              backgroundColor: 'transparent',
            },
            ':focus': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiTreeView: {
        styleOverrides: {
          root: {
            minHeight: 56,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          endAdornment: {
            right: '16px !important',
          },
          tag: {
            margin: '0 4px',
          },
          input: {
            cursor: 'pointer',
          },
          inputRoot: {
            cursor: 'pointer',
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            marginTop: '0 !important',
            color: palette.gray[600],
          },
          positionEnd: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 56,
            height: '100%',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: palette.gray[100],
            height: 40,
            borderRadius: 12,
            fontSize: 16,
            fontWeight: 600,
            lineHeight: '19px',
            color: palette.gray[600],
            fontFamily: 'Inter',
          },
          deleteIcon: {
            width: 24,
            height: 24,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: palette.background.default,
          },
        },
      },

      MuiDrawer: {
        styleOverrides: {
          paper: {
            zIndex: 11,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: palette.background.default,
            zIndex: 11,
            '::-webkit-scrollbar-thumb': {
              width: '0 !important',
            },
          },
          container: {
            position: 'relative',
            backgroundColor: palette.black + '30',
            zIndex: 10,
          },
          h2: {
            display: 'flex',
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            height: 56,
            borderRadius: 16,
          },
        },
      },
    },
  };
};
