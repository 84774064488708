import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import {
  OrderCreatePage,
  NewOrderPage,
  AllOrdersPage,
  CashboxOperationsPage,
  CashShiftsPage,
  CashShiftHistoryPage,
  CashShiftDetailsPage,
} from 'pages';

const ordersRoutes = {
  path: 'order',
  element: <DashboardLayout />,
  children: [
    {
      path: 'create',
      element: <OrderCreatePage />,
    },
    {
      path: 'new-order',
      children: [{ path: ':id', element: <NewOrderPage /> }],
    },
    {
      path: 'all',
      element: <AllOrdersPage />,
    },
    {
      path: 'cash-shifts',
      children: [
        { path: '', element: <CashShiftsPage /> },
        { path: 'details/:id', element: <CashShiftDetailsPage /> },
        { path: 'history', element: <CashShiftHistoryPage /> },
      ],
    },
    {
      path: 'cashbox-operations',
      element: <CashboxOperationsPage />,
    },
    { path: '*', element: <Navigate to="/order/all" /> },
    { path: '', element: <Navigate to="/order/all" /> },
  ],
};
export default ordersRoutes;
