/* eslint-disable */
import { request } from '../axios';

const attributeValue = {
  getAll: id => request.get(`v2/attribute-value?attribute_id=${id}&limit=5000&page=1`),
  create: data => request.post(`v2/attribute-value`, data),
  getSingle: id => request.get(`v2/attribute-value/${id}`),
  update: data => request.put(`v2/attribute-value/${data.id}`, data.data),
  delete: id => request.delete(`v2/attribute-value/${id}`),
};

export default attributeValue;
