/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './shared/constants/locales/en/translation.json';
import translationRU from './shared/constants/locales/ru/translation.json';
import translationUZ from './shared/constants/locales/uz/translation.json';
import translationKA from './shared/constants/locales/ka/translation.json';
import translationKK from './shared/constants/locales/kk/translation.json';
import translationAZ from './shared/constants/locales/az/translation.json';
import translationAR from './shared/constants/locales/ar/translation.json';
import translationTR from './shared/constants/locales/tr/translation.json';
import translationURPK from './shared/constants/locales/ur-PK/translation.json';
import detector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: translationEN,
  },
  uz: {
    translation: translationUZ,
  },
  ru: {
    translation: translationRU,
  },
  ka: {
    translation: translationKA,
  },
  kk: {
    translation: translationKK,
  },
  az: {
    translation: translationAZ,
  },
  tr: {
    translation: translationTR,
  },
  ar: {
    translation: translationAR,
  },
  'ur-PK': {
    translation: translationURPK,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: ['ru', 'en'],
    // lng: 'uz',
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
