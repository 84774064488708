import { lazyLoad } from '../../../../shared/utils/loadable';

export const OperationsPage = lazyLoad(
  () => import('./OperationsPage'),
  module => module.default,
);

export const RoleOperationsPage = lazyLoad(
  () => import('./RoleOperationsPage'),
  module => module.default,
);

export const ShopOperationsPage = lazyLoad(
  () => import('./ShopOperationsPage'),
  module => module.default,
);
