/* eslint-disable */
import { request } from 'services/axios';
import qs from 'qs';

const smsMailing = {
  getAll: filter => request.get(`v1/sms-mailing${qs.stringify(filter, { addQueryPrefix: true })}`),
  getMailingCustomers: (id, filter) =>
    request.get(
      `v1/sms-mailing/${id}/clients${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getAddedCustomers: (id, filter) =>
    request.get(
      `v1/sms-mailing/${id}/clients-list-crm${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getSingle: id => request.get(`v1/sms-mailing/${id}`),
  create: data => request.post(`v1/sms-mailing`, data),
  addCustomers: data => request.post(`v2/sms-mailing/${data?.id}/add-customer`, data?.body),
  sendToModeration: data => request.post(`v2/sms-mailing/${data?.id}/send-to-moderation`, data?.body),
  stop: id => request.patch(`v1/sms-mailing/${id}/stop`),
  start: id => request.patch(`v1/sms-mailing/${id}/start`),
  delete: id => request.delete(`v1/sms-mailing/${id}`),
  createV2: data => (data?.id ? request.post(`v2/sms-mailing/${data?.id}`, data?.body) : request.post(`v2/sms-mailing`, data?.body)),
  addCustomer: data => request.post(`v2/sms-mailing/${data?.id}/add-customer`, data?.body),
  removeCustomer: data => request.post(`v2/sms-mailing/${data?.id}/remove-customer`, data?.body),
  getSingleClients: (id, filter) =>
    request.get(
      `v1/sms-mailing/${id}/clients${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getOperators: () => request.get(`v1/mobile-operators`),
  createNickname: data => request.post(`v1/nickname`, data),
  editNickname: data => request.put(`v1/nickname/${data?.id}`, data?.body),
  deleteNickname: id => request.delete(`v1/nickname/${id}`),
  getNicknames: filter =>
    request.get(
      `v1/nickname${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getNicknameDetails: id => request.get(`v1/nickname/${id}`),
};

export default smsMailing;
