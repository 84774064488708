/* eslint-disable */
const productEfficiencyTableHeaders = {
  settings: {
    showCategoriesInOneColumn: true,
  },
  columns: [
    {
      headerName: 'Характеристики товаров',
      field: 'product_characteristics',
      hide: false,
      initialWidth: 206,
      sortable: false,
      default_width: 206,
      marryChildren: true,
      lockPosition: 'left',
      suppressMovable: true,
      is_main: true,
      children: [
        {
          field: 'shop_name',
          headerName: 'Магазин',
          headerClass: 'product-efficiency',
          suppressMovable: true,
          lockPosition: 'left',
        },
        {
          field: 'name',
          headerName: 'Наименование',
          headerClass: 'product-efficiency',
          suppressMovable: true,
          lockPosition: 'left',
        },
        {
          field: 'sku',
          headerName: 'Артикул',
          headerClass: 'product-efficiency',
          suppressMovable: true,
          lockPosition: 'left',
        },
        {
          field: 'bar_code',
          headerName: 'Баркод',
          headerClass: 'product-efficiency',
          suppressMovable: true,
          lockPosition: 'left',
        },
        {
          field: 'category',
          headerName: 'Категория',
          headerClass: 'product-efficiency',
          suppressMovable: true,
          lockPosition: 'left',
        },
        {
          field: 'brand_name',
          headerName: 'Бренд',
          headerClass: 'product-efficiency',
          suppressMovable: true,
          lockPosition: 'left',
        },
        {
          field: 'supplier_name',
          headerName: 'Поставщики',
          headerClass: 'product-efficiency',
          suppressMovable: true,
          lockPosition: 'left',
        },
        {
          field: 'is_archived',
          headerName: 'Архивирован',
          headerClass: 'product-efficiency',
          suppressMovable: true,
          lockPosition: 'left',
        },
        // {
        //   field: 'color',
        //   headerName: 'Цвет',
        //   headerClass: 'product-efficiency',
        // },
        // {
        //   field: 'size',
        //   headerName: 'Размер',
        //   headerClass: 'product-efficiency',
        // },
      ],
    },
    {
      headerName: 'Остаток на начало периода',
      field: 'stock_begin',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'stock_amount_begin',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 158,
        },
        {
          field: 'stock_supply_sum_begin',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'stock_retail_sum_begin',
          headerName: 'Сумма по цене продажи',
          headerClass: 'product-efficiency',
        },
      ],
    },
    {
      headerName: 'Импорт товара',
      field: 'import',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'import_amount',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 300,
        },

        {
          field: 'import_supply_sum',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'import_retail_sum',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },

        // {
        //   field: 'import_supply_price',
        //   headerName: 'Цена поставки',
        //   headerClass: 'product-efficiency',
        // },
        // {
        //   field: 'import_retail_price',
        //   headerName: 'Цена продажи',
        //   headerClass: 'product-efficiency',
        // },
      ],
    },
    {
      headerName: 'Заказы',
      field: 'order',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'order_amount',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 300,
        },

        {
          field: 'order_supply_sum',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'order_retail_sum',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },

        // {
        //   field: 'order_supply_price',
        //   headerName: 'Цена поставки',
        //   headerClass: 'product-efficiency',
        // },
        // {
        //   field: 'order_retail_price',
        //   headerName: 'Цена продажи',
        //   headerClass: 'product-efficiency',
        // },
      ],
    },
    {
      headerName: 'Возврат заказов',
      field: 'supplier_order_return',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'supplier_order_return_amount',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 300,
        },

        {
          field: 'supplier_order_return_supply_sum',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'supplier_order_return_retail_sum',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },
      ],
    },
    {
      headerName: 'Входящий трансфер',
      field: 'inc_transfer',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'inc_transfer_amount',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 300,
        },

        {
          field: 'inc_transfer_supply_sum',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'inc_transfer_retail_sum',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },

        // {
        //   field: 'inc_transfer_supply_price',
        //   headerName: 'Цена поставки',
        //   headerClass: 'product-efficiency',
        // },

        // {
        //   field: 'inc_transfer_retail_price',
        //   headerName: 'Цена продажи',
        //   headerClass: 'product-efficiency',
        // },
      ],
    },
    {
      headerName: 'Возврат продажи',
      field: 'returned',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'returned_amount',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 300,
        },

        {
          field: 'returned_supply_sum',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'returned_retail_sum',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },
        {
          field: 'returned_sales_sum',
          headerName: 'Сумма по цене продажи c учетом скидки',
          headerClass: 'product-efficiency',
        },

        // {
        //   field: 'returned_supply_price',
        //   headerName: 'Цена поставки',
        //   headerClass: 'product-efficiency',
        // },
        // {
        //   field: 'returned_sales_price',
        //   headerName: 'Цена продажи',
        //   headerClass: 'product-efficiency',
        // },
        // {
        //   field: 'return_retail_price_total',
        //   headerName: 'Сумма продажи с учетом скидки',
        //   headerClass: 'product-efficiency',
        // },
      ],
    },
    {
      headerName: 'Продажи',
      field: 'sold',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'sold_amount',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 300,
        },

        {
          field: 'sold_supply_sum',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'sold_retail_sum',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },
        {
          field: 'sold_sales_sum',
          headerName: 'Сумма по цене продажи c учетом скидки',
          headerClass: 'product-efficiency',
        },

        // {
        //   field: 'sold_supply_price',
        //   headerName: 'Цена поставки',
        //   headerClass: 'product-efficiency',
        // },
        // {
        //   field: 'sold_sales_price',
        //   headerName: 'Цена продажи',
        //   headerClass: 'product-efficiency',
        // },
      ],
    },
    {
      headerName: 'Списания',
      field: 'write_off',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'write_off_amount',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 300,
        },

        {
          field: 'write_off_supply_sum',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'write_off_retail_sum',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },

        // {
        //   field: 'write_off_supply_price',
        //   headerName: 'Цена поставки',
        //   headerClass: 'product-efficiency',
        // },
        // {
        //   field: 'write_off_retail_price',
        //   headerName: 'Цена продажи',
        //   headerClass: 'product-efficiency',
        // },
      ],
    },
    {
      headerName: 'Исходящий трансфер',
      field: 'out_transfer',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'out_transfer_amount',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 300,
        },

        {
          field: 'out_transfer_supply_sum',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'out_transfer_retail_sum',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },

        // {
        //   field: 'out_transfer_supply_price',
        //   headerName: 'Цена поставки',
        //   headerClass: 'product-efficiency',
        // },
        // {
        //   field: 'out_transfer_retail_price',
        //   headerName: 'Цена продажи',
        //   headerClass: 'product-efficiency',
        // },
      ],
    },
    {
      headerName: 'Переоценка',
      field: 'repricing',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'repricing_amount',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 158,
        },
        {
          field: 'repricing_supply_sum',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'repricing_retail_sum',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },
      ],
    },
    {
      headerName: 'Остаток на конец периода',
      field: 'stock_end',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'stock_amount_end',
          headerName: 'Кол-во',
          headerClass: 'product-efficiency',
          width: 158,
        },

        {
          field: 'stock_supply_sum_end',
          headerName: 'Сумма по цене поставки',
          headerClass: 'product-efficiency',
          initialWidth: 250,
        },
        {
          field: 'stock_retail_sum_end',
          headerName: 'Сумма продажи',
          headerClass: 'product-efficiency',
        },
      ],
    },
    {
      headerName: 'Скорость продаж',
      field: 'sellout',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'qty_sellout',
          headerName: 'Кол-во',
          headerClass: 'qty_sellout',
          width: 158,
        },
        {
          field: 'sum_sellout',
          headerName: 'Сумма',
          headerClass: 'sum_sellout',
          initialWidth: 158,
        },
      ],
    },
    {
      headerName: 'Оборачиваемость остатка',
      field: 'sellout_qty',
      headerClass: 'group-cell',
      hide: false,
      minWidth: 70,
      sortable: false,
      default_width: 158,
      marryChildren: true,
      children: [
        {
          field: 'day_difference',
          headerName: 'Кол-во дней',
          headerClass: 'day_difference',
          width: 158,
        },
        {
          field: 'sellout_in_number',
          headerName: 'Скорость продаж',
          headerClass: 'sellout_in_number',
          width: 190,
        },
        {
          field: 'sellout_by_days',
          headerName: 'Оборачиваемость остатка в днях',
          headerClass: 'sellout_by_days',
          initialWidth: 350,
        },
      ],
    },
  ],
};

export default productEfficiencyTableHeaders;
