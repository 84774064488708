/* eslint-disable */
const SortIcon = props => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.5 12H8.5C8.21875 12 8 12.25 8 12.5V13.5C8 13.7812 8.21875 14 8.5 14H10.5C10.75 14 11 13.7812 11 13.5V12.5C11 12.25 10.75 12 10.5 12ZM6.5 10H5V0.5C5 0.25 4.75 0 4.5 0H3.5C3.21875 0 3 0.25 3 0.5V10H1.5C1.03125 10 0.8125 10.5625 1.125 10.875L3.625 13.875C3.71875 13.9688 3.84375 14 4 14C4.125 14 4.25 13.9688 4.34375 13.875L6.84375 10.875C7.15625 10.5625 6.9375 10 6.5 10ZM14.5 4H8.5C8.21875 4 8 4.25 8 4.5V5.5C8 5.78125 8.21875 6 8.5 6H14.5C14.75 6 15 5.78125 15 5.5V4.5C15 4.25 14.75 4 14.5 4ZM12.5 8H8.5C8.21875 8 8 8.25 8 8.5V9.5C8 9.78125 8.21875 10 8.5 10H12.5C12.75 10 13 9.78125 13 9.5V8.5C13 8.25 12.75 8 12.5 8ZM16.5 0H8.5C8.21875 0 8 0.25 8 0.5V1.5C8 1.78125 8.21875 2 8.5 2H16.5C16.75 2 17 1.78125 17 1.5V0.5C17 0.25 16.75 0 16.5 0Z"
      fill="#4993DD"
    />
  </svg>
);

export default SortIcon;
