import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import { CompaniesPage } from 'pages';

const adminRoutes = {
  path: 'admin',
  children: [
    { path: '', element: <Navigate to="/admin/companies" /> },
    {
      path: 'companies',
      children: [
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: '', element: <CompaniesPage /> }],
        },
      ],
    },
  ],
};

export default adminRoutes;
