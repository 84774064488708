/* eslint-disable */
import { request } from 'services/axios';
import qs from 'qs';

const applications = {
  getAll: filter => request.get(`v1/application${qs.stringify(filter, { addQueryPrefix: true })}`),
  getSingle: id => request.get(`v1/application/${id}`),
  activate: data => request.post('v1/application/activate', data),
  deactivate: data => request.post('v1/application/deactivate', data),
  edit: data => request.post('v1/application/edit', data),
  companyIsMarked: filter => request.get(`/v1/company-is-marked${qs.stringify(filter, { addQueryPrefix: true })}`),
  applicationRequest: data => request.post(`v1/application-request`, data),
  applicationStatus: id => request.get(`v1/application-request/${id}`),
  paymeAuth: data => request.post(`v1/payme-auth`, data),
  getPaymeCashboxes: () => request.get(`v1/payme-auth`),
  kaspiPayAuth: data => request.post('v1/kaspi/terminal', data),
  getKaspiPayTerminals: () => request.get('v1/kaspi/terminal'),
};

export default applications;
