import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: '0 32px',
  },
  title: {
    padding: '32px 32px 0 32px',
  },
}));
