/* eslint-disable */
const DeleteIconBig = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="24" height="24" rx="8" fill="#EB5757" />
    <path
      d="M13.8047 11.75L16.5391 9.01562C16.8945 8.6875 16.8945 8.14062 16.5391 7.8125L15.9375 7.21094C15.6094 6.85547 15.0625 6.85547 14.7344 7.21094L12 9.94531L9.23828 7.21094C8.91016 6.85547 8.36328 6.85547 8.03516 7.21094L7.43359 7.8125C7.07812 8.14062 7.07812 8.6875 7.43359 9.01562L10.168 11.75L7.43359 14.5117C7.07812 14.8398 7.07812 15.3867 7.43359 15.7148L8.03516 16.3164C8.36328 16.6719 8.91016 16.6719 9.23828 16.3164L12 13.582L14.7344 16.3164C15.0625 16.6719 15.6094 16.6719 15.9375 16.3164L16.5391 15.7148C16.8945 15.3867 16.8945 14.8398 16.5391 14.5117L13.8047 11.75Z"
      fill="white"
    />
  </svg>
);

export default DeleteIconBig;
