/* eslint-disable */
const productInventoryTableHeaders = {
  settings: {
    showCategoriesInOneColumn: true,
  },
  columns: [
    {
      headerName: 'ID Инвентаризации',
      field: 'external_id',
      is_main: true,
      lockPosition: 'left',
      suppressMovable: true,
      always_active: true,
      block: 'stocktaking_properties',
    },
    {
      headerName: 'Название инвентаризации',
      field: 'stocktaking_name',
      is_main: true,
      block: 'stocktaking_properties',
    },

    {
      headerName: 'Название магазина',
      field: 'shop_name',
      is_main: true,
      block: 'stocktaking_properties',
    },
    {
      headerName: 'Наименование',
      field: 'product_name',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Артикул',
      field: 'product_sku',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Баркод',
      field: 'product_barcode',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Единица измерения',
      field: 'product_measurement_unit',
      block: 'product_properties',
    },
    {
      headerName: 'Категория',
      field: 'product_categories',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Бренд',
      field: 'product_brand',
      is_main: true,
      block: 'product_properties',
    },
    // BACKEND IS NOT READY YET
    // {
    //   headerName: 'Поставщик',
    //   field: 'product_supplier',
    //   is_main: true,
    // },
    {
      headerName: 'Архивирован',
      field: 'product_is_archived',
      is_main: true,
      block: 'product_properties',
    },
    {
      headerName: 'Цена поставки',
      field: 'supply_price',
      block: 'product_properties',
    },
    {
      headerName: 'Цена продажи',
      field: 'retail_price',
      block: 'product_properties',
    },
    {
      headerName: 'Заявлено',
      field: 'expected_measurement_value',
      block: 'stocktaking_details',
    },
    {
      headerName: 'Проверено',
      field: 'scanned_measurement_value',
      block: 'stocktaking_details',
    },
    {
      headerName: 'Продано во время и-ци',
      field: 'sold_measurement_value',
      block: 'stocktaking_details',
      initialWidth: 300,
    },
    {
      headerName: 'Отложено во время и-ции',
      field: 'postponed_measurement_value',
      block: 'stocktaking_details',
      initialWidth: 300,
    },
    {
      headerName: 'Списано во время и-ции',
      field: 'written_off_measurement_value',
      block: 'stocktaking_details',
      initialWidth: 300,
    },
    {
      headerName: 'В трансфере во время и-ции',
      field: 'in_transfer_measurement_value',
      block: 'stocktaking_details',
      initialWidth: 300,
    },
    {
      headerName: 'Перемещено во время и-ции',
      field: 'transferred_measurement_value',
      block: 'stocktaking_details',
      initialWidth: 300,
    },
    {
      headerName: 'Автоимпорт в результате и-ции',
      field: 'imported_after_stocktaking_finish',
      block: 'stocktaking_results',
      initialWidth: 350,
    },
    {
      headerName: 'Автосписание в результате и-ции',
      field: 'written_off_after_stocktaking',
      block: 'stocktaking_results',
      initialWidth: 350,
    },
  ],
};

export default productInventoryTableHeaders;
