/* eslint-disable */
import { useTheme } from '@mui/material';
import useCompanyTariff from 'pages/settings/plan/api/useCompanyTariff';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useNavbarStatuses = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { data } = useCompanyTariff();

  const planName = data?.subscription_items?.find(item => item?.item_type === 'plan')?.display_name;

  const planStatus = useMemo(() => {
    if (!data)
      return {
        text: t('plan.navbar.active', { date: '-' }),
        color: palette.blue[500],
      };
    const plan_status = data?.info_state;
    const date = data?.info_next_date || data?.info_date || '-';

    if (plan_status === 'active') {
      return {
        text: t('plan.navbar.active', { date }),
        color: palette.blue[500],
      };
    }

    if (plan_status === 'in_trial') {
      return {
        text: t('plan.navbar.free_trial', {
          date,
        }),
        color: palette.blue[500],
      };
    }

    if (plan_status === 'overdue') {
      return {
        text: t('plan.navbar.debt', {
          date,
        }),
        color: palette.yellow[500],
      };
    }

    if (plan_status === 'limited_access') {
      return {
        text: t('plan.navbar.limited', {
          date,
        }),
        color: palette.red[500],
      };
    }

    if (plan_status === 'blocked') {
      return {
        text: t('plan.navbar.blocked', {
          date,
        }),
        color: palette.red[500],
      };
    }

    return {
      text: t('plan.navbar.active', { date }),
      color: palette.blue[500],
    };
  }, [t, data, palette]);

  return { planStatus, planName };
};

export default useNavbarStatuses;
