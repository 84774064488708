/* eslint-disable */
import { request } from 'services/axios';

const ePos = {
  addLabel: data => request.post('/v1/order/add-e-pos-label', data),
  getLabels: id => request.get(`/v2/order-labels?order_id=${id}`),
  deleteProductLabels: data => request.post('/v1/order/remove-e-pos-label', data),
  deleteAllLabels: data => request.post('/v1/order/remove-e-pos-label-all', data),
  addLog: data => request.post('/v1/order/add-e-pos-log?Billz-Response-Channel=HTTP', data),
  getLog: id => request.get(`/v2/order-epos-log?order_id=${id}`),
};

export default ePos;
