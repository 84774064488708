import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '0 32px',
    color: theme.palette.text.primary,
    fontWeight: 600,

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
