import { lazyLoad } from '../../../../shared/utils/loadable';

export const MassiveOperationsPage = lazyLoad(
  () => import('./MassiveOperationsPage'),
  module => module.default,
);

export const SmallLeftOperationsPage = lazyLoad(
  () => import('./SmallLeftOperationsPage'),
  module => module.default,
);

export const ProductPropertyOperationsPage = lazyLoad(
  () => import('./ProductPropertyOperationsPage'),
  module => module.default,
);

export const ProductPriceOperationsPage = lazyLoad(
  () => import('./ProductPriceOperationsPage'),
  module => module.default,
);

export const UploadPhotoOperationsPage = lazyLoad(
  () => import('./UploadPhotoOperationsPage'),
  module => module.default,
);

export const PrintPriceTagOperationsPage = lazyLoad(
  () => import('./PrintPriceTagOperationsPage'),
  module => module.default,
);

export const ProductScaleOperationsPage = lazyLoad(
  () => import('./ProductScaleOperationsPage'),
  module => module.default,
);

export const ProductMarkingOperationsPage = lazyLoad(
  () => import('./ProductMarkingOperationsPage'),
  module => module.default,
);
