/* eslint-disable */
const productImportTableHeaders = {
  settings: {
    showCategoriesInOneColumn: true,
  },
  columns: [
    {
      headerName: 'Дата импорта',
      field: 'date',
      hide: false,
      is_main: true,
      lockPosition: 'left',
      suppressMovable: true,
      always_active: true,
    },
    {
      headerName: 'ID',
      field: 'external_id',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Магазин',
      field: 'shop_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Наименование',
      field: 'product_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Баркод',
      field: 'product_barcode',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Артикул',
      field: 'product_sku',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Категория',
      field: 'product_categories',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Бренд',
      field: 'product_brand_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Кол-во импортированного товара',
      field: 'imported_qty',
      hide: false,
      initialWidth: 330,
    },
    {
      headerName: 'Сумма импорта по цене поставки',
      field: 'imported_supply_sum',
      hide: false,
      initialWidth: 330,
    },
    {
      headerName: 'Сумма импорта по цене продажи',
      field: 'imported_retail_sum',
      hide: false,
      initialWidth: 330,
      borderRight: true,
    },
    // {
    //   headerName: 'Расход кол-во',
    //   field: 'outcome',
    //   hide: false,
    // },
    {
      headerName: 'Продажи кол-во',
      field: 'sold_qty',
      hide: false,
    },
    {
      headerName: 'Продажи по сумме цены поставки',
      field: 'sold_supply_sum',
      hide: false,
      initialWidth: 330,
    },
    {
      headerName: 'Продажи по сумме цены продажи',
      field: 'sold_retail_sum',
      hide: false,
      borderRight: true,
      initialWidth: 330,
    },
    {
      headerName: 'Списания кол-во',
      field: 'written_off_qty',
      hide: false,
    },
    {
      headerName: 'Списания по сумме цены поставки',
      field: 'written_off_supply_sum',
      hide: false,
      initialWidth: 330,
    },
    {
      headerName: 'Списания по сумме цены продажи',
      field: 'written_off_retail_sum',
      hide: false,
      borderRight: true,
      initialWidth: 330,
    },
    {
      headerName: 'Отправленные трансферы кол-во',
      field: 'transfer_finished_qty',
      hide: false,
      initialWidth: 330,
    },
    {
      headerName: 'Сумма отправленных трансферов по цене поставки',
      field: 'transfer_finished_supply_sum',
      hide: false,
      initialWidth: 350,
    },
    {
      headerName: 'Сумма отправленных трансферов по цене продажи',
      field: 'transfer_finished_retail_sum',
      hide: false,
      borderRight: true,
      initialWidth: 350,
    },
    {
      headerName: 'Принятые трансферы кол-во',
      field: 'transfer_arrived_qty',
      hide: false,
      initialWidth: 330,
    },
    {
      headerName: 'Сумма принятых трансферов по цене поставки',
      field: 'transfer_arrived_supply_sum',
      hide: false,
      initialWidth: 350,
    },
    {
      headerName: 'Сумма принятых трансферов по цене продажи',
      field: 'transfer_arrived_retail_price',
      hide: false,
      borderRight: true,
      initialWidth: 350,
    },
    {
      headerName: 'Возвраты кол-во',
      field: 'returned',
      hide: false,
    },
    {
      headerName: 'Сумма возвратов по цене поставки',
      field: 'returned_supply_sum',
      hide: false,
      initialWidth: 350,
    },
    {
      headerName: 'Сумма возвратов по цене продажи',
      field: 'returned_retail_sum',
      hide: false,
      borderRight: true,
      initialWidth: 330,
    },
    {
      headerName: 'Возвраты заказа кол-во',
      field: 'return_supplier_order_qty',
      hide: false,
      initialWidth: 330,
    },
    {
      headerName: 'Сумма возвратов заказа по цене поставки',
      field: 'return_supplier_order_supply_sum',
      hide: false,
      initialWidth: 360,
    },
    {
      headerName: 'Сумма возвратов заказа по цене продажи',
      field: 'return_supplier_order_retail_sum',
      hide: false,
      borderRight: true,
      initialWidth: 360,
    },
    {
      headerName: 'Переоценка кол-во',
      field: 'repricing_qty',
      hide: false,
      initialWidth: 280,
    },
    {
      headerName: 'Сумма переоценок по цене поставки',
      field: 'repricing_supply_sum',
      hide: false,
      initialWidth: 350,
    },
    {
      headerName: 'Сумма переоценок по цене продажи',
      field: 'repricing_retail_sum',
      hide: false,
      borderRight: true,
      initialWidth: 350,
    },
    {
      headerName: 'Остаток кол-во',
      field: 'left_qty',
      hide: false,
    },
    {
      headerName: 'Сумма остатка по цене поставки',
      field: 'left_supply_sum',
      hide: false,
      initialWidth: 330,
    },
    {
      headerName: 'Сумма остатка по цене продажи',
      field: 'left_retail_sum',
      hide: false,
      initialWidth: 330,
    },
  ],
};

export default productImportTableHeaders;
