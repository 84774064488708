/* eslint-disable */
export const getRemainingTime = (time, t) => {
  if (!time || !Number.isFinite(time)) {
    return '';
  }
  if (time < 60) {
    return `${t('placeholders.remained_seconds')} ${Math.round(time)}`;
  }
  if (time < 3600) {
    return `${t('placeholders.remained_minutes')} ${Math.round(time / 60)}`;
  }
  if (time < 216000) {
    return `${t('placeholders.remained_hours')} ${Math.round(time / 60 / 24)}`;
  }
  return '';
};
