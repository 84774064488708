/* eslint-disable */
const sellerReportTableHeaders = {
  columns: [
    {
      headerName: 'Дата',
      field: 'date',
      hide: false,
      is_main: true,
      always_active: true,
    },
    {
      headerName: 'Магазин',
      field: 'shop_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Продавец',
      field: 'seller_name',
      hide: false,
      always_active: true,
      is_main: true,
    },
    {
      headerName: 'Выручка',
      field: 'gross_sales',
      hide: false,
    },
    {
      headerName: 'Сумма скидки',
      field: 'discount_sum',
      hide: false,
    },
    {
      headerName: '% cкидки',
      field: 'discount_percent',
      hide: false,
    },
    {
      headerName: 'Чистая выручка',
      field: 'net_gross_sales',
      hide: true,
    },
    {
      headerName: 'План по чистой выручке',
      field: 'target',
      hide: true,
    },
    {
      headerName: '% плана по чистой выручке',
      field: 'target_percent',
      hide: true,
    },
    {
      headerName: 'Валовая прибыль',
      field: 'net_gross_profit',
      hide: true,
    },
    {
      headerName: 'Продажи по цене поставки',
      field: 'net_sold_supply_sum',
      hide: true,
    },
    {
      headerName: 'Сумма возвратов',
      field: 'sum_returned',
      hide: true,
    },
    {
      headerName: 'Кол-во проданных товаров',
      field: 'sold_measurement_value',
      hide: true,
    },
    {
      headerName: 'Количество возвращенных товаров',
      field: 'returned_measurement_value',
      hide: true,
    },
    {
      headerName: 'Кол-во проданных с учетом возвратов',
      field: 'sold_without_return',
      hide: true,
    },
    {
      headerName: 'Количество транзакций',
      field: 'transaction_count',
      hide: true,
    },
    {
      headerName: 'Кол-во транзакций продаж',
      field: 'orders_count',
      hide: true,
    },
    {
      headerName: 'Средний чек',
      field: 'average_cheque',
      hide: true,
    },
    {
      headerName: 'Ср. кол-во продуктов',
      field: 'average_measurement_value',
      hide: true,
    },
    {
      headerName: 'Ср. цена продуктов',
      field: 'average_price',
      hide: true,
    },
  ],
};

export default sellerReportTableHeaders;
