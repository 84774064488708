/* eslint-disable */
const SidebarIcon = props => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.0625 6.5C0.78125 6.78125 0.78125 7.25 1.0625 7.53125L7.125 13.625C7.4375 13.9062 7.90625 13.9062 8.1875 13.625L8.90625 12.9062C9.1875 12.625 9.1875 12.1562 8.90625 11.8438L4.09375 7L8.90625 2.1875C9.1875 1.875 9.1875 1.40625 8.90625 1.125L8.1875 0.40625C7.90625 0.125 7.4375 0.125 7.125 0.40625L1.0625 6.5ZM7.0625 6.5C6.78125 6.78125 6.78125 7.25 7.0625 7.53125L13.125 13.625C13.4375 13.9062 13.9062 13.9062 14.1875 13.625L14.9062 12.9062C15.1875 12.625 15.1875 12.1562 14.9062 11.8438L10.0938 7L14.9062 2.1875C15.1875 1.875 15.1875 1.40625 14.9062 1.125L14.1875 0.40625C13.9062 0.125 13.4375 0.125 13.125 0.40625L7.0625 6.5Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default SidebarIcon;
