/* eslint-disable */
import { backgroundProgressActionTypes } from '../actions/backgroundProgressActions/backgroundProgressActionTypes';

const initialState = [
  // {
  //   correlation_id: null,
  //   isLoading: false,
  //   name: '',
  //   id: '',
  //   slug: 'import',
  //   DebtsSMSPage: 0
  // },
];

const backgroundProgressReducer = (state = initialState, action) => {
  const { payload } = action;

  const otherProgresses = state.filter(el => el.slug !== payload?.slug) || [];
  const filteredProgresses = state.filter(el => el.slug === payload?.slug) || [];
  const fountProgress = state.find(el => el.correlation_id === payload?.correlation_id);

  // detecting DebtsSMSPage order
  // 1. by correlation_id, if it is given
  // 2. DebtsSMSPage in payload
  // 3. last DebtsSMSPage in same type
  const index = fountProgress?.index || payload?.index || filteredProgresses?.length ? filteredProgresses.length - 1 : 0;
  const updatedProgresses =
    filteredProgresses?.length && index < filteredProgresses?.length
      ? filteredProgresses?.map(progress =>
          progress?.index === index || (progress.correlation_id === payload.correlation_id && payload.correlation_id)
            ? {
                ...progress,
                ...payload,
                slug: progress.slug,
              }
            : progress,
        )
      : [...filteredProgresses, payload];

  switch (action.type) {
    case backgroundProgressActionTypes.SET_BACKGROUND_PROGRESS:
      return [].concat(otherProgresses, updatedProgresses)?.filter(el => 'correlation_id' in el);
    case backgroundProgressActionTypes.REMOVE_BACKGROUND_PROGRESS:
      return state.filter(el => el.slug !== payload.slug && el?.index === index);
    case backgroundProgressActionTypes.CLEAR_BACKGROUND_PROGRESS:
      return [];
    default:
      return state;
  }
};
export function getBackgroundProgress(state, slug, id, index = 0) {
  return state.filter(el => el.slug === slug && (id ? el.correlation_id === id : true))?.[index] || {};
}
export function getBackgroundProgressStatistics({ state, slug, index = 0 }) {
  return {
    progress: state?.backgroundProgress?.filter(el => el.slug === slug)?.[index],
    count: state?.backgroundProgress?.filter(el => el.slug === slug)?.length,
    totalProgressCount: state?.backgroundProgress?.length,
  };
}
export default backgroundProgressReducer;
