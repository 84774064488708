/* eslint-disable */
import { faArrowRight, faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserShopSelectDrawer from 'layouts/Navbar/userShopSelectDrawer';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'stories/Button/Button';

const ChangeShop = ({ classes, userDetails, closeDrawer, refetchUserDetails }) => {
  const { t } = useTranslation();

  const [isOpenShopMenu, setIsOpenShopMenu] = React.useState(false);

  return (
    <>
      <Button
        secondary
        fullWidth
        adornmentEnd={<FontAwesomeIcon icon={faArrowRight} />}
        className={classes.actionBtn}
        contentClass={classes.actionBtnContent}
        onClick={() => setIsOpenShopMenu(true)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <FontAwesomeIcon icon={faStore} />
          {t('menu.profile.store_change')}
        </div>
      </Button>
      <UserShopSelectDrawer
        isOpen={isOpenShopMenu}
        userDetails={userDetails}
        onClose={() => {
          setIsOpenShopMenu(false);
          closeDrawer();
        }}
        onBackClick={() => setIsOpenShopMenu(false)}
        refetch={refetchUserDetails}
      />
    </>
  );
};

export default ChangeShop;
