import { lazyLoad } from '../../../shared/utils/loadable';

export const PromoCodesPage = lazyLoad(
  () => import('./PromoCodesPage'),
  module => module.default,
);

export const PromoCodesOnboardingPage = lazyLoad(
  () => import('./PromoCodesOnboardingPage'),
  module => module.default,
);
