/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const role = {
  getAll: filter => request.get(`v2/role${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v2/role`, data),
  getRole: id => request.get(`v2/role/${id}`),
  getRolePermissions: id => request.get(`v2/role/${id}/permissions`),
  updateRolePermissions: data => request.put(`v2/role/${data.id}/permissions`, data.data),
  update: data => request.put(`v2/role/${data.id}`, data.data),
  delete: id => request.delete(`v2/role/${id}`),
  bulkUpdateRoles: data => request.patch('v1/role/bulk-update', data),
};

export default role;
