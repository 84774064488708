/* eslint-disable */
import { Box, Drawer, Typography } from '@mui/material';
import Button from 'stories/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { removeUserId } from 'store/actions/companyActions/companyActions';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { clearLocalStorageItems } from 'shared/utils/clearLocalStorageItems';

const useStyles = makeStyles(theme => ({
  drawer: {
    '& .MuiDrawer-paper': {
      width: '30vw',
      padding: '64px 32px',
      borderRadius: '0 64px 64px 0',
      backgroundColor: theme.palette.background.default,
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
  },
  body: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: '28px',
    color: theme.palette.black,
  },
  shopBtn: {
    marginBottom: 8,
    color: theme.palette.gray[600],
    '& svg': {
      display: 'none',
    },
  },
  red: {
    color: theme.palette.red[500],
  },
  gray: {
    color: theme.palette.gray[600],
  },
  backBtn: {
    width: 48,
    height: 48,
  },
  warningIcon: {
    marginBottom: 24,
    color: theme.palette.red[500],
    fontSize: 36,
  },
}));

export default function UserLogOutDrawer({ isOpen, closeDrawer, goBack }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const logout = () => {
    dispatch(removeUserId());
    clearLocalStorageItems();
    sessionStorage.clear();
    window.location.replace('/login');
  };

  return (
    <>
      <Drawer open={isOpen} onClose={closeDrawer} anchor="left" elevation={1} className={classes.drawer}>
        <Box className={classes.wrapper}>
          <Box className={classes.body}>
            <Box width="100%" display="flex" alignItems="center">
              <Box circle onClick={goBack} className={classes.backBtn}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Box>
              <Box ml={2}>
                <Typography variant="h1" className={classes.title}>
                  {t('menu.profile.user_logout_drawer.account_exit')}
                </Typography>
              </Box>
            </Box>
            <Box textAlign="center" px={8}>
              <Box className={classes.warningIcon}>
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Box>
              <Typography>{t('menu.profile.user_logout_drawer.exit_msg')}</Typography>
            </Box>
            <Box>
              <Button secondary fullWidth className={classes.red} onClick={logout}>
                {t('menu.profile.user_logout_drawer.affirmative')}
              </Button>
              <Box pb={2} />
              <Button secondary fullWidth onClick={goBack} className={classes.gray}>
                {t('menu.profile.user_logout_drawer.cancel_btn')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
