import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  label: {
    marginBottom: 16,
    color: theme.palette.text.primary,
  },

  input: {
    width: '100%',
    backgroundColor: theme.palette.background.gray,
    color: theme.palette.text.primary,
    borderRadius: 16,
    padding: 16,

    '&:focus': {
      outline: 'none',
    },

    '&:placeholder': {
      color: theme.palette.text.secondary,
    },
  },
}));
