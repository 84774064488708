/* eslint-disable */
import { inventoryScanTableHeaderActionTypes } from '../actions/inventoryScanTableHeaderActions/inventoryScanTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'Заявлено',
      accessor: 'declared',
      is_active: true,
    },
    {
      Header: 'Проверено',
      accessor: 'scanned',
      is_active: true,
    },
    {
      Header: 'Движение товара',
      accessor: 'product_activities',
      is_active: true,
    },
  ],
};

const inventoryScanTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case inventoryScanTableHeaderActionTypes.UPDATE_INVENTORY_SCAN_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case inventoryScanTableHeaderActionTypes.RESET_INVENTORY_SCAN_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default inventoryScanTableHeaderReducer;
