const productTransferTableHeaders = {
  columns: [
    {
      headerName: 'Наименование',
      field: 'product_name',
      hide: false,
      is_main: true,
      always_active: true,
      suppressMovable: true,
      lockPosition: 'left',
    },
    {
      headerName: 'Трансфер',
      field: 'transfer_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Дата и время отправки',
      field: 'dispatched_date',
      hide: false,
      is_main: false,
    },
    {
      headerName: 'Дата и время принятия',
      field: 'acception_date',
      hide: false,
      is_main: false,
    },
    {
      headerName: 'Артикул',
      field: 'product_sku',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Баркод',
      field: 'product_barcode',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Бренд',
      field: 'product_brand_name',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Поставщик',
      field: 'supplier_name',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Категория',
      field: 'product_categories',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Магазин отправки',
      field: 'dispatch_shop',
      hide: false,
      is_main: false,
    },
    {
      headerName: 'Магазин приема',
      field: 'receive_shop',
      hide: false,
      is_main: false,
    },
    {
      headerName: 'Кол-во отправленных',
      field: 'dispatched_amount',
      hide: false,
      is_main: true,
      always_active: true,
      suppressMovable: true,
    },
    {
      headerName: 'Кол-во принятых',
      field: 'received_amount',
      hide: false,
      is_main: true,
      always_active: true,
      suppressMovable: true,
    },
    {
      headerName: 'Сумма по цене поставки',
      field: 'delivery_price_sum',
      hide: false,
      is_main: true,
      always_active: true,
      suppressMovable: true,
    },
    {
      headerName: 'Сумма по цене продажи',
      field: 'sale_price_sum',
      hide: false,
      is_main: true,
      always_active: true,
      suppressMovable: true,
    },
  ],
};

export default productTransferTableHeaders;
