import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  circledBorder: {
    borderRadius: 32,
  },

  spaceBetweenCont: {
    display: 'flex',
    paddingRight: 0,
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  spaceNormal: {
    justifyContent: 'flex-start',
    paddingLeft: 0,
  },
  inline: {
    color: theme.palette.blue[600],
    minWidth: 0,
    width: 'auto !important',
    height: ({ height }) => height || 'auto',
    padding: 0,
    '&.MuiButton-label': {
      width: 'auto !important',
    },
  },

  isLoading: {
    pointerEvents: 'none',
    cursor: 'auto',
  },
  content: {
    display: 'flex',
    justifyContent: ({ align }) => align || 'center',
    textAlign: ({ align }) => align || 'center',
    alignItems: 'center',
    width: '100%',
    minWidth: 'fit-content',
    opacity: '1',
    transition: '0.3s',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },

  hide: {
    visibility: 'hidden',
    opacity: '0',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  circle: {
    width: '48px !important',
    height: 48,
    backgroundColor: theme.palette.gray[100],
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.gray[101],
    },
  },
  whiteDisabled: {
    backgroundColor: `#fff !important`,
    border: `1px solid ${theme.palette.gray[300]}`,
    color: theme.palette.gray[300],
  },
}));
export default useStyles;
