import axios, { AxiosRequestConfig } from 'axios';

import { uriPrefix } from '../config';
import { ApiRequest } from './ApiRequest';
import i18n from 'i18next';

export class ApiCaller {
  private _baseUrl: string;
  private headers: {
    'Accept-Language': string;
    Authorization: string;
    'platform-id': string;
  };

  constructor(_baseUrl: string) {
    this._baseUrl = uriPrefix + _baseUrl;
    this.headers = {
      'Accept-Language': i18n.language,
      Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`,
      'platform-id': import.meta.env.VITE_APP_PLATFORM_ID,
    };
  }

  get<T, D = unknown>(url: string, params?: D, config?: AxiosRequestConfig): ApiRequest<T> {
    const controller = new AbortController();
    const request = () =>
      axios.get<T>(this._baseUrl + url, {
        ...config,
        signal: controller.signal,
        headers: this.headers,
        params: params,
      });
    return new ApiRequest<T>(request, controller);
  }

  post<T, D = unknown>(url: string, body: D, config?: AxiosRequestConfig): ApiRequest<T> {
    const controller = new AbortController();
    const request = () =>
      axios.post<T>(this._baseUrl + url, body, {
        ...config,
        signal: controller.signal,
        headers: this.headers,
      });
    return new ApiRequest<T>(request, controller);
  }

  patch<T, D = unknown>(url: string, data: D, config?: AxiosRequestConfig): ApiRequest<T> {
    const controller = new AbortController();
    const request = () =>
      axios.patch<T>(this._baseUrl + url, data, {
        ...config,
        signal: controller.signal,
        headers: this.headers,
      });
    return new ApiRequest<T>(request, controller);
  }

  put<T, D = unknown>(url: string, data: D, config?: AxiosRequestConfig): ApiRequest<T> {
    const controller = new AbortController();
    const request = () =>
      axios.put<T>(this._baseUrl + url, data, {
        ...config,
        signal: controller.signal,
        headers: this.headers,
      });
    return new ApiRequest<T>(request, controller);
  }

  delete<T>(url: string, config?: AxiosRequestConfig): ApiRequest<T> {
    const controller = new AbortController();
    const request = () =>
      axios.delete<T>(this._baseUrl + url, {
        ...config,
        signal: controller.signal,
        headers: this.headers,
      });
    return new ApiRequest<T>(request, controller);
  }
}
