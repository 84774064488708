/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

interface InventoryTableHeaderState {
  columns: any[];
}

export const initialState: InventoryTableHeaderState = {
  columns: [
    {
      headerName: 'ID',
      field: 'id',
      hide: false,
      minWidth: 70,
      width: 129,
    },
    {
      headerName: 'Наименование',
      field: 'name',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Магазин',
      field: 'shop_name',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Кол-во',
      field: 'quantity',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Разница',
      field: 'difference',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Сумма разницы',
      field: 'amount_of_difference',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Тип',
      field: 'type',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Статус',
      field: 'status',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Создание',
      field: 'creation',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Завершение',
      field: 'completion',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Создал',
      field: 'created_by',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Завершил',
      field: 'finished_by',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Действие',
      field: 'actions',
      hide: false,
      minWidth: 70,
    },
  ],
};

const inventoryTableHeaderSlice = createSlice({
  name: 'inventoryTableHeader',
  initialState,
  reducers: {
    updateTable: (state, action) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    resetTable: () => {
      return initialState;
    },
  },
});

export const { updateTable, resetTable } = inventoryTableHeaderSlice.actions;
export default inventoryTableHeaderSlice.reducer;
