/* eslint-disable */
import { request } from '../axios';

const measurementUnit = {
  getAll: () => request.get(`v2/measurement-unit`),
  create: data => request.post(`v2/measurement-unit`, data),
  getSingle: id => request.get(`v2/measurement-unit/${id}`),
  update: data => request.put(`v2/measurement-unit/${data.id}`, data.data),
  delete: id => request.delete(`v2/measurement-unit/${id}`),
  defaultUnit: () => request.get(`v2/default-measurement-unit`),
};

export default measurementUnit;
