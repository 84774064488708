/* eslint-disable */
import { transferTableHeaderActionTypes } from '../actions/transferTableHeaderActions/transferTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      headerName: 'ID',
      field: 'id',
      hide: false,
      width: 110,
    },
    {
      headerName: 'Наименование',
      field: 'name',
      hide: false,
    },
    { headerName: 'В магазин', field: 'departure_shop', hide: false },
    { headerName: 'Из магазина', field: 'arrival_shop', hide: false },
    {
      headerName: 'Кол-во',
      field: 'total_measurement_value',
      hide: false,
      width: 110,
    },
    {
      headerName: 'Статус',
      field: 'status',
      hide: false,
      resizable: false,
    },
    {
      headerName: 'Создал',
      field: 'created_by',
      hide: false,
    },
    {
      headerName: 'Принял',
      field: 'accepted_by',
      hide: false,
    },
    {
      headerName: 'Сумма',
      field: 'retail_price',
      hide: false,
    },
    {
      headerName: 'Дата отправки',
      field: 'created_at',
      hide: false,
    },
    {
      headerName: 'Дата принятия',
      field: 'accepted_at',
      hide: false,
    },
    {
      headerName: '',
      field: 'action',
      hide: false,
      width: 87,
      resizable: false,
    },
  ],
};

const transferTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case transferTableHeaderActionTypes.UPDATE_TRANSFER_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case transferTableHeaderActionTypes.RESET_TRANSFER_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default transferTableHeaderReducer;
