import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDrawer-paper': {
      width: 384,
      padding: '64px 32px',
      borderRadius: '64px 0 0 64px',
      backgroundColor: theme.palette.background.default,
    },
  },
}));
