/* eslint-disable */
import DashboardLayout from 'layouts/DashboardLayout';
import CatalogLayout from 'layouts/CatalogLayout';

import { WclPage, WclSendPage, WclPreScoringPage } from 'pages';

const wclRoutes = {
  path: 'wcl',
  children: [
    {
      path: '',
      element: <DashboardLayout />,

      children: [
        {
          path: '',
          element: <WclPage />,
        },
      ],
    },
    {
      path: 'send',
      element: <CatalogLayout />,
      children: [{ path: '', element: <WclSendPage /> }],
    },
    {
      path: 'pre-scoring',
      element: <DashboardLayout />,
      children: [{ path: ':id', element: <WclPreScoringPage /> }],
    },
  ],
};

export default wclRoutes;
