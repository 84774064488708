/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LocationState {
  path: string;
  filter: string;
}

export const initialState: LocationState = {
  path: '',
  filter: '',
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    changeLocation: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { changeLocation } = locationSlice.actions;
export default locationSlice.reducer;
