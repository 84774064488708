/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const finance = {
  getAllCategories: filter => request.get(`v1/account${qs.stringify(filter, { addQueryPrefix: true })}`),
  getFilteredCategories: filter => request.get(`v1/used-account${qs.stringify(filter, { addQueryPrefix: true })}`),
  getSingleCategory: id => request.get(`v1/account/${id}`),
  createCategory: data => request.post(`v1/account`, data),
  updateCategory: data => request.put(`v1/account`, data),
  deleteCategory: id => request.delete(`v1/account/${id}`),
  recoverCategory: id => request.patch(`v1/account/recover/${id}`),
  mergeCategory: data => request.post(`v1/account/merge`, data),

  getAllCashboxOperations: filter => request.get(`v1/gl-transaction${qs.stringify(filter, { addQueryPrefix: true })}`),

  getAllCloseShifts: filter =>
    request.get(
      `v1/transactions/shift-close${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),

  singleGetCloseShift: id => request.get(`v1/transactions/shift-close/${id}`),
  transactionsStatistics: filter =>
    request.get(
      `v1/transactions/statistics${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  acceptCloseShift: data => request.post(`v1/shift-close/accept`, data),

  getAllTransfer: filter =>
    request.get(
      `v1/transactions/transfer${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),

  singleGetMovement: id => request.get(`v1/transactions/transfer/${id}`),
  acceptMovement: data => request.post(`v1/transactions/transfer/accept`, data),
  deleteMovement: id => request.delete(`v1/transactions/transfer/${id}`),
  updateMovement: ({ id, data }) => request.put(`v1/transactions/transfer/${id}`, data),

  getAllConversion: filter =>
    request.get(
      `v1/transactions/conversion${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  singleGetConversion: id => request.get(`v1/transactions/conversion/${id}`),
  deleteConversion: id => request.delete(`v1/transactions/conversion/${id}`),
  updateConversion: ({ id, data }) => request.put(`v1/transactions/conversion/${id}`, data),

  getAllBalances: filter =>
    request.get(
      `v1/balances${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  createOperation: data => request.post(`v1/gl-transaction`, data),
  createTransfer: data => request.post(`v1/transactions/transfer`, data),
  createConversion: data => request.post(`v1/transactions/conversion`, data),
  deleteOperation: id => request.delete(`v1/gl-transaction/${id}`),
  updateOperation: data => request.put(`v1/gl-transaction/${data?.id}`, data?.body),
  getOperation: id => request.get(`v1/gl-transaction/${id}`),
  getCashBoxBalance: filter => request.get(`v1/cashbox-balance${qs.stringify(filter, { addQueryPrefix: true })}`),

  // EXCEL REPORTS
  getExcelIncomeExpense: data => request.post(`v1/gl-transaction-excel`, data),

  getExcelCloseShifts: data => request.post(`v1/transactions/shift-close-excel`, data),

  getExcelMovements: data => request.post(`v1/transactions/transfer-excel`, data),

  getExcelConversion: data => request.post(`v1/transactions/conversion-excel`, data),

  getExcelCashboxHistory: data => request.post(`v1/cashbox-shift-excel`, data),

  getExcelState: data => request.post(`v1/balances-excel`, data),
};

export default finance;
