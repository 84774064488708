/* eslint-disable */
const WarningSmallIcon = () => (
  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.6719 20.6719L16.4219 1.125C15.5781 -0.328125 13.375 -0.375 12.5312 1.125L1.28125 20.6719C0.4375 22.125 1.51562 24 3.25 24H25.7031C27.4375 24 28.5156 22.1719 27.6719 20.6719ZM14.5 16.5938C15.6719 16.5938 16.6562 17.5781 16.6562 18.75C16.6562 19.9688 15.6719 20.9062 14.5 20.9062C13.2812 20.9062 12.3438 19.9688 12.3438 18.75C12.3438 17.5781 13.2812 16.5938 14.5 16.5938ZM12.4375 8.85938C12.3906 8.53125 12.6719 8.25 13 8.25H15.9531C16.2812 8.25 16.5625 8.53125 16.5156 8.85938L16.1875 15.2344C16.1406 15.5625 15.9062 15.75 15.625 15.75H13.3281C13.0469 15.75 12.8125 15.5625 12.7656 15.2344L12.4375 8.85938Z"
      fill="#F2C94C"
    />
  </svg>
);

export default WarningSmallIcon;
