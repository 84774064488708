/* eslint-disable */
import axios from 'axios';
import { createKaspiApiByIp } from 'services/axios/integrations';

const baseURL = 'https://j2z1q.wiremockapi.cloud';

const kaspi = {
  authorization: ({ ip, name }) => {
    // const baseURL = `http://${ip}:8080`
    return axios.get(`${baseURL}/v2/register`, {
      params: { name: `${name}-test` },
    });
  },
  payment: ({ ip, params }) => {
    // const baseURL = `http://${ip}:8080`
    return axios.get(`${baseURL}/v2/payment`, { params });
  },
  status: ({ ip, params }) => {
    // const baseURL = `http://${ip}:8080`
    return axios.get(`${baseURL}/v2/status`, { params });
  },
  refund: ({ ip, params }) => {
    // const baseURL = `http://${ip}:8080`
    return axios.get(`${baseURL}/v2/refund`, { params });
  },
};

export default kaspi;
