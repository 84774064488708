/* eslint-disable */
const clientPurchasesTableHeaders = {
  settings: {
    showCategoriesInOneColumn: true,
  },
  columns: [
    {
      headerName: 'Дата покупки',
      field: 'created_at',
      hide: false,
      is_main: true,
      lockPosition: 'left',
      suppressMovable: true,
      always_active: true,
    },
    {
      headerName: 'Магазин',
      field: 'shop_name',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Тип транзакции',
      field: 'order_type',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'ID транзакции',
      field: 'order_number',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Продавец',
      field: 'seller_name',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'ID клиента',
      field: 'customer_number',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'ФИО клиента',
      field: 'customer_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Телефон',
      field: 'phone_numbers',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Тэг',
      field: 'tags',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Группа',
      field: 'groups',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Наименование',
      field: 'product_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Артикул',
      field: 'product_sku',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Баркод',
      field: 'product_barcode',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Категория',
      field: 'product_categories',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Бренд',
      field: 'product_brand_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Выручка',
      field: 'gross_sales',
      hide: true,
    },
    {
      headerName: 'Чистая выручка',
      field: 'net_sales',
      hide: false,
    },
    {
      headerName: 'Продажи по себестоимости',
      field: 'net_sold_supply_sum',
      hide: true,
    },
    {
      headerName: 'Валовая прибыль',
      field: 'net_profit',
      hide: false,
    },
    {
      headerName: 'Кол-во проданных',
      field: 'sold_measurement_value',
      hide: true,
    },
    {
      headerName: 'Кол-во возвращенных',
      field: 'returned_measurement_value',
      hide: true,
    },
    {
      headerName: 'Средняя скидка',
      field: 'discount_percent',
      hide: false,
    },
  ],
};

export default clientPurchasesTableHeaders;
