/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const companyPaymentType = {
  getAll: filter => request.get(`v1/company-payment-type${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v1/company-payment-type`, data),
  getSingle: id => request.get(`v1/company-payment-type/${id}`),
  update: ({ id, data }) => request.put(`v1/company-payment-type/${id}`, data),
  delete: id => request.delete(`v1/company-payment-type/${id}`),
};

export default companyPaymentType;
