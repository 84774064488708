/* eslint-disable */
const checkIfAnyChildRouteHasAccess = (children, prevAccess) => {
  let access = prevAccess ?? false;
  children.forEach(child => {
    if (child?.children?.length) {
      checkIfAnyChildRouteHasAccess(child?.children, access);
    } else if ('is_active' in child && child?.is_active === true) {
      access = true;
    }
  });
  return access;
};

function recursiveChildFilter(permission, forbiddenRoutes) {
  if (permission.slug === 'order-assign-client-sms-auth' && permission?.is_active) {
    const [route = '', options = ''] = permission.route?.split('?');
    const slug = permission?.slug;
    forbiddenRoutes.push({
      route,
      options,
      slug,
    });
  }
  if (permission?.children?.length) {
    if ('is_active' in permission && permission?.is_active === false) {
      const hasChildAccess = checkIfAnyChildRouteHasAccess(permission?.children);
      if (!hasChildAccess) {
        const [route = '', options = ''] = permission.route?.split('?');
        const slug = permission?.slug;
        forbiddenRoutes.push({
          route,
          options,
          slug,
        });
      }
    }
    permission?.children.forEach(child => {
      recursiveChildFilter(child, forbiddenRoutes);
    });
  } else if ('is_active' in permission && permission?.is_active === false && permission.slug !== 'order-assign-client-sms-auth') {
    const [route = '', options = ''] = permission.route?.split('?');
    const slug = permission?.slug;
    forbiddenRoutes.push({
      route,
      options,
      slug,
    });
  }
}

export default function getDisallowedRoutes(rolePermissions) {
  const forbiddenRoutes = [];
  if (rolePermissions?.length) {
    rolePermissions?.forEach(permission => {
      recursiveChildFilter(permission, forbiddenRoutes);
    });
  } else {
    rolePermissions?.sections?.forEach(section => {
      section?.permissions?.forEach(permission => {
        recursiveChildFilter(permission, forbiddenRoutes);
      });
    });
  }

  return forbiddenRoutes;
}
