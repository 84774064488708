/* eslint-disable */
export default {
  body1: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
  },
  body2: {
    fontFamily: 'Gilroy-Bold, sans-serif',
    fontSize: '24px',
    lineHeight: '28px',
  },
  caption: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    color: '#BDBDBD',
    letterSpacing: 'normal',
  },
  h1: {
    fontFamily: 'Gilroy-Bold, sans-serif',
    fontWeight: 500,
    fontSize: 36,
    lineHeight: '42px',
    color: 'black',
  },
  h2: {
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600,
    fontSize: 29,
  },
  h3: {
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600,
    fontSize: 24,
  },
  h4: {
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '21px',
  },
  h5: {
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600,
    fontSize: 16,
  },
  h6: {
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '16px',
  },
  smallInfo: {
    fontFamily: '"Inter", sans-serif',
    fontWeight: 600,
    fontSize: 12,
  },
  overline: {
    fontWeight: 500,
  },
};
