/* eslint-disable */
import axios from 'axios';
import { request } from 'services/axios';

const baseURL = 'https://j2z1q.wiremockapi.cloud';

const ingenico = {
  payment: ({ port, data }) => {
    return axios.post(`http://localhost:${port}/ingenico/payment`, data);
  },
  addLog: data => request.post('/v1/order/add-ingenico-log', data),
  cancel: ({ port }) => {
    return axios.post(`http://localhost:${port}/ingenico/cancel-last`);
  },
};

export default ingenico;
