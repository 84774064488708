import React from 'react';
import { IconButton as IconButtonBase } from '@mui/material';
import { useStyles } from './IconButton.styles';
import clsx from 'clsx';
import { IconProps } from '../../../shared/types/icon.types';

type IconButtonProps = {
  icon: React.ComponentType<IconProps>;
  onClick: () => void;
  variant?: 'default' | 'info' | 'error';
  className?: string;
  disabled?: boolean;
};

/*
FIXME: создать все возможные размеры иконок и создать
 и поддержать их в компоненте Icon.
 Пробрасывать Icon и size в IconButton тоже.
* */
const IconButton = ({ icon, onClick, variant = 'default', className, ...props }: IconButtonProps) => {
  const classes = useStyles();
  const rootClassName = clsx(classes.root, classes[variant], className);

  return (
    <IconButtonBase className={rootClassName} onClick={onClick} {...props}>
      {React.createElement(icon)}
    </IconButtonBase>
  );
};

export default IconButton;
