/* eslint-disable */
import { calculateDiscountPercent } from 'shared/utils/calculateDiscountPercent';
import { calculateProductRounding } from 'shared/utils/calculateProductRounding';
import { createRounding } from 'shared/utils/createRounding';
import toFixed from 'shared/utils/toFixed';
import { toFixedWithoutZeros } from 'shared/utils/toFixedWithoutZeros';
import { cartActionTypes } from '../actions/cartActions/cartActionTypes';

const initialCartState = {
  cartProducts: [],
  cartItems: [],
  cartItemsByOrderId: {},
  promocodes: [],
  totalPrice: null,
  totalDiscount: null,
  discount: {
    unit: 'PERCENTAGE',
    value: 0,
  },
  orderPayments: [],
  returnPayments: [],
  rounding: {
    digit: 1,
    kind: '',
    subject: '',
  },
  sellers: [],
  activeSeller: null,
  returnItems: [],
  leftMeasurementValues: {},
  versionNumber: 0,
};

function addNewProductToCart(state, cartToAdd) {
  const { activeSeller, discount } = state;
  return [
    {
      ...cartToAdd,
      measurement_value: cartToAdd?.measurement_value ? cartToAdd?.measurement_value : 1,
      items_price: Number(toFixedWithoutZeros(cartToAdd.retail_price, 6)),
      price: cartToAdd.retail_price,
      discount_unit: discount.unit || cartToAdd?.discount_unit,
      discount_value: discount.value || cartToAdd?.discount_value,
      sellers: state?.sellers?.length ? (activeSeller ? [activeSeller] : state?.sellers) : [],
      sequence_number: state.cartProducts.length,
    },
    ...state.cartProducts,
  ];
}

function changeQuantity(cartItems, payload) {
  const newItems = cartItems.map(item => {
    if (item?.id === payload?.id && item?.version_number === payload?.version_number) {
      const stringValue = payload?.measurement_value ? `${payload?.measurement_value}` : '';
      const dotIncludes = stringValue ? stringValue?.includes('.') : false;
      item.measurement_value =
        payload?.precision && dotIncludes && payload?.measurement_value !== ''
          ? parseFloat(toFixed(Number(payload?.measurement_value), payload?.precision))
          : Number(payload?.measurement_value);
      item.items_price = toFixedWithoutZeros(item?.measurement_value * item.price, 6);
    }
    return item;
  });

  return newItems;
}

const removeDuplicates = arr => {
  const map = new Map();

  for (let i = arr.length - 1; i >= 0; i--) {
    const item = arr[i];
    if (!map.has(item.seller_id)) {
      map.set(item.seller_id, item);
    }
  }

  return Array.from(map.values()).reverse();
};

// function syncCartItemWithDatabase(state, payload) {
//   const items =
//     payload?.order_detail?.items ||
//     payload?.order_detail?.order_items ||
//     payload?.items
//
//   const copyCartItems: any[] = []
//
//   state.cartItems.forEach((item) => {
//     copyCartItems.push(item)
//   })
//
//   const modifiedItem = items?.find(
//     (el) => el?.product_id === payload?.left_measurement_value?.product_id
//   )
//
//   const indexInCart = copyCartItems.findIndex(
//     (el) => el?.id === modifiedItem?.product_id
//   )
//
//   const sellers =
//     modifiedItem?.sellers?.length > 0 && state?.sellers?.length > 0
//       ? modifiedItem?.sellers.map((obj1) =>
//           state.sellers.find((obj2) => obj2.seller_id === obj1.seller_id)
//         )
//       : modifiedItem?.sellers
//       ? modifiedItem?.sellers?.map((el) => ({
//           current_cashbox_id: payload.cashbox_id,
//           seller_name: el.seller.name,
//           seller_id: el.seller_id,
//           color: '',
//         }))
//       : []
//
//   const measurement_value = Math.abs(modifiedItem.measurement_value || 0)
//   const total_price =
//     Number(toFixedWithoutZeros(modifiedItem.total_price, 6)) || 0
//   const items_price =
//     Number(toFixedWithoutZeros(modifiedItem.price, 6)) * measurement_value || 0
//   const commonDiscount = modifiedItem.discount_amount
//     ? toFixedWithoutZeros(
//         (Math.abs(modifiedItem.discount_amount) / items_price || 1) * 100,
//         6
//       ) || 0
//     : 0
//
//   copyCartItems[indexInCart] = {
//     ...modifiedItem.product,
//     measurement_value,
//     total_price,
//     items_price,
//     price: Number(toFixedWithoutZeros(modifiedItem.price, 6)) || 0,
//     discount_unit: modifiedItem.discount_unit,
//     discount_value: modifiedItem.discount_value,
//     discount_percent: modifiedItem.discount_percent,
//     hasCommonDiscount: Number(commonDiscount),
//     version_number: 0,
//     wholeSaleEnabled: modifiedItem?.used_wholesale_price,
//     sellers,
//   }
//
//   return copyCartItems
// }

function syncCartWithDatabase(state, { data, isManual }) {
  const items = data?.order_detail?.items || data?.order_detail?.order_items || data?.items;

  const versionNumber = (data?.order_detail?.items || data?.order_version) + 1;

  const leftMeasurementValues = {
    ...state.leftMeasurementValues,
    [data?.left_measurement_value?.product_id]: data?.left_measurement_value?.left_measurement_value,
  };

  if (!!items?.length) {
    const allSellers: {
      current_cashbox_id: string;
      seller_name: string;
      seller_id: string;
      color: string;
    }[] = [];
    const cartItems = [];
    const returnItems = [];
    const cartItemsByOrderId = {};

    const promocodes = data.promo_codes || [];
    const totalPrice = data.order_detail?.total_price
      ? Number(toFixedWithoutZeros(data.order_detail.total_price, 6)) || 0
      : Number(toFixedWithoutZeros(data.total_price, 6)) || 0;

    // if (state.cartItems.length === items?.length && isManual) {
    //   const updatedItems = syncCartItemWithDatabase(state, data)
    //
    //   return {
    //     cartItems: updatedItems,
    //     totalPrice,
    //     versionNumber,
    //   }
    // }

    items?.forEach((item, index) => {
      // const sellers =
      //   item.sellers?.length > 0 && state?.sellers?.length > 0
      //     ? item.sellers.map((obj1) =>
      //         state.sellers.find((obj2) => obj2.seller_id === obj1.seller_id)
      //       )
      //     : item?.sellers
      //     ? item?.sellers?.map((el) => ({
      //         current_cashbox_id: data?.cashbox_id,
      //         seller_name: el.seller.name,
      //         seller_id: el.seller_id,
      //         color: '',
      //       }))
      //     : []

      const sellers = item?.sellers
        ? item?.sellers?.map(el => ({
            current_cashbox_id: data?.cashbox_id,
            seller_name: el.seller.name,
            seller_id: el.seller_id,
            color: '',
          }))
        : [];

      // if (item.sellers && state.sellers.length === 0) {
      if (item.sellers) {
        item.sellers.forEach(el =>
          allSellers.push({
            current_cashbox_id: data?.cashbox_id,
            seller_name: el.seller.name,
            seller_id: el.seller_id,
            color: '',
          }),
        );
      }

      const measurement_value = Math.abs(item.measurement_value || 0);
      const total_price = Number(toFixedWithoutZeros(item.total_price, 6)) || 0;
      const items_price = Number(toFixedWithoutZeros(item.price, 6)) * measurement_value || 0;
      const commonDiscount = item.discount_amount
        ? toFixedWithoutZeros((Math.abs(item.discount_amount) / items_price || 1) * 100, 6) || 0
        : 0;

      const cartItem = {
        ...item.product,
        marking_codes: item?.marking_codes,
        measurement_value,
        total_price,
        items_price,
        price: Number(toFixedWithoutZeros(item.price, 6)) || 0,
        discount_unit: item.discount_unit,
        discount_value: item.discount_value,
        discount_percent: item.discount_percent,
        hasCommonDiscount: Number(commonDiscount),
        version_number: `${item.product?.id}${index}`,
        sellers,
        wholeSaleEnabled: item?.used_wholesale_price,
        order_item_id: item?.id,
      };

      cartItem['leftMeasurementValue'] = leftMeasurementValues?.[item?.product_id];

      if (total_price <= 0 && measurement_value !== 0 && item.returned_measurement_value > 0) {
        returnItems.push({
          ...item,
          ...state.returnItems.find(el => el.product_id === item.product_id),
          marking_codes: item?.marking_codes,
          measurement_value,
          total_price,
          items_price,
          discount_unit: item.discount_unit,
          discount_value: item.discount_value,
        });
      } else if (total_price >= 0 && measurement_value !== 0) {
        cartItems.push(cartItem);
      }
    });

    const uniqueSellers = [...state.sellers, ...allSellers].filter((v, i, a) => a.findIndex(v2 => v2.seller_id === v.seller_id) === i);

    return {
      cartItems,
      returnItems,
      promocodes,
      // sellers: state.sellers.length === 0 ? uniqueSellers : state.sellers,
      sellers: uniqueSellers,
      totalPrice,
      leftMeasurementValues,
      versionNumber,
    };
  }

  if (items?.length === 0) {
    return {
      promocodes: data?.promo_codes || [],
      cartItems: [],
      returnItems: [],
      totalPrice: 0,
    };
  }
  return state;
}

function syncCartItemWithDatabaseNew(state, { data, isManual }) {
  const items = data?.order_products;

  if (isManual && items?.length > 0) {
    return {
      ...state,
      versionNumber: data?.version || 0,
    };
  }

  if (!!items?.length) {
    const cartItems = [];
    const returnItems = [];

    let totalPrice = 0;

    items?.forEach((item, index) => {
      const measurement_value = Math.abs(item.sold_measurement_value || item.returned_measurement_value || 0);
      const total_price = Number(toFixedWithoutZeros(item.price, 6)) * measurement_value || 0;
      totalPrice += total_price;

      const items_price = Number(toFixedWithoutZeros(item.price, 6)) * measurement_value || 0;

      const sellers = item?.sellers
        ? item?.sellers?.map(el => ({
            current_cashbox_id: data?.cashbox_id,
            seller_name: el.name,
            seller_id: item?.seller_ids?.[0],
            color: '',
          }))
        : [];

      const cartItem = {
        ...item.product,
        measurement_value,
        total_price,
        items_price,
        price: Number(toFixedWithoutZeros(item.price, 6)) || 0,
        hasCommonDiscount: 0,
        sellers,
        version_number: `${item.product?.id}${index}`,
        wholeSaleEnabled: item?.used_wholesale_price,
      };

      if (item.returned_measurement_value > 0) {
        returnItems.push({
          ...item,
          ...state.returnItems.find(el => el.product_id === item.product_id),
          measurement_value,
          total_price,
          items_price,
        });
      } else if (total_price >= 0 && measurement_value !== 0) {
        cartItems.push(cartItem);
      }
    });

    return {
      cartItems,
      returnItems,
      totalPrice,
      versionNumber: data?.version || 0,
    };
  }

  if (items?.length === 0) {
    return {
      cartItems: [],
      returnItems: [],
      totalPrice: 0,
      versionNumber: data?.version || 0,
    };
  }
  return state;
}

function setDiscount(cartItems, payload) {
  return cartItems.map(item => {
    if (item?.id === payload?.id) {
      item.discount_unit = payload.discount.unit;
      item.discount_value = parseFloat(payload.discount.value).toFixed(6) * 1 || 0;
      item.items_price = (item.measurement_value * item.price).toFixed(6) * 1;
      return item;
    }
    return item;
  });
}

export const cartTotalPriceCalculator = (cartItems, returnItems) => {
  const returns_items_price = returnItems.reduce((total, cartItem) => total + Number(cartItem.total_price), 0);
  const cart_items_price = cartItems.reduce((total, cartItem) => total + Number(cartItem.total_price), 0);
  const result = cart_items_price - returns_items_price;
  return result;
};

function calculateTotalPrice(cartItems, rounding) {
  const newItems = cartItems?.map(item => {
    if (item?.discount_percent > 0) {
      if (item.discount_unit === 'PERCENTAGE') {
        let total_price = item.items_price - (item.items_price * item.discount_value) / 100;
        if (rounding?.subject !== 'product') {
          total_price = total_price?.toFixed(6) * 1;
        }
        item.total_price = calculateProductRounding(rounding, total_price);
      }

      if (item.discount_unit === 'CURRENCY') {
        let total_price = item.discount_value * item?.measurement_value;
        if (rounding?.subject === 'cheque') total_price = total_price?.toFixed(6) * 1;

        item.total_price = calculateProductRounding(rounding, total_price);
      }
    } else {
      if (item?.returnType) {
        item.total_price = item?.total_price?.toFixed(6) * 1;
      } else {
        item.total_price = (item.measurement_value * item.price)?.toFixed(6);
      }
    }
    delete item.retail_price;
    delete item.category_name;
    delete item.description;
    return item;
  });
  return newItems;
}

function changeOrderAmount(orderPayments, payload) {
  const newItems = orderPayments.map(item => {
    if (item?.id === payload?.id) {
      item.paid_amount = payload.paid_amount;
    }
    return item;
  });
  return newItems;
}

function setManualDiscountValue(cartItems, payload) {
  const discount = calculateDiscountPercent(cartItems, payload);
  const newItems = cartItems.map(item => ({
    ...item,
    total_price: (item.items_price - (item.items_price * discount.discount_value) / 100).toFixed(6),
    discount_unit: discount.discount_unit,
    discount_value: parseFloat(discount.discount_value),
  }));
  return newItems;
}

function addSellerToCart(state, payload) {
  const cartProducts = state.cartProducts.map(item => {
    if (item?.sellers?.length === 0) {
      return {
        ...item,
        sellers: [payload],
      };
    }
    return item;
  });
  return cartProducts;
}
function transferItems(state, payload) {
  const { item, toSeller } = payload;
  return state.cartItems.map(cartItem =>
    cartItem?.id === item?.id
      ? {
          ...cartItem,
          sellers: [toSeller],
        }
      : cartItem,
  );
}

function addNewProductToReturnsCart(state, cartToAdd) {
  const isInCart = state.returnItems.find(returnItem => returnItem?.id === cartToAdd?.id);

  if (isInCart) {
    return state.returnItems.map(returnItem =>
      returnItem?.id === cartToAdd?.id && returnItem?.measurement_value !== cartToAdd?.total_measurement_value
        ? {
            ...returnItem,
            measurement_value: returnItem?.measurement_value + 1,
            items_price: (returnItem?.measurement_value + 1) * returnItem.price,
          }
        : returnItem,
    );
  }

  return [
    ...state.returnItems,
    {
      ...cartToAdd,
      measurement_value: cartToAdd?.measurement_value,
      price: cartToAdd.retail_price,
      discount_unit: state.discount?.apply ? state.discount.unit : cartToAdd?.discount_unit,
      discount_value: state.discount?.apply ? state.discount.value : cartToAdd?.discount_value,
      sequence_number: state.returnItems.length,
    },
  ];
}

const cartReducer = (state = initialCartState, action) => {
  const { payload } = action;
  switch (action.type) {
    case cartActionTypes.ADD_TO_CART:
      const isInCart = state.cartProducts.find(cartProduct => cartProduct?.id === payload?.id);

      if (isInCart) {
        return state;
      }

      return {
        ...state,
        cartProducts: addNewProductToCart(state, payload),
      };
    case cartActionTypes.REMOVE_FROM_CART: {
      if (payload.type === 'cartItem') {
        const filteredCartItems = state.cartItems.filter((cartItem: any) => cartItem?.id !== payload?.id);
        return {
          ...state,
          cartItems: filteredCartItems,
          totalPrice: cartTotalPriceCalculator(filteredCartItems, state.returnItems),
        };
      }
      return {
        ...state,
        cartProducts: state.cartProducts.filter((cartItem: any) => cartItem?.id !== payload?.id),
      };
    }
    case cartActionTypes.CLEAR_CART: {
      if (payload === 'cartItems') {
        return {
          ...state,
          cartItems: [],
          cartItemsByOrderId: {},
          promocodes: [],
          discount: {
            unit: state.discount?.unit || 'PERCENTAGE',
            value: 0,
          },
          orderPayments: [],
          returnPayments: [],
          sellers: [],
          activeSeller: null,
          returnItems: [],
          totalPrice: 0,
          leftMeasurementValues: {},
        };
      }

      return {
        ...state,
        cartItems: [],
        cartItemsByOrderId: {},
        cartProducts: [],
        promocodes: [],
        discount: {
          unit: state.discount?.unit || 'PERCENTAGE',
          value: 0,
        },
        orderPayments: [],
        returnPayments: [],
        sellers: [],
        activeSeller: null,
        returnItems: [],
        totalPrice: 0,
        leftMeasurementValues: {},
      };
    }
    case cartActionTypes.SYNC_CART_WITH_DATABASE_NEW:
      if (
        payload.data?.order_bill_version === payload.data?.order_version &&
        payload.data?.order_bill_version > 0 &&
        state.versionNumber === payload.data?.order_version
      ) {
        return {
          ...state,
          ...syncCartWithDatabase(state, payload),
        };
      }

      return {
        ...state,
        ...syncCartItemWithDatabaseNew(state, payload),
      };
    case cartActionTypes.SYNC_CART_WITH_DATABASE:
      const updatedState = syncCartWithDatabase(state, payload);

      return {
        ...state,
        ...updatedState,
      };
    case cartActionTypes.CHANGE_CART_ITEM_QUANTITY:
      return {
        ...state,
        cartItems: calculateTotalPrice(changeQuantity(state.cartItems, payload), state.rounding),
        totalPrice: cartTotalPriceCalculator(
          calculateTotalPrice(changeQuantity(state.cartItems, payload), state.rounding),
          state.returnItems,
        ),
      };
    case cartActionTypes.SET_CART_ITEM_DISCOUNT:
      return {
        ...state,
        cartItems: calculateTotalPrice(setDiscount(state.cartItems, payload), state.rounding),
      };
    case cartActionTypes.SET_MANUAL_DISCOUNT:
      return {
        ...state,
        discount: {
          unit: payload.discount_unit || state.discount.unit,
          value: payload.discount_value,
        },
      };
    case cartActionTypes.ADD_TO_ORDER_PAYMENT:
      return {
        ...state,
        orderPayments: [...state.orderPayments, payload],
      };
    case cartActionTypes.ADD_TO_RETURN_PAYMENT:
      return {
        ...state,
        returnPayments: payload,
      };
    case cartActionTypes.REMOVE_FROM_ORDER_PAYMENT:
      return {
        ...state,
        orderPayments: state.orderPayments.filter(orderItem => orderItem?.id !== payload?.id),
      };
    case cartActionTypes.REMOVE_ALL_FROM_ORDER_PAYMENT:
      return {
        ...state,
        orderPayments: [],
      };
    case cartActionTypes.REMOVE_ALL_FROM_RETURN_PAYMENT:
      return {
        ...state,
        returnPayments: [],
      };
    case cartActionTypes.SET_ORDER_AMOUNT:
      return {
        ...state,
        orderPayments: changeOrderAmount(state.orderPayments, payload),
      };
    case cartActionTypes.SET_ACTIVE_ROUNDING:
      return {
        ...state,
        rounding: createRounding(payload),
      };
    case cartActionTypes.ADD_SELLER:
      return {
        ...state,
        sellers: removeDuplicates([...state.sellers, payload]),
        activeSeller: payload,
      };
    case cartActionTypes.REMOVE_SELLER:
      return {
        ...state,
        sellers: state.sellers.filter(seller => seller.seller_id !== payload.seller_id),
        activeSeller: null,
      };
    case cartActionTypes.SET_ACTIVE_SELLER:
      return {
        ...state,
        activeSeller: payload,
      };
    case cartActionTypes.TRANSFER_SELLER_ITEMS:
      return {
        ...state,
        cartItems: transferItems(state, payload),
        sellers: state.sellers.filter(seller => seller.seller_id !== payload.fromSeller.seller_id),
        activeSeller: null,
      };
    case cartActionTypes.ADD_TO_RETURNS:
      const items = calculateTotalPrice(addNewProductToReturnsCart(state, payload), state.rounding);
      return {
        ...state,
        returnItems: items,
        cartItems: [],
        cartItemsByOrderId: {},
        totalPrice: cartTotalPriceCalculator([], items),
      };
    case cartActionTypes.REMOVE_FROM_RETURNS:
      return {
        ...state,
        returnItems: state.returnItems.filter(returnItem => returnItem?.id !== payload?.id),
        totalPrice: cartTotalPriceCalculator(
          state.cartItems,
          state.returnItems.filter(returnItem => returnItem?.id !== payload?.id),
        ),
      };
    case cartActionTypes.CHANGE_RETURN_ITEM_QUANTITY:
      return {
        ...state,
        returnItems: calculateTotalPrice(changeQuantity(state.returnItems, payload), state.rounding),
        totalPrice: cartTotalPriceCalculator(
          state.cartItems,
          calculateTotalPrice(changeQuantity(state.returnItems, payload), state.rounding),
        ),
      };
    default:
      return state;
  }
};
export default cartReducer;
