/* eslint-disable */
import { transferSingleTableHeaderActionTypes } from '../actions/transferSingleTableHeaderActions/transferSingleTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'Категория',
      accessor: 'category',
      is_active: true,
    },
    {
      Header: 'Цена поставки',
      accessor: 'supply_price',
      is_active: true,
    },
    { Header: 'Цена продажи', accessor: 'price', is_active: true },
    { Header: 'Отправлено', accessor: 'departured', is_active: true },
    // { Header: 'Принято', accessor: 'accepted', is_active: true },
  ],
};

const transferSingleTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case transferSingleTableHeaderActionTypes.UPDATE_TRANSFER_SINGLE_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case transferSingleTableHeaderActionTypes.RESET_TRANSFER_SINGLE_TABLE_HEADER:
      return initialState;
    case transferSingleTableHeaderActionTypes.REMOVE_CUSTOM_COLUMN:
      const filteredColumns = [...state?.columns].filter(column => column?.accessor !== payload);
      return {
        ...state,
        columns: filteredColumns,
      };
    case transferSingleTableHeaderActionTypes.UPDATE_CUSTOM_COLUMN:
      const newColumns = [...state?.columns].map(column => {
        return column?.accessor === payload?.id ? { ...column, Header: payload?.name } : column;
      });
      return {
        ...state,
        columns: newColumns,
      };

    default:
      return state;
  }
};

export default transferSingleTableHeaderReducer;
