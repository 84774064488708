/* eslint-disable */
const BigWarningCircleIcon = props => (
  <svg width="36" height="35" viewBox="0 0 36 35" fill="#EB5757" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M35.4375 17.5C35.4375 7.9375 27.5625 0.0625 18 0.0625C8.36719 0.0625 0.5625 7.9375 0.5625 17.5C0.5625 27.1328 8.36719 34.9375 18 34.9375C27.5625 34.9375 35.4375 27.1328 35.4375 17.5ZM18 21.0156C19.7578 21.0156 21.2344 22.4922 21.2344 24.25C21.2344 26.0781 19.7578 27.4844 18 27.4844C16.1719 27.4844 14.7656 26.0781 14.7656 24.25C14.7656 22.4922 16.1719 21.0156 18 21.0156ZM14.9062 9.41406C14.8359 8.92188 15.2578 8.5 15.75 8.5H20.1797C20.6719 8.5 21.0938 8.92188 21.0234 9.41406L20.5312 18.9766C20.4609 19.4688 20.1094 19.75 19.6875 19.75H16.2422C15.8203 19.75 15.4688 19.4688 15.3984 18.9766L14.9062 9.41406Z"
      fill="inherit"
    />
  </svg>
);

export default BigWarningCircleIcon;
