import { request } from '../axios';
import { IAttributes } from 'shared/types/product.types';
import * as qs from 'qs';

const attribute = {
  getAll: (filter): Promise<{ data: IAttributes }> => request.get(`v2/attribute${qs.stringify(filter, { addQueryPrefix: true })}`),
  getSingle: id => request.get(`v2/attribute/${id}`),
  create: ({ data, protocol }) => request.post(`v2/attribute${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  update: ({ id, data, protocol }) => request.put(`v2/attribute/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  delete: ({ id, protocol }) => request.delete(`v2/attribute/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  restore: ({ id, data, protocol }) => request.post(`v2/attribute-restore/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
};

export default attribute;
