/* eslint-disable */
import Switch from '@mui/material/Switch';

export default function StyledSwitch({ register, name, defaultValue, onChange, checked, id, ...props }) {
  return (
    <Switch
      id={id}
      onChange={onChange}
      defaultChecked={defaultValue}
      value={name}
      checked={checked}
      disableRipple
      {...register?.(name)}
      {...props}
    />
  );
}
