/* eslint-disable */
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import TickIcon from 'shared/icons/TickIcon';
import { generateCustomStyles } from 'stories/Select/SelectStyles';
import { useTranslation } from 'react-i18next';
import checkAccess from 'components/RouteAccess/checkAccess';
import DeleteSmallIcon from 'shared/icons/DeleteSmallIcon';
import DeleteIconBig from 'shared/icons/DeleteIconBig';
import colors from 'theme/mui.config';
import event from 'shared/utils/event';
import StyledTooltip from 'components/StyledTooltip';
import QuestionIcon from 'shared/icons/QuestionIcon';

const SingleValue = ({ children, selectProps, ...props }) => {
  return (
    <components.SingleValue selectProps={selectProps} {...props}>
      {selectProps?.withDesc && selectProps?.singleValueWithDesc ? (
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography>{children}</Typography>
          <Typography
            sx={{
              color: 'gray.400',
            }}
          >
            {props?.data?.desc}
          </Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {selectProps?.beforeContent && (
            <Typography style={{ color: colors.gray[400], marginRight: 2 }}>{selectProps?.beforeContent}</Typography>
          )}
          <span
            id={`select-${props?.data?.name}`}
            style={{
              lineHeight: 1.2,
              display: `-webkit-box`,
              maxHeight: `calc(16px * 1.2 * 1)`,
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: `hidden`,
              textOverflow: `ellipsis`,
            }}
          >
            {children}
          </span>
        </Box>
      )}
    </components.SingleValue>
  );
};

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '& > button': {
      background: 'transparent',
      border: 0,
      cursor: 'pointer',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 16,
      zIndex: 99,
    },
  },
  noLabel: {
    marginBottom: 0,
  },
  required: {
    '&::after': {
      content: '" *"',
      color: theme.palette.red[500],
    },
  },
  orderedLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    marginRight: 16,
    borderRadius: '50px',
    color: theme.palette.white,
    backgroundColor: theme.palette.blue[600],
  },
  lazyOption: {
    padding: '18px 16px',
    height: 'auto',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: '"Inter", sans-serif',
    alignItems: 'center',
    userSelect: 'none',
    fontWeight: 600,
    color: theme.palette.gray[600],

    '&:hover': {
      backgroundColor: theme.palette.gray[101],
    },
  },
  lazyOptionWithDesc: {
    padding: '18px 16px',
    height: 'auto',
    cursor: 'pointer',
    fontFamily: '"Inter", sans-serif',
    userSelect: 'none',
    fontWeight: 600,
    color: theme.palette.gray[600],
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.gray[101],
    },
  },
}));
const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <DeleteIconBig />
  </components.MultiValueRemove>
);

const CustomOption = props => {
  const cls = useStyles();

  return props?.selectProps?.withDesc ? (
    <div
      {...props.innerProps}
      ref={props?.selectProps?.lastElementRef}
      id={props?.label}
      className={cls.lazyOptionWithDesc}
      style={{
        borderBottom: props?.data?.borderBottom && '1px dashed #cfcfcf',
      }}
    >
      <div>
        <Typography>{props?.label}</Typography>
        <Typography
          sx={{
            color: props?.data?.descColor || 'gray.400',
          }}
        >
          {props?.data?.desc}
        </Typography>
      </div>
      {props?.data?.id === props?.selectProps?.value?.id && <TickIcon style={{ marginLeft: 12 }} />}
    </div>
  ) : (
    <div {...props.innerProps} ref={props?.selectProps?.lastElementRef} id={props?.label} className={cls.lazyOption}>
      {props?.label}
    </div>
  );
};

const OptionWithTickIcon = ({ children, ...props }) => (
  <components.Option {...props}>
    <Box display="flex" justifyContent="space-between" width="100%">
      <span>{children}</span>
      {props?.data?.id === props?.selectProps?.value?.id && <TickIcon style={{ marginLeft: 12 }} />}
    </Box>
  </components.Option>
);

function SelectSimple({
  id,
  withAllSelect,
  control,
  name,
  options,
  getOptionLabel = option => option.name,
  getOptionValue,
  defaultValue,
  boxStyle,
  label,
  placeholder = 'components.enterAttribute',
  uncontrolled,
  value = '',
  onChange,
  beforeContent,
  white,
  isMulti,
  required,
  maxWidth,
  error,
  asteriks,
  maxOptionMenuHeight,
  solidBorder,
  isSearchable = true,
  disabled = false,
  removable,
  onRemove,
  index,
  small,
  mini,
  labelOrder,
  minWidth,
  isClearable = true,
  customValue,
  placeholderWrap = true,
  formatOptionLabel,
  dashed,
  menuPlacement = 'bottom',
  eventMessage,
  onBlur,
  openMenu = false,
  withDesc,
  singleValueWithDesc = true,
  menuStyles,
  hint,
  tickOption = true,
}) {
  const customStyles = generateCustomStyles({
    withAllSelect,
    minWidth: minWidth ?? (mini ? 150 : 256),
    beforeContent,
    white,
    error,
    maxOptionMenuHeight,
    small,
    mini,
    solidBorder,
    placeholderWrap: placeholderWrap || true,
    dashed,
    menuStyles,
  });
  const cls = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={cls.root} {...boxStyle} maxWidth={maxWidth}>
      {label && (
        <Box display="flex" justifyContent={hint ? 'flex-start' : 'space-between'} alignItems={'center'} sx={{ mb: 2 }}>
          <Typography className={`${label && required && asteriks ? cls.required : ''}`} variant="h5">
            {labelOrder && <span className={cls.orderedLabel}>{labelOrder}</span>}
            {t(label)}
          </Typography>
          {hint && (
            <Box ml={1} height={18}>
              <StyledTooltip title={t(hint)} placement="top">
                <QuestionIcon />
              </StyledTooltip>
            </Box>
          )}
        </Box>
      )}
      {uncontrolled ? (
        <Select
          inputId={id}
          styles={customStyles}
          name={name}
          formatOptionLabel={formatOptionLabel}
          isSearchable={isSearchable}
          placeholder={t(placeholder)}
          noOptionsMessage={() => t('components.no_options')}
          formatCreateLabel={inputValue => t('components.add_inputValue', { inputValue })}
          onChange={val => onChange(val)}
          value={value}
          isDisabled={disabled}
          isMulti={isMulti}
          isClearable={isClearable}
          getOptionLabel={getOptionLabel}
          options={options || []}
          defaultValue={defaultValue || ''}
          beforeContent={beforeContent}
          menuPlacement={menuPlacement}
          menuPosition="fixed"
          components={{
            ...(removable && {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }),
            ...(!isMulti && { SingleValue }),
            MultiValueRemove,
            ...(tickOption && { Option: OptionWithTickIcon }),
          }}
          getOptionValue={getOptionValue}
          onBlur={onBlur}
          autoFocus={openMenu}
          openMenuOnFocus={openMenu}
        />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue || ''}
          rules={{
            required,
          }}
          render={({ field: { onChange: onFieldChange, value: fieldValue } }) => (
            <Select
              inputId={id}
              isDisabled={disabled}
              styles={customStyles}
              isSearchable={isSearchable}
              placeholder={t(placeholder)}
              menuPlacement={menuPlacement}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              noOptionsMessage={() => t('menu.orders.all.filter_menu_order.no_options')}
              isMulti={isMulti}
              formatCreateLabel={inputValue => t('components.add_inputValue', { inputValue })}
              onChange={val => {
                const formattedValue = customValue ? customValue(val) : val;
                onFieldChange(formattedValue);
                if (eventMessage) {
                  event(eventMessage);
                }
              }}
              onBlur={onBlur}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              options={options || []}
              defaultValue={defaultValue || ''}
              value={fieldValue}
              beforeContent={beforeContent}
              isClearable={isClearable}
              withDesc={withDesc}
              singleValueWithDesc={singleValueWithDesc}
              components={{
                ...(removable && {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }),
                ...(!isMulti && { SingleValue }),
                MultiValueRemove,
                Option: CustomOption,
              }}
              autoFocus={openMenu}
              openMenuOnFocus={openMenu}
            />
          )}
        />
      )}
      {removable && (
        <button type="button" onClick={() => onRemove(index)}>
          <DeleteSmallIcon id={`${id}-deleteIcon`} />
        </button>
      )}
    </Box>
  );
}

export default checkAccess(SelectSimple);
