import * as qs from 'qs';
import { request } from '../axios';

const importProduct = {
  getAll: filter => request.get(`v2/import${qs.stringify(filter, { addQueryPrefix: true })}`),
  getSingle: filter =>
    request.get(
      `v1/import/${filter.id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  search: filter =>
    request.get(
      `v2/import-search/${filter.id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  getSingleDetails: filter =>
    request.get(
      `v2/import/${filter.id}${qs.stringify(filter.params, {
        addQueryPrefix: true,
      })}`,
    ),
  getImportPriceDiff: id => request.get(`v2/import-items-dp/${id}`),

  create: data => request.post(`v1/import`, data),
  delete: ({ id, protocol }) => request.delete(`v2/import/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  update: ({ id, data, protocol }) =>
    request.put(`v2/import/update-details/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  importInventory: ({ data, protocol }) => request.post(`v2/import/inventory${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  generateExcel: data => request.post(`v2/import-export/${data.id}`, data.body),
  generateImportReport: data => request.post(`v1/import-report-by-products-excel`, data),
};

export default importProduct;
