/* eslint-disable */
import { Checkbox, Box, FormControlLabel } from '@mui/material';
import DragDropIcon from 'shared/icons/DragDropIcon';
import { arrayMove, sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 48,
    marginTop: 8,
    backgroundColor: theme.palette.gray[100],
    borderRadius: 16,
    justifyContent: 'space-between',
    zIndex: 1400,
    paddingRight: 16,
    paddingLeft: 16,
    '&:nth-of-type(1)': {
      marginTop: 0,
    },
  },
  label: {
    flex: '1 0 90%',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    cursor: 'pointer',
    fontSize: theme.fontSize.base,
    fontWeight: 600,
    lineHeight: '19px',
    color: theme.palette.gray[600],
  },
  dragdrop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 85,
    height: '100%',
    cursor: 'n-resize',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    paddingLeft: 16,
    '& h3': {
      fontWeight: 600,
    },
  },
}));

export default function CheckboxWithDragDrop({ data, checkAllField, name, defaultValue, control, setData, itemsRef }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const onSortEnd = props => {
    const { oldIndex, newIndex } = props;
    const newList = arrayMove(data, oldIndex, newIndex);
    setData(newList);
    return newList;
  };
  const handleChange = (name, checked) => {
    const changedData = data.map(el => {
      if (el.name === name) {
        el.is_active = checked;
        el.changed = true;
        itemsRef.current = [...itemsRef.current?.filter(item => item?.name !== el?.name), el];
      }
      return el;
    });
    setData(changedData);
    return changedData;
  };
  const handleAllCheckedChange = e => {
    const isChecked = e.target.checked;
    const changedData = data.map(el => el && { ...el, is_active: isChecked });
    setData(changedData);
    itemsRef.current = changedData;
  };
  const DragHandle = sortableHandle(() => (
    <div className={classes.dragdrop}>
      <DragDropIcon />
    </div>
  ));
  const SortableContainer = sortableContainer(({ children }) => <Box>{children}</Box>);
  const SortableItem = sortableElement(({ data, handleChange, onChange }) => (
    <div className={classes.root}>
      <FormControlLabel
        control={<Checkbox name={data.name} />}
        onChange={e => {
          const listValue = handleChange(data.name, e.target.checked);
          onChange(listValue);
        }}
        id={data.name}
        disabled={data?.always_active}
        checked={data?.is_active}
        name={data.name}
        label={data.label?.ru || data.label}
      />
      <DragHandle />
    </div>
  ));
  return (
    <>
      {checkAllField && (
        <Box className={classes.header}>
          <FormControlLabel
            control={<Checkbox name="toggle_all" />}
            onChange={handleAllCheckedChange}
            checked={data?.filter(el => el.id !== 'selection')?.every(el => el?.is_active)}
            label={t('menu.products.import.nav.select_all')}
          />
        </Box>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange } }) => (
          <SortableContainer
            onSortEnd={props => {
              const listValue = onSortEnd(props);
              onChange(listValue);
            }}
            useDragHandle
          >
            {data?.map(
              (item, index) =>
                item.id !== 'selection' && (
                  <SortableItem
                    key={`item-${item.id}`}
                    index={index}
                    data={item}
                    onChange={onChange}
                    handleChange={handleChange}
                    classes={classes}
                  />
                ),
            )}
          </SortableContainer>
        )}
      />
    </>
  );
}
