import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserService } from '@/shared/services';
import { error } from 'shared/utils/toast';

export const useDeleteIntegrationUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => (await UserService.deleteUser(id).fetch()).data,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['USERS', 'LIST'],
      });
    },
    onError: () => {
      error('integrations.error.delete');
    },
  });
};
