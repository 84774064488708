import { useInfiniteQuery } from '@tanstack/react-query';
import { UserService } from 'shared/services/lib/UserService';
import { UserServiceNS } from 'shared/services/lib/UserService.d';

export const useGetUsersListInfiniteQuery = (params: UserServiceNS.LoadIntegratedUsersParamsDTO = {}) => {
  return useInfiniteQuery({
    queryKey: ['USERS', 'LIST', params],
    queryFn: async () => (await UserService.getUsers(params).fetch()).data,
  });
};
