/* eslint-disable */
import { request } from '../axios';

const pack = {
  getAll: id => request.get(`v1/package?company_id=${id}`),
  create: data => request.post(`v1/package`, data),
  getSingle: id => request.get(`v1/package/${id}`),
  update: data => request.put(`v1/package/${data.id}`, data.data),
  delete: id => request.put(`v1/package/${id}`),
};

export default pack;
