import * as qs from 'qs';
import { request } from '../axios';

const supplierOrder = {
  getAll: filter => request.get(`v2/supplier-order${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: ({ data, protocol }) => request.post(`v2/supplier-order${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  update: ({ id, data, protocol }) =>
    request.put(`v2/supplier-order/update/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  return: ({ id, data, protocol }) =>
    request.post(`v2/supplier-order/return/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  accept: ({ id, data, protocol }) =>
    request.put(`v2/supplier-order/accept/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  cancel: ({ id, protocol, data = null }) =>
    request.put(`v2/supplier-order/cancel/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  createFromFile: data => request.post(`v2/supplier-order-from-file`, data),
  getSupplierOrders: (id, filter) =>
    request.get(
      `v2/supplier/orders/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getSingle: id => request.get(`v2/supplier-order/${id}`),
  deleteReturn: ({ id, protocol }) => request.delete(`v2/supplier-order/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
  changeStatus: ({ id, protocol }) =>
    request.patch(`v2/supplier-order/change-status${qs.stringify(protocol, { addQueryPrefix: true })}`, { id }), // Переведен на HTTP
  payment: ({ data, protocol }) => request.post(`v2/supplier-order/payment${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  getPayments: id => request.get(`v2/supplier-order/${id}/payments`),
  getItems: filter =>
    request.get(
      `v2/supplier-order-products/${filter?.id}${qs.stringify(filter?.params, {
        addQueryPrefix: true,
      })}`,
    ),
  addItems: ({ data, protocol }) => request.put(`v2/supplier-order/add-item${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  addItemWithBarcode: ({ data, protocol }) =>
    request.put(`v2/supplier-order/add-item-by-barcode${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP

  deleteItems: data => request.delete(`v2/supplier-order/remove-items`, { data }),
  updateComment: ({ data, protocol }) =>
    request.patch(`v2/supplier-order/update-comment${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP
  updateRetailPriceChange: data => request.patch(`v2/supplier-order/update-retail-price-change-type`, data),
  getFileProgress: id => request.get(`v2/file-supplier-order-progress/${id}`),
  getFile: data =>
    request.get(
      `v2/file-supplier-order/${data?.correlation_id}${qs.stringify(data.params, {
        addQueryPrefix: true,
      })}`,
    ),
  getExcelProperties: () => request.get(`v2/excel/supplier-order-properties`),
  getExcelPropertiesFile: () => request.get(`v2/excel/supplier-order-properties-file`),
  getSuppliersReport: data => request.post(`v1/supplier-order-report-by-products-excel`, data),
  supplierOrderInventory: data => request.post('v2/order/stocktaking/create', data),

  // Не работает (с бэка нужна доработка)
  // generateExcel: ({ id, data, protocol }) =>
  //   request.post(`v2/supplier-order-export/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`, data), // Переведен на HTTP

  generateExcel: ({ id, data }) => request.post(`v2/supplier-order-export/${id}`, data),
  getOrderPriceDiff: (id, filter) =>
    request.get(
      `v2/supplier-order-items-dp/${id}${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  deleteSupplierOrderPayment: ({ id, protocol }) =>
    request.delete(`v2/supplier-order-payment/${id}${qs.stringify(protocol, { addQueryPrefix: true })}`), // Переведен на HTTP
};

export default supplierOrder;
