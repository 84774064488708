import { useNavigate, useMatch, NavLink as RouterLink } from 'react-router-dom';
import ArrowForward from 'shared/icons/ArrowForward';
import { setGuideCompleted, setGuideRun, setGuideStep, setGuideStop } from 'store/actions/guideActions/guideActions';
import promiseCallback from 'shared/utils/promiseCallback';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import { ListItem } from '@/shared/ui';

const Badge = ({ text }) => {
  return (
    <Box
      sx={theme => ({
        width: 35,
        height: 20,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#DDD6FE',
        color: theme.palette.violet[500],
        fontFamily: 'Gilroy-Bold',
        fontSize: 10,
        borderRadius: 8,
        padding: '4px 6px',
        marginLeft: 1,
        lineHeight: '11px',
      })}
    >
      <span>{text}</span>
    </Box>
  );
};

function NavItem({ item, classes, handleClickNavItems, handleShowDialog, isActive, setNextHref }) {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { step, size, guideSlug, started } = useSelector(state => state.guide, shallowEqual);
  const match = useMatch(item?.active || '/never-route');
  const forwardNextStep = () => {
    promiseCallback(() => dispatch(setGuideStop()))
      .then(() => {
        if (size - 1 === step) {
          dispatch(setGuideCompleted(guideSlug));
          navigate('/dashboard/welcome');
        } else if (started) {
          promiseCallback(() => dispatch(setGuideStep(step + 1)))
            .then(() => setTimeout(() => dispatch(setGuideRun()), 300))
            .catch(() => {});
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <ListItem
        aria-describedby="popper"
        selected={!!match}
        className={`${classes.listItem} ${item.className || ''} drawer_list_item`}
        style={{ backgroundColor: isActive && palette.bluegray[200] }}
        component={RouterLink}
        to={item.href}
        onClick={event => {
          if (!!window.sessionStorage.getItem('disableRoute') && !item.icon) {
            event.preventDefault();
            handleShowDialog(true);
            setNextHref(item.href);
            return;
          }
          forwardNextStep();
          if (item.children?.length) {
            event.preventDefault();
            handleClickNavItems(item);
          }
        }}
      >
        {item?.icon && <div className={`${classes.itemIcon} drawer_icon`}>{item.icon}</div>}
        <div
          className={classes.itemLabel}
          id={item.label}
          style={{
            color: isActive && palette.blue[500],
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {t(`navbar.${item.label}`)}
          {item?.new && <Badge text={item?.new} />}
        </div>
        {item?.icon && <div className={classes.itemArrow}>{item.icon && <ArrowForward />}</div>}
      </ListItem>
    </>
  );
}

export default NavItem;
