import { useFlag } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useTrackSpecificURLs = tracker => {
  const location = useLocation();
  const isEnabled = useFlag('openreplay');

  useEffect(() => {
    if (isEnabled) {
      const keywordsToTrack = ['order/new-order', 'products/inventory/scan', 'order/all'];
      const shouldTrack = keywordsToTrack.some(keyword => location.pathname.includes(keyword));

      if (!shouldTrack) {
        return tracker.stop();
      }
      tracker.start({
        userID: getSubdomainFromCurrentURL(),
      });
    }
  }, [location, isEnabled]);
};

export function getSubdomainFromCurrentURL() {
  const currentURL = window.location.href;
  const urlObj = new URL(currentURL);
  const hostname = urlObj.hostname;
  const parts = hostname.split('.');
  return parts[0];
}
