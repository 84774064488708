/* eslint-disable */
import mixpanel from 'mixpanel-browser';
import { getSubdomainFromCurrentURL } from './useTrackSpecificURLs';

const mixpanelTrack = (eventName, properties = {}) => {
  mixpanel.track(eventName, {
    ...properties,
    domain: location.origin,
    domain_company: getSubdomainFromCurrentURL(),
  });
};
export default mixpanelTrack;
