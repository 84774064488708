import { ApiCaller } from '../utils';
import { Service } from './Service';
import { AxiosRequestConfig } from 'axios';
import { ShopServiceNS } from './ShopService.d';

const BASE_URL = 'v1';
const apiCaller = new ApiCaller(BASE_URL);

export class ShopService implements Service {
  public static getList(params: ShopServiceNS.LoadListParamsDTO, config?: AxiosRequestConfig) {
    return apiCaller.get<{ shops: ShopServiceNS.ShopDTO[]; count: number }>('/shop', { ...params }, { ...config });
  }
}
