/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const cashier = {
  getAll: filter => request.get(`v1/cashier${qs.stringify(filter, { addQueryPrefix: true })}`),
  create: data => request.post(`v1/cashier`, data),
  getSingle: id => request.get(`v1/cashier/${id}`),
  update: data => request.put(`v1/cashier/${data.id}`, data.data),
  delete: id => request.delete(`v1/cashier/${id}`),
};

export default cashier;
