/* eslint-disable */
import { useEffect } from 'react';

export default function useDisableEnterOnInput() {
  useEffect(() => {
    const ready = () => {
      window.addEventListener('keydown', e => {
        if (e.keyCode === 13 && e.target.tagName === 'INPUT') {
          e.preventDefault();
          return false;
        }
      });
    };

    document.addEventListener('DOMContentLoaded', ready);
    return () => {
      document.removeEventListener('DOMContentLoaded', ready);
    };
  }, []);
}
