/* eslint-disable */
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 0,
    background: theme.palette.bluegray[400],
    '&:hover': {
      background: `${theme.palette.bluegray[401]}`,
    },
    zIndex: 1301,
    height: 64,
    width: 256,
  },
  buttonMini: {
    margin: '5px auto 30px',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50%',
    background: theme.palette.bluegray[400] + ' !important',
    '&:hover': {
      background: theme.palette.bluegray[401] + ' !important',
    },
    '& svg': {
      width: 16,
      height: 16,
      color: theme.palette.white,
    },
  },
  customMargin: {
    width: '256px',
    height: '64px',
  },
  customMiniMargin: {
    margin: 0,
  },
  absoluteButton: {
    position: 'fixed',
    width: 48,
    height: 48,
    margin: 0,
    left: '32px',
    bottom: '32px',
    zIndex: 3000,
  },
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontFamily: theme.fontFamily.gilroyBold,
  },
  popoverContainer: {
    '& .MuiPopover-paper': {
      boxShadow: 'none',
      background: 'transparent',
      top: 'unset !important',
      left: '0 !important',
      bottom: '0 !important',
      padding: 0,
      margin: 0,
    },
  },
  popoverMiniContainer: {
    '& .MuiPopover-paper': {
      left: '32px !important',
      bottom: '30px !important',
    },
  },
  socialButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: 'transparent',
    paddingBottom: '16px',
  },
  socialButton: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    width: 208,
    borderRadius: '32px',
    cursor: 'pointer',
    transition: 'background .1s ease-in',
    background: theme.palette.gray[100],
    '&:hover': {
      background: theme.palette.gray[200],
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '56px',
    height: '56px',
  },
}));
