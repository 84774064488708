/* eslint-disable */
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Guide from 'components/Guide';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import LoadingContainer from 'components/LoadingContainer';
import NavBar from '../Navbar';
import { makeStyles } from '@mui/styles';
import WelcomeDialog from 'pages/register/WelcomeDialog';
import PlanNotification from 'pages/settings/plan/notification/Notification';
import Downtime from 'components/Downtime';
import { useQueryParams } from 'shared/hooks/useQueryParams';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
  },
  contentContainer: {
    flex: '1 1 auto',
    paddingLeft: 0,
  },
  content: {
    display: 'flex',
    flex: '1 1 auto',
    height: '100%',
  },
  main: {
    flex: '1 1 100%',
  },
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { values } = useQueryParams();
  const { user_id } = useSelector(state => state.company);
  const { forbiddenRoutes } = useSelector(state => state?.permissionRoutes);
  const isDowntime = !forbiddenRoutes.find(el => el?.slug === 'downtime');

  useEffect(() => {
    if ((!user_id || !localStorage.getItem('access_token')) && !values.noRedirect && !localStorage.getItem('first_attempt')) {
      navigate('/login');
    }
  }, [user_id]);

  useEffect(() => {
    if (localStorage.getItem('first_attempt') && !user_id) {
      setOpen(true);
    }
  }, [localStorage.getItem('first_attempt'), user_id]);

  return (
    <Box display="flex" width="100%" minHeight="100%">
      <WelcomeDialog open={open} setOpen={setOpen} />
      <LoadingContainer readyState>
        <div className={classes.root}>
          <NavBar />
          <Guide />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              {isDowntime && <Downtime />}
              <div className={classes.content}>
                <main className={classes.main}>
                  <PlanNotification />
                  <Outlet />
                </main>
              </div>
            </div>
          </div>
        </div>
      </LoadingContainer>
    </Box>
  );
};

export default DashboardLayout;
