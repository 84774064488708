/* eslint-disable */
import { useFormContext } from 'react-hook-form';
import Button from 'stories/Button/Button';
import ArrowBackIcon from 'shared/icons/ArrowBackIcon';
import RadioButton from 'components/RadioButton';
import { useQuery } from '@tanstack/react-query';
import { requests } from 'services/requests';
import { error } from 'shared/utils/toast';
import { useTranslation } from 'react-i18next';
import { useStyles } from './useStyles';
import Steps from './Steps';
import { useDispatch, useSelector } from 'react-redux';
import event from 'shared/utils/event';
import { useWebsocketMutationNew } from 'shared/hooks/useWebsocketMutationNew';
import { setCompanyId, setUserId } from 'store/actions/companyActions/companyActions';
import { request } from 'services/axios';

const CompanyDetails = ({ setStep, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currency, company_timezone, company_name } = useSelector(state => state?.company);
  const classes = useStyles();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = useFormContext();
  const { data: segments } = useQuery(['segments'], requests.company.companySegments);
  const { mutate, isLoading } = useWebsocketMutationNew(requests.users.registerUser, {
    onWebsocketSuccess: ({ data }) => {
      event('registration_success');
      localStorage.setItem('access_token', data?.token?.access_token);
      localStorage.setItem('refresh_token', data?.token?.refresh_token);
      dispatch(setCompanyId(data?.company_id));
      dispatch(setUserId(data?.user_id));
      const model = {
        currencies: [
          {
            code: currency?.iso_code,
            type: 'supply_price',
          },
          {
            code: currency?.iso_code,
            type: 'retail_price',
          },
        ],
        currency_rates: [
          {
            rate: 0,
            source_currency: currency?.iso_code,
            target_currency: currency?.iso_code,
          },
        ],
      };
      const headers = {
        Authorization: `Bearer ${data?.token?.access_token}`,
      };
      request
        .get(`v1/time-zone`, {
          headers,
        })
        .then(({ data: timeZones }) => {
          const timeZone = timeZones?.time_zones?.find(item => item?.short_name === company_timezone?.name);

          const companyForm = {
            address: '',
            bank_accounts: [],
            country_id: '',
            email: '',
            inn: '',
            legal_name: '',
            mfo: '',
            name: company_name,
            postcode: '',
            time_zone_id: timeZone?.id,
          };
          request
            .put(`v1/company/${data?.company_id}`, companyForm, {
              headers,
            })
            .then(() => {
              request
                .post('v2/company-currencies', model, {
                  headers,
                })
                .then(() => {
                  window.location.reload();
                })
                .catch(() => {
                  window.location.reload();
                });
            });
        });

      handleClose();
    },
    onWebsocketError: () => error(`menu.sales.toast.error.message`),
  });

  const onSubmit = data => {
    if (!data?.company_segment) {
      setError('company_segment', { type: 'required' });
      return;
    }

    const requestBody = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      passcode_token: localStorage.getItem('passcode_token'),
      password: data?.password,
      segment_id: data?.company_segment,
    };

    mutate(requestBody);
  };

  return (
    <>
      <header className={classes.header}>
        <Steps current={2} />
        <div className={classes.accountSettings}>
          <h1 className={classes.title}>
            <ArrowBackIcon onClick={() => setStep('user')} />
            {t('menu.register.whatShop')}
          </h1>
          <Button
            variant="contained"
            className={classes.button}
            size="small"
            type="submit"
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
            fullWidth
          >
            {t('dashboard.next')}
          </Button>
        </div>
      </header>
      <section className={classes.section}>
        <div className={classes.container}>
          {segments?.data?.map(item => {
            return (
              <div key={item?.id}>
                <RadioButton
                  id={item?.id}
                  name="company_segment"
                  error={errors?.company_segment}
                  value={item?.id}
                  title={item?.name}
                  register={register}
                />
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default CompanyDetails;
