/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

interface OrderViewTableHeaderState {
  columns: any[];
}

export const initialState: OrderViewTableHeaderState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'К заказу',
      accessor: 'to_order',
      is_active: true,
    },
    {
      Header: 'Цена поставки',
      accessor: 'price',
      is_active: true,
    },
    {
      Header: 'Цена продажи',
      accessor: 'sale_price',
      is_active: true,
    },
  ],
};

const orderViewTableHeaderSlice = createSlice({
  name: 'orderViewTableHeader',
  initialState,
  reducers: {
    updateTable: (state, action) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    resetTable: () => {
      return initialState;
    },
  },
});

export const { updateTable, resetTable } = orderViewTableHeaderSlice.actions;
export default orderViewTableHeaderSlice.reducer;
