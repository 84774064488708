/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from 'shared/icons/CloseIcon';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper.MuiDialog-paper': {
      width: '100%',
      maxWidth: 784,
      boxShadow: 'none !important',
      borderRadius: 24,
      position: 'relative',
    },
    '& .MuiBackdrop-root': {
      background: 'rgba(0,0,0,0.3)',
    },
  },

  content: {
    zIndex: 13,
    padding: '56px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    '& h2': {
      color: theme.palette.black,
      fontFamily: 'Gilroy-Bold',
      fontSize: '36px',
      lineHeight: '42px',
      marginTop: 32,
      marginBottom: 32,
    },
  },
  desc: {
    fontSize: '16px',
    lineHeight: '19px',
    '& div': {
      color: theme.palette.red[600],
    },
  },
  actions: {
    '& button': {
      height: '56px !important',
      minWidth: '196px',
    },
    gap: '32px',
  },
}));

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<any>>;
  actions?: React.ReactNode;
  title?: string;
  icon?: React.ReactNode;
  desc?: string | React.ReactElement;
  descWidth?: number | string;
  supDesc?: string | React.ReactElement;
  noCloseIcon?: boolean;
  setDisableSubmit?: (a: boolean) => void;
  descComponent?: React.ReactElement;
}

const ConfirmDialog: React.FC<Props> = ({
  open,
  setOpen,
  actions,
  title = '',
  icon,
  desc,
  descWidth,
  supDesc,
  noCloseIcon,
  setDisableSubmit,
  descComponent,
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
    if (setDisableSubmit) {
      setDisableSubmit(false);
    }
  };
  const classes = useStyles();
  return (
    <>
      <Dialog onClose={handleClose} open={open} className={classes.root} disableScrollLock>
        {!noCloseIcon && (
          <Box
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 32,
              right: 32,
              zIndex: 14,
            }}
          >
            <CloseIcon />
          </Box>
        )}
        <Box className={classes.content}>
          <Box className={classes.title}>
            {icon}
            <Typography variant="h2">{typeof title === 'string' ? t(title) : title}</Typography>
          </Box>
          <Typography sx={{ width: descWidth ? descWidth : null }} className={classes.desc} mb={5}>
            {typeof desc === 'string' ? t(desc) : desc}
            {typeof supDesc === 'string' ? <Box>{t(supDesc)}</Box> : supDesc}
            {descComponent ? descComponent : ''}
          </Typography>
          <Box display="flex" className={classes.actions}>
            {actions}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;
