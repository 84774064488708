/* eslint-disable */
import { Box, Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import UserInfo from './UserInfo';
import ChangeShop from './ChangeShop';
import BillzPartner from '../BillzPartner';
import PlanInfoButton from './PlanInfoButton';
import BackgroundProgresses from './BackgroundProgresses';

const useStyles = makeStyles(theme => ({
  drawer: {
    '& .MuiDrawer-paper': {
      width: '30vw',
      padding: '64px 32px',
      borderRadius: '0 64px 64px 0',
      backgroundColor: theme.palette.background.default,
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
  },
  body: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  avatarPlaceholder: {
    position: 'relative',
    height: 40,
    width: 40,
    borderRadius: 20,
    marginRight: 12,
    fontWeight: 600,
    fontSize: 16,
    backgroundColor: theme.palette.blue[600],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    transition: '0.3s',
  },
  title: {
    margin: 0,
    fontSize: 36,
    lineHeight: '42px',
    color: theme.palette.black,
  },
  avatar: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center top',
  },
  shopname: {
    marginTop: 2,
    color: theme.palette.gray[400],
  },
  logoutBtn: {
    color: theme.palette.red[500],
  },
  actionBtn: {
    height: 'unset',
    mingHeight: 56,
    padding: '16px',
    paddingRight: 0,
    marginTop: '16px',
    color: theme.palette.gray[600],
    '& svg': {
      color: theme.palette.blue[500],
    },
  },
  actionBtnContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    backgroundColor: theme.palette.gray[100],
    borderRadius: 24,
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      backgroundColor: theme.palette.gray[101],
    },
  },
}));

const UserDrawerContent = ({ closeDrawer, userDetails, refetchUserDetails }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.body}>
        <UserInfo classes={classes} closeDrawer={closeDrawer} userDetails={userDetails} />
        <Box>
          <BillzPartner classes={classes} closeDrawer={closeDrawer} />
          <PlanInfoButton classes={classes} closeDrawer={closeDrawer} />
          <ChangeShop classes={classes} closeDrawer={closeDrawer} userDetails={userDetails} refetchUserDetails={refetchUserDetails} />
          <BackgroundProgresses classes={classes} closeDrawer={closeDrawer} />
        </Box>
      </Box>
    </Box>
  );
};

export default function UserDrawer({ isOpen, closeDrawer, refetchUserDetails, userDetails }) {
  const classes = useStyles();

  return (
    <Drawer open={!!isOpen} onClose={closeDrawer} anchor="left" elevation={1} className={classes.drawer}>
      <UserDrawerContent closeDrawer={closeDrawer} userDetails={userDetails} refetchUserDetails={refetchUserDetails} />
    </Drawer>
  );
}
