/* eslint-disable */
import React, { useState } from 'react';
import { Box, TextField, Typography, InputAdornment } from '@mui/material';
import { Controller } from 'react-hook-form';
import ShowPasswordIcon from 'shared/icons/ShowPasswordIcon';
import HidePasswordIcon from 'shared/icons/HidePasswordIcon';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: 0,
  },
  textfield: {
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0,
    },
    '& .Mui-error:not(.Mui-focused)': {
      border: '0 0 0 3px red',
    },
  },
  secondary: {
    '& .MuiOutlinedInput-root': {
      height: ({ height }) => `${height}px !important`,
      borderRadius: ({ height }) => `${height === 56 ? 16 : 20}px !important`,
    },
  },
  adornment: {
    width: 56,
    height: 56,
    cursor: 'pointer',
    maxHeight: 56,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noMargin: {
    marginTop: 0,
  },
  noLabel: {
    marginTop: 20,
  },
}));

function InputPassword({
  error,
  control,
  label,
  placeholder,
  name,
  fullWidth,
  boxStyle,
  defaultValue,
  multiline,
  rowsMax,
  disabled,
  required = true,
  onKeyDown,
  password,
  autoComplete = 'off',
  noLabel,
  secondary,
  height = 56,
  minLength,
}) {
  const { t } = useTranslation();
  const classes = useStyles({ height });
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box width={fullWidth ? '100%' : 320} className={classes.root} mt={!label && 2} {...boxStyle}>
      <Typography mb={!!label ? 2 : 0} variant="h5">
        {label}
      </Typography>
      <Controller
        name={name}
        render={({ field: { onChange, value } }) => (
          <TextField
            id={name}
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            autoComplete={autoComplete}
            placeholder={placeholder}
            disabled={disabled}
            onKeyDown={onKeyDown}
            fullWidth
            multiline={multiline}
            value={value}
            onChange={onChange}
            rows={rowsMax}
            InputProps={{
              endAdornment: (
                <InputAdornment className={classes.adornment} onClick={() => setShowPassword(!showPassword)} position="end">
                  {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                </InputAdornment>
              ),
            }}
            className={`${classes.textfield} ${!label && classes.noMargin} ${secondary && classes.secondary} ${noLabel && classes.noLabel}`}
            error={error}
          />
        )}
        control={control}
        rules={{
          required,
          minLength: {
            value: minLength,
            message: t('components.min_length', { minLength }),
          },
          validate: value => {
            if (password) {
              return password?.current === value || password === value || t('components.passwordNotMatch');
            }
          },
        }}
        defaultValue={defaultValue || ''}
      />
    </Box>
  );
}

export default InputPassword;
