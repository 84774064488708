/* eslint-disable */
import * as qs from 'qs';
import { request, requestNotRelative } from '../axios';

const company = {
  create: data => request.post(`v1/company`, data),
  register: data => request.post(`v1/register-company`, data),
  getSingle: () => request.get(`v1/company`),
  getAccess: () => request.get(`v2/company-access`),
  getTariff: () => request.get(`v2/company-tariff`),
  update: data => request.put(`v1/company/${data.id}`, data.data),
  companySegments: () => request.get(`v1/company-segment`),
  subdomain: data => request.patch(`v1/company/subdomen`, data),
  search: name => request.get(`v1/company-search?name=${name}`),
  getAll: filter => request.get(`v2/company${qs.stringify(filter, { addQueryPrefix: true })}`),
  clear: id => request.post(`v2/company/${id}`),
  block: data => request.patch(`v2/company/${data?.id}/status`, data?.body),
  setIsPaid: data => request.patch(`v2/company/${data?.id}/set-is-paid`, data?.body),
  setIsTest: data => request.patch(`v2/company/${data?.id}/set-is-test`, data?.body),
  getExcel: filter => request.get(`v2/company-excel${qs.stringify(filter, { addQueryPrefix: true })}`),
  getUserRegion: () => requestNotRelative.get(`v1/ip/string`),
  financeSettings: () => request.get('/v1/company-finances-settings'),
  getFeatureFlags: filter =>
    request.get(
      `v2/company-feature-flags${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  clearNewFlow: ({ id, data }) => request.post(`v2/company/clear/${id}`, data),
  updateFeatureFlag: data => request.post(`v2/company/${data.id}/set-finances-flag`, data.data),
  updateCompanySettings: data => request.patch(`v2/company/${data.id}`, data.data),
  updateCompanyFields: ({ id, data }) => request.patch(`v2/company-update-admin-fields/${id}`, data),
};

export default company;
