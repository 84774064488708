/* eslint-disable */
import { importTableHeaderActionTypes } from '../actions/importTableHeaderActions/importTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      headerName: 'ID',
      field: 'id',
      hide: false,
      minWidth: 70,
      width: 135,
    },
    {
      field: 'name',
      hide: false,
      minWidth: 70,
    },
    { field: 'shop_name', hide: false, minWidth: 70 },
    {
      field: 'total_measurement_value',
      hide: false,
      minWidth: 120,
    },
    {
      field: 'retail_price',
      hide: false,
      minWidth: 180,
    },
    {
      field: 'status',
      hide: false,
      resizable: false,
    },
    {
      field: 'created_at',
      hide: false,
      minWidth: 70,
      initialWidth: 160,
    },
    {
      field: 'created_by',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'finished_by',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'import_type',
      hide: false,
      minWidth: 70,
    },
    {
      field: 'process_percentage',
      hide: false,
      resizable: false,
      minWidth: 250,
    },
  ],
};

const importTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case importTableHeaderActionTypes.UPDATE_IMPORT_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case importTableHeaderActionTypes.RESET_IMPORT_TABLE_HEADER:
      return initialState;

    default:
      return state;
  }
};

export default importTableHeaderReducer;
