import * as qs from 'qs';
import { request } from '../axios';

const report = {
  getExcelReportShop: data => request.post(`v1/general-report-excel`, data),
  getExcelReportSeller: data => request.post(`v1/seller-general-report-excel`, data),
  getExcelReportProducts: data => request.post(`v1/product-general-report-excel`, data),
  getExcelReportProductsLeftovers: data => request.post(`v1/stock-report-excel`, data),
  getExcelReportABC: data => request.post(`v1/abc-segmentation-report-export`, data),
  getExcelReportProductsReturn: data => request.post(`v1/supplier-order-returned-export`, data),

  getExcelReportProductsReturns: data => request.post(` /v1/supplier-order-returned-export`, data),

  getExcelReportSuppliers: data => request.post(`v1/sells-supplier-report-excel`, data),
  getExcelReportProductPerformance: data => request.post(`v1/report-product-performance-excel`, data),
  getExcelReportPurchases: data => request.post(`v1/customer-purchases-report-excel`, data),
  getExcelReportImports: data => request.post(`v1/import-report-excel`, data),
  getExcelReportInventory: data => request.post(`v1/stocktaking-summary-table-excel-export`, data),
  getExcelReportTransactions: data => request.post(`v1/transaction-report-excel`, data),
  getExcelReportClients: data => request.post(`v1/customer-general-report-excel`, data),
  getExcelReportCashFlow: data => request.post('v1/cashflow-excel', data),
  getExcelReportProfitAndLose: data => request.post('/v1/report-profit-and-lose-excel', data),
  clientReport: filter =>
    request.get(
      `v1/general-customer-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  dashboardReportV1: filter =>
    request.get(
      `v1/dashboard-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  dashboardReportV2: filter =>
    request.get(
      `v2/dashboard-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),

  getExcelReportProductsTransfer: data => request.post(`v1/transfer-report-excel`, data),
  productTransferTable: filter => request.get(`v1/transfer-report-table${qs.stringify(filter, { addQueryPrefix: true })}`),
  productTransferTotal: filter => request.get(`v1/transfer-report-totals${qs.stringify(filter, { addQueryPrefix: true })}`),

  generalReportTable: filter => request.get(`v1/general-report-table${qs.stringify(filter, { addQueryPrefix: true })}`),
  generalReport: filter => request.get(`v1/general-report${qs.stringify(filter, { addQueryPrefix: true })}`),
  productReport: filter =>
    request.get(
      `v1/general-product-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  salesReport: filter => request.get(`v1/general-sales-report${qs.stringify(filter, { addQueryPrefix: true })}`),
  sellerReport: filter =>
    request.get(
      `v1/general-seller-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  productLeftReport: filter => request.get(`v1/product-left-report${qs.stringify(filter, { addQueryPrefix: true })}`),
  productPerformanceReport: filter =>
    request.get(
      `v1/product-performance-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  productGeneralReport: filter =>
    request.get(
      `v1/product-general-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  productGeneralTable: filter =>
    request.get(
      `v1/product-general-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  productImportReport: filter => request.get(`v1/import-report-table${qs.stringify(filter, { addQueryPrefix: true })}`),
  inventoryResultsTable: filter =>
    request.get(
      `v1/stocktaking-summary-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  inventoryResultsTotal: filter =>
    request.get(
      `v1/stocktaking-summary-totals${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  writeOffResultsTable: filter =>
    request.get(
      `v1/write-off-report-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  writeOffResultsTotal: filter =>
    request.get(
      `v1/write-off-report-totals${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  productImportTable: filter => request.get(`v1/import-report-totals${qs.stringify(filter, { addQueryPrefix: true })}`),
  productSupplierReport: filter =>
    request.get(
      `v1/product-sells-by-suppliers-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  productLeftoverReport: filter =>
    request.get(
      `v1/stock-report-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getSegments: () => request.get(`v1/get-segments`),
  productAbcReport: filter =>
    request.get(
      `v1/abc-segmentation-report-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  updateSegments: data => request.post(`v1/update-segment`, data),
  searchSupplies: filter => request.get(`v1/search-supplies${qs.stringify(filter, { addQueryPrefix: true })}`),

  productReturnReport: filter =>
    request.get(
      `v1/supplier-order-return-report-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  productReturnReportTotal: filter =>
    request.get(
      `v1/supplier-order-return-report-totals${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  shopTransactionPayments: filter =>
    request.get(
      `v1/transaction-report-payments${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  shopTransactionReport: filter =>
    request.get(
      `v1/transaction-report-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  shopTransactionTable: filter =>
    request.get(
      `v1/transaction-report-totals${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  favouriteReports: () => request.get(`v1/favourite-report`),
  addToFavouriteReport: data => request.post(`v1/favourite-report`, data),
  removeFromFavouriteReport: name => request.delete(`v1/favourite-report/${name}`),
  productSalesReport: filter =>
    request.get(
      `v1/product-sales-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  sellerGeneralReport: filter =>
    request.get(
      `v1/seller-general-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  sellerGeneralTable: filter =>
    request.get(
      `v1/seller-general-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  sellerSalesReport: filter =>
    request.get(
      `v1/seller-sales-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  sellerPerformanceReport: filter =>
    request.get(
      `v1/seller-performance-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  customerGeneralReport: filter =>
    request.get(
      `v1/customer-general-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  customerGeneralTable: filter =>
    request.get(
      `v1/customer-general-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  customerPurchaseReport: filter =>
    request.get(
      `v1/customer-purchases-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  customerPurchaseTable: filter =>
    request.get(
      `v1/customer-purchases-totals${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  productEfficiencyTable: filter =>
    request.get(
      `v1/report-product-performance-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  productEfficiencyTotal: filter =>
    request.get(
      `v1/report-product-performance-totals${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  customerSalesReport: filter =>
    request.get(
      `v1/customer-sales-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  customerPerformanceReport: filter =>
    request.get(
      `v1/customer-performance-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  customerGroupSalesReport: filter =>
    request.get(
      `v1/group-sales-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  profitAndLossReport: filter =>
    request.get(
      `v1/profit-and-lose${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  cashFlowReportTable: filter =>
    request.get(
      `v1/cashflow-report-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  cashFlowGeneralReport: filter =>
    request.get(
      `v1/cashflow-general-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  cashFlowCategoryReport: filter =>
    request.get(
      `v1/cashflow-category-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  cashFlowOperationTypeReport: filter =>
    request.get(
      `v1/cashflow-operation-type-report${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  sellersReportProductsTable: filter =>
    request.get(
      `v1/sales-by-seller-report-table${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  sellersReportProductsTotals: filter =>
    request.get(
      `v1/sales-by-seller-report-totals${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  sellersReportProductsExcel: data => request.post(`v1/sales-by-seller-report-export`, data),
  writeOffReportProductsExcel: data => request.post(`v1/write-off-report-table-export`, data),
};

export default report;
