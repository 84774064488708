import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';

import {
  ClientsReportsPage,
  ClientsReportsSinglePage,
  ClientReportPurchasesPage,
  FavouriteReportsPage,
  FinanceReportsPage,
  FinanceReportsSinglePage,
  FinanceMovementsReportsPage,
  ProductReportsPage,
  ProductReportsSinglePage,
  ProductReportsEfficiencyPage,
  ProductInventoryPage,
  ProductReportsWriteOffPage,
  ProductReportsImportsPage,
  ProductReportsSuppliersPage,
  ProductLeftoversPage,
  ProductOrderReturnsPage,
  AbcAnalysePage,
  SellersReportsPage,
  SellersReportsSinglePage,
  ShopsReportsPage,
  ShopReportSinglePage,
  TransactionsSingleReportPage,
  SellerProductsReportPage,
  ProductTransfersPage,
} from 'pages';

const reportsRoutes = {
  path: 'reports',
  element: <DashboardLayout />,
  children: [
    { path: '', element: <Navigate to="/reports/favorites" /> },
    {
      path: 'favorites',
      children: [{ path: '', element: <FavouriteReportsPage /> }],
    },
    {
      path: 'shop',
      children: [
        { path: '', element: <ShopsReportsPage /> },
        {
          path: ':id',
          element: <ShopReportSinglePage />,
        },
        {
          path: 'transactions',
          element: <TransactionsSingleReportPage />,
        },
      ],
    },
    {
      path: 'products',
      children: [
        { path: '', element: <ProductReportsPage /> },
        {
          path: ':id',
          element: <ProductReportsSinglePage />,
        },
        {
          path: 'efficiency',
          element: <ProductReportsEfficiencyPage />,
        },
        {
          path: 'import',
          element: <ProductReportsImportsPage />,
        },
        {
          path: 'supplier',
          element: <ProductReportsSuppliersPage />,
        },
        {
          path: 'leftover',
          element: <ProductLeftoversPage />,
        },
        {
          path: 'returns',
          element: <ProductOrderReturnsPage />,
        },
        {
          path: 'wholesale',
          element: <ProductLeftoversPage />,
        },
        {
          path: 'inventory',
          element: <ProductInventoryPage />,
        },
        {
          path: 'write-off',
          element: <ProductReportsWriteOffPage />,
        },
        {
          path: 'abc',
          element: <AbcAnalysePage />,
        },
        {
          path: 'transfers',
          element: <ProductTransfersPage />,
        },
      ],
    },
    {
      path: 'finances',
      children: [
        { path: '', element: <FinanceReportsPage /> },
        {
          path: ':id',
          element: <FinanceReportsSinglePage />,
        },
        {
          path: 'movements',
          element: <FinanceMovementsReportsPage />,
        },
      ],
    },
    {
      path: 'clients',
      children: [
        { path: '', element: <ClientsReportsPage /> },
        {
          path: ':id',
          element: <ClientsReportsSinglePage />,
        },
        {
          path: 'purchases',
          element: <ClientReportPurchasesPage />,
        },
      ],
    },
    {
      path: 'sellers',
      children: [
        { path: '', element: <SellersReportsPage /> },
        {
          path: ':id',
          element: <SellersReportsSinglePage />,
        },
        {
          path: 'products',
          element: <SellerProductsReportPage />,
        },
      ],
    },
    { path: '*', element: <Navigate to="/reports/shop" /> },
  ],
};

export default reportsRoutes;
