/* eslint-disable */
import { useState } from 'react';
import { Box } from '@mui/material';
import TickSmallIcon from '../icons/TickSmallIcon';
import TimesSmallIcon from '../icons/TimesSmallIcon';
import WarningSmallIcon from '../icons/WarningSmallIcon';
import DeleteMiddleIcon from '../icons/DeleteMiddleIcon';
import { toast } from 'react-toastify';
import Timer from '../../components/Timer';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    backgroundColor: theme.palette.background.default,
    minHeight: 72,
    boxShadow: theme.boxShadow['32-12'],
    borderRadius: 24,
    '& button': {
      background: 'transparent',
      border: 0,
      width: 24,
      height: 24,
      outline: 0,
      cursor: 'pointer',
    },
    '& svg': {
      minWidth: 24,
    },
  },
  message: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '19px',
    display: 'flex',
    fontFamily: theme.fontFamily.inter,
    color: theme.palette.gray[600],
    textAlign: 'center',
    padding: '16px 0',
    '& a': {
      color: theme.palette.blue[500],
      '&:hover': {
        color: theme.palette.blue[600],
      },
    },
  },
  timer: {
    color: theme.palette.blue[600],
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  layout: {
    padding: '0 16px',
  },
  dataViewer: {
    position: 'relative',
    display: 'flex',
    height: 300,
    overflowY: 'auto',
    overflowX: 'auto',
    width: 400,
    '& > button': {
      border: 0,
      background: 'transparent',
      alignItems: 'flex-start',
      height: 24,
      width: 48,
      cursor: 'pointer',
    },
  },
}));
const Notification = ({ closeToast, timer, message, dynamicValues = { noT: false }, icon, onClick }) => {
  const cls = useStyles();
  const [seconds, setSeconds] = useState(15);
  const { t } = useTranslation();

  return (
    <Box className={cls.root}>
      {icon}
      <div className={cls.layout}>
        <span id="toastify-message" className={cls.message}>
          {typeof message === 'object' ? message : dynamicValues?.noT ? message : t(message, dynamicValues)}
        </span>
        {timer ? (
          <button
            type="button"
            className={cls.timer}
            onClick={() => {
              onClick();
              closeToast();
            }}
          >
            {t('components.toastCancelTimer1')}
            <Timer count={seconds} setCount={setSeconds} />
            {t('components.toastCancelTimer2')}
          </button>
        ) : null}
      </div>
      <button type="button" id="close-toast" onClick={closeToast}>
        <TimesSmallIcon />
      </button>
    </Box>
  );
};

export const success = (msg, dynamicValues?, options?) => {
  toast(<Notification icon={<TickSmallIcon />} message={msg} dynamicValues={dynamicValues} />, options);
};
export const warning = (msg, dynamicValues?, options?) => {
  toast(<Notification icon={<WarningSmallIcon />} message={msg} dynamicValues={dynamicValues} />, options);
};
export const error = (msg, dynamicValues?, options?) => {
  toast(<Notification icon={<DeleteMiddleIcon />} message={msg} dynamicValues={dynamicValues} />, options);
};
export const timerCancel = (msg, onClick) => {
  toast(<Notification timer icon={<WarningSmallIcon />} onClick={onClick} message={msg} />, { autoClose: 15000, closeOnClick: false });
};
