/* eslint-disable */
import { Box, Dialog, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'stories/Button/Button';
import CloseIcon from 'shared/icons/CloseIcon';
import { useTranslation } from 'react-i18next';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useState, useCallback, memo, useMemo } from 'react';
import TelegramIcon from 'shared/icons/TelegramIcon';
import WhatsAppIcon from 'shared/icons/WhatsAppIcon';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { ListItem } from '@/shared/ui';

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper.MuiDialog-paper': {
      width: '100%',
      maxWidth: 768,
      boxShadow: 'none !important',
      borderRadius: 24,
      position: 'relative',
      zIndex: 11,
      // height: 474,
    },
  },
  blockedUser: {
    '& .MuiDialog-paper.MuiDialog-paper': {
      minHeight: 493,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 32,
    right: 32,
    minWidth: 0,
    padding: 0,
    borderRadius: '50%',
    '&:hover circle': {
      fill: theme.palette.gray[200],
    },
    zIndex: 14,
  },
  content: {
    zIndex: 13,
    padding: '48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    '& h2': {
      color: theme.palette.black,
      fontFamily: 'Gilroy-Bold',
      fontSize: '36px',
      lineHeight: '42px',
      marginTop: 32,
      marginBottom: 16,
      textAlign: 'center',
    },
  },
  phone: {
    color: theme.palette.black,
    fontFamily: 'Gilroy-Bold',
    fontSize: '24px',
    lineHeight: '28.13px',
    marginTop: 8,
  },
  desc: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: 32,
    textAlign: 'center',
  },
  helpBtn: {
    width: '100%',
    height: 64,
    background: theme.palette.bluegray[400] + ' !important',
    '&:hover': {
      background: theme.palette.bluegray[401] + ' !important',
    },
  },
  helpBtnMini: {
    margin: '5px auto 30px',
    width: 32,
    height: 32,
    borderRadius: '50%',
    background: theme.palette.bluegray[400] + ' !important',
    '&:hover': {
      background: theme.palette.bluegray[401] + ' !important',
    },
  },
  helpText: {
    marginLeft: '4px !important',
    fontFamily: theme.fontFamily.gilroyBold + ' !important',
  },
  phoneContainer: {
    height: 121,
    width: 512,
    border: '2px dashed #CFCFCF',
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  socialButtons: {
    width: 512,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  socialButton: {
    width: 248,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  telegramButton: {
    width: '100%',
    minHeight: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '16px',
    padding: '18px',
    borderRadius: '16px',
    background: theme.palette.gray[100],
    transition: 'background .1s ease-in',
    '&:hover': {
      background: theme.palette.gray[101],
    },
    '& p': {
      color: theme.palette.blue[500],
    },
    cursor: 'pointer',
  },
}));

interface Props {
  toggle: () => void;
  open: boolean;
}

export const HelpPopup: React.FC<Props> = ({ toggle, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { country_code } = useSelector(state => state?.company);
  const phoneNumber = useMemo(() => {
    const numbers = {
      UZ: '+998 78 113 71 44',
      KZ: '+7 707 774 01 70',
    };
    return numbers[country_code] || numbers.UZ;
  }, []);

  return (
    <Dialog onClose={toggle} open={open} className={classes.root} disableScrollLock>
      <Box onClick={toggle} className={classes.closeIcon}>
        <CloseIcon />
      </Box>
      <Box className={classes.content}>
        <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M35.4375 17.5C35.4375 7.9375 27.5625 0.0625 18 0.0625C8.36719 0.0625 0.5625 7.9375 0.5625 17.5C0.5625 27.1328 8.36719 34.9375 18 34.9375C27.5625 34.9375 35.4375 27.1328 35.4375 17.5ZM18 21.0156C19.7578 21.0156 21.2344 22.4922 21.2344 24.25C21.2344 26.0781 19.7578 27.4844 18 27.4844C16.1719 27.4844 14.7656 26.0781 14.7656 24.25C14.7656 22.4922 16.1719 21.0156 18 21.0156ZM14.9062 9.41406C14.8359 8.92188 15.2578 8.5 15.75 8.5H20.1797C20.6719 8.5 21.0938 8.92188 21.0234 9.41406L20.5312 18.9766C20.4609 19.4688 20.1094 19.75 19.6875 19.75H16.2422C15.8203 19.75 15.4688 19.4688 15.3984 18.9766L14.9062 9.41406Z"
            fill="#1F78FF"
          />
        </svg>
        <Box className={classes.title}>
          <Typography variant="h2">{t('navbar.need_help_title')}</Typography>
        </Box>
        <Typography className={classes.desc}>{t('navbar.need_help_desc')}</Typography>
        <Box className={classes.phoneContainer} px={4} mb={2}>
          <Box>
            <Typography>{t('navbar.support_phone')}</Typography>
            <a href={`tel:${phoneNumber?.replaceAll(' ', '')}`}>
              <Typography className={classes.phone} variant="h2">
                {phoneNumber}
              </Typography>
            </a>
          </Box>
          <Box
            sx={{
              width: '176px',
              height: '56px',
              '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              },
            }}
          >
            <img src="/images/Pic2.png" />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" width={512}>
          {country_code === 'KZ' && (
            <Button
              onClick={() => {
                window.open('https://wa.me/998900291199', '_blank');
              }}
              secondary
              fullWidth
              style={{ marginRight: 8 }}
              adornmentStart={<WhatsAppIcon />}
            >
              {t('navbar.need_help_whatsapp')}
            </Button>
          )}
          <SocialButton country_code={country_code} />
        </Box>
      </Box>
    </Dialog>
  );
};

const NeedHelpButton = ({ isOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return (
    <>
      <ListItem className={isOpen ? classes.helpBtn : classes.helpBtnMini} button={true} onClick={toggle}>
        <Box width="100%" display="inline-flex" alignItems="center" justifyContent="center" color="#fff">
          <FontAwesomeIcon icon={faQuestionCircle} />
          {isOpen && (
            <Typography color="#fff" className={classes.helpText}>
              {t('navbar.need_help')}
            </Typography>
          )}
        </Box>
      </ListItem>
      <HelpPopup toggle={toggle} open={open} />
    </>
  );
};

export default memo(NeedHelpButton);

const SocialButton = ({ country_code }: { country_code: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.telegramButton} onClick={() => window.open('https://t.me/billzsupport_bot', '_blank')}>
      <TelegramIcon />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography>{country_code === 'KZ' ? t('navbar.need_help_tg') : t('navbar.support_service_telegram')}</Typography>
        {country_code !== 'KZ' && (
          <Typography style={{ fontSize: '12px' }}>
            {/* HARDCODE UZ NEEDED */}
            Telegramdagi qo'llab-quvvatlash xizmatiga yozing
          </Typography>
        )}
      </Box>
    </Box>
  );
};
