import { lazyLoad } from '../../../shared/utils/loadable';

export const RoleListPage = lazyLoad(
  () => import('./RoleListPage'),
  module => module.default,
);

export const PermissionsPage = lazyLoad(
  () => import('./PermissionsPage'),
  module => module.default,
);

export const SectionsPage = lazyLoad(
  () => import('./SectionsPage'),
  module => module.default,
);

export * from './operations';
export * from './create';
