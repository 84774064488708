/* eslint-disable */
import { orderCreateTableHeaderActionTypes } from '../actions/orderCreateTableHeaderActions/orderCreateTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'Кол-во',
      accessor: 'quantity',
      is_active: true,
    },
    { Header: 'К заказу', accessor: 'for_order', is_active: true },
    { Header: 'Цена поставки', accessor: 'supply_price', is_active: true },
    { Header: 'Наценка', accessor: 'extra_charge', is_active: true },
    { Header: 'Цена продажи', accessor: 'retail_price', is_active: true },
    { Header: 'Малый остаток', accessor: 'small_remains', is_active: true },
    // { Header: 'До окончания', accessor: 'till_finish', is_active: true },
  ],
};

const orderCreateTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case orderCreateTableHeaderActionTypes.UPDATE_ORDER_CREATE_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case orderCreateTableHeaderActionTypes.RESET_ORDER_CREATE_TABLE_HEADER:
      return initialState;
    case orderCreateTableHeaderActionTypes.REMOVE_CUSTOM_COLUMN:
      const filteredColumns = [...state?.columns].filter(column => column?.accessor !== payload);
      return {
        ...state,
        columns: filteredColumns,
      };
    case orderCreateTableHeaderActionTypes.UPDATE_CUSTOM_COLUMN:
      const newColumns = [...state?.columns].map(column => {
        return column?.accessor === payload?.id ? { ...column, Header: payload?.name } : column;
      });
      return {
        ...state,
        columns: newColumns,
      };

    default:
      return state;
  }
};

export default orderCreateTableHeaderReducer;
