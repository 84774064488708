/* eslint-disable */
const shopTransactionTableHeaders = {
  columns: [
    {
      headerName: 'Дата',
      field: 'date',
      hide: false,
      is_main: true,
      lockPosition: 'left',
      suppressMovable: true,
      always_active: true,
    },
    {
      headerName: 'ID',
      field: 'external_id',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Магазин',
      field: 'shop_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'ФИО клиента',
      field: 'customer_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'ФИО кассира',
      field: 'cashier_name',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'ФИО продавца',
      field: 'seller_names',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Тип транзакции',
      field: 'order_type',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Кол-во проданных товаров и услуг',
      field: 'sold_measurement_value',
      hide: false,
    },
    {
      headerName: 'Кол-во возвращенных товаров',
      field: 'returned_measurement_value',
      hide: false,
    },
    {
      headerName: 'Сумма транзакции',
      field: 'total_price',
      hide: false,
    },
    {
      headerName: 'Скидка',
      field: 'discount_percent',
      hide: false,
    },
    {
      headerName: 'Сумма оплаты без скидки',
      field: 'gross_sales',
      hide: false,
    },
    {
      headerName: 'Средняя цена за товар / услугу в чеке',
      field: 'average_product_price',
      hide: false,
    },
    {
      headerName: 'Комментарий к транзакции',
      field: 'comment',
      hide: false,
    },
    {
      headerName: 'История редактирований',
      field: 'is_modified',
      hide: false,
    },
  ],
};

export default shopTransactionTableHeaders;
