import { ApiCaller } from '../utils';
import { AxiosRequestConfig } from 'axios';
import { Service } from './Service';
import { ApplicationsServiceNS } from './ApplicationsService.d';
import { DefaultResponse } from '../../types/common.types';

const BASE_URL = 'v1';
const apiCaller = new ApiCaller(BASE_URL);

export class ApplicationsService implements Service {
  public static getList(params: ApplicationsServiceNS.LoadListParamsDTO, config?: AxiosRequestConfig) {
    return apiCaller.get<ApplicationsServiceNS.LoadApplicationsListDTO>('/application', params, config);
  }
  // FIXME:
  // getById сейчас не работает как описано в сваггере
  // вместо возвращения application, он возвращает массив fields
  // поэтому getFieldsById имеет одинаковый путь, но с правильными типами
  public static getById(id: string, config?: AxiosRequestConfig) {
    return apiCaller.get<ApplicationsServiceNS.ApplicationDTO>(`/application/${id}`, config);
  }

  public static getFieldsById(id: string, config?: AxiosRequestConfig) {
    return apiCaller.get<ApplicationsServiceNS.ApplicationFieldsDTO>(`/application/${id}`, config);
  }
  // End of FIXME

  public static getStatusById(id: string, config?: AxiosRequestConfig) {
    return apiCaller.get<ApplicationsServiceNS.ApplicationStatusDTO>(`/application-request/${id}`, config);
  }

  public static postActivate(params: ApplicationsServiceNS.ActivateApplicationParamsDTO, config?: AxiosRequestConfig) {
    return apiCaller.post<DefaultResponse>('/application/activate', params, config);
  }

  public static postDeactivate(params: ApplicationsServiceNS.DeactivateApplicationParamsDTO, config?: AxiosRequestConfig) {
    return apiCaller.post<DefaultResponse>('/application/deactivate', params, config);
  }

  public static postRequestApplication(params: ApplicationsServiceNS.RequestApplicationParamsDTO, config?: AxiosRequestConfig) {
    return apiCaller.post<DefaultResponse>('/application-request', params, config);
  }

  public static putBillzPayOnboarding(params: ApplicationsServiceNS.ActivateBillzPayDTO, config?: AxiosRequestConfig) {
    return apiCaller.put<DefaultResponse>('/billz-pay/onboarding', params, config);
  }
}
