/* eslint-disable */
const sellerReportProductsTableHeaders = {
  settings: {
    showCategoriesInOneColumn: true,
  },
  columns: [
    {
      headerName: 'Магазин',
      field: 'shop_name',
      hide: false,
      is_main: true,
      always_active: true,
      lockPosition: 'left',
    },
    {
      headerName: 'Дата',
      field: 'date',
      hide: false,
      is_main: true,
      always_active: true,
      lockPosition: 'left',
    },
    {
      headerName: 'Продавец',
      field: 'seller_name',
      hide: false,
      is_main: true,
      always_active: true,
    },
    {
      headerName: 'Наименование',
      field: 'product_name',
      hide: false,
      is_main: true,
      always_active: true,
    },
    {
      headerName: 'Артикул',
      field: 'product_sku',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Баркод',
      field: 'product_barcode',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Категория',
      field: 'product_categories',
      hide: false,
      is_main: true,
    },
    {
      headerName: 'Бренд',
      field: 'product_brand_name',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Поставщики',
      field: 'product_suppliers',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Архивирован',
      field: 'product_is_archived',
      hide: true,
      is_main: true,
    },
    {
      headerName: 'Кол-во проданных',
      field: 'sold_measurement_value',
      desc: 'Количество товаров, которые были проданы за выбранный период без учета возвратов',
      hide: false,
      comparable: true,
    },
    {
      headerName: 'Кол-во возвращенных',
      field: 'returned_measurement_value',
      desc: 'Количество товаров, которые были возвращены за выбранный период',
      hide: false,
      comparable: true,
    },
    {
      headerName: 'Продано за вычетом возвратов',
      field: 'net_sold_measurement_value',
      desc: 'Количество проданных товаров за вычетом возвратов',
      hide: false,
      comparable: true,
    },
    {
      headerName: 'Продажи без учета скидки',
      field: 'gross_sales',
      desc: 'Продажи без учета скидки (Продажи товаров по цене Retail Price UZS х Количество продаж)',
      hide: false,
      comparable: true,
    },
    {
      headerName: 'Сумма возвратов',
      field: 'returned_sales_sum',
      desc: 'Описание значения',
      hide: true,
      comparable: true,
    },
    {
      headerName: 'Продажи со скидкой с учетом возвратов',
      field: 'net_sales',
      desc: 'Описание значения',
      hide: true,
      comparable: true,
    },
    // {
    //   headerName: 'Продажи с учетом скидки',
    //   field: 'sold_with_discount',
    //   desc: 'Описание значения',
    //   hide: true,
    //   comparable: true,
    // },
    {
      headerName: 'Продажи по цене закупки',
      field: 'sold_supply_sum',
      desc: 'Описание значения',
      hide: true,
      comparable: true,
    },
    {
      headerName: 'Валовая  прибыль',
      field: 'net_profit',
      desc: 'Описание значения',
      hide: true,
      comparable: true,
    },
    {
      headerName: 'Средняя наценка',
      field: 'average_margin',
      desc: 'Описание значения',
      hide: true,
      comparable: true,
    },
    {
      headerName: 'Скидка',
      field: 'discount',
      desc: 'Описание значения',
      hide: true,
      comparable: true,
    },
    // {
    //   headerName: 'Начальный остаток',
    //   field: 'left_start_date',
    //   desc: 'Описание значения',
    //   hide: false,
    //   comparable: true,
    // },
    // {
    //   headerName: 'Сумма начального остатка по цене продаж',
    //   field: 'left_start_date_retail_price',
    //   desc: 'Описание значения',
    //   hide: false,
    //   comparable: true,
    // },
    // {
    //   headerName: 'Сумма начального остатка по цене покупки',
    //   field: 'left_start_date_supply_price',
    //   desc: 'Описание значения',
    //   hide: false,
    //   comparable: true,
    // },
    // {
    //   headerName: 'Конечный остаток',
    //   field: 'left_end_date',
    //   desc: 'Описание значения',
    //   hide: false,
    //   comparable: true,
    // },
    // {
    //   headerName: 'Сумма конечного остатка по цене продаж',
    //   field: 'left_end_date_retail_price',
    //   desc: 'Описание значения',
    //   hide: false,
    //   comparable: true,
    // },
    // {
    //   headerName: 'Сумма конечного остатка по цене покупки',
    //   field: 'left_end_date_supply_price',
    //   desc: 'Описание значения',
    //   hide: false,
    //   comparable: true,
    // },
  ],
};

export default sellerReportProductsTableHeaders;
