/* eslint-disable */
import { Navigate } from 'react-router-dom';
import CatalogLayout from 'layouts/CatalogLayout';

import { CardPage } from 'pages';

const crmRoutes = {
  path: 'client-details',
  element: <CatalogLayout />,
  children: [
    { path: ':id', element: <CardPage /> },
    { path: '*', element: <Navigate to="/clients" /> },
  ],
};

export default crmRoutes;
