import { Paper } from '../Paper';
import { useStyles } from './CopyToClipboardBlock.styles';
import clsx from 'clsx';

type CopyToClipboardBlockProps = {
  value: string;
  onCopySuccess?: () => void;
  className?: string;
};

const CopyToClipboardBlock = ({ value, className, onCopySuccess }: CopyToClipboardBlockProps) => {
  const classes = useStyles();

  const handleClick = () => {
    navigator.clipboard.writeText(value).then(onCopySuccess);
  };

  const rootClassName = clsx(classes.root, className);

  return (
    <Paper className={rootClassName} type="dashed" borderRadius="md" onClick={handleClick}>
      {value}
    </Paper>
  );
};

export default CopyToClipboardBlock;
