import React from 'react';
import { useTranslation } from 'react-i18next';
import usePageTitle from 'shared/hooks/usePageTitle';
import { CreateUserDrawer } from '../../../components/settings/CreateUserDrawer';
import TabContainer from '../../../components/Tab/TabContainer';
import { useColumns } from './useColumns';
import PaginationTable from '../../../components/Table/PaginationTable';
import ConfirmDialog from '../../../components/ConfirmDialog';
import BigWarningCircleIcon from '../../../shared/icons/BigWarningCircleIcon';
import { Button, SectionHeader } from '../../../shared/ui';
import { EmptyStub } from '../../../components/settings/EmptyStub';
import { useNavigate } from 'react-router-dom';
import { UserType } from '../../../shared/types/user.types';
import { useStyles } from './IntegrationKeys.styles';
import { EditUserDrawer } from '../EditUserDrawer';
import InputSearch from '../../Input/InputSearch';
import PlusIcon from '../../../shared/icons/PlusIcon';
import { tabStatuses } from '../../../shared/constants/employeeStatuses';
import { UserServiceNS } from '@/shared/services';
import { useGetUsersListInfiniteQuery, useDeleteIntegrationUserMutation, useUserSecretMutation } from '@/shared/hooks/user';

const DEFAULT_TABLE_LIMIT = 10;

const IntegrationKeys = () => {
  usePageTitle('titles.integrations_keys');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const deletedStatus = tabStatuses.find(item => item.status_id === 'deleted');
  const deletedStatusId = deletedStatus?.id;

  const [searchValue, setSearchValue] = React.useState('');
  const [tab, setTab] = React.useState<'active' | 'deleted'>('active');
  const [showCreateUserDrawer, setShowCreateUserDrawer] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState<string | null>(null);
  const [user, setUser] = React.useState<UserServiceNS.UserDTO | null>(null);
  const [currentPage, setCurrentPage] = React.useState(1);

  const dataRef = React.useRef<UserServiceNS.LoadUsersListDTO | null>(null);

  const {
    data: payload,
    isLoading: isUsersListLoading,
    isFetching,
  } = useGetUsersListInfiniteQuery({
    type: UserType.INTEGRATED,
    search: searchValue,
    limit: DEFAULT_TABLE_LIMIT,
    page: currentPage,
    status_ids: tab === 'deleted' && deletedStatus?.id ? deletedStatusId : [],
  });

  const data = payload?.pages.flatMap(page => page).at(0);
  const users = data?.users ?? [];

  const {
    mutate: deleteIntegratedUser,
    isSuccess: isDeleted,
    isLoading: isDeleting,
    reset: resetDeleting,
  } = useDeleteIntegrationUserMutation();

  const { mutate: getSecret } = useUserSecretMutation();

  const handleCopy = (id: string) => {
    getSecret(id);
  };

  const handleBackClick = () => {
    navigate('/settings/company');
  };

  const isLoading = users.length === 0 && searchValue.length === 0 && !isFetching;
  const activeUsersCount = tab === 'active' ? data?.count : dataRef?.current?.count;
  const deletedUsersCount = data?.deleted_users_count ?? 0;
  const counts = [activeUsersCount ?? 0, deletedUsersCount];
  const columns = useColumns({ status: tab, onEdit: setUser, onCopy: handleCopy, onDelete: setDeleteId });

  const tabs = [
    {
      id: 'active',
      label: t('titles.active'),
    },
    {
      id: 'deleted',
      label: t('titles.deleted'),
    },
  ];

  React.useEffect(() => {
    if (data && tab !== 'deleted') {
      dataRef.current = data;
    }
  }, [data, tab]);

  React.useEffect(() => {
    if (isDeleted) {
      setDeleteId(null);
      resetDeleting();
    }
  }, [isDeleted, resetDeleting]);

  return (
    <div className={classes.root}>
      <SectionHeader title={t('titles.integrations_keys')} onBackClick={handleBackClick} />

      <div className={classes.content}>
        {isLoading ? (
          <EmptyStub onClick={() => setShowCreateUserDrawer(true)} />
        ) : (
          <div className={classes.block}>
            <TabContainer className={classes.tabs} tabs={tabs} selected={tab} setSelected={setTab} counts={counts} />

            <div className={classes.top}>
              <InputSearch name="search" placeholder={t('integrations.search_placeholder')} fullWidth setSearchTerm={setSearchValue} />

              <Button startIcon={<PlusIcon fill="#fff" />} className={classes.createButton} onClick={() => setShowCreateUserDrawer(true)}>
                {t('integrations.new_key')}
              </Button>
            </div>

            <PaginationTable
              width="100%"
              key={tab}
              columns={columns}
              data={users}
              isDataLoading={isUsersListLoading}
              pageCount={Math.ceil((data?.count ?? 1) / DEFAULT_TABLE_LIMIT)}
              noDataTitle={t('integrations.not_found.title')}
              noDataDesc={t('integrations.not_found.description')}
              showPageSizeSelect={false}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </div>

      <CreateUserDrawer visible={showCreateUserDrawer} onClose={() => setShowCreateUserDrawer(false)} />
      {user && <EditUserDrawer user={user} visible={Boolean(user)} onClose={() => setUser(null)} />}

      {deleteId && (
        <ConfirmDialog
          open={Boolean(deleteId)}
          setOpen={() => setDeleteId(null)}
          icon={<BigWarningCircleIcon />}
          title={t('integrations.delete_key_modal.title')}
          desc={t('integrations.delete_key_modal.description')}
          actions={
            <>
              <Button variant="secondary" onClick={() => setDeleteId(null)}>
                {t('buttons.cancel')}
              </Button>
              <Button disabled={isDeleting} onClick={() => deleteId && deleteIntegratedUser(deleteId)}>
                {t('buttons.delete')}
              </Button>
            </>
          }
        />
      )}
    </div>
  );
};

export default IntegrationKeys;
