/* eslint-disable */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function usePageTitle(title) {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = title ? `${t(String(title))} | BILLZ` : 'BILLZ';
  }, [title]);
}
