/* eslint-disable */
import { useEffect, useRef } from 'react';
import { messages$ } from 'services/websocket/SharedWorkerWebsocketClient';
import tryParseJSON from '../utils/tryParseJSON';
// import useWebsocket from './useWebsocket'

// const useListenWebsocket = (fn, deps = []) => {
// const { websocket } = useWebsocket()
// const handleWebsocketResponse = (event) => {
//   const message = isParsableString(event?.data)
//     ? JSON.parse(event?.data || null)
//     : event?.data || null
//   const success =
//     typeof message === 'object'
//       ? !('status_code' in message) || message?.status_code === 200
//       : true
//   if (fn) {
//     fn(message, success)
//   }
// }
// useEffect(() => {
//   if (websocket?.addEventListener) {
//     websocket.addEventListener('message', handleWebsocketResponse)
//   }
//   return () => {
//     if (websocket?.removeEventListener) {
//       websocket.removeEventListener('message', handleWebsocketResponse)
//     }
//   }
// }, deps)
// }

const useListenWebsocket = (fn, deps = []) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = fn;
  }, [fn]);

  useEffect(() => {
    const handleWebsocketResponse = message => {
      const success = typeof message === 'object' ? !('status_code' in message) || message?.status_code === 200 : true;
      if (savedCallback.current) {
        savedCallback.current(message, success);
      }
    };

    const subscription = messages$.subscribe(handleWebsocketResponse);

    return () => {
      subscription.unsubscribe();
    };
  }, deps);
};
export default useListenWebsocket;
