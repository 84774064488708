/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const companyCurrency = {
  getAll: filter => request.get(`v1/company-currency${qs.stringify(filter, { addQueryPrefix: true })}`),
  getAllCompanyCurrencies: () => request.get(`v2/company-currencies`),
  getCurrencyRates: (filter?) =>
    request.get(
      `v2/company-currency-rates${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  create: data => request.post(`v1/company-currency`, data),
  getSingle: id => request.get(`v1/company-currency/${id}`),
  update: ({ id, data }) => request.put(`v1/company-currency/${id}`, data),
  delete: id => request.delete(`v1/company-currency/${id}`),
};

export default companyCurrency;
