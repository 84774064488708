/* eslint-disable */
import { writeOffCreateTableHeaderActionTypes } from '../actions/writeOffCreateTableHeaderActions/writeOffCreateTableHeaderActionTypes';

export const initialState = {
  columns: [
    {
      Header: 'Наименование',
      accessor: 'name',
      is_active: true,
    },
    {
      Header: 'Артикул',
      accessor: 'articul',
      is_active: true,
    },
    {
      Header: 'Баркод',
      accessor: 'barcode',
      is_active: true,
    },
    {
      Header: 'Категория',
      accessor: 'category',
      is_active: true,
    },
    {
      Header: 'Текущее кол-во',
      accessor: 'quantity',
      is_active: true,
    },
    { Header: 'Цена поставки', accessor: 'price', is_active: true },
    { Header: 'Цена продажи', accessor: 'retail_price', is_active: true },
    { Header: 'Списание', accessor: 'write_off', is_active: true },
  ],
};

const writeOffCreateTableHeaderReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case writeOffCreateTableHeaderActionTypes.UPDATE_WRITE_OFF_CREATE_TABLE_HEADER:
      return {
        ...state,
        columns: payload,
      };

    case writeOffCreateTableHeaderActionTypes.RESET_WRITE_OFF_CREATE_TABLE_HEADER:
      return initialState;
    case writeOffCreateTableHeaderActionTypes.REMOVE_CUSTOM_COLUMN:
      const filteredColumns = [...state?.columns].filter(column => column?.accessor !== payload);
      return {
        ...state,
        columns: filteredColumns,
      };
    case writeOffCreateTableHeaderActionTypes.UPDATE_CUSTOM_COLUMN:
      const newColumns = [...state?.columns].map(column => {
        return column?.accessor === payload?.id ? { ...column, Header: payload?.name } : column;
      });
      return {
        ...state,
        columns: newColumns,
      };

    default:
      return state;
  }
};

export default writeOffCreateTableHeaderReducer;
