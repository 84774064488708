/* eslint-disable */
const ArrowForward = props => (
  <svg width="10" height="14" viewBox="0 0 10 14" fill="#BDBDBD" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.90625 7.53125C9.1875 7.25 9.1875 6.78125 8.90625 6.5L2.84375 0.40625C2.53125 0.125 2.0625 0.125 1.78125 0.40625L1.0625 1.125C0.78125 1.40625 0.78125 1.875 1.0625 2.1875L5.875 7L1.0625 11.8438C0.78125 12.1562 0.78125 12.625 1.0625 12.9062L1.78125 13.625C2.0625 13.9062 2.53125 13.9062 2.84375 13.625L8.90625 7.53125Z"
      fill="inherit"
    />
  </svg>
);

export default ArrowForward;
