import SettingIcon from 'shared/icons/SettingIcon';
import BagIcon from 'shared/icons/BagIcon';
import ScannerIcon from 'shared/icons/ScannerIcon';
import UserIcon from 'shared/icons/UserIcon';
import CalcIcon from 'shared/icons/CalcIcon';
import PieChartIcon from 'shared/icons/PieChartIcon';
import { productsRouteData } from './products/routeData';
import DocumentIcon from 'shared/icons/DocumentIcon';
import FinancingIcon from 'shared/icons/FinancingIcon';

export const routeData = [
  productsRouteData,
  {
    label: 'sales',
    id: '2',
    icon: <ScannerIcon />,
    href: '/order',
    className: 'sale_guide_element',
    children: [
      {
        label: 'new_sale',
        active: '/order/new-order/*',
        id: '21',
        href: '/order/create',
      },
      {
        label: 'all_sales',
        active: '/order/all/*',
        id: '22',
        href: '/order/all',
      },
      {
        label: 'cash_shifts',
        active: '/order/cash-shifts/*',
        id: '23',
        href: '/order/cash-shifts',
        slug: 'cash-shifts-detail',
        featureFlag: 'finance',
        // noSlug: ['cashbox-shifts'],
      },
      {
        label: 'cashbox_operations',
        active: '/order/cashbox-operations/*',
        id: '24',
        href: '/order/cashbox-operations',
      },
    ],
  },
  {
    label: 'clients',
    id: '3',
    icon: <UserIcon />,
    href: '/clients',
    children: [
      {
        label: 'all_clients',
        active: '/clients',
        id: '31',
        href: '/clients',
      },
      {
        label: 'group_of_clients',
        active: '/clients/clients-group/*',
        id: '32',
        href: '/clients/clients-group',
      },
      {
        label: 'loyalty_program',
        active: '/clients/loyalty-program/*',
        id: '34',
        href: '/clients/loyalty-program',
      },
      {
        label: 'debt_of_clients',
        active: '/clients/debts/*',
        id: '35',
        href: '/clients/debts/cashbox',
      },
    ],
  },
  {
    label: 'marketing',
    id: '4',
    icon: <CalcIcon />,
    href: '/marketing',
    children: [
      {
        label: 'promotions',
        active: '/marketing/promotions/*',
        id: '41',
        href: '/marketing/promotions',
      },
      {
        label: 'promo-codes',
        active: '/marketing/promo-codes/*',
        id: '42',
        href: '/marketing/promo-codes/tutorial',
        slug: 'marketing-promo-codes',
      },
      {
        label: 'sms_mailing',
        active: '/marketing/sms-mailing/*',
        id: '43',
        href: '/marketing/sms-mailing',
      },
      {
        label: 'gift_cards',
        active: '/marketing/gift-cards/*',
        id: '44',
        href: '/marketing/gift-cards/tutorial',
      },
    ],
  },

  {
    label: 'reports',
    id: '5',
    href: '/reports',
    icon: <PieChartIcon />,
    children: [
      {
        label: 'favorites',
        active: '/reports/favorites/*',
        id: '51',
        href: '/reports/favorites',
      },
      {
        label: 'shop',
        active: '/reports/shop/*',
        id: '53',
        href: '/reports/shop',
      },
      {
        label: 'products',
        active: '/reports/products/*',
        id: '54',
        href: '/reports/products',
      },
      {
        label: 'sellers',
        active: '/reports/sellers/*',
        id: '55',
        href: '/reports/sellers',
      },
      {
        label: 'clients',
        active: '/reports/clients/*',
        id: '56',
        href: '/reports/clients',
      },
      {
        label: 'finance',
        active: '/reports/finances/*',
        id: '57',
        href: '/reports/finances',
      },
    ],
  },
  {
    label: 'finance',
    id: '6',
    href: '/finance',
    icon: <DocumentIcon />,
    children: [
      {
        label: 'finance_categories',
        active: '/finance/categories',
        id: '61',
        href: '/finance/categories',
      },
      {
        label: 'finance_transactions',
        active: '/finance/transactions',
        id: '62',
        href: '/finance/transactions',
      },
      {
        label: 'cashbox_state',
        active: '/finance/state',
        id: '63',
        href: '/finance/state',
      },
    ],
  },
  {
    label: 'wcl',
    id: '7',
    href: '/wcl',
    icon: <FinancingIcon />,
  },
  {
    label: 'management',
    id: '8',
    href: '/management',
    icon: <BagIcon />,
    children: [
      {
        label: 'employees',
        active: '/management/employees/*',
        id: '78',
        href: '/management/employees',
      },
      {
        label: 'roles',
        active: '/management/roles/*',
        id: '79',
        href: '/management/roles',
      },
    ],
  },
  {
    label: 'settings',
    id: '7',
    href: '/settings',
    icon: <SettingIcon />,
    className: 'settings_guide_element',
    children: [
      {
        label: 'profile',
        active: '/settings/profile/*',
        id: '71',
        href: '/settings/profile',
      },
      {
        label: 'company',
        active: '/settings/company/*',
        id: '72',
        href: '/settings/company',
      },
      {
        label: 'plan',
        active: '/settings/plan/*',
        id: '73',
        href: '/settings/plan',
      },
      {
        label: 'cheques',
        id: '76',
        active: '/settings/cheque/*',
        href: '/settings/cheque',
      },
      {
        label: 'currencies_and_payments',
        active: '/settings/payment/*',
        id: '77',
        href: '/settings/payment',
      },
      {
        label: 'products',
        active: '/settings/product/*',
        id: '78',
        href: '/settings/product',
      },
      {
        label: 'notification',
        active: '/settings/notification/*',
        id: '79',
        href: '/settings/notification',
      },
      {
        label: 'applications',
        active: '/settings/applications/*',
        id: '80',
        href: '/settings/applications',
      },
    ],
  },
];
