/* eslint-disable */
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import Button from 'stories/Button/Button';

const BillzPartner = ({ classes, closeDrawer }) => {
  const { t } = useTranslation();

  function handleBillzPartner() {
    closeDrawer();
    window.open('https://billz.io/landing/partners', '_blank');
  }

  return (
    <>
      <Button
        secondary
        fullWidth
        adornmentEnd={<FontAwesomeIcon icon={faArrowRight} />}
        className={classes.actionBtn}
        contentClass={classes.actionBtnContent}
        onClick={handleBillzPartner}
      >
        <Box sx={{ display: 'flex', alignItems: 'start', gap: '4px' }}>
          <Typography style={{ textAlign: 'left' }}>
            <Trans components={{ span: <span style={{ color: '#4993DD' }} /> }}>{t('dashboard.billz.partner.title')}</Trans>
          </Typography>
        </Box>
      </Button>
    </>
  );
};

export default BillzPartner;
