/* eslint-disable */
import metrics from './metrics';
import auth from './auth';
import barcode from './barcode';
import users from './users';
import products from './products';
import country from './country';
import timeZone from './time-zone';
import company from './company';
import dashboard from './dashboard';
import image from './image';
import avatar from './avatar';
import currency from './currency';
import companyCurrency from './company-currency';
import cheque from './cheque';
import paymentType from './payment-type';
import companyPaymentType from './company-payment-type';
import shop from './shop';
import cashBox from './cash-box';
import productType from './product-type';
import product from './product';
import productDetail from './product-detail';
import brand from './brand';
import category from './category';
import measurementUnit from './measurement-unit';
import productVariant from './product-variant';
import attribute from './attribute';
import attributeValue from './attribute-value';
import modificator from './modificator';
import productModificator from './product-modificator';
import pack from './package';
import productAttributes from './product-attribute';
import admin from './admin';
import excelExport from './excel-export';
import userCompany from './user-company';
import supplier from './supplier';
import productCharacteristic from './product-characteristic';
import order from './order';
import customer from './customer';
import cashier from './cashier';
import rounding from './rounding';
import report from './report';
import importProduct from './importProduct';
import excelImport from './excel-import';
import transfer from './transfer';
import repricing from './repricing';
import writeOff from './write-off';
import loyaltyProgram from './loyalty-program';
import stocktaking from './stocktaking';
import supplierOrder from './supplier-order';
import employee from './employee';
import role from './role';
import permission from './permission';
import priceTag from './price-tags';
import smsMailing from './sms-mailing';
import promotion from './promotion';
import telegramNotification from './telegram-notification';
import debt from './debt';
import finance from './finance';
import ePos from './e-pos';
import applications from './applications';
import webkassa from './webkassa';
import integrations from './integrations';
import giftCard from './giftCard';
import kaspi from './kaspi';
import ingenico from './ingenico';
import insurance from './insurance';

export const requests = {
  permission,
  barcode,
  role,
  auth,
  employee,
  users,
  products,
  country,
  timeZone,
  company,
  image,
  avatar,
  currency,
  companyCurrency,
  cheque,
  paymentType,
  companyPaymentType,
  shop,
  cashBox,
  productType,
  product,
  productDetail,
  brand,
  dashboard,
  category,
  measurementUnit,
  productVariant,
  attribute,
  attributeValue,
  modificator,
  productModificator,
  pack,
  productAttributes,
  admin,
  excelExport,
  userCompany,
  supplier,
  productCharacteristic,
  order,
  customer,
  cashier,
  rounding,
  report,
  importProduct,
  excelImport,
  transfer,
  repricing,
  writeOff,
  loyaltyProgram,
  stocktaking,
  supplierOrder,
  priceTag,
  smsMailing,
  promotion,
  telegramNotification,
  debt,
  finance,
  ePos,
  applications,
  webkassa,
  integrations,
  giftCard,
  kaspi,
  metrics,
  ingenico,
  insurance,
};
