/* eslint-disable */
const ScannerIcon = props => (
  <svg width="32" height="48" viewBox="0 0 32 48" fill="#1F78FF" xmlns="http://www.w3.org/2000/svg" {...props}>
    {props?.gray ? (
      <>
        <path
          d="M13.4148 16C13.7776 16 14.0721 16.2972 14.0721 16.6642C14.0721 17.0303 13.7784 17.3275 13.4148 17.3275L12.008 17.3284C10.8403 17.3301 9.89033 18.2898 9.89033 19.4691V21.1218C9.89033 21.4879 9.59497 21.786 9.23224 21.786C8.86951 21.786 8.57501 21.4879 8.57501 21.1218V19.4691C8.57501 17.5585 10.1149 16.0026 12.0071 16.0009L13.4148 16Z"
          fill="currentColor"
        />
        <path
          d="M19.6096 16.0003H20.9854C22.8828 16.0003 24.4252 17.557 24.4252 19.472V21.122C24.4252 21.4881 24.1316 21.7862 23.768 21.7862C23.4053 21.7862 23.1108 21.4881 23.1108 21.122V19.472C23.1108 18.2892 22.1573 17.3269 20.9854 17.3269H19.6096C19.2469 17.3269 18.9524 17.0305 18.9524 16.6645C18.9524 16.2975 19.2469 16.0003 19.6096 16.0003Z"
          fill="currentColor"
        />
        <path
          d="M18.8858 19.3268H14.1151C13.0234 19.3381 12.146 20.2385 12.1555 21.3412V22.4482C12.1581 22.5772 12.2608 22.6826 12.3887 22.687H20.6105C20.7392 22.6835 20.8419 22.578 20.8462 22.4482V21.3412C20.8471 20.8086 20.6433 20.2969 20.2754 19.916C19.9101 19.5386 19.4083 19.3268 18.8858 19.3268Z"
          fill="currentColor"
        />
        <path
          d="M7.65809 24.0367H25.3428C25.7055 24.0367 26 24.3339 26 24.7008C26 25.0669 25.7055 25.3633 25.3428 25.3633H24.4256V28.5274C24.4256 30.4432 22.8823 32 20.9857 32H19.61C19.2464 32 18.9519 31.7028 18.9519 31.3358C18.9519 30.9697 19.2464 30.6725 19.61 30.6725H20.9857C22.1577 30.6725 23.1111 29.7111 23.1111 28.5274V25.3633H20.8458V26.2445C20.8545 27.3472 19.9779 28.2484 18.8854 28.2589H14.1146C13.023 28.2484 12.1464 27.3472 12.155 26.2445V25.3633H9.88973V28.5309C9.88973 29.7102 10.8406 30.6699 12.0091 30.6716L13.4151 30.6725C13.7778 30.6725 14.0723 30.9697 14.0723 31.3358C14.0715 31.7028 13.7778 32 13.4142 32L12.0074 31.9991C10.1151 31.9974 8.57441 30.4415 8.57441 28.5309V25.3633H7.65809C7.29536 25.3633 7 25.0669 7 24.7008C7 24.3339 7.29536 24.0367 7.65809 24.0367Z"
          fill="currentColor"
        />
      </>
    ) : (
      <>
        <path
          d="M13.4148 16C13.7776 16 14.0721 16.2972 14.0721 16.6642C14.0721 17.0303 13.7784 17.3275 13.4148 17.3275L12.008 17.3284C10.8403 17.3301 9.89033 18.2898 9.89033 19.4691V21.1218C9.89033 21.4879 9.59497 21.786 9.23224 21.786C8.86951 21.786 8.57501 21.4879 8.57501 21.1218V19.4691C8.57501 17.5585 10.1149 16.0026 12.0071 16.0009L13.4148 16Z"
          fill="inherit"
        />
        <path
          d="M19.6096 16.0003H20.9854C22.8828 16.0003 24.4252 17.557 24.4252 19.472V21.122C24.4252 21.4881 24.1316 21.7862 23.768 21.7862C23.4053 21.7862 23.1108 21.4881 23.1108 21.122V19.472C23.1108 18.2892 22.1573 17.3269 20.9854 17.3269H19.6096C19.2469 17.3269 18.9524 17.0305 18.9524 16.6645C18.9524 16.2975 19.2469 16.0003 19.6096 16.0003Z"
          fill="inherit"
        />
        <path
          d="M18.8858 19.3268H14.1151C13.0234 19.3381 12.146 20.2385 12.1555 21.3412V22.4482C12.1581 22.5772 12.2608 22.6826 12.3887 22.687H20.6105C20.7392 22.6835 20.8419 22.578 20.8462 22.4482V21.3412C20.8471 20.8086 20.6433 20.2969 20.2754 19.916C19.9101 19.5386 19.4083 19.3268 18.8858 19.3268Z"
          fill="inherit"
        />
        <path
          d="M7.65809 24.0367H25.3428C25.7055 24.0367 26 24.3339 26 24.7008C26 25.0669 25.7055 25.3633 25.3428 25.3633H24.4256V28.5274C24.4256 30.4432 22.8823 32 20.9857 32H19.61C19.2464 32 18.9519 31.7028 18.9519 31.3358C18.9519 30.9697 19.2464 30.6725 19.61 30.6725H20.9857C22.1577 30.6725 23.1111 29.7111 23.1111 28.5274V25.3633H20.8458V26.2445C20.8545 27.3472 19.9779 28.2484 18.8854 28.2589H14.1146C13.023 28.2484 12.1464 27.3472 12.155 26.2445V25.3633H9.88973V28.5309C9.88973 29.7102 10.8406 30.6699 12.0091 30.6716L13.4151 30.6725C13.7778 30.6725 14.0723 30.9697 14.0723 31.3358C14.0715 31.7028 13.7778 32 13.4142 32L12.0074 31.9991C10.1151 31.9974 8.57441 30.4415 8.57441 28.5309V25.3633H7.65809C7.29536 25.3633 7 25.0669 7 24.7008C7 24.3339 7.29536 24.0367 7.65809 24.0367Z"
          fill="inherit"
        />
      </>
    )}
  </svg>
);

export default ScannerIcon;
