/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

interface ReturnOrderTableHeaderState {
  columns: any[];
}

export const initialState: ReturnOrderTableHeaderState = {
  columns: [
    {
      headerName: 'ID',
      field: 'id',
      hide: false,
      minWidth: 70,
      width: 129,
    },
    {
      headerName: 'Наименование',
      field: 'name',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Магазин',
      field: 'shop',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Поставщик',
      field: 'supplier',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Статус',
      field: 'status',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Сумма возврата',
      field: 'return_amount',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Кол-во',
      field: 'returned_quantity',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Дата',
      field: 'created_at',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Пользователь',
      field: 'user',
      hide: false,
      minWidth: 70,
    },
    {
      headerName: 'Действие',
      field: 'actions',
      hide: false,
      minWidth: 70,
    },
  ],
};

const returnOrderTableHeaderSlice = createSlice({
  name: 'returnOrderTableHeader',
  initialState,
  reducers: {
    updateTable: (state, action) => {
      return {
        ...state,
        columns: action.payload,
      };
    },
    resetTable: () => {
      return initialState;
    },
  },
});

export const { updateTable, resetTable } = returnOrderTableHeaderSlice.actions;
export default returnOrderTableHeaderSlice.reducer;
