/* eslint-disable */
const shopReportTableHeaders = {
  columns: [
    {
      headerName: 'Дата',
      field: 'date',
      hide: false,
      is_main: true,
      always_active: true,
    },
    {
      headerName: 'Магазин',
      field: 'shop_name',
      hide: false,
      is_main: true,
      always_active: true,
    },
    {
      headerName: 'Выручка',
      field: 'gross_sales',
      hide: false,
    },
    {
      headerName: 'Сумма скидки',
      field: 'discount_sum',
      hide: true,
    },
    {
      headerName: 'Процент Скидки',
      field: 'discount_percent',
      hide: true,
    },
    {
      headerName: 'Количество возвращенных товаров',
      field: 'products_returned',
      hide: true,
    },
    {
      headerName: 'Возвраты по цене продажи',
      field: 'returned_retail_price',
      hide: true,
    },
    {
      headerName: 'Возвраты по цене со скидкой',
      field: 'returned_discount_price',
      hide: true,
    },
    {
      headerName: 'Возвраты по цене закупки',
      field: 'returned_supply_price',
      hide: true,
    },
    {
      headerName: 'Чистая выручка',
      field: 'net_gross_sales',
      hide: false,
    },
    {
      headerName: 'План по чистой выручке',
      field: 'target',
      hide: false,
    },
    {
      headerName: '% плана по чистой выручке',
      field: 'target_percent',
      hide: false,
    },
    {
      headerName: 'Продажи по цене закупки',
      field: 'sales_supply_price',
      hide: true,
    },
    {
      headerName: 'Валовая прибыль',
      field: 'gross_profit',
      hide: false,
    },
    {
      headerName: 'Средняя наценка',
      field: 'average_extra_charge',
      hide: true,
    },
    {
      headerName: 'Кол-во проданных товаров',
      field: 'products_sold',
      hide: true,
    },
    // {
    //   headerName: 'Импорт, кол-во',
    //   field: 'imported_measurement_value',
    //   hide: true,
    // },
    // {
    //   headerName: 'Импорт по цене продажи',
    //   field: 'imported_retail_price',
    //   hide: true,
    // },
    // {
    //   headerName: 'Импорт по цене закупки',
    //   field: 'imported_supply_price',
    //   hide: true,
    // },
    {
      headerName: 'Общее кол-во транзакций',
      field: 'transactions_count',
      hide: true,
    },
    {
      headerName: 'Количество транзакций продаж',
      field: 'orders_count',
      hide: true,
    },
    {
      headerName: 'Количество транзакций возвратов',
      field: 'returns_count',
      hide: true,
    },
    {
      headerName: 'Количество транзакций обменов',
      field: 'exchanges_count',
      hide: true,
    },
    {
      headerName: 'Среднее кол-во товаров в чеке',
      field: 'average_measurement_value',
      hide: true,
    },
    {
      headerName: 'Средний чек',
      field: 'average_cheque',
      hide: true,
    },
    {
      headerName: 'Средняя цена товара в чеке',
      field: 'average_price',
      hide: true,
    },
    {
      headerName: 'Продажи на квадратный метр',
      field: 'sales_per_square',
      hide: true,
    },
  ],
};

export default shopReportTableHeaders;
