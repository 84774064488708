/* eslint-disable */
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  circle: {
    fill: theme.palette.gray[100],
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      fill: theme.palette.gray[101],
    },
  },
}));

const ArrowBackIcon = props => {
  const classes = useStyles();
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="24" cy="24" r="24" className={classes.circle} />
      <path
        d="M19.5703 23.6875C19.2539 24.0039 19.2539 24.5312 19.5703 24.8477L26.3906 31.7031C26.7422 32.0195 27.2695 32.0195 27.5859 31.7031L28.3945 30.8945C28.7109 30.5781 28.7109 30.0508 28.3945 29.6992L22.9805 24.25L28.3945 18.8359C28.7109 18.4844 28.7109 17.957 28.3945 17.6406L27.5859 16.832C27.2695 16.5156 26.7422 16.5156 26.3906 16.832L19.5703 23.6875Z"
        fill="#4993DD"
      />
    </svg>
  );
};
export default ArrowBackIcon;
