import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.gray[100],
    marginBottom: 16,
    borderRadius: 32,
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.gray[101],

      '& $actions': {
        color: theme.palette.blue[500],
      },
      '& $arrowIcon': {
        fill: theme.palette.blue[500],
      },
    },
  },
  icon: {
    width: 56,
    height: 56,
    borderRadius: 16,
    marginRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  block: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',

    color: theme.palette.gray[400],
  },
  arrowIcon: {
    marginLeft: 8,
    color: theme.palette.gray[400],
  },
  mb8: {
    marginBottom: 8,
  },
}));
