/* eslint-disable */
const HidePasswordIcon = props => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 12.5C7.625 12.5 5.6875 10.6875 5.53125 8.34375L2.25 5.8125C1.8125 6.375 1.40625 6.9375 1.09375 7.5625C1.03125 7.6875 1 7.84375 1 8C1 8.1875 1.03125 8.34375 1.09375 8.46875C2.78125 11.7812 6.15625 14 10 14C10.8125 14 11.625 13.875 12.4062 13.6875L10.8125 12.4375C10.5312 12.5 10.25 12.5 10 12.5ZM19.7812 14.3438L16.3438 11.6562C17.375 10.7812 18.25 9.6875 18.875 8.46875C18.9375 8.34375 18.9688 8.1875 18.9688 8.03125C18.9688 7.84375 18.9375 7.6875 18.875 7.5625C17.1875 4.25 13.8125 2 10 2C8.3125 2.03125 6.75 2.4375 5.375 3.1875L1.40625 0.125C1.3125 0.0625 1.21875 0.03125 1.09375 0.03125C0.9375 0.03125 0.78125 0.09375 0.71875 0.21875L0.09375 1C0.03125 1.09375 0 1.1875 0 1.3125C0 1.46875 0.0625 1.59375 0.1875 1.6875L18.5625 15.9062C18.6562 15.9688 18.75 16 18.875 16C19.0312 16 19.1875 15.9375 19.2812 15.8125L19.875 15.0312C19.9375 14.9375 19.9688 14.8438 19.9688 14.7188C19.9688 14.5625 19.9062 14.4375 19.7812 14.3438ZM14.0625 9.90625L12.8125 8.9375C12.9375 8.65625 12.9688 8.34375 13 8C13 8 13 8 13 7.96875C13 6.34375 11.6562 5 10.0312 5C9.75 5 9.46875 5.0625 9.1875 5.125C9.375 5.375 9.46875 5.6875 9.5 6C9.46875 6.125 9.46875 6.21875 9.4375 6.3125L7.125 4.5625C7.90625 3.90625 8.90625 3.53125 10 3.5C12.4688 3.5 14.5 5.53125 14.5 8C14.5 8.6875 14.3125 9.3125 14.0625 9.90625Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default HidePasswordIcon;
