/* eslint-disable */
import guideData from 'shared/constants/guideData';
import { guideActionTypes } from '../actions/guideActions/guideActionTypes';

const initialState = {
  run: false,
  guideSlug: null,
  completed: [],
  started: false,
  step: 0,
  size: 0,
};
const guideReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case guideActionTypes.SET_GUIDE_STEP:
      return {
        ...state,
        step: payload,
      };
    case guideActionTypes.SET_GUIDE_SLUG:
      return {
        ...state,
        guideSlug: payload,
        size: guideData?.[payload]?.length,
      };
    case guideActionTypes.SET_GUIDE_RUN:
      return {
        ...state,
        run: true,
      };
    case guideActionTypes.SET_GUIDE_START:
      return {
        ...state,
        started: true,
      };
    case guideActionTypes.SET_GUIDE_STOP:
      return {
        ...state,
        run: false,
      };
    case guideActionTypes.SET_GUIDE_COMPLETED:
      return {
        guideSlug: null,
        run: false,
        size: 0,
        step: 0,
        started: false,
        completed:
          typeof payload === 'string'
            ? state.completed?.includes(payload)
              ? state.completed
              : [...state.completed, payload]
            : [...state.completed, ...payload],
      };
    default:
      return state;
  }
};

export default guideReducer;
