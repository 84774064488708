/* eslint-disable */
import * as qs from 'qs';
import { request } from '../axios';

const cashBox = {
  getAll: filter => request.get(`v1/cash-box${qs.stringify(filter, { addQueryPrefix: true })}`),
  getAllWithHQ: filter => request.get(`v1/account-asset${qs.stringify(filter, { addQueryPrefix: true })}`),

  getCashBoxShiftsAll: filter => request.get(`v1/cashbox-shifts${qs.stringify(filter, { addQueryPrefix: true })}`),
  getCashBoxShiftsByCashbox: filter => request.get(`v1/cashbox-shift-list${qs.stringify(filter, { addQueryPrefix: true })}`),
  getCashBoxShiftsHistoryAll: filter => request.get(`v1/cashbox-shift${qs.stringify(filter, { addQueryPrefix: true })}`),
  getCashBoxShiftsHistorySingle: id => request.get(`v1/cashbox-shift/${id}`),

  getCashBoxShiftsStatistics: filter =>
    request.get(
      `v1/cashbox-shifts/statistics${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getCashBoxOperationsStatistics: filter =>
    request.get(
      `v1/cashbox-operation/statistics${qs.stringify(filter, {
        addQueryPrefix: true,
      })}`,
    ),
  getCashBoxShifts: id => request.get(`v1/cashbox/${id}/shift`),
  getCashBoxBalancePaymentTypes: id => request.get(`v1/cashbox/${id}/balance/payment_types`),
  getCashBoxBalanceCategories: id => request.get(`v1/cashbox/${id}/balance/categories`),

  create: data => request.post(`v1/cash-box`, data),
  getSingle: id => request.get(`v1/cash-box/${id}`),
  update: data => request.put(`v1/cash-box/${data.id}`, data.data),
  delete: id => request.delete(`v1/cash-box/${id}`),
  getCashBoxShiftStatus: params => request.get(`v1/shift-status`, { params }),
  getOfflineDevices: id => request.get(`v1/cashbox-history/${id}/device`),
};

export default cashBox;
