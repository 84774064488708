import { useTranslation } from 'react-i18next';
import Button from 'stories/Button/Button';
import DownloadIcon from 'shared/icons/DownloadIcon';

type DownloadButtonProps = {
  isDownloading: boolean;
  onDownload: () => void;
};

const DownloadButton = ({ isDownloading, onDownload }: DownloadButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="outlined"
      size="small"
      id="download-button"
      startIcon={<DownloadIcon />}
      fullWidth
      isLoading={isDownloading}
      onClick={onDownload}
    >
      {t('table_columns.download')}
    </Button>
  );
};

export default DownloadButton;
