/* eslint-disable */
import { Navigate } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';

import { LoginPage, RedirectPage, NotFoundPage, NewRegisterPage } from 'pages';

import settingsRoutes from './settings';
import dashboardRoutes from './dashboard';
import productsRoutes from './products';
import ordersRoutes from './orders';
import clientsRoutes from './clients';
import marketingRoutes from './marketing';
import managementRoutes from './management';
import adminRoutes from './admin';
import reportsRoutes from './reports';
import financeRoutes from './finance';
import wclRoutes from './wcl';
import crmRoutes from './clients/crmRoutes';
import presentationRoutes from './presentation/index';

const routes = [
  {
    path: '',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/dashboard" />,
      },
      {
        path: 'redirect',
        element: <RedirectPage />,
      },
      {
        path: '404',
        element: <NotFoundPage />,
      },
      {
        path: '*',
        element: <Navigate to="/login" />,
      },
    ],
  },
  { ...dashboardRoutes },
  { ...settingsRoutes },
  { ...productsRoutes },
  { ...ordersRoutes },
  { ...clientsRoutes },
  { ...marketingRoutes },
  { ...managementRoutes },
  { ...adminRoutes },
  { ...reportsRoutes },
  { ...financeRoutes },
  { ...wclRoutes },
  { ...crmRoutes },
  { ...presentationRoutes },
  {
    path: 'register',
    element: <NewRegisterPage />,
  },
  {
    path: 'login',
    element: <LoginPage />,
  },
];
export default routes;
