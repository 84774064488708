import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },

  title: {
    marginBottom: 12,
  },
  description: {
    display: 'block',
    marginBottom: 32,
  },
  button: {
    display: 'inline-block',
    width: 'fit-content',
  },
}));
