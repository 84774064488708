import React from 'react';
import { useStyles } from './EditUserDrawer.styles';
import { Button, Input, SectionHeader, Drawer, Divider } from '../../../shared/ui';
import { useUpdateUserMutation } from '@/shared/hooks/user';
import { useTranslation } from 'react-i18next';
import { UserServiceNS } from '@/shared/services';

type EditUserDrawerProps = {
  user: UserServiceNS.UserDTO;
  visible: boolean;
  onClose: () => void;
};
const EditUserDrawer = ({ user, visible, onClose }: EditUserDrawerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const nameRef = React.useRef('');

  const { mutate: updateUser, isSuccess: isUpdateSuccess, reset: resetUpdateUser, isLoading } = useUpdateUserMutation();

  const isSuccess = isUpdateSuccess;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    nameRef.current = event.target.value;
  };

  const handleCreate = () => {
    if (user && nameRef.current) {
      updateUser({ ...user, first_name: nameRef.current });
      return;
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      onClose();
      resetUpdateUser();
    }
  }, [isSuccess, onClose, resetUpdateUser]);

  return (
    <Drawer open={visible} onClose={onClose} anchor="right" elevation={1} className={classes.root}>
      <div className={classes.content}>
        <div>
          <SectionHeader className={classes.header} title={t('integrations.edit_key')} onBackClick={onClose} />

          <Divider marginVertical="xl" />

          <Input
            defaultValue={user.first_name}
            className={classes.input}
            fullWidth
            placeholder={t('placeholders.enter_name')}
            label={t('integrations.key_name')}
            onChange={handleNameChange}
            autoFocus
          />
        </div>

        <Button disabled={isLoading} onClick={handleCreate}>
          {t('buttons.edit')}
        </Button>
      </div>
    </Drawer>
  );
};

export default EditUserDrawer;
