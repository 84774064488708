/* eslint-disable */
import { CustomButton } from 'layouts/Navbar/NeedHelpButtonNew/CustomButton';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'stories/Button/Button';
import { useStyles } from './useStyles';
import { LifeRingIcon } from './LifeRingIcon';
import { NeedHelpPopover } from './Popover';

interface INeedHelpButtonNewProps {
  isOpen?: boolean;
  isAbsolute?: boolean;
}

export const NeedHelpButtonNew = ({ isOpen, isAbsolute }: INeedHelpButtonNewProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    window.chaport.q('close');
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | string) => {
    if (anchorEl !== null) {
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'needhelp_popover' : 'undefined';

  useEffect(() => {
    if (!!window.chaport.open) {
      window.chaport.on('stateChange', function () {
        window.chaport.setVisitorData({
          chat: {
            startPage: window.location.href,
            currentPage: window.location.href,
          },
        });
        window.chaport.newPageView({
          url: window.location.href,
          title: window.location.href,
        });
      });

      window.chaport.on('widget.stateChange', function () {
        const state = window.chaport.getWidgetState();
        if (!anchorEl) {
          if (state === 'unreadMessage') {
            setAnchorEl('any');
          }
          if (state === 'collapsed') {
            setAnchorEl(document.getElementById('need-help-button'));
          }
        }
      });
    }
  }, []);

  return (
    <>
      {isAbsolute ? (
        createPortal(
          <Button
            className={`${classes.buttonMini} ${isAbsolute ? classes.absoluteButton : ''}`}
            onClick={handleClick}
            id="need-help-button"
          >
            {open ? <FontAwesomeIcon icon={faXmark} /> : <LifeRingIcon />}
          </Button>,
          document.body,
        )
      ) : (
        <CustomButton id="need-help-button" isOpen={isOpen} classes={classes} open={open} handleClick={handleClick} />
      )}
      <NeedHelpPopover
        id={id}
        anchorEl={anchorEl}
        open={open}
        handleClick={handleClick}
        handleClose={handleClose}
        isMini={!isOpen}
        isAbsolute={isAbsolute}
      />
    </>
  );
};
