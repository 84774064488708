/* eslint-disable */
const QuestionIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 8C15.75 3.75 12.25 0.25 8 0.25C3.71875 0.25 0.25 3.75 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.25 15.75 15.75 12.2812 15.75 8ZM8.1875 2.8125C9.96875 2.8125 11.8438 4.1875 11.8438 6C11.8438 8.4375 9.25 8.46875 9.25 9.34375V9.375C9.25 9.59375 9.0625 9.75 8.875 9.75H7.125C6.90625 9.75 6.75 9.59375 6.75 9.375V9.25C6.75 7.96875 7.71875 7.46875 8.4375 7.0625C9.0625 6.71875 9.46875 6.46875 9.46875 6C9.46875 5.375 8.65625 4.96875 8.03125 4.96875C7.1875 4.96875 6.8125 5.375 6.25 6.09375C6.125 6.25 5.875 6.28125 5.71875 6.15625L4.625 5.34375C4.46875 5.21875 4.4375 5 4.5625 4.8125C5.40625 3.53125 6.5 2.8125 8.1875 2.8125ZM8 10.5625C8.78125 10.5625 9.4375 11.2188 9.4375 12C9.4375 12.8125 8.78125 13.4375 8 13.4375C7.1875 13.4375 6.5625 12.8125 6.5625 12C6.5625 11.2188 7.1875 10.5625 8 10.5625Z"
      fill="#BDBDBD"
    />
  </svg>
);

export default QuestionIcon;
